import API_CFA from '../../../api/cfa'
import API_TALLY from '../../../api/tally'
import API_REVENUE from "../../../api/rev";

export default {

  namespaced: true,

  state: {

    requests: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    myLoader: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    searchParameter: '',

    pageNo: 0,
    pageSize: 15,

    startTime: '',
    endTime: '',
    name: '',
    shiftId: '',
    cargoType: "",
    talliedDocumentId: '',
    payloadDate: '',
    reportName: '',


    billType: "",
    billStatus: "",
    totalElements: "",
    totalPages: "",
    pageNumber: "",
    isFirst: false,
    isLast: false,
    params: '',
    hasSearchedVariable: false

  },

  mutations: {

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMS: (state, params) => state.params = params,

    TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

    TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

    PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

    IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

    IS_LAST: (state, isLast) => state.isLast = isLast,

    BILL_TYPE: (state, billType) => state.billType = billType,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    SHIFT_ID: (state, shiftId) => state.shiftId = shiftId,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    TALLIED_DOC_ID: (state, talliedDocumentId) => state.talliedDocumentId = talliedDocumentId,

    BILL_STATUS: (state, billStatus) => state.billStatus = billStatus,

    CARGO_TYPE: (state, cargoType) => state.cargoType = cargoType,

    REPORT_NAME: (state, reportName) => state.reportName = reportName,

    PAYLOAD_DATE: (state, payloadDate) => state.payloadDate = payloadDate,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    START_TIME: (state, startTime) => state.startTime = startTime,

    END_TIME: (state, endTime) => state.endTime = endTime,

    SHIFT_NAME: (state, name) => state.name = name,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert

  },

  actions: {

    hasSearchngParams: ({state, commit}, payload) => commit('HAS_SEARCH_VARIABLE', payload),

    paramsSearching: ({state, commit}, payload) => commit('SEARCH_PARAMS', payload),

    pageNumbering: ({state, commit}, payload) => commit('PAGE_NO', payload),

    requestlisting: ({state, commit}, payload) => commit('REQUEST_LIST', payload),

    pageSizing: ({state, commit}, payload) => commit('PAGE_SIZE', payload),

    billtyping: ({state, commit}, payload) => commit('BILL_TYPE', payload),

    starttiming: ({state, commit}, payload) => commit('START_TIME', payload),

    endtiming: ({state, commit}, payload) => commit('END_TIME', payload),

    reportnaming: ({state, commit}, payload) => commit('REPORT_NAME', payload),

    tallieddocumentiding: ({state, commit}, payload) => commit('TALLIED_DOC_ID', payload),

    billstatus: ({state, commit}, payload) => commit('BILL_STATUS', payload),

    cargotyping: ({state, commit}, payload) => commit('CARGO_TYPE', payload),

    patloaddating: ({state, commit}, payload) => commit('PAYLOAD_DATE', payload),

    shiftnaming: ({state, commit}, payload) => commit('SHIFT_NAME', payload),

    shiftiding: ({state, commit}, payload) => commit('SHIFT_ID', payload),

    successalerting: ({state, commit}, payload) => commit('SUCCESS_ALERT', payload),

    erroralerting: ({state, commit}, payload) => commit('ERROR_ALERT', payload),

    getAllUnbilledTallySheetCharges({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        API_TALLY.getTallySheetDocumentCharges(getters.getPageSize, getters.getPageNo).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

    getAllUnbilledTallySheetChargesAfterCreated({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true);
        commit('REQUEST_LIST', []);

        API_CFA.getTallySheetDocumentCharges(getters.getPageSize, getters.getPageNo).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('MY_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('MY_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e)
        })
      })
    },

    generateBillForTalliedChargesAwaitingBilling({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true);

        API_REVENUE.generateBillForTalliedCharges(getters.getTalliedDocumentId, getters.getOperationType).then(
          response => {

            if (response.data.status) {

              commit('MY_LOADER', false)
              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false);

            } else {

              commit('MY_LOADER', false)
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e)
        })
      })
    },

    getAllUnbilledCFAChargeDetails({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        TALLYAPI.getUnbilledTallyChargeDetails(getters.getTalliedDocumentId).then(
          response => {

            if (response.data.status) {

              if (response.data.data.length > 0) {

                commit('REQUEST_LIST', response.data.data);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

    generateBillForTalliedChargesAwaitingBilling({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true);

        API_REVENUE.generateBillForTalliedCharges(getters.getTalliedDocumentId).then(
          response => {

            if (response.data.status) {

              commit('ERROR_MESSAGE', '');
              commit('SUCCESS_MESSAGE', ', Bill has been generated');
              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false);
              commit('MY_LOADER', false);


            } else {

              commit('ERROR_MESSAGE', ', Bill is not generated, please regenerate or contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('MY_LOADER', false);

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);
          reject(e)
        })
      })
    },

    loadCfaBillsAfterCreated({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)
        commit('REQUEST_LIST', [])
        commit('HAS_SEARCH_VARIABLE', true)

        API_CFA.searchGeneralCfaCharges(getters.getSearchParams).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('MY_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('MY_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not found, please contact system administrator')
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e)
        })
      })
    },

    /* SEARCH */

    searchWithGeneralChargeParams({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])
        commit('HAS_SEARCH_VARIABLE', true)

        API_CFA.searchGeneralCfaCharges(getters.getSearchParams).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not found, please contact system administrator')
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

    /* BILLING */

    getAllTallyBillsOnCreated({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        API_REVENUE.getTallyBillsForApproval(getters.getBillType, getters.getBillStatus, getters.getPageSize, getters.getPageNo).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

    getAllTallyBillsAfterCreated({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)
        commit('REQUEST_LIST', [])

        API_REVENUE.getTallyBillsForApproval(getters.getBillType, getters.getBillStatus, getters.getPageSize, getters.getPageNo).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('MY_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('MY_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e)
        })
      })
    },

    approveTallyBill({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        API_REVENUE.getTallyBillsForApproval(getters.getTalliedDocumentId).then(
          response => {

            if (response.data.status) {

              if (response.data.data.length > 0) {

                commit('REQUEST_LIST', response.data.data);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    }

  },

  getters: {

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParams: state => state.params,

    getRequestList: state => state.requests,

    getTalliedDocumentId: state => state.talliedDocumentId,

    getPayloadDate: state => state.payloadDate,

    getCargoType: state => state.cargoType,

    getReportName: state => state.reportName,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getShiftName: state => state.name,

    getShiftId: state => state.shiftId,

    getStartTime: state => state.startTime,

    getEndTime: state => state.endTime,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getMyLoader: state => state.myLoader,

    getBillType: state => state.billType,

    getTotalElement: state => state.totalElements,

    getTotalPage: state => state.totalPages,

    getPageNumber: state => state.pageNumber,

    getIsFirst: state => state.isFirst,

    getIsLast: state => state.isLast,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getBillStatus: state => state.billStatus,

  }

}
