const baseUrl = '/shipping-tallying';
const routes = [
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "DashboardLayout" */
        '../layout/DashboardLayout.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'DASHBOARD_VIEW'
    },
    children: [
      {
        path: '/overview/ship-talling',
        name: 'OverviewTally',
        component: () => import(/* webpackChunkName: "OverviewTally" */
          '../pages/Overview'),
        alias: '/',
        meta: {
          requiresAuth: true,
          permission: 'ST_DASHBOARD_VIEW'
        }
      },

      {
        path: baseUrl + '/configuration/shift',
        name: 'shiftConfiguration',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/configuration/tally/ShiftConfiguration'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-OPERATION-SHIFTS_POST'
        }
      },
      {
        path: baseUrl + '/tally-shifts-list',
        name: 'shiftView',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/shift/ShiftsView'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-OPERATION-SHIFTS_POST'
        }
      },
      {
        path: baseUrl + '/shift/clerk',
        name: 'shiftClerk',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/clerk/shiftClerk'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-OPERATION-SHIFTS_POST'
        }
      },

      {
        path: baseUrl + '/assignments/clerk',
        name: 'tallyAssignments',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/assignment/TallyClerkAssignment'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-OPERATION-SHIFTS_POST'
        }
      },
      {
        path: baseUrl + '/config/tally/activity',
        name: 'tallyActivity',
        component: () => import(/* webpackChunkName: "tallyConfig" */
          '../pages/configuration/tally/activities'),
        meta: {
          requiresAuth: true,
          permission: 'SYSTEM_CONFIG'
        }
      },

      {
        path: baseUrl + '/config/tally/tariff',
        name: 'tallyConfTariff',
        component: () => import(/* webpackChunkName: "tallyConfig" */
          '../pages/configuration/tally/tallyTariffConf'),
        meta: {
          requiresAuth: true,
          permission: 'SYSTEM_CONFIG'
        }
      },

      {
        path: baseUrl + '/shift/clerks-in-shift-list/:shiftId',
        name: 'clerkinshift',
        component: () => import(/* webpackChunkName: "clerkinshift" */
          '../pages/shippingTallying/shift/clerkInShifts'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-CLERK-SHIFTS_GET-USERS-IN-SHIFT_{SHIFTID}_GET'
        }
      },
      {
        path: baseUrl + '/resource/edit/:resourceId',
        name: 'resourceEdit',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/resource/editResource'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-RESOURCES_PUT'
        }
      },
      {
        path: baseUrl + '/resource/create',
        name: 'resourceCreate',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/resource/resource'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-RESOURCES_POST'
        }
      },
      {
        path: baseUrl + '/resource/clerk',
        name: 'resourceClerk',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/resource/resourceClerk'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-CLERK-DEVICES_POST'
        }
      },
      {
        path: baseUrl + '/clerk/resource/all/',
        name: 'getresourceClerk',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/resource/resourceClerkView'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-CLERK-DEVICES_GET'
        }
      },

      {
        path: baseUrl + '/assignments/:notificationId',
        name: 'Assignments',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/assignment/AssignmentsView'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-CLERK-ASSIGNMENTS_BY-NOTIFICATION_{NOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/shift/callId/assignment/:shiftId/:day/:notificationId',
        name: 'clerkAssignedDayShift',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/assignment/ClerkAsignedView'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-CLERK-ASSIGNMENTS_USERS_{SHIFTID}_{DAY}_{NOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/clerk/assignment/:notificationId',
        name: 'clerkAssignment',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/operation/TallyingOperation'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-CLERK-ASSIGNMENTS_AUTO_ASSIGNMENTS_POST'
        }
      },

      {
        path: baseUrl + '/notification/',
        name: 'tallyingNotification',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyNotification/Notification'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_POST'
        }
      },
      // {
      //   path: baseUrl + '/view/notification',
      //   name: 'tallyingNotificationView',
      //   component: () => import(/* webpackChunkName: "importApprovedDocument" */
      //     '../pages/shippingTallying/tallyNotification/NotificationView'),
      //   meta: {
      //     requiresAuth: true,
      //     permission: 'API_TALLY-NOTIFICATIONS_ALL_PAGEABLE_GET'
      //   }
      // },

      {
        path: baseUrl + '/view/notification-details/:documentId',
        name: 'tallyingNotificationViewDetails',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyNotification/NotificationDetails'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },

      // New routes

      {
        path: baseUrl + '/view/notification/loading',
        name: 'loadingNotification',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyNotification/NotificationView'),
        props: {selectedTallyType: 'LOADING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/tally-sheet/loading',
        name: 'loadingTally',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyDocument/tallySheetList'),
        props: {selectedTallyType: 'LOADING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/tally-reports/loading',
        name: 'loadingTallyReports',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyDocument/tallyReports'),
        props: {selectedTallyType: 'LOADING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/tally-charges/loading',
        name: 'loadingTallyCharges',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/subComponents/tallyCharges'),
        props: {selectedTallyType: 'LOADING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/notification/discharging',
        name: 'dischargeNotification',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyNotification/NotificationView'),
        props: {selectedTallyType: 'DISCHARGING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/tally-sheet/discharging',
        name: 'dischargeTally',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyDocument/tallySheetList'),
        props: {selectedTallyType: 'DISCHARGING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/tally-reports/discharging',
        name: 'dischargeTallyReports',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyDocument/tallyReports'),
        props: {selectedTallyType: 'DISCHARGING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/tally-charges/discharging',
        name: 'dischargeTallyCharges',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/subComponents/tallyCharges'),
        props: {selectedTallyType: 'DISCHARGING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/notification/stuffing',
        name: 'stuffingNotification',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyNotification/NotificationView'),
        props: {selectedTallyType: 'STUFFING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/tally-sheet/stuffing',
        name: 'stuffingTally',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyDocument/tallySheetList'),
        props: {selectedTallyType: 'STUFFING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/tally-reports/stuffing',
        name: 'stuffingTallyReports',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyDocument/tallyReports'),
        props: {selectedTallyType: 'STUFFING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/tally-charges/stuffing',
        name: 'stuffingTallyCharges',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/subComponents/tallyCharges'),
        props: {selectedTallyType: 'STUFFING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/notification/de-stuffing',
        name: 'de-stuffingNotification',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyNotification/NotificationView'),
        props: {selectedTallyType: 'DESTUFFING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/tally-sheet/de-stuffing',
        name: 'de-stuffingTally',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyDocument/tallySheetList'),
        props: {selectedTallyType: 'DESTUFFING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/tally-reports/de-stuffing',
        name: 'de-stuffingTally',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/tallyDocument/tallyReports'),
        props: {selectedTallyType: 'DESTUFFING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + '/view/tally-charges/de-stuffing',
        name: 'de-stuffingTallyCharges',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/subComponents/tallyCharges'),
        props: {selectedTallyType: 'DESTUFFING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },
      {
        path: baseUrl + "/tally-charge-detail/:chargeId",
        name: 'tallyChargeDetail',
        component: () => import(/* webpackChunkName: "importApprovedDocument" */
          '../pages/shippingTallying/subComponents/tallyChargeDetail'),
        // props: {selectedTallyType: 'DESTUFFING'},
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-NOTIFICATIONS_{TALLYNOTIFICATIONID}_GET'
        }
      },


      // {
      //   path: baseUrl + '/edit/notification/:notificationId',
      //   name: 'tallyingNotificationEdit',
      //   component: () => import(/* webpackChunkName: "importApprovedDocument" */
      //     '../pages/shippingTallying/tallyNotification/editNotification'),
      //   meta: {
      //     requiresAuth: true,
      //     permission: 'API_TALLY-NOTIFICATIONS_ALL_PAGEABLE_GET'
      //   }
      // },


      // {
      //   path: baseUrl + '/document-view/:documentId',
      //   name: 'ShippingTallyingApplicationPage',
      //   component: () => import(/* webpackChunkName: "ShippingTallyingApplicationPage" */
      //     '../pages/shippingTallying/subComponents/application'),
      //   meta: {
      //     requiresAuth: true
      //   }
      // },

      {
        path: baseUrl + '/tally-sheet-details/:documentId',
        name: 'ShippingTallyingSheetDetails',
        component: () => import(/* webpackChunkName: "ShippingTallyingSheetDetails" */
          '../pages/shippingTallying/subComponents/tallySheetDetails'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: baseUrl + '/tally-sheet-bln-details/:cargoType/:documentId',
        name: 'ShippingTallyingSheetBLNDetails',
        component: () => import(/* webpackChunkName: "ShippingTallyingSheetBLNDetails" */
          '../pages/shippingTallying/subComponents/blnDetails'),
        meta: {
          requiresAuth: true,
          permission: 'ST_DASHBOARD_VIEW'
        }
      },
      {
        path: baseUrl + '/tallying-sheet',
        name: 'shippingTallyingSheet',
        component: () => import(/* webpackChunkName: "shippingTallyingSheet" */
          '../pages/report/shippingTallying/tallySheet'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET'
        }
      },
      {
        path: baseUrl + '/export/approved-loading-list',
        name: 'exportApprovedLoadingList',
        component: () =>
          import(
            /* webpackChunkName: "approvedLoadingList" */
            '../pages/shippingTallying/export/approvedLoadingList'),
        meta: {
          requiresAuth: true
        }
      },
      // {
      //   path: baseUrl + '/export/stuffed-containers',
      //   name: 'destuffing',
      //   component: () => import(/* webpackChunkName: "destuffing" */
      //     '../pages/shippingTallying/export/destaffing'),
      //   meta: {
      //     requiresAuth: true
      //   }
      // // },
      // {
      //   path: baseUrl + '/import/destuffed-containers',
      //   name: 'stuffing',
      //   component: () => import(/* webpackChunkName: "stuffing" */
      //     '../pages/shippingTallying/import/staffing'),
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      {
        path: baseUrl + '/shipping-tallying/import/approved-discharged-lists',
        name: 'importApprovedDischargeList',
        component: () => import(/* webpackChunkName: "approvedDischargeList" */
          '../pages/shippingTallying/import/approvedDischargeList'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: baseUrl + '/stuffing/tallied-containers/:StuffingOrStrippingId',
        name: 'stuffedContainers',
        component: () => import(/* webpackChunkName: "stuffedContainers" */
          '../pages/shippingTallying/subComponents/talliedContainer'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET'
        }
      },
      {
        path: baseUrl + '/export/loading-tally-sheets',
        name: 'loadingTally',
        component: () => import(/* webpackChunkName: "loadingTally" */
          '../pages/shippingTallying/export/loadingTally'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET'
        }
      },
      {
        path: baseUrl + '/export/stuffing-tally-sheets',
        name: 'stuffingTally',
        component: () => import(/* webpackChunkName: "stuffingTally" */
          '../pages/shippingTallying/export/stuffingTally'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET'
        }
      },


      {
        path: baseUrl + '/import/discharge-tally-sheets',
        name: 'dischargeTally',
        component: () => import(/* webpackChunkName: "dischargeTally" */
          '../pages/shippingTallying/import/dischargeTally'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET'
        }
      },
      {
        path: baseUrl + '/import/destuffing-tally-sheets',
        name: 'destuffingTally',
        component: () => import(/* webpackChunkName: "destuffingTally" */
          '../pages/shippingTallying/import/destuffingTally'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET'
        }
      },
      {
        path: baseUrl + '/shipping-tallying/import/approved-manifests',
        name: 'importApprovedManifest',
        component: () => import(/* webpackChunkName: "approvedManifest" */
          '../pages/shippingTallying/import/approvedManifest'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: baseUrl + '/shipping-tally-operations-reports/:stuffingType/:documentId',
        name: 'operationReport',
        component: () => import(/* webpackChunkName: "operationReport" */
          '../pages/report/shippingTallying/tallying'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLIED-DE-STUFFING_GET-BY-ID_{TALLIEDDESTUFFINGID}_GET'
        }
      },

    ]
  }
];

export default routes;
