const routes = [

  {
    path: '/',
    name: 'loginPage',
    component: () => import(/* webpackChunkName: "AuthLayout" */
      '../layout/AuthLayout')
  },

  {
    path: '/login',
    name: 'login',
    alias: '/',
    component: () => import(/* webpackChunkName: "AuthLayout" */
      '../layout/AuthLayout')
  },
  {
    path: '/request/reset-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "ForgotPassword" */
      '../pages/ForgotPassword')
  },
  {
    path: '/reset-password',
    props: (route) => ({ query: route.query.token }),
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "ResetPassword" */
      '../pages/ResetPassword')
  },
  {
    path: '/reset-one-time-password',
    props: (route) => ({ query: route.query.token }),
    name: 'VerifyAccount',
    component: () => import(/* webpackChunkName: "VerifyAccount" */
      '../pages/VerifyAccount')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "Logout" */
      '../pages/Logout.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logout-on-session-expire',
    name: 'LogoutOnSessionExpire',
    component: () => import(/* webpackChunkName: "LogoutOnSessionExpire" */
      '../pages/LogoutOnSessionExpire'),
    meta: {
      requiresAuth: true
    }
  }
]

export default routes
