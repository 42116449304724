/* import axios */
import axios from 'axios';
import {CFA_SERVER_URL} from "../api/settings";
/* base url for clearing and forwarding microservice server */

/* axios default headers for the below initiated instances */
axios.defaults.headers['access-control-allow-Origin'] = "*";
axios.defaults.headers['Access-Control-Allow-Methods'] = 'POST, PUT, GET, OPTIONS, DELETE';
axios.defaults.headers['Access-Control-Allow-Credentials'] = true;
axios.defaults.headers['Cache-Control'] = 'no-cache';
axios.defaults.timeout = 120 * 1000; //  120 seconds

/* axios instance for clearing and forwarding microservice server */
const HTTP_CFA = axios.create({

  'X-Requested-With': 'XMLHttpRequest',

  baseURL: CFA_SERVER_URL

});


export default {

  handle401 (success, error) {

    return  HTTP_CFA.interceptors.response.use(success, error);

  },

  authenticate (token) {

    return HTTP_CFA.defaults.headers.common.Authorization = 'Bearer ' + token

  },

  isAuthenticated (token) {

    return HTTP_CFA.defaults.headers.common.Authorization = 'Bearer ' + token
  },

  // getApplicationsCharges (size, number) {
  //
  //   return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/new/pageable/charged-requests/?size=' + size + '&page=' + number);
  //
  // },

getApplicationsCharges (size, number) {

  return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/search/charged/requests?size=' + size + '&page=' + number);

},

  searchGeneralCfaCharges(params) {

    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/search/charged/requests' + params)
    
  },

  getRequestCharges (requestId) {

    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/new/charged-request/' + requestId)
    
  },

  getTotalCfaApplicationForDashboard () {

    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/dashboard')
    
  },

  // applicationStatusToSubmit (requestId, status) {

  //   return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/request/update-status/' + requestId + '?status=' + status)
    
  // },

  getAdditionalFieldFromTeSWS (requestId) {

    return HTTP_CFA.get(CFA_SERVER_URL + '/cargoes/populate/' + requestId)

  },

  checkIfBlExists (data) {

    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/customer/request-check-blnumber/',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  internalCheckIfBlExists (data) {

    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/request-check-blnumber/',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  applicationStatusToSubmit (data) {

    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/request/submit',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  filterCfaApplicationsWithMultipleParameters (data, size, page) {

    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/list/search/requests?size=' + size + '&page=' + page,

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  filterCfaApplicationsReportWithMultipleParameters (data) {

    return HTTP_CFA.post(CFA_SERVER_URL + '/cfareport/list/async/report',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  applicationActivityAssignmentReport (data) {

    return HTTP_CFA.post(CFA_SERVER_URL + '/cfareport/assignment/async/report',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  customerNewApplication (data) {

    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/customer/request',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  defaultcustomerRegistration (data) {

    return HTTP_CFA.post(CFA_SERVER_URL + '/customers/add/custom',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  TASACNewApplication (data) {

    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/request',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  getFileFromMinio (fileName) {

    return HTTP_CFA.get(CFA_SERVER_URL + '/files/' + fileName,
    {
      headers: {

        'Content-Type': 'application/octet-stream'
        
      },
      responseType: 'blob'
    })
    
  },
  
  updateApplicationStatus (requestId, status) {

    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/request/update-status?requestId=' + requestId + '&status=' + status,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  markAttachmentAsRead (requestAttachmentId) {

    return HTTP_CFA.get(CFA_SERVER_URL + '/request-attachments/open/' + requestAttachmentId)
    
  },

  verifyAllApplicationAttachments (requestId) {

    return HTTP_CFA.put(CFA_SERVER_URL + '/clearingforwarding/verify-attachments/all?requestId=' + requestId)
    
  },

};
