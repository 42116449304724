import API from '../../../api/index'

export default {

  namespaced: true,

  state: {

    valuesBeforeEdited: [],
    nameBeforeDeleted: '',
    customerId: '',
    customerNameBeforeDeleted: '',
    attachmentNameBeforeDeleted: '',
    params: '',
    pageNo: 0,
    pageSize: 15,
    myFirstLoader: false,
    myLoader: false,
    requests: [],
    totalElements: "",
    totalPages: "",
    pageNumber: "",
    isFirst: false,
    isLast: false,
    noDataFound: false

  },

  mutations: {

    TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

    TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

    PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

    IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

    IS_LAST: (state, isLast) => state.isLast = isLast,

    SEARCH_PARAMS: (state, params) => state.params = params,

    VALUES_BEFORE_EDIT: (state, valuesBeforeEdited) => state.valuesBeforeEdited = valuesBeforeEdited,

    CUSTOMER_NAME_BEFORE_EDIT: (state, customerNameBeforeDeleted) => state.customerNameBeforeDeleted = customerNameBeforeDeleted,

    ATTACHMENT_NAME_BEFORE_EDIT: (state, attachmentNameBeforeDeleted) => state.attachmentNameBeforeDeleted = attachmentNameBeforeDeleted,

    NAME_BEFORE_DELETE: (state, nameBeforeDeleted) => state.nameBeforeDeleted = nameBeforeDeleted,

    CUSTOMER_ID: (state, customerId) => state.customerId = customerId,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,


  },

  actions: {

    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    paramsSearching: ({ state, commit }, payload) => commit('SEARCH_PARAMS', payload),

    valuesBeforeEdit: ({ state, commit }, payload) => commit('VALUES_BEFORE_EDIT', payload),

    nameBeforeDelete: ({ state, commit }, payload) => commit('NAME_BEFORE_DELETE', payload),

    customerNameBeforeDelete: ({ state, commit }, payload) => commit('CUSTOMER_NAME_BEFORE_EDIT', payload),

    attachmentNameBeforeDelete: ({ state, commit }, payload) => commit('ATTACHMENT_NAME_BEFORE_EDIT', payload),

    customerid: ({ state, commit }, payload) => commit('CUSTOMER_ID', payload),

    loadCfaCustomers({commit , getters}, {callerType}) {

      return new Promise((resolve, reject) => {

        callerType === 'onCreated' ? commit('FIRST_LOADER', true) : commit('MY_LOADER', true)
        commit('REQUEST_LIST', []);

        let api

        if(getters.getSearchParams !== '') {

          api = API.getSearchPageableCustomer(getters.getSearchParams, getters.getPageNo, getters.getPageSize)

        } else {

          api = API.getPageableCustomer(getters.getPageNo, getters.getPageSize)

        }

        api.then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                callerType === 'onCreated' ? commit('FIRST_LOADER', false) : commit('MY_LOADER', false)
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                callerType === 'onCreated' ? commit('FIRST_LOADER', false) : commit('MY_LOADER', false)

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          callerType === 'onCreated' ? commit('FIRST_LOADER', false) : commit('MY_LOADER', false)

          reject(e)
        })
        
      })

    },

  },

  getters: {

    getValuesBeforeEdit: state => state.valuesBeforeEdited,

    getNameBeforeDelete: state => state.nameBeforeDeleted,

    getCustomerNameBeforeDelete: state => state.customerNameBeforeDeleted,

    getAttachmentNameBeforeDelete: state => state.attachmentNameBeforeDeleted,

    getCustomerId: state => state.customerId,

    getSearchParams: state => state.params,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyFirstLoader: state => state.myFirstLoader,

    getMyLoader: state => state.myLoader,

    getTotalPage: state => state.totalPages,

    getTotalElement: state => state.totalElements,

    getRequestList: state => state.requests,

    getNoDataFound: state => state.noDataFound,

    getIsFirst: state => state.isFirst,

    getIsLast: state => state.isLast,

    getPageNumber: state => state.pageNumber

  }

}
