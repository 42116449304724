export default {

  namespaced: true,

  state: {

    isImportPage: '',
    isExportPage: '',
    isCustomerPage: '',
    isChatRoomPage: '',
    isNewAppPage: ''

  },

  mutations: {

    IMPORT_PAGE: (state, isImportPage) => state.isImportPage = isImportPage,

    EXPORT_PAGE: (state, isExportPage) => state.isExportPage = isExportPage,

    CUSTOMER_PAGE: (state, isCustomerPage) => state.isCustomerPage = isCustomerPage,

    CHATROOM_PAGE: (state, isChatRoomPage) => state.isChatRoomPage = isChatRoomPage,

    NEW_APP_PAGE: (state, isNewAppPage) => state.isNewAppPage = isNewAppPage

  },

  actions: {

    importPage: ({ state, commit }, payload) => commit('IMPORT_PAGE', payload),

    exportPage: ({ state, commit }, payload) => commit('EXPORT_PAGE', payload),

    customerPage: ({ state, commit }, payload) => commit('CUSTOMER_PAGE', payload),

    chatRoomPage: ({ state, commit }, payload) => commit('CHATROOM_PAGE', payload),

    newAppPage: ({ state, commit }, payload) => commit('NEW_APP_PAGE', payload)

  },

  getters: {

    getImportPage: state => state.isImportPage,

    getExportPage: state => state.isExportPage,

    getCustomerPage: state => state.isCustomerPage,

    getChatRoomPage: state => state.isChatRoomPage,

    getNewAppPage: state => state.isNewAppPage

  }

}
