import AGENCY_API from '../../../api/agency'

export default {
  namespaced: true,

  state: {

    requestId: '',
    requests: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    reloadAllRequest: false,
    hasSearchedVariable: false,
    searchParameter: '',
    sortBy: '',
    voyageId: '',
    queryDate: '',
    categoryId: '',
    saveDetails: {},

    totalElements: "",
    totalPages: "",
    pageNumber: "",
    isFirst: false,
    isLast: false,
    params: '',

  },
  mutations: {


    SEARCH_PARAMS: (state, params) => state.params = params,

    VOYAGE_ID: (state, voyageId) => state.voyageId = voyageId,

    QUERY_DATE: (state, queryDate) => state.queryDate = queryDate,

    CATEGORY_ID: (state, categoryId) => state.categoryId = categoryId,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

    TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

    PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

    IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

    IS_LAST: (state, isLast) => state.isLast = isLast,

    SAVE_DETAILS: (state, saveDetails) => state.saveDetails = saveDetails,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    RELOAD_ALL_APPLICATIONS: (state, reloadAllRequest) => state.reloadAllRequest = reloadAllRequest,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy

  },
  actions: {

    hasSearchngParams: ({state, commit}, payload) => commit('HAS_SEARCH_VARIABLE', payload),

    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    requestStatusing: ({ state, commit }, payload) => commit('REQUEST_STATUS', payload),

    savingDetails: ({ state, commit }, payload) => commit('SAVE_DETAILS', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    searchingParameter: ({ state, commit }, payload) => commit('SEARCH_PARAMETER', payload),

    paramsSearching: ({ state, commit }, payload) => commit('SEARCH_PARAMS', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    voyageIding: ({ state, commit }, payload) => commit('VOYAGE_ID', payload),

    queryingDate: ({ state, commit }, payload) => commit('QUERY_DATE', payload),

    categoryIding: ({ state, commit }, payload) => commit('CATEGORY_ID', payload),

    reloadingRequest: ({ state, commit }, payload) => commit('RELOAD_ALL_APPLICATIONS', payload),

    loadPrincipalsParticularsPageableOnCreated({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.listAllPrincipalParticular(getters.getPageNo, getters.getPageSize).then(
          response => {
            if (response.data.status) {
              
              if (response.data.data.length > 0) {

                commit('REQUEST_LIST', response.data.data);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

              commit('NO_DATA_FOUND', true)
              commit('FIRST_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {

          commit('NO_DATA_FOUND', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })

    },

    searchPrincipalsParticulars({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        commit('HAS_SEARCH_VARIABLE', true);
        // console.log('getters.getSearchParams', getters.getSearchParams)
        // console.log('getters.getPageSize', getters.getPageSize)
        // console.log('getters.getPageNo', getters.getPageNo)
        AGENCY_API.searchPrincipalParticular(getters.getSearchParams, getters.getPageSize, getters.getPageNo).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not found, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);
          reject(e)
        })

      })

    },

    loadPrincipalsParticularsPageableAfterCreated({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.listAllPrincipalParticular(getters.getPageNo, getters.getPageSize).then(
          response => {
            if (response.data.status) {

              if (response.data.data.length > 0) {

                commit('REQUEST_LIST', response.data.data);
                commit('MY_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('MY_LOADER', false);

              }

            } else {

              commit('NO_DATA_FOUND', true)
              commit('MY_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {

          commit('NO_DATA_FOUND', true)
          commit('MY_LOADER', false)

          reject(e)
        })
      })

    },

    LoadListAllPrincipalAccounts({ commit }) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.listAllPrincipalAccounts().then(
          response => {

            if (response.data.status) {

              if (response.data.data.length > 0) {

                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)

              } else {

                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)

              }

            } else {

              commit('NO_DATA_FOUND', true)
              commit('FIRST_LOADER', false)

            }

            resolve()
          }

        ).catch(e => {
          
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    
    loadPrincipalsParticularsById({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.getPrincipalParticularById(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('PAGE_COUNT', 1)
                commit('FIRST_LOADER', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    loadPrincipalsParticularsByCarrierCode({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.getPrincipalParticularByCarrierCode(getters.getSearchParameter).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('PAGE_COUNT', 1)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('NO_DATA_FOUND', true)
              commit('FIRST_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    addPrincipalDetails({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])
        // console.log('in api')
        // console.log(getters.getSaveDetails)
        AGENCY_API.addPrincipalParticular(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data.content)
                commit('PAGE_COUNT', response.data.data.totalPages)
                commit('FIRST_LOADER', false)
                commit('FIRST_LOADER', false)
                // commit('ERROR_MESSAGE', ", Requests Not loaded, please contact system administrator");
                // commit('SUCCESS_MESSAGE', "");
                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve(response.data.data)
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    updatePrincipalDetails({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.updatePrincipalParticular(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data);
                // commit('PAGE_COUNT', response.data.message);
                commit('SUCCESS_MESSAGE', 'Successfully updated')
                commit('FIRST_LOADER', false)
                commit('SUCCESS_ALERT', true)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
                commit('ERROR_ALERT', true)
                commit('ERROR_MESSAGE', 'Sorry, something went wrong, please contact your System Administrator')
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    }

  },
  getters: {

    getVoyageId: state => state.voyageId,

    getQueryDate: state => state.queryDate,

    getCategoryId: state => state.categoryId,

    getRequestList: state => state.requests,

    getPrincipalList: state => state.requests,

    getTotalElement: state => state.totalElements,

    getTotalPage: state => state.totalPages,

    getPageNumber: state => state.pageNumber,

    getIsFirst: state => state.isFirst,

    getIsLast: state => state.isLast,

    getSaveDetails: state => state.saveDetails,

    getPageCount: state => state.pageCount,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getRequestId: state => state.requestId,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyLoader: state => state.myLoader,

    getReloadAllRequest: state => state.reloadAllRequest,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParameter: state => state.searchParameter,

    getSortBy: state => state.sortBy,

    getSearchParams: state => state.params,

  }

}
