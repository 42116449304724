import AGENCY_APi from '../../../api/agency'

export default {
  namespaced: true,

  state: {

    requestId: '',
    requests: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    deliveryOrderStatus: false,
    hasSearchedVariable: false,
    searchParameter: '',
    sortBy: '',
    voyageId: '',
    queryDate: '',
    categoryId: '',
    saveDetails: {},

    totalElements: "",
    totalPages: "",
    pageNumber: "",
    isFirst: false,
    isLast: false,
    params: '',
    hasSearchedVariable: false

  },
  mutations: {

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMS: (state, params) => state.params = params,

    TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

    TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

    PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

    IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

    IS_LAST: (state, isLast) => state.isLast = isLast,

    VOYAGE_ID: (state, voyageId) => state.voyageId = voyageId,

    QUERY_DATE: (state, queryDate) => state.queryDate = queryDate,

    CATEGORY_ID: (state, categoryId) => state.categoryId = categoryId,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    SAVE_DETAILS: (state, saveDetails) => state.saveDetails = saveDetails,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    DELIVERY_ORDER_STATUS: (state, deliveryOrderStatus) => state.deliveryOrderStatus = deliveryOrderStatus,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy

  },
  actions: {

    hasSearchngParams: ({state, commit}, payload) => commit('HAS_SEARCH_VARIABLE', payload),

    paramsSearching: ({state, commit}, payload) => commit('SEARCH_PARAMS', payload),

    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    requestStatusing: ({ state, commit }, payload) => commit('REQUEST_STATUS', payload),

    savingDetails: ({ state, commit }, payload) => commit('SAVE_DETAILS', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    searchingParameter: ({ state, commit }, payload) => commit('SEARCH_PARAMETER', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    voyageIding: ({ state, commit }, payload) => commit('VOYAGE_ID', payload),

    queryingDate: ({ state, commit }, payload) => commit('QUERY_DATE', payload),

    categoryIding: ({ state, commit }, payload) => commit('CATEGORY_ID', payload),

    deliveryOrderStatusValue: ({ state, commit }, payload) => commit('DELIVERY_ORDER_STATUS', payload),

    loadAllDoRequests({ commit, getters }) {
      return new Promise((resolve,reject)=>{
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
      })
    },

    getDeliveryOrder ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_APi.getDeliveryOrder(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('NO_DATA_FOUND', true)
              commit('FIRST_LOADER', false)
              commit('ERROR_ALERT', true)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    searchDeliveryOrders ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_APi.searchDeliveryOrders(getters.getSearchParameter).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.data.totalPages)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    updateDeliveryOrder ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('MY_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_APi.updateDeliveryOrder(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('MY_LOADER', false)
                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
              } else {
                // commit('NO_DATA_FOUND', true)
                // commit('PAGE_COUNT', response.data.data.totalPages)
                commit('MY_LOADER', false)
                commit('SUCCESS_ALERT', false)
                commit('ERROR_ALERT', true)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    saveDeliveryOrder ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])
        console.log(getters.getSaveDetails)
        AGENCY_APi.saveDeliveryOrder(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
                commit('SUCCESS_MESSAGE', 'Delivery Order Saved Successfully ')
                commit('SUCCESS_ALERT', true)
              } else {
                commit('ERROR_MESSAGE', 'Sorry, something went wrong')
                commit('SUCCESS_ALERT', false)
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.data.totalPages)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    setDeliveryOrderAsCharged ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('MY_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_APi.setDeliveryOrderAsCharged(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('MY_LOADER', false)
                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
              } else {
                // commit('NO_DATA_FOUND', true)
                // commit('PAGE_COUNT', response.data.data.totalPages)
                commit('MY_LOADER', false)
                commit('SUCCESS_ALERT', false)
                commit('ERROR_ALERT', true)
              }
            } else {

              commit('SUCCESS_ALERT', false)
              commit('ERROR_ALERT', true)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    getDeliveryOderPDF: function ({ commit, getters }) {
      this.globalLoader = true
      this.globalLoaderName = 'Invoice'
      commit('MY_LOADER', true);
      AGENCY_APi.getDeliveryOderPDF(getters.getRequestId).then(
        response => {
          const blobUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
          const link = document.createElement('a')

          link.href = blobUrl

          link.download = 'Delivery_Order_' + getters.getCategoryId

          document.body.appendChild(link)

          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          )
          document.body.removeChild(link)

          commit('MY_LOADER', false);
          commit('ERROR_ALERT', false);
          commit('SUCCESS_ALERT', true);


        }).catch(e => {
        commit('MY_LOADER', false);
        commit('ERROR_ALERT', true)
        commit('SUCCESS_ALERT', false)
      })
    },

    loadAllDeliveryOrdersByStatusPageable ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_APi.getAllDeliveryOrdersByStatusPageable(getters.getPageNo, getters.getPageSize, getters.getDeliveryOrderStatus).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.data.totalPages)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    loadAllDeliveryOrder ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_APi.getAllDeliveryOrders(getters.getPageNo, getters.getPageSize).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.data.totalPages)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

  //  Delivery Order Charges
    updateDeliveryOrderCharge ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('MY_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_APi.updateDeliveryOrderCharge(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('MY_LOADER', false)
                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
              } else {
                // commit('NO_DATA_FOUND', true)
                // commit('PAGE_COUNT', response.data.data.totalPages)
                commit('MY_LOADER', false)
                commit('SUCCESS_ALERT', false)
                commit('ERROR_ALERT', true)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    addDeliveryOrderCharge ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])
        console.log(getters.getSaveDetails)
        AGENCY_APi.saveDeliveryOrderCharge(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
                commit('SUCCESS_MESSAGE', 'Delivery Order Saved Successfully ')
                commit('SUCCESS_ALERT', true)
              } else {
                commit('ERROR_MESSAGE', 'Sorry, something went wrong')
                commit('SUCCESS_ALERT', false)
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.data.totalPages)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    getDeliveryOrderCharge ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])


        AGENCY_APi.getDeliveryOrderCharge(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.data.totalPages)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },


  },
  getters: {

    getVoyageId: state => state.voyageId,

    getQueryDate: state => state.queryDate,

    getCategoryId: state => state.categoryId,

    getRequestList: state => state.requests,

    getSaveDetails: state => state.saveDetails,

    getPageCount: state => state.pageCount,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getRequestId: state => state.requestId,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyLoader: state => state.myLoader,

    getDeliveryOrderStatus: state => state.deliveryOrderStatus,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParameter: state => state.searchParameter,

    getSortBy: state => state.sortBy,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParams: state => state.params,

    getTotalElement: state => state.totalElements,

    getTotalPage: state => state.totalPages,

    getPageNumber: state => state.pageNumber,

    getIsFirst: state => state.isFirst,

    getIsLast: state => state.isLast,

  }

}
