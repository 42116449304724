import TALLY_API from "../../../api/tally";

export  default  {
  namespaced: true,
  state: {
    tallyTariffConfigId: '',
    tallyTariffs: [],
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    myLoader: false,
    pageNo: '',
    pageSize:'',
    saveDetails: {}
  },
  mutations: {

    TALLY_TARIFF_CONFIG_ID: (state, tallyTariffConfigId) => state.tallyTariffConfigId = tallyTariffConfigId,

    TALLY_TARIFFS: (state, tallyTariffs) => state.tallyTariffs = tallyTariffs,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    SAVE_DETAILS: (state, saveDetails) => state.saveDetails = saveDetails,
  },
  actions: {
    tallyTariffConfigurationId: ({ state, commit }, payload) => commit('TALLY_TARIFF_CONFIG_ID', payload),
    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),
    pageNumbering: ({state, commit}, payload) => commit('PAGE_NO', payload),
    savingDetails: ({ state, commit }, payload) => commit('SAVE_DETAILS', payload),

    getPageableTallyTariffs({commit, getters}){
      return new Promise((resolve, reject)=> {
        commit('FIRST_LOADER', true)
        commit('TALLY_TARIFFS', []);

        TALLY_API.getAllTallyCargoTariffFeesPageable(getters.getPageNo, getters.getPageSize).then(
          response =>{
            if (response.data.status){
              if (Object.keys(response.data.data).length > 0) {
                commit('TALLY_TARIFFS', response.data.data)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
              }
            }else{
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
              commit('SUCCESS_MESSAGE', '')
              commit('SUCCESS_ALERT', false)
              commit('ERROR_ALERT', true)
              commit('FIRST_LOADER', false)
              commit('NO_DATA_FOUND', true)

            }
            resolve()
          }
        ).catch(e =>{
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)
          commit('NO_DATA_FOUND', true)
          reject(e)
        })
      })
    },
    getAllTallyTariffs({commit, getters}){
      return new Promise((resolve, reject)=> {
        commit('FIRST_LOADER', true)
        commit('TALLY_TARIFFS', []);

        TALLY_API.getAllTallyCargoTariffFees().then(
          response =>{
            if (response.data.status){
              if (Object.keys(response.data.data).length > 0) {
                commit('TALLY_TARIFFS', response.data.data)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
              }
            }else{
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
              commit('SUCCESS_MESSAGE', '')
              commit('SUCCESS_ALERT', false)
              commit('ERROR_ALERT', true)
              commit('FIRST_LOADER', false)
              commit('NO_DATA_FOUND', true)

            }
            resolve()
          }
        ).catch(e =>{
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)
          commit('NO_DATA_FOUND', true)
          reject(e)
        })
      })
    },
    addTallyTariff ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        // commit('TALLY_TARIFFS', []);

        TALLY_API.addTallyCargoTariffFeeDefault(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('FIRST_LOADER', false);
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
              } else {
                commit('SUCCESS_ALERT', false);
                commit('ERROR_ALERT', true);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('FIRST_LOADER', false);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    updateTallyTariff ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        TALLY_API.updateTallyCargoTariffFeeDefault(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('FIRST_LOADER', false);
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
              } else {
                commit('SUCCESS_ALERT', false);
                commit('ERROR_ALERT', true);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('FIRST_LOADER', false);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    deleteTallyTariff ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        TALLY_API.deleteTallyCargoTariffFeeDefault(getters.getTallyTariffConfigId).then(
          response => {
            if (response.data.status) {
              commit('FIRST_LOADER', false);
              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false);
            } else {
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('FIRST_LOADER', false);

            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },

  },
  getters: {
    getPageNo: state => state.pageNo,
    getPageSize: state => state.pageSize,
    getTallyTariffConfigId: state => state.tallyTariffConfigId,
    getMyFirstLoader: state => state.myFirstLoader,
    getMyLoader: state => state.myLoader,
    getErrorAlert: state => state.ErrorAlert,
    getSuccessAlert: state => state.SuccessAlert,
    getSuccessMessage: state => state.SuccessMessage,
    getErrorMessage: state => state.ErrorMessage,
    getNoDataFound: state => state.noDataFound,
    getSaveDetails: state => state.saveDetails,
    getTallyTariffs: state => state.tallyTariffs,
  }
}
