import API_CFA from '../../../api/cfa'
import AGENCY_API from '../../../api/agency'
import API from '../../../api'
import API_REVENUE from '../../../api/rev'

export default {

  namespaced: true,

  state: {

    noInternetConnection: false,
    error403: false,
    isPanelOpen: false,
    globalLoader: false,
    disbursementServiceCategoryLoader: false,
    disbursementServiceCategoryItemLoader: false,
    allCompaniesLoader: false,
    saServiceProviderLoader: false,
    fileUrl: '',
    fileName: '',
    fileExtension: '',
    requestId: '',
    allCustomers: [],
    allSystemUsers: [],
    allApplicationActivities: [],
    allApplicationStatuses: [],
    allCompanies: [],
    allRegimes: [],
    allStations: [],
    allCfaAgents: [],
    activeTariffItems: [],
    allShippingAgencyServiceProviders: [],
    allDisbursementServiceCategories: [],
    allDisbursementServiceCategoryItems: [],
    disbursementServiceCategoryId: '',
    componentKey: 0,
    autoRefreshNotifications: false,

    allCustomersForFilter: []

  },

  mutations: {

    NO_INTERNET_CONNECTION: (state, noInternetConnection) => state.noInternetConnection = noInternetConnection,

    ERROR_403: (state, error403) => state.error403 = error403,

    TOGGLE_SIDE_BAR: (state, isPanelOpen) => state.isPanelOpen = isPanelOpen,

    GLOBAL_LOADER: (state, globalLoader) => state.globalLoader = globalLoader,

    FILE_NAME: (state, fileName) => state.fileName = fileName,

    FILE_EXTENSION: (state, fileExtension) => state.fileExtension = fileExtension,

    FILE_URL: (state, fileUrl) => state.fileUrl = fileUrl,

    ALL_CUSTOMERS: (state, allCustomers) => state.allCustomers = allCustomers,

    ALL_SYSTEM_USERS: (state, allSystemUsers) => state.allSystemUsers = allSystemUsers,

    ALL_APP_ACTIVITIES: (state, allApplicationActivities) => state.allApplicationActivities = allApplicationActivities,

    ALL_APP_STATUSES: (state, allApplicationStatuses) => state.allApplicationStatuses = allApplicationStatuses,

    ALL_CUSTOMERS_FOR_FILTER: (state, allCustomersForFilter) => state.allCustomersForFilter = allCustomersForFilter,

    ACTIVE_TARIFF_ITEMS: (state, activeTariffItems) => state.activeTariffItems = activeTariffItems,

    ALL_COMPANIES: (state, allCompanies) => state.allCompanies = allCompanies,

    ALL_REGIMES: (state, allRegimes) => state.allRegimes = allRegimes,

    ALL_STATIONS: (state, allStations) => state.allStations = allStations,

    ALL_CFAAGENTS: (state, allCfaAgents) => state.allCfaAgents = allCfaAgents,

    ALL_SA_SERVICE_PROVIDER: (state, allShippingAgencyServiceProviders) => state.allShippingAgencyServiceProviders = allShippingAgencyServiceProviders,

    ALL_DISBURSMENT_SERVICE_CATEGORIES: (state, allDisbursementServiceCategories) => state.allDisbursementServiceCategories = allDisbursementServiceCategories,

    ALL_DISBURSMENT_SERVICE_CATEGORY_ITEMS: (state, allDisbursementServiceCategoryItems) => state.allDisbursementServiceCategoryItems = allDisbursementServiceCategoryItems,

    DISBURSMENT_SERVICE_CATEGORY_ID: (state, disbursementServiceCategoryId) => state.disbursementServiceCategoryId = disbursementServiceCategoryId,

    DISBURSMENT_SERVICE_CATEGORY_LOADER: (state, disbursementServiceCategoryLoader) => state.disbursementServiceCategoryLoader = disbursementServiceCategoryLoader,

    DISBURSMENT_SERVICE_CATEGORY_ITEM_LOADER: (state, disbursementServiceCategoryItemLoader) => state.disbursementServiceCategoryItemLoader = disbursementServiceCategoryItemLoader,

    ALL_COMPANIES_LOADER: (state, allCompaniesLoader) => state.allCompaniesLoader = allCompaniesLoader,

    SA_SERVICE_PROVIDER_LOADER: (state, saServiceProviderLoader) => state.saServiceProviderLoader = saServiceProviderLoader,

    COMPONENT_KEY: (state, componentKey) => state.componentKey = componentKey,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    AUTO_REFRESH_NOTIFICATION: (state, autoRefreshNotifications) => state.autoRefreshNotifications = autoRefreshNotifications

  },

  actions: {

    noInternet: ({ state, commit }, payload) => commit('NO_INTERNET_CONNECTION', payload),

    autoRefreshingNotifications: ({ state, commit }, payload) => commit('AUTO_REFRESH_NOTIFICATION', payload),

    allcustomerforfiltering: ({ state, commit }, payload) => commit('ALL_CUSTOMERS_FOR_FILTER', payload),

    erroring403: ({state, commit}, payload) => commit('ERROR_403', payload),

    toggleSideBar: ({ state, commit }, payload) => commit('TOGGLE_SIDE_BAR', payload),

    fileNaming: ({ state, commit }, payload) => commit('FILE_NAME', payload),

    fileExtensioning: ({ state, commit }, payload) => commit('FILE_EXTENSION', payload),

    fileUrling: ({ state, commit }, payload) => commit('FILE_URL', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    disbursmentServiceCategoryIding: ({ state, commit }, payload) => commit('DISBURSMENT_SERVICE_CATEGORY_ID', payload),

    componentKeying: ({state, commit}, payload) => commit('COMPONENT_KEY', payload),

    loadFileFromMinio ({ commit, getters }, { action = 'download' }) {

      return new Promise((resolve, reject) => {

      commit('GLOBAL_LOADER', true)

      let fileNameAndExtension = getters.getFileUrl.split('documents/')[1]
      let fileExtension = fileNameAndExtension.split('.')[1]

        API_CFA.getFileFromMinio(fileNameAndExtension).then(
          response => {

            if(action === 'download') {

              if(response.status === 204) {

                commit('GLOBAL_LOADER', false)

              } else {

                commit('GLOBAL_LOADER', false)

                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', getters.getFileName + '.' + fileExtension)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url);

              }

              resolve(response)

            } else {

              let url

              if(response.status === 204) {

                commit('GLOBAL_LOADER', false)

              } else {

                commit('GLOBAL_LOADER', false)

                url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/' + fileExtension}))

              }

              resolve([url, response])

            }

        }
        ).catch((e) => {

          commit('GLOBAL_LOADER', false)

          reject(e)

        })

      })

    },

    downloadFDAdocument ({ commit, getters }, { action = 'download' }) {

      return new Promise((resolve, reject) => {

        commit('GLOBAL_LOADER', true)

        AGENCY_API.getFdaAccountPDF(getters.getRequestId).then( async (response)=> {
            const text = await (new Response(response.data)).text();


            if(action === 'download') {

              if(response.status === 204) {

                commit('GLOBAL_LOADER', false)

              } else {

                commit('GLOBAL_LOADER', false)

                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', getters.getFileName + '.' + getters.getFileExtension)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url);

              }

              resolve(response)

            } else {

              let url

              if(response.status === 204) {

                commit('GLOBAL_LOADER', false)

              } else {

                commit('GLOBAL_LOADER', false)

                url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))

              }

              resolve([url, response])

            }

          }
        ).catch((e) => {

          commit('GLOBAL_LOADER', false)

          reject(e)

        })

      })

    },

    downloadBillOfLading ({ commit, getters }, { action = 'download' }) {

      return new Promise((resolve, reject) => {

        commit('GLOBAL_LOADER', true)

        AGENCY_API.getBillOfLadingPDF(getters.getRequestId).then(
          response => {

            if(action === 'download') {

              if(response.status === 204) {

                commit('GLOBAL_LOADER', false)

              } else {

                commit('GLOBAL_LOADER', false)

                const url = window.URL.createObjectURL(new Blob([response.data]))
                // window.open(url)
                const link = document.createElement('a')
                link.href = url
                link.target = "_blank";
                link.setAttribute('Download', getters.getFileName + '.' + getters.getFileExtension)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url);

              }

              resolve(response)

            } else {

              let url

              if(response.status === 204) {

                commit('GLOBAL_LOADER', false)

              } else {

                commit('GLOBAL_LOADER', false)

                url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))

              }

              resolve([url, response])

            }

          }
        ).catch((e) => {

          commit('GLOBAL_LOADER', false)

          reject(e)

        })

      })

    },

    downloadBlobDocument ({ commit, getters }, { action = 'download' }) {

      return new Promise((resolve, reject) => {

      commit('GLOBAL_LOADER', true)

      AGENCY_API.getSubDisbursementAccountPDF(getters.getRequestId).then(
          response => {

            if(action === 'download') {

              if(response.status === 204) {

                commit('GLOBAL_LOADER', false)

              } else {

                commit('GLOBAL_LOADER', false)

                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', getters.getFileName + '.' + getters.getFileExtension)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url);

              }

              resolve(response)

            } else {

              let url

              if(response.status === 204) {

                commit('GLOBAL_LOADER', false)

              } else {

                commit('GLOBAL_LOADER', false)

                url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))

              }

              resolve([url, response])

            }

        }
        ).catch((e) => {

          commit('GLOBAL_LOADER', false)

          reject(e)

        })

      })

    },

    getStatus () {
      API.getStatus().then(
        response => {
          if (response.data.status) {

            let respData=response.data.data;

            for(let i=0;i<respData.length;i++){
              if(respData[i].statusDescription!=='Re Assigned'){
                this.assignmentStatus.push(respData[i]);
              }
            }
            this.assignedUserData.statusId=4;
          } else {

          }
        }
      ).catch(e => {

      })
    },

    loadApplicationActivityStatuses ({ commit }) {

      return new Promise((resolve, reject) => {

      commit('ALL_APP_STATUSES', [])

      API.getStatus().then(
          response => {

            if(response.data.status) {

              commit('ALL_APP_STATUSES', response.data.data)

            } else {

              commit('ALL_APP_STATUSES', [])

            }

            resolve()

        }
        ).catch((e) => {

          reject(e)

        })

      })

    },

    loadApplicationActivities ({ commit }) {

      return new Promise((resolve, reject) => {

      commit('ALL_APP_ACTIVITIES', [])

      API.getActivity().then(
          response => {

            if(response.data.status) {

              commit('ALL_APP_ACTIVITIES', response.data.data)

            } else {

              commit('ALL_APP_ACTIVITIES', [])

            }

            resolve()

        }
        ).catch((e) => {

          reject(e)

        })

      })

    },

    loadSystemUsers ({ commit }) {

      return new Promise((resolve, reject) => {

      commit('ALL_SYSTEM_USERS', [])

      API.getUsers().then(
          response => {

            if(response.data.status) {

              commit('ALL_SYSTEM_USERS', response.data.data)

            } else {

              commit('ALL_SYSTEM_USERS', [])

            }

            resolve()

        }
        ).catch((e) => {

          reject(e)

        })

      })

    },

    loadCustomers ({ commit }) {

      return new Promise((resolve, reject) => {

      commit('ALL_CUSTOMERS', [])

      API.getCustomerAll().then(
          response => {

            if(response.data.status) {

              commit('ALL_CUSTOMERS', response.data.data)

            } else {

              commit('ALL_CUSTOMERS', [])

            }

            resolve()

        }
        ).catch((e) => {

          reject(e)

        })

      })

    },

    loadActiveTariffItems ({ commit }) {

      return new Promise((resolve, reject) => {

      commit('ACTIVE_TARIFF_ITEMS', [])

      API_REVENUE.getTariffItemsActive().then(
          response => {

            if(response.data.status) {

              commit('ACTIVE_TARIFF_ITEMS', response.data.data)

            } else {

              commit('ACTIVE_TARIFF_ITEMS', [])

            }

            resolve()

        }
        ).catch((e) => {

          reject(e)

        })

      })

    },

    loadCompanies ({ commit }) {

      return new Promise((resolve, reject) => {

      commit('ALL_COMPANIES', [])
      commit('ALL_COMPANIES_LOADER', true)

      API.getCompany().then(
          response => {

            if(response.data.status) {

              commit('ALL_COMPANIES', response.data.data)
              commit('ALL_COMPANIES_LOADER', false)

            } else {

              commit('ALL_COMPANIES', [])
              commit('ALL_COMPANIES_LOADER', false)

            }

            resolve()

        }
        ).catch((e) => {

          commit('ALL_COMPANIES_LOADER', false)

          reject(e)

        })

      })

    },

    loadRegimes ({ commit }) {

      return new Promise((resolve, reject) => {

      commit('ALL_REGIMES', []);

      API.getRegime().then(
          response => {

            if(response.data.status) {
              commit('ALL_REGIMES', response.data.data)

            } else {

              commit('ALL_REGIMES', [])

            }

            resolve()

        }
        ).catch((e) => {

          reject(e)

        })

      })

    },

    loadStations ({ commit }) {

      return new Promise((resolve, reject) => {

      commit('ALL_STATIONS', []);

      API.getStation().then(
          response => {

            if(response.data.status) {
              commit('ALL_STATIONS', response.data.data)

            } else {

              commit('ALL_STATIONS', [])

            }

            resolve()

        }
        ).catch((e) => {

          reject(e)

        })

      })

    },

    loadCfaAgents ({ commit }) {

      return new Promise((resolve, reject) => {

      commit('ALL_CFAAGENTS', [])

      AGENCY_API.getAllCfaAgents().then(
          response => {

            if(response.data.status) {

              commit('ALL_CFAAGENTS', response.data.data)

            } else {

              commit('ALL_CFAAGENTS', [])

            }

            resolve()

        }
        ).catch((e) => {

          reject(e)

        })

      })

    },

    loadShippingAgencyServiceProvider ({ commit }) {

      return new Promise((resolve, reject) => {

      commit('ALL_SA_SERVICE_PROVIDER', [])
      commit('SA_SERVICE_PROVIDER_LOADER', true)

      AGENCY_API.getAllShippingAgencyServiceProvider().then(
          response => {

            if(response.data.status) {

              commit('ALL_SA_SERVICE_PROVIDER', response.data.data)
              commit('SA_SERVICE_PROVIDER_LOADER', false)

            } else {

              commit('ALL_SA_SERVICE_PROVIDER', [])
              commit('SA_SERVICE_PROVIDER_LOADER', false)

            }

            resolve()

        }
        ).catch((e) => {

          reject(e)
          commit('SA_SERVICE_PROVIDER_LOADER', false)

        })

      })

    },

    loadDisbursementServiceCategories ({ commit }) {

      return new Promise((resolve, reject) => {

      commit('ALL_DISBURSMENT_SERVICE_CATEGORIES', [])
      commit('DISBURSMENT_SERVICE_CATEGORY_LOADER', true)

      AGENCY_API.getAllDisbursementServiceCategories().then(
          response => {

            if(response.data.status) {

              commit('ALL_DISBURSMENT_SERVICE_CATEGORIES', response.data.data)
              commit('DISBURSMENT_SERVICE_CATEGORY_LOADER', false)

            } else {

              commit('ALL_DISBURSMENT_SERVICE_CATEGORIES', [])
              commit('DISBURSMENT_SERVICE_CATEGORY_LOADER', false)

            }

            resolve()

        }
        ).catch((e) => {

          commit('DISBURSMENT_SERVICE_CATEGORY_LOADER', false)
          reject(e)

        })

      })

    },

    loadDisbursementServiceCategoryItems ({ commit, getters }) {

      return new Promise((resolve, reject) => {

      commit('ALL_DISBURSMENT_SERVICE_CATEGORY_ITEMS', [])
      commit('DISBURSMENT_SERVICE_CATEGORY_ITEM_LOADER', true)

      AGENCY_API.getAllDisbursmentServiceItemByServiceCategoryId(getters.getDisbursementServiceCategoryId).then(
          response => {

            if(response.data.status) {

              commit('ALL_DISBURSMENT_SERVICE_CATEGORY_ITEMS', response.data.data)
              commit('DISBURSMENT_SERVICE_CATEGORY_ITEM_LOADER', false)

            } else {

              commit('ALL_DISBURSMENT_SERVICE_CATEGORY_ITEMS', [])
              commit('DISBURSMENT_SERVICE_CATEGORY_ITEM_LOADER', false)

            }

            resolve()

        }
        ).catch((e) => {

          commit('DISBURSMENT_SERVICE_CATEGORY_ITEM_LOADER', false)
          reject(e)

        })

      })

    },

  },

  getters: {

    getNoInternetConnection: state => state.noInternetConnection,

    getError403: state => state.error403,

    getToggleSideBar: state => state.isPanelOpen,

    getGlobalLoader: state => state.globalLoader,

    getFileUrl: state => state.fileUrl,

    getFileName: state => state.fileName,

    getFileExtension: state => state.fileExtension,

    getAllCustomers: state => state.allCustomers,

    getAllSystemUsers: state => state.allSystemUsers,

    getAllApplicationActivities: state => state.allApplicationActivities,

    getApplicationActivityStatuses: state => state.allApplicationStatuses,

    getallCompanies: state => state.allCompanies,

    getAllCfaAgents: state => state.allCfaAgents,

    getAllRegimes: state => state.allRegimes,

    getAllStations: state => state.allStations,

    getActiveTariffItems: state => state.activeTariffItems,

    getAllDisbursementServiceCategories: state => state.allDisbursementServiceCategories,

    getAllShippingAgencyServiceProviders: state => state.allShippingAgencyServiceProviders,

    getAllDisbursementServiceCategoryItems: state => state.allDisbursementServiceCategoryItems,

    getDisbursementServiceCategoryLoader: state => state.disbursementServiceCategoryLoader,

    getDisbursementServiceCategoryItemLoader: state => state.disbursementServiceCategoryItemLoader,

    getSAServiceProviderLoader: state => state.saServiceProviderLoader,

    getAllCompaniesLoader: state => state.allCompaniesLoader,

    getDisbursementServiceCategoryId: state => state.disbursementServiceCategoryId,

    getComponentKey: state => state.componentKey,

    getRequestId: state => state.requestId,

    getAllCustomersForFilter: state => state.allCustomersForFilter,

    getAutoRefreshNotifications: state => state.autoRefreshNotifications

  }

}
