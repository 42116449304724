<template>
  <transition name="slide-fade">
    <div
      class="sidebar"
      :class="[ closeSidebarPanel ? 'closeSideBar' : 'openSideBar']"
      :style="sidebarStyle"
      v-if="!getToggleSideBar"
      :data-color="backgroundColor"
      :data-image="backgroundImage"
    >
      <div class="sidebar-wrapper">
        <div class="logo">
          <a
            href=""
            @click="goToCurrentPath"
            class="simple-text logo__container"
          >
            <div class="logo-img">
              <img
                src="/img/logo.png"
                alt
              >
            </div>
            {{ title }}
          </a>
        </div>

        <slot name="content" />
        <ul class="nav nav-main__links">
          <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
          <slot>
            <sidebar-link
              v-for="(link,index) in sidebarLinks"
              :key="link.name + index"
              :to="link.path"
              @click="closeNavbar"
              :link="link"
            >
              <i :class="link.icon" />
              <p>{{ link.name }}</p>
            </sidebar-link>
          </slot>
        </ul>
        <ul
          class="nav nav-bottom"
          v-if="$slots['bottom-links']"
        >
          <slot name="bottom-links" />
        </ul>
      </div>
    </div>
  </transition>
</template>
<script>
import SidebarLink from './SidebarLink.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    SidebarLink
  },
  data () {
    return {
      isPanelOpen: true
    }
  },
  props: {
    title: {
      type: String,
      default: 'TASAC- SBMS'
    },
    backgroundColor: {
      type: String,
      default: 'blue'
    },
    backgroundImage: {
      type: String
      // default: "img/sidebar-5.jpg"
    },
    activeColor: {
      type: String
      // default: "info",
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  methods: {

    goToCurrentPath () {
      this.$router.push(this.$router.currentRoute.path)
    }

  },
  provide () {
    return {
      autoClose: this.autoClose
    }
  },
  computed: {

    sidebarStyle () {
      return {
        backgroundImage: `url(${this.backgroundImage})`
      }
    },

    ...mapGetters('global', [

      'getToggleSideBar'

    ]),

    closeSidebarPanel () {
      return this.getToggleSideBar
    }

  }
}
</script>
<style>
.sidebar .sidebar-wrapper {
  display: flex;
  flex-direction: column;
}
.sidebar .nav-main__links {
  flex: 1;
}
.sidebar .sidebar-wrapper .logo .logo__container {
  padding-left: 10px;
}

.closeSideBar {
  display: none !important;
  width: 0 !important;
}

.openSideBar {
  display: block;
  width: 290px !important;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
