import AGENCY_API from '../../../api/agency'

export default {

    namespaced: true,

    state: {

        requestId: '',
        requests: [],
        pageCount: 1,
        myFirstLoader: false,
        noDataFound: false,
        pageNo: 0,
        pageSize: 15,
        myLoader: false,
        totalElements: "",
        totalPages: "",
        pageNumber: "",
        isFirst: false,
        isLast: false,
        params: '',

    },
    mutations: {

        REQUEST_ID: (state, requestId) => state.requestId = requestId,

        SEARCH_PARAMS: (state, params) => state.params = params,

        REQUEST_LIST: (state, requests) => state.requests = requests,

        TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

        TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

        PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

        IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

        IS_LAST: (state, isLast) => state.isLast = isLast,

        PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

        FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

        NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

        PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

        PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

        MY_LOADER: (state, myLoader) => state.myLoader = myLoader

    },
    actions: {
        
        pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

        pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

        requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

        paramsSearching: ({state, commit}, payload) => commit('SEARCH_PARAMS', payload),

        loadDeliveryOderAttachmentsPageableOnCreated({ commit, getters }) {

        return new Promise((resolve, reject) => {

            commit('FIRST_LOADER', true)
            commit('REQUEST_LIST', [])

            AGENCY_API.getDeliveryOrderAttachmentsTypes(getters.getPageNo, getters.getPageSize).then(
            response => {
                if (response.data.status) {

                if (response.data.data.content.length > 0) {

                    commit('REQUEST_LIST', response.data.data.content);
                    commit('FIRST_LOADER', false);
                    commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                    commit('TOTAL_PAGES', response.data.data.totalPages);
                    commit('PAGE_NUMBER', response.data.data.number);
                    commit('IS_FIRST', response.data.data.first);
                    commit('IS_LAST', response.data.data.last);
                    commit('NO_DATA_FOUND', false);

                } else {

                    commit('NO_DATA_FOUND', true);
                    commit('REQUEST_LIST', response.data.data.content);
                    commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                    commit('TOTAL_PAGES', response.data.data.totalPages);
                    commit('PAGE_NUMBER', response.data.data.number);
                    commit('IS_FIRST', response.data.data.first);
                    commit('IS_LAST', response.data.data.last);
                    commit('FIRST_LOADER', false);

                }

                } else {

                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
                }

                resolve()
            }
            ).catch(e => {
            
            commit('NO_DATA_FOUND', true)
            commit('FIRST_LOADER', false)

            reject(e)
            })
        })

        },

        loadDeliveryOderAttachmentsPageableAfterCreated({ commit, getters }) {

        return new Promise((resolve, reject) => {

            commit('MY_LOADER', true)
            commit('REQUEST_LIST', [])

            AGENCY_API.getDeliveryOrderAttachmentsTypes(getters.getPageNo, getters.getPageSize).then(
            response => {
                if (response.data.status) {

                if (response.data.data.content.length > 0) {

                    commit('REQUEST_LIST', response.data.data.content);
                    commit('MY_LOADER', false);
                    commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                    commit('TOTAL_PAGES', response.data.data.totalPages);
                    commit('PAGE_NUMBER', response.data.data.number);
                    commit('IS_FIRST', response.data.data.first);
                    commit('IS_LAST', response.data.data.last);
                    commit('NO_DATA_FOUND', false);

                } else {

                    commit('NO_DATA_FOUND', true);
                    commit('REQUEST_LIST', response.data.data.content);
                    commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                    commit('TOTAL_PAGES', response.data.data.totalPages);
                    commit('PAGE_NUMBER', response.data.data.number);
                    commit('IS_FIRST', response.data.data.first);
                    commit('IS_LAST', response.data.data.last);
                    commit('MY_LOADER', false);

                }

                } else {

                commit('NO_DATA_FOUND', true)
                commit('MY_LOADER', false)
                }

                resolve()
            }
            ).catch(e => {
            
            commit('NO_DATA_FOUND', true)
            commit('MY_LOADER', false)

            reject(e)
            })
        })
        
        },

        /* SEARCH */

        searchWithGeneralChargeParams({commit , getters}) {

            return new Promise((resolve, reject) => {
    
            commit('FIRST_LOADER', true)
            commit('REQUEST_LIST', [])
    
            AGENCY_API.searchDeliveryOrderAttachmentsTypes(getters.getSearchParams).then(
                response => {
    
                if (response.data.status) {
    
                    if (response.data.data.content.length > 0) {
    
                    commit('REQUEST_LIST', response.data.data.content);
                    commit('FIRST_LOADER', false);
                    commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                    commit('TOTAL_PAGES', response.data.data.totalPages);
                    commit('PAGE_NUMBER', response.data.data.number);
                    commit('IS_FIRST', response.data.data.first);
                    commit('IS_LAST', response.data.data.last);
                    commit('NO_DATA_FOUND', false);
    
                    } else {
    
                    commit('NO_DATA_FOUND', true);
                    commit('REQUEST_LIST', response.data.data.content);
                    commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                    commit('TOTAL_PAGES', response.data.data.totalPages);
                    commit('PAGE_NUMBER', response.data.data.number);
                    commit('IS_FIRST', response.data.data.first);
                    commit('IS_LAST', response.data.data.last);
                    commit('FIRST_LOADER', false);
    
                    }
    
                } else {
    
                }
    
                resolve()
                }
            ).catch(e => {

                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', true)
    
                reject(e)
            })
            })
        },

    },
    getters: {

        getRequestList: state => state.requests,

        getSearchParams: state => state.params,

        getTotalElement: state => state.totalElements,

        getTotalPage: state => state.totalPages,

        getPageNumber: state => state.pageNumber,

        getIsFirst: state => state.isFirst,

        getIsLast: state => state.isLast,

        getPageCount: state => state.pageCount,

        getMyFirstLoader: state => state.myFirstLoader,

        getNoDataFound: state => state.noDataFound,

        getRequestId: state => state.requestId,

        getPageSize: state => state.pageSize,

        getPageNo: state => state.pageNo,

        getMyLoader: state => state.myLoader,

        getSearchParameter: state => state.searchParameter

    }

}
