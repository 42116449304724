const URL = 'sbms-dev.tasac.go.tz';
const micro_service_port = '9062';
const protocol = 'http://';
const micro_service_url = `${protocol}${URL}:${micro_service_port}`;
const useSSL = false;

// const URL = 'sbms-dev.tasac.go.tz';
// const URL = 'localhost';
// const micro_service_port = '9062';
// const protocol = 'http://';
// const micro_service_url = `${protocol}${URL}:${micro_service_port}`;
// const useSSL = false;

/* base url for staff authentication microservice server */
export const AUTH_SERVER_URL = `${micro_service_url}/uaa`;

/* base url for customer authentication microservice server */
export const CUST_AUTH_SERVER_URL = `${micro_service_url}/customeruaa`;

/* base url for revenue microservice server */
export const REVENUE_SERVER_URL = `${micro_service_url}/revenue`;

/* base url for clearing and forwarding microservice server */
export const CFA_SERVER_URL = `${micro_service_url}/cfa`;

/* base url for configuration microservice server */
export const CONFIG_SERVER_URL = `${micro_service_url}/config`;

/* base url for document control microservice server */
export const DC_SERVER_URL = `${micro_service_url}/document-control`;

/* base url for shipping tally microservice server */
export const TALLY_SERVER_URL = `${micro_service_url}/tally/api`;

/* base url for shipping agency microservice server */
export const AGENCY_SERVER_URL = `${micro_service_url}/ship-agency`;

export const CLIENT_ID = "tasacWebUi";
export const CLIENT_SECRET = "@tasacClient";
export const ROLE_PROFILE = "PERMISSION_PROFILE";
export const REFRESH_GRANT_TYPE = "refresh_token";
export const TOKEN_GRANT_TYPE = "password";
