import TALLYAPI from '../../../api/tally'

export default {

  namespaced: true,

  state: {

    resources: []

  },

  mutations: {

    GET_RESOURCES (state, resources) {
      state.resources = resources
    }

  },

  actions: {

    getResources (context) {
      TALLYAPI.getAllResources().then((response) => {
        context.commit('GET_RESOURCES', response.data.data)
      })
    },

    deleteResource (context, resourceId) {
      TALLYAPI.deleteResource(resourceId).then((response) => {
        context.dispatch('resources/getResources', null, { root: true })
      })
    },

    creeateResource (context, { active, mayShiftShipBeforeCompletion, name, sharedByClerks, usedIn }) {
      TALLYAPI.CreateResource({
        active,
        mayShiftShipBeforeCompletion,
        name,
        sharedByClerks,
        usedIn
      }).then(() => {
        context.dispatch('resources/getResources', null, { root: true })
      })
    }
  },

  getters: {

    getAllResources: state => state.resources

  }
}
