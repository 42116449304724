import containers from '../assets/files/container';

const packageTypeCodes = {
  AE: 'Aerosol',
  BX: 'Box',
  AM: 'Ampoule, Non-Protected',
  BY: 'Board, In Bundle/Bunch/Truss',
  AP: 'Ampoule, Protected',
  BZ: 'Bars, In Bundle/Bunch/Truss',
  AT: 'Atomizer',
  CA: 'Can, Rectangular',
  BA: 'Barrel',
  CB: 'Beer Crate',
  BB: 'Bobbin',
  CC: 'Churn',
  BC: 'Bottlecrate, Bottlerack',
  CD: 'Can(Small) With Handle & Spout',
  BD: 'Board',
  CF: 'Coffer, Trunk',
  BE: 'Bundle',
  CG: 'Cage',
  BF: 'Balloon, Non-Protected',
  CH: 'Chest',
  BG: 'Bag',
  CI: 'Canister',
  BH: 'Bunch',
  CJ: 'Coffin',
  BI: 'Bin',
  CK: 'Cask',
  BJ: 'Bucket',
  CL: 'Coil',
  BK: 'Basket',
  CO: 'Carboy, Non-Protected',
  BL: 'Bale, Compressed',
  CP: 'Carboy, Protected',
  BN: 'Bale, Non-Compressed',
  CR: 'Crate',
  BO: 'Cylindrical Bottle & Can',
  CS: 'Case',
  BP: 'Balloon, Protected',
  CT: 'Carton',
  BQ: 'Bottle, Protected Cylindrical',
  CU: 'Cup',
  BR: 'Bar',
  CV: 'Cover',
  BS: 'Bottle, Non-Protected, Bulbous',
  CX: 'Can, Cylindrical',
  BT: 'Bolt',
  CY: 'Cylinder',
  BU: 'Butt',
  CZ: 'Canvas',
  BV: 'Bottle, Protected Bulbous',
  DJ: 'Demijohn, Non-Protected',
  DP: 'Demijohn, Protected',
  KG: 'Keg',
  DR: 'Drum',
  LG: 'Log',
  DZ: 'Dozen',
  LI: 'Liter',
  EN: 'Envelope',
  LV: 'Lifvan',
  FC: 'Fruit Crate',
  LZ: 'Logs, In Bundle/Bunch/Truss',
  FD: 'Framed crate',
  M2: 'Square Meter',
  FI: 'Firkin',
  MB: 'Multiply bag',
  FL: 'Flask',
  MC: 'Milkcrate',
  FO: 'Footlocker',
  ME: 'Meter',
  FP: 'Film pack',
  MS: 'Multiwall Sack',
  FR: 'Frame',
  MT: 'Mat',
  GB: 'Gas Bottle',
  MX: 'Match Box',
  GI: 'Girder',
  NE: 'Unpacked Or Unpackaged',
  GL: 'Gallon',
  NKPK: 'No Control On No, Of Packages',
  GZ: 'Girders, In Bundle/Bunch/Truss',
  NS: 'Nest',
  HG: 'Hogshead',
  NT: 'Net',
  HR: 'Hamper',
  OZ: 'Ounzes',
  IH: 'Drum',
  PA: 'Packet',
  IN: 'Ingot',
  PC: 'Parcel',
  IZ: 'Ingots, In Bundle/Bunch/Truss',
  PE: 'Piece',
  PG: 'Plate',
  PH: 'Pitcher',
  JR: 'Jar',
  PI: 'Pipe, Tubes',
  PK: 'Package, Parcel, Packet',
  PL: 'Pail',
  PN: 'Plank',
  PO: 'Pouch',
  PR: 'Pairs',
  PT: 'Pot',
  PU: 'Tray Pack',
  PV: 'Pipes (Bundle, Bunch Or Truss)',
  PX: 'Pallet',
  PY: 'Plates, In Bundle/Bunch/Truss',
  PZ: 'Pipes, In Bundle/Bunch/Truss',
  RD: 'Rod',
  JC: 'Jerrican, Rectangular',
  RG: 'Ring',
  JG: 'Jug',
  RL: 'Reel',
  RO: 'Roll',
  JT: 'Jute bag',
  RT: 'Rednet',
  JY: 'Jerrican, Cylindrical',
  RZ: 'Rods, In Bundle/Bunch/Truss',
  SA: 'Sack',
  SC: 'Shallow Crate',
  SD: 'Spindle',
  SE: 'Sea-Chest',
  SH: 'Sachet',
  SK: 'Skeleton Case',
  SL: 'Slipsheet',
  SM: 'Sheetmetal',
  SP: 'Spire',
  ST: 'Sheet',
  SU: 'Suitcase',
  SW: 'Shrinkwrapped',
  SZ: 'Sheets, In Bundle/Bunch/Truss',
  TB: 'Tub',
  TC: 'Tea-Chest',
  TD: 'Tube, Collapsible',
  TK: 'Tank, Rectangular',
  TN: 'Tin',
  TO: 'Tun',
  TR: 'Trunk',
  TS: 'Truss',
  TT: 'Truck Tank',
  TU: 'Tube',
  TY: 'Tank, Cylindrical',
  TZ: 'Tubes, In Bundle/Bunch/Truss',
  UN: 'Units',
  VA: 'Vat',
  VG: 'Bulk, Gas (at 1031 Mbar/15°)',
  VH: 'Vehicle',
  VI: 'Vial',
  VL: 'Bulk, Liquid',
  VO: 'Bulk (Nodules)',
  VP: 'Vacuum-Packed',
  VQ: 'Bulk, Liquefied Gas',
  VR: 'Bulk (Grains)',
  VY: 'Bulk, Solid, Fine Part(Powders)',
  WB: 'Wicker bottle',
  YD: 'Yard',
  ZZ: 'Not Available'
};

const cargoType = [
  {id: 'Container', name: 'Container'},
  {id: 'Vehicle', name: 'Vehicle'},
  {id: 'Package', name: 'Package'},
  {id: 'Dry Bulk', name: 'Dry Bulk'},
  {id: 'Liquid Bulk', name: 'Liquid Bulk'},
  {id: 'General Cargo', name: 'General Cargo'}
];

const days = [
  {id: 'MONDAY', name: 'MONDAY'},
  {id: 'TUESDAY', name: 'TUESDAY'},
  {id: 'WEDNESDAY', name: 'WEDNESDAY'},
  {id: 'THURSDAY', name: 'THURSDAY'},
  {id: 'FRIDAY', name: 'FRIDAY'},
  {id: 'SATURDAY', name: 'SATURDAY'},
  {id: 'SUNDAY', name: 'SUNDAY'}
];

export default {

  data() {
    return {

      customerData: '',
      containers: containers,

    };
  },

  methods: {


    notifyUser(type, title,text){
      return  this.loadNotification(type, 4000, title,  text,'response2', 1000, true, true)
    },

    loadNotification(type, duration, title, text, group, speed = 1000, ignoreDuplicates, closeOnClick, classes = 'n-light ') {
      this.$notify({
        classes:classes,
        group: group,
        title: title,
        text: text,
        closeOnClick: closeOnClick,
        duration: duration,
        type: type,
        speed: speed,
        animationName:'v-fade-left',
        position:'top right',
        ignoreDuplicates: ignoreDuplicates
      });
    },

    loadSessionExpiryNotification(type, duration, title, text, group, speed = 1000) {
      this.$notify({

        group: group,
        title: title,
        text: text,
        closeOnClick: true,
        duration: duration,
        type: type,
        speed: speed,
        ignoreDuplicates: true
      });
    },

    loadConnectionLostNotification(type, duration, title, text, group, speed = 1000) {
      this.$notify({

        group: group,
        title: title,
        text: text,
        closeOnClick: true,
        duration: duration,
        type: type,
        speed: speed,
        ignoreDuplicates: true
      });
    },

    getContainerName(code) {
      for (let k = 0; k < containers.length; k++) {
        if (code === containers[k].containerCode) {
          return containers[k].lengthofContainer + ' feet ( ' + containers[k].descriptionofContainerCode + ' )';
        }
      }
    },

    getGlobalCargoType() {
      return cargoType;
    },

    getDays() {
      return days;
    },

    autoYearGenerator(myYear) {
      const full = new Date();
      const currentYear = full.getFullYear();
      const yearsArray = [];

      for (let startYear = myYear; startYear <= currentYear; startYear++) {
        const yearObject = {

          value: startYear,
          text: startYear

        };

        yearsArray.push(yearObject);
      }

      return yearsArray;
    },

    dateToHuman(stringDate) {

      if (stringDate !== null || typeof stringDate !== undefined) {
        const splittedDate = stringDate.split('T');

        // return splittedDate[0];
        return this.$moment(splittedDate[0]).local().format("DD-MM-YYYY")
      }

    },

    dateTimeToHuman(stringDate) {

      if (stringDate !== null && typeof stringDate !== 'undefined') {

        const splittedDate = stringDate.split('T');
        const vuejsDate = splittedDate[0];

        if (typeof splittedDate[1] !== 'undefined' && splittedDate[1].toString().includes('.')) {

            const vuejsTime = splittedDate[1].split('.');
            return this.$moment(vuejsDate + ' ' + vuejsTime[0]).local().tz(this.$moment.tz.guess()).format("DD-MM-YYYY HH:mm:ss")
            // return vuejsDate + ' ' + vuejsTime[0];

        } else if (typeof splittedDate[1] !== 'undefined' && !splittedDate[1].includes('.')) {

          return this.$moment(vuejsDate + ' ' + splittedDate[1]).local().tz(this.$moment.tz.guess()).format("DD-MM-YYYY HH:mm:ss")
          // return vuejsDate + ' ' + splittedDate[1];

        } else {

            return this.$moment(vuejsDate).local().format("DD-MM-YYYY")
            // return vuejsDate;

        }

      }
    },

    /**
     * input: array of bl objects with packageType key
     * returns: pktyDetails key with details of the packageType Code
     */
    getPackageTypeCodeDetails(blArray) {
      if (blArray !== null && typeof blArray !== 'undefined') {
        for (let x = 0; x < blArray.length; x++) {
          blArray[x].pktyDetails = packageTypeCodes[blArray[x].packageType] ? packageTypeCodes[blArray[x].packageType] + ' (' + blArray[x].packageType + ')' : blArray[x].packageType;
        }
        return blArray;
      }
    },

    scrollPageTo(to, duration = 600) {
      const easeOutQuad = function (t, b, c, d) {
        t /= d;
        return -c * t * (t - 2) + b;
      };
      return new Promise((resolve, reject) => {
        const element = document.scrollingElement;
        if (typeof to === 'string') {
          to = document.querySelector(to) || reject();
        }
        if (typeof to !== 'number') {
          to = to.getBoundingClientRect().top + element.scrollTop;
        }
        const start = element.scrollTop;
        const change = to - start;
        let currentTime = 0;
        const increment = 20;
        const animateScroll = function () {
          currentTime += increment;
          const val = easeOutQuad(currentTime, start, change, duration);
          element.scrollTop = val;
          if (currentTime < duration) {
            setTimeout(animateScroll, increment);
          } else {
            resolve();
          }
        };
        animateScroll();
      });
    },

    removeComma(value) {

      if (value !== null && typeof value !== 'undefined' && value !== '') {

        return value.toString().replace(/\,/g, '');

      } else {

        return value;

      }

    },

    removeHyphen(value) {

      if (value !== null && typeof value !== 'undefined' && value !== '') {

        return value.toString().replace(/\-/g, '');

      } else {

        return value;

      }

    },

    removeCommaForArray(dataArray, keyValue) {
      if (dataArray !== null && typeof dataArray !== 'undefined') {
        for (let x = 0; x < dataArray.length; x++) {
          if (typeof dataArray[x] !== 'undefined') {
            dataArray[x][keyValue] = dataArray[x][keyValue].toString().replace(/\,/g, '');
          }
        }
        return dataArray;
      } else {
        return dataArray;
      }
    },

    spaceToUnderscoreString (string) {

      return string.replace(/ /g,"_")

    },

    putComma(value, decimalPlaces = 2) {

      if (value !== null && typeof value !== 'undefined') {

        let newValue = parseFloat(value).toFixed(decimalPlaces)
        let finalValue = '';

        if (newValue.toString().includes('.')) {

          let wholeNumber = ''
          let wholeNumberComma = ''
          wholeNumber = newValue.toString().split('.')[0]
          wholeNumberComma = wholeNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          finalValue = wholeNumberComma + '.' + newValue.toString().split('.')[1]

        } else {

          finalValue = newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        }

        return finalValue;

      } else {

        return '';

      }

    },
    underscoreToSpaceString (string) {

      return string.replace(/_/g, ' ')

    },

    hyphenToSpaceString (string) {

      return string.replace(/-/g, ' ')

    },

    spaceToHyphenString (string) {

      return string.replace(/\s+/g, '-').toLowerCase()

    },

    putHyphen(value) {
      if (value !== null && typeof value !== 'undefined') {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '-');
      } else {
        return '';
      }
    },

    camelCaseToTitleCase(camelCase){
      if (camelCase === null || typeof camelCase === 'undefined') {
        return '';
      }else{
        camelCase = camelCase.trim();
        var newText = "";
        for (var i = 0; i < camelCase.length; i++) {
          if (/[A-Z]/.test(camelCase[i])
            && i != 0
            && /[a-z]/.test(camelCase[i-1])) {
            newText += " ";
          }
          if (i == 0 && /[a-z]/.test(camelCase[i]))
          {
            newText += camelCase[i].toUpperCase();
          } else {
            newText += camelCase[i];
          }
        }

        return newText;
      }
},

    convertSnakeCaseToSentenceCase(value) {
      if (value !== null && typeof value !== 'undefined') {
        return value.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
      } else {
        return ';'
      }
    },

    scrollToBottom() {
      const position = document.body.clientHeight + window.innerHeight;
      this.scrollPageTo(position);
    },

    scrollToTop() {
      const position = document.body.clientHeight - window.innerHeight;
      this.scrollPageTo(position);
    },

    daysBetweenEAT(date1, date2) {

      const date11 = new Date(date1);
      const date22 = new Date(date2.split('T')[0]);

      // The number of milliseconds in one day
      const ONE_DAY = 1000 * 60 * 60 * 24;

      // Convert both dates to milliseconds
      const date1_ms = date11.getTime();
      const date2_ms = date22.getTime();

      // Calculate the difference in milliseconds
      const difference_ms = date2_ms - date1_ms;

      // Convert back to days and return
      return Math.round(difference_ms / ONE_DAY);

    },

    daysBetween(date1, date2, includeStartDate) {
      const date11 = new Date(date1);
      const date22 = new Date(date2);

      // The number of milliseconds in one day
      const ONE_DAY = 1000 * 60 * 60 * 24;

      // Convert both dates to milliseconds
      const date1_ms = date11.getTime();
      const date2_ms = date22.getTime();

      // Calculate the difference in milliseconds
      const difference_ms = Math.abs(date1_ms - date2_ms);

      // Convert back to days and return
      if(includeStartDate)
        return Math.round(difference_ms / ONE_DAY) + 1
      else
      return Math.round(difference_ms / ONE_DAY)
    },

    validateAmount(value) {
      // var value= $("#field1").val();
      const regex = /^(\d+|\d{1,3},\d{3}|\d{1,3},\d{3},\d{3}|\d{1,3}(,\d{3})*|\d{1,3}(,\d{3})*\.\d+)$/;

      if (regex.test(value)) {
        // Input is valid, check the number of decimal places
        const twoDecimalPlaces = /\.\d{2}$/g;
        const oneDecimalPlace = /\.\d{1}$/g;
        const noDecimalPlacesWithDecimal = /\.\d{0}$/g;

        if (value.match(twoDecimalPlaces)) {
          // all good, return as is
          return value;
        }
        if (value.match(noDecimalPlacesWithDecimal)) {
          // add two decimal places
          return value + '00';
        }
        if (value.match(oneDecimalPlace)) {
          // ad one decimal place
          return value + '0';
        }
        // else there is no decimal places and no decimal
        return value + '.00';
      }
      return null;
    },

    getMinioFileFromTesws(presignedUrl, type = null) {
      if (type === 'download') {
        const link = document.createElement('a');
        // link.download = fullName;
        link.href = presignedUrl;
        // link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        const link = document.createElement('a');
        // link.download = fullName;
        link.href = presignedUrl;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    updatedValueStatus(arrayStore, arrayData, type = null, name = null) {
      const finalArrayRemove = [];

      let counterEqual = 0;

      let counterChange = 0;

      for (let i = 0; i < arrayStore.length; ++i) {
        if (arrayStore[i] === arrayData[i]) {
          counterEqual++;
        } else {
          finalArrayRemove.push('( ' + arrayStore[i] + ' => ' + arrayData[i] + ' )');

          counterChange++;
        }
      }

      if (counterEqual > 0 && counterChange === 0) {
        return ', No changes made to ' + type + ' [ ' + name + ' ]';
      } else {
        if (finalArrayRemove.length === 1) {
          return ', Field updated is ' + finalArrayRemove + ' for ' + type + ' [ ' + name + ' ]';
        } else {
          return ', Fields updated are ' + finalArrayRemove + ' for ' + type + ' [ ' + name + ' ]';
        }
      }
    },

    // etoFixed(x) {

    //   if (Math.abs(x) < 1.0) {
    //     var e = parseInt(x.toString().split('e-')[1]);
    //     if (e) {
    //         x *= Math.pow(10,e-1);
    //         x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    //     }
    //   } else {
    //     var e = parseInt(x.toString().split('+')[1]);
    //     if (e > 20) {
    //         e -= 20;
    //         x /= Math.pow(10,e);
    //         x += (new Array(e+1)).join('0');
    //     }
    //   }
    //   console.log('x', x)
    //   return x;

    // },

    getApplicationStatus(IncomingStatus, newApplicationStatus) {

      let outGoingStatus = '';

      switch (IncomingStatus) {

        case 'Request Submitted':

          newApplicationStatus === 1 ? outGoingStatus = 'Submitted' : outGoingStatus = 'New';

          break;

        case 'Request Accepted':

          outGoingStatus = 'Accepted';

          break;

        case 'Request Returned For Major Changes':

          outGoingStatus = 'Has Major Issue';

          break;

        case 'Request Returned For Minor Changes':

          outGoingStatus = 'Has Minor Issue';

          break;

        case 'Request Resubmitted After Changes':

          outGoingStatus = 'Resubmitted After Attending Raised Issues';

          break;

        case 'Request is dismissed':

          outGoingStatus = 'Dismissed Application';

          break;

        case 'Draft Request':

          outGoingStatus = 'Draft';

          break;

        case 'Request Assessed':

          outGoingStatus = 'Tariffed';

          break;

        case 'Request Assessment Rejected':

          outGoingStatus = 'Tariff Rejected';

          break;

        case 'Request Charged':

          outGoingStatus = 'Charged';

          break;

        case 'Request Billed':

          outGoingStatus = 'Billed';

          break;

        case 'Approved':

          outGoingStatus = 'Approved';

          break;

        case 'Request Assessment Sent To GEPG':

          outGoingStatus = 'Bill Approved';

          break;

        case 'Request Bill Paid':

          outGoingStatus = 'Paid';

          break;

        case 'Request Closed':

          outGoingStatus = 'Closed';

          break;

        case 'Not Paid':

          outGoingStatus = 'Not Paid';

          break;

        case 'Paid':

          outGoingStatus = 'Paid';

          break;

        case 'Canceled':

          outGoingStatus = 'Cancelled';

          break;

        case 'Waiting Control Number':

          outGoingStatus = 'Waiting Control Number';

          break;

        case 'Not Approved':

          outGoingStatus = 'Not Approved';

          break;

        case 'ACCEPTED':

          outGoingStatus = 'Accepted';
  
          break;

        case 'CREATED':

          outGoingStatus = 'Created';
  
          break;

        case 'Submiting to GePG':

          outGoingStatus = 'Submiting to GePG';

          break;

        case 'Bill Expired':

            outGoingStatus = 'Expired';

        break;

        case 'Request Charge Rejected':

          outGoingStatus = 'Rejected Charge';

          break;

        default:

          outGoingStatus = 'Duplicate';
      }

      return outGoingStatus;

    },

    getBillStatus(IncomingStatus) {

      let outGoingStatus = '';

      switch (IncomingStatus) {

        case 'Submiting to GePG':

          outGoingStatus = 'submiting to GePG';

          break;

        case 'Canceled':

          outGoingStatus = 'canceled bills';

          break;

        case 'Paid':

          outGoingStatus = 'paid bills';

          break;

        case 'Not Paid':

          outGoingStatus = 'approved bills';

          break;

        case 'Not Approved':

          outGoingStatus = 'bills for approval';

          break;

        case 'Waiting Control Number':

          outGoingStatus = 'bills waiting GePG control number';

          break;

        case 'Bill Expired':

            outGoingStatus = 'expired bills';

        break;

        default:

          outGoingStatus = 'All';
      }

      return outGoingStatus;

    },

    applicationStatusAndNameMapper(IncomingStatus) {
      let outGoingStatus = '';

      switch (IncomingStatus) {
        case 'SUBMITTED':

          outGoingStatus = 'New';

          break;

        case 'APPROVED':

          outGoingStatus = 'Accepted';

          break;

        case 'REJECTED_MAJOR':

          outGoingStatus = 'Major Issue';

          break;

        case 'REJECTED_MINOR':

          outGoingStatus = 'Minor Issue';

          break;

        case 'RETURNED':

          outGoingStatus = 'Returned';

          break;

        case 'ASSESSED':

          outGoingStatus = 'Tariff';

          break;

        case 'REJECTED_CHARGE':

          outGoingStatus = 'Rejected Tariff';

          break;

        case 'CHARGED':

          outGoingStatus = 'Charged';

          break;

        case 'REJECTED_CHARGE':

          outGoingStatus = 'Rejected Charged';

          break;

        case 'BILLED':

          outGoingStatus = 'Billed';

          break;

        case 'BILL_APPROVED':

          outGoingStatus = 'Bill Approved';

          break;

        case 'PAID':

          outGoingStatus = 'Bill Paid';

          break;

        case 'CLOSED':

          outGoingStatus = 'Closed';

          break;

        case 'DISMISSED':

          outGoingStatus = 'Dismissed';

          break;

        case 'ALL':

          outGoingStatus = 'All';

          break;

        default:

          outGoingStatus = 'Duplicate';
      }

      return outGoingStatus;

    },

    applicationStatusAndUrlParamMapper(IncomingStatus) {
      let outGoingStatus = '';

      switch (IncomingStatus) {
        case 'draft-request':

          outGoingStatus = 'DRAFT';

          break;
        case 'request-submitted':

          outGoingStatus = 'SUBMITTED';

          break;

        case 'request-accepted':

          outGoingStatus = 'APPROVED';

          break;

        case 'request-returned-for-major-changes':

          outGoingStatus = 'REJECTED_MAJOR';

          break;

        case 'request-returned-for-minor-changes':

          outGoingStatus = 'REJECTED_MINOR';

          break;

        case 'request-resubmitted-after-changes':

          outGoingStatus = 'RETURNED';

          break;

        case 'request-assessed':

          outGoingStatus = 'ASSESSED';

          break;

        case 'request-assessment-rejected':

          outGoingStatus = 'REJECTED_ASSESSMENT';

          break;

        case 'request-charged':

          outGoingStatus = 'CHARGED';

          break;

        case 'request-charge-rejected':

          outGoingStatus = 'REJECTED_CHARGE';

          break;

        case 'request-billed':

          outGoingStatus = 'BILLED';

          break;

        case 'request-assessment-sent-to-gepg':

          outGoingStatus = 'BILL_APPROVED';

          break;

        case 'request-bill-paid':

          outGoingStatus = 'PAID';

          break;

        case 'request-closed':

          outGoingStatus = 'CLOSED';

          break;

        case 'request-is-dismissed':

          outGoingStatus = 'DISMISSED';

          break;

          default: // 'all':

          outGoingStatus = 'ALL';

      }

      return outGoingStatus;

    },

    getColoredStatus (status) {

      // if(!status.includes("Request") && !status.includes("is")) {

      //   if(status === 'Draft' || status === 'draft') { status = 'Draft Request' }
      //   else if (status === 'dismissed') { status = 'Request is Dismissed' }
      //   else { status = 'Request ' + status }

      // }

      let className = '';

      switch (status) {

        case 'Draft Request':

          className = 'class-draft-app';

          break;

        case 'Request Submitted':

          className = 'class-new-app';

          break;

        case 'Request Accepted':
        case 'APPROVED':
        case 'ACCEPTED':

          className = 'class-approved-app';

          break;

        case 'Request Returned For Major Changes':

          className = 'class-rejected-mojor-app';

          break;

        case 'Not Approved':

          className = 'class-bill-not-approved';

          break;

        case 'Waiting Control Number':

          className = 'class-bill-waiting-control-number';

          break;

        case 'Paid':
        case 'ISSUED':
        case 'Approved':

          className = 'class-paid-app';

          break;
        case 'Not Paid':

          className = 'class-bill-not-paid';

          break;

        case 'Canceled':

          className = 'class-rejected-mojor-app';

          break;

        case 'Bill Expired':

          className = 'class-charged-app';

          break;
        case 'Submiting to GePG':

          className = 'class-bill-submitting-to-gepg';

          break;
        case 'Request Returned For Minor Changes':

          className = 'class-rejected-minor-app';

          break;

        case 'Request Resubmitted After Changes':

          className = 'class-returned-app';

          break;

        case 'Request Assessed':

          className = 'class-assessed-app';

          break;

        case 'Request Assessment Rejected':

          className = 'class-rejected-assessment-app';

          break;

        case 'Request Charged':
        case 'CHARGED':

          className = 'class-charged-app';

          break;

        case 'Request Charge Rejected':
        case 'CANCELLED':

          className = 'class-rejected-charge-app';

          break;

        case 'Request Billed':
        case 'BILLED':

          className = 'class-billed-app';

          break;

        case 'Request Assessment Sent To GEPG':
        case 'CHECKED':

          className = 'class-bill-approved-app';

          break;

        case 'Request Bill Paid':
        case 'PAID':

          className = 'class-paid-app';

          break;

        case 'Request Closed':
        case 'CREATED':

          className = 'class-closed-app';

          break;

          default: //case 'Request is Dismissed':

          className = 'class-dismissed-app';

          break;

          //class-duplicate-app

      }

      return className;

    },

    getFriendlyMessageSentTime (dateTime) {

        dateTime = this.dateTimeToHuman(dateTime)
        let date = dateTime.split(' ')[0]
        let time = dateTime.split(' ')[1]
        date = date.split('-')
        let todayDate = new Date()
        let dateObject = this.$moment(new Date(this.$moment(date[2] + '-' + date[1] + '-' + date[0]).format('YYYY, MM, DD')))
        let today = this.$moment(new Date(this.$moment(todayDate.toISOString().split('T')[0]).format('YYYY, MM, DD')))

        if(dateObject.diff(today, 'days') === 0) {

            return 'Today ' + time

        } else if (dateObject.diff(today, 'days') === 1) {

            return 'Yesterday ' + time

        } else {

            return dateTime

        }

    },
    // validateTzPhoneNumber (startWith) {

    //   console.log('phoneNumber.charAt(0)', typeof phoneNumber.charAt(0))

    //   if(startWith === '255') {

    //     if(phoneNumber.charAt(0) === '2') {

    //       if(phoneNumber.charAt(1) == 5) {

    //         if(phoneNumber.charAt(2) == 5) {



    //         } else {

    //           phoneNumber = ''

    //         }

    //       } else {

    //         phoneNumber = ''

    //       }

    //     } else {

    //       phoneNumber = ''

    //     }

    //   }


    //   console.log('phoneNumber', phoneNumber)

    // },

    dateTimeToLocalDateTime (dateTime) {
      console.log(dateTime)
        let localDateTime = dateTime.getFullYear() + '-' + (dateTime.getMonth() + 1) + '-' + dateTime.getDate() + 'T' + dateTime.getHours() + ':' + dateTime.getMinutes() + ':' + dateTime.getSeconds()

        let dateSegment = localDateTime.split('T')[0]
        let timeSegment = localDateTime.split('T')[1]

        let year = dateSegment.split('-')[0]
        let month = dateSegment.split('-')[1]
        let day = dateSegment.split('-')[2]

        let hour = timeSegment.split(':')[0]
        let minute = timeSegment.split(':')[1]
        let second = timeSegment.split(':')[2]

        let fullMonth
        let fullDay

        let fullHour
        let fullMinute
        let fullSecond

        if(month.length === 1) { fullMonth = '0' + month } else { fullMonth = month }
        if(day.length === 1) { fullDay = '0' + day } else { fullDay = day }

        if(hour.length === 1) { fullHour = '0' + hour } else { fullHour = hour }
        if(minute.length === 1) { fullMinute = '0' + minute } else { fullMinute = minute }
        if(second.length === 1) { fullSecond = '0' + second } else { fullSecond = second }

        let fullDate =  year + '-' + fullMonth + '-' + fullDay + 'T' + fullHour + ':' + fullMinute + ':' + fullSecond + '.000Z'
        console.log("fullDate", year + '-' + fullMonth + '-' + fullDay + 'T' + fullHour + ':' + fullMinute + ':' + fullSecond + '.000Z')

        return fullDate

    },

    updatedRoleAndPermissionStatus(arrayAdded, arrayRemoved, arrayStore, name, type) {
      const finalArrayAdd = [];
      const finalArrayRemove = [];
      let counterContainAdd = 0;
      let counterNotContainAdd = 0;
      let counterContainRemove = 0;
      let counterNotContainRemove = 0;

      /* REMOVAL */
      for (let i = 0; i < arrayStore.length; i++) {
        if (arrayRemoved.includes(arrayStore[i])) {
          counterContainRemove++;
        } else {
          finalArrayRemove.push(arrayStore[i]);

          counterNotContainRemove++;
        }
      }

      /* ADDITIONAL */
      for (let i = 0; i < arrayAdded.length; i++) {
        if (arrayStore.includes(arrayAdded[i])) {
          counterContainAdd++;
        } else {
          finalArrayAdd.push(arrayAdded[i]);

          counterNotContainAdd++;
        }
      }

      if (type === 'role') {
        if (counterNotContainAdd === 0 && counterNotContainRemove === 0) {
          return ', No changes made to role for user [ ' + name + ' ]';
        }

        if (counterNotContainRemove > 0 && finalArrayRemove.length === 1 && counterNotContainAdd === 0) {
          return ', Role removed is ( ' + finalArrayRemove + ' ) from user [ ' + name + ' ]';
        }

        if (counterNotContainRemove > 0 && finalArrayRemove.length > 1 && counterNotContainAdd === 0) {
          return ', Roles removed are ( ' + finalArrayRemove + ' ) from user [ ' + name + ' ]';
        }

        if (counterNotContainAdd > 0 && finalArrayAdd.length === 1 && counterNotContainRemove === 0) {
          return ', Role assigned is ( ' + finalArrayAdd + ' ) to user [ ' + name + ' ]';
        }

        if (counterNotContainAdd > 0 && finalArrayAdd.length > 1 && counterNotContainRemove === 0) {
          return ', Roles assigned are ( ' + finalArrayAdd + ' ) to user [ ' + name + ' ]';
        }

        if (counterNotContainRemove > 0 && finalArrayRemove.length === 1 && counterNotContainAdd > 0 && finalArrayAdd.length === 1) {
          return ', Role removed is ( ' + finalArrayRemove + ' ), assigned is ( ' + finalArrayAdd + ' ) for user [ ' + name + ' ]';
        }

        if (counterNotContainRemove > 0 && finalArrayRemove.length > 1 && counterNotContainAdd > 0 && finalArrayAdd.length > 1) {
          return ', Roles removed are ( ' + finalArrayRemove + ' ), assigned are ( ' + finalArrayAdd + ' ) for user [ ' + name + ' ]';
        }

        if (counterNotContainRemove > 0 && finalArrayRemove.length === 1 && counterNotContainAdd > 0 && finalArrayAdd.length > 1) {
          return ', Role removed is ( ' + finalArrayRemove + ' ), assigned are ( ' + finalArrayAdd + ' ) for user [ ' + name + ' ]';
        }

        if (counterNotContainRemove > 0 && finalArrayRemove.length > 1 && counterNotContainAdd > 0 && finalArrayAdd.length === 1) {
          return ', Roles removed are ( ' + finalArrayRemove + ' ), assigned is ( ' + finalArrayAdd + ' ) for user [ ' + name + ' ]';
        }
      } else {
        if (counterNotContainAdd === 0 && counterNotContainRemove === 0) {
          return ', No changes made to role [ ' + name + ' ]';
        }

        if (counterNotContainRemove > 0 && finalArrayRemove.length === 1 && counterNotContainAdd === 0) {
          return ', Permission removed is ( ' + finalArrayRemove + ' ) from role [ ' + name + ' ]';
        }

        if (counterNotContainRemove > 0 && finalArrayRemove.length > 1 && counterNotContainAdd === 0) {
          return ', Permissions removed are ( ' + finalArrayRemove + ' ) from role [ ' + name + ' ]';
        }

        if (counterNotContainAdd > 0 && finalArrayAdd.length === 1 && counterNotContainRemove === 0) {
          return ', Permission assigned is ( ' + finalArrayAdd + ' ) to role [ ' + name + ' ]';
        }

        if (counterNotContainAdd > 0 && finalArrayAdd.length > 1 && counterNotContainRemove === 0) {
          return ', Permissions assigned are ( ' + finalArrayAdd + ' ) to role [ ' + name + ' ]';
        }

        if (counterNotContainRemove > 0 && finalArrayRemove.length === 1 && counterNotContainAdd > 0 && finalArrayAdd.length === 1) {
          return ', Permission removed is ( ' + finalArrayRemove + ' ), assigned is ( ' + finalArrayAdd + ' ) for role [ ' + name + ' ]';
        }

        if (counterNotContainRemove > 0 && finalArrayRemove.length > 1 && counterNotContainAdd > 0 && finalArrayAdd.length > 1) {
          return ', Permissions removed are ( ' + finalArrayRemove + ' ), assigned are ( ' + finalArrayAdd + ' ) for role [ ' + name + ' ]';
        }

        if (counterNotContainRemove > 0 && finalArrayRemove.length === 1 && counterNotContainAdd > 0 && finalArrayAdd.length > 1) {
          return ', Permission removed is ( ' + finalArrayRemove + ' ), assigned are ( ' + finalArrayAdd + ' ) for role [ ' + name + ' ]';
        }

        if (counterNotContainRemove > 0 && finalArrayRemove.length > 1 && counterNotContainAdd > 0 && finalArrayAdd.length === 1) {
          return ', Permissions removed are ( ' + finalArrayRemove + ' ), assigned is ( ' + finalArrayAdd + ' ) for role [ ' + name + ' ]';
        }
      }
    },

    trimString (string) {

        return string.toString().trim()

    },

    trimComma (string) {

      return string.replace(/,/g, '')

    },

    reverseString(str) {

        return str.split("").reverse().join("");

    },

    /**
     * @param {string | number}
     */
    commafyAmountWithoutDecimal(number) {

      if(isNaN(number.replace(/,/g, ''))) {

          number = number.replace(/[^\d.-]/g, '')

      }

      let numberString

      if(number.includes(',')) { numberString = number.replace(/,/g, '') } else { numberString = number }

      const emptyStr = '';
      const group_regex = /\d{3}/g;

      // delete extra period by regex replace.
      const trimComma = str => str.replace(/^[,]+|[,]+$/g, emptyStr)


      const str = numberString + emptyStr;
      const [integer, decimal] = str.split('.')

      const conversed = this.reverseString(integer);

      const grouped = trimComma(this.reverseString(
        conversed.replace(/\d{3}/g, match => `${match},`)
      ));

      return !decimal ? grouped : `${grouped}.${decimal}`;

    },

    hyphenifyTin(number) {

      let numberString

      if(number.includes('-')) { numberString = number.replace(/-/g, '') } else { numberString = number }

      const emptyStr = '';
      const group_regex = /\d{3}/g;

      // delete extra hyphen by regex replace.
      const trimComma = str => str.replace(/^[-]+|[-]+$/g, emptyStr)


      const str = numberString + emptyStr;
      const [integer, decimal] = str.split('.')

      const conversed = this.reverseString(integer);

      const grouped = trimComma(this.reverseString(
        conversed.replace(/\d{3}/g, match => `${match}-`)
      ));

      return !decimal ? grouped : `${grouped}.${decimal}`;

    },

    commafyAmountWithDecimal (number) {

      if(isNaN(number.replace(/./g, '')) || isNaN(number.replace(/,/g, ''))) {

          number = number.replace(/[^\d.-]/g, '')

      }

      let numberString

      if(number.includes(',')) { numberString = number.replace(/,/g, '') } else { numberString = number }

      const emptyStr = '';
      const group_regex = /\d{3}/g;

      // delete extra comma by regex replace.
      const trimComma = str => str.replace(/^[,]+|[,]+$/g, emptyStr)


      const str = numberString + emptyStr;
      const [integer, decimal] = str.split(',')

      if(integer.includes('.')) {

        const conversed = this.reverseString(integer.split('.')[0]);

        const grouped = trimComma(this.reverseString(
          conversed.replace(/\d{3}/g, match => `${match},`)
        ));

        return !decimal ? grouped + '.' + integer.split('.')[1] : `${grouped}.${decimal}`;

      } else {

        const conversed = this.reverseString(integer);

        const grouped = trimComma(this.reverseString(
          conversed.replace(/\d{3}/g, match => `${match},`)
        ));

        return !decimal ? grouped : `${grouped}.${decimal}`;

      }

    },

    amountDecimalPlaces (amount) {

      return Number(amount).toFixed(2);

    },

    clearLocalStorage () {

      localStorage.removeItem("search-params")
      localStorage.removeItem("cfa-customer")
      localStorage.removeItem("page-no")
      localStorage.removeItem("page-size")
      localStorage.removeItem("tab-status")
      localStorage.removeItem("operation-type")

      localStorage.removeItem("cfa-application-customer")
      localStorage.removeItem("edi-company")
      localStorage.removeItem("do-request-fca-agent")
      localStorage.removeItem("dc-charge-company")
      localStorage.removeItem("all-bill-summary-customer")

    },

    numberWithCommas (x) {

      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    },

    avoidPaste () {

      return false

    }

  }

};
