const baseUrl = '/shipping-agency';

const routes = [
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "DashboardLayout" */
        '../layout/DashboardLayout.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'DASHBOARD_VIEW'
    },
    children: [
      /* Principals Details IPO */
      {
        path: baseUrl + '/principal',
        name: 'PrincipalDetails',
        component: () => import(/* webpackChunkName: "PrincipalDetails" */
          '../pages/V2shippingAgency/principals/principalsList'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      /* Principal Items IPO */
      {
        path: baseUrl + '/principalItems/:principalId',
        name: 'PrincipalItems',
        component: () => import(/* webpackChunkName: "PrincipalItems" */
          '../pages/shippingAgency/principal/principalItems'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/principal/account/:principalId',
        name: 'PrincipalAccount',
        component: () => import(/* webpackChunkName: "PrincipalItems" */
          '../pages/shippingAgency/principal/principalAccountStatement'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      /* Disbursement Accounts IPO */
      {
        path: baseUrl + '/disbursementAccounts',
        name: 'DisbursementAccounts',
        component: () => import(/* webpackChunkName: "DisbursementAccounts" */
          '../pages/shippingAgency/disbursement/DisbursementAccounts'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },

      /* Sub-Disbursement Accounts Details IPO */
      {
        path: baseUrl + '/sub-disbursement-accounts/account-details/:accountId',
        name: 'SubDisbursementInvoicelist',
        component: () => import(/* webpackChunkName: "disbursementInvoicelist" */
          '../pages/shippingAgency/disbursement/sub-disbursment-account/subDisbursmentAccount'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/sub-disbursement/transactions/:referenceId',
        name: 'sub-disbursementTransactions',
        component: () => import(/* webpackChunkName: "disbursementTransactions" */
          '../pages/shippingAgency/disbursement/sub-disbursment-account/sub-disbersmentTransactions'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/principal/refunds/manage/:refundId/:subDisbursementId',
        name: 'principalRefundsManage',
        component: () => import(/* webpackChunkName: "principalRefundsManage" */
          '../pages/shippingAgency/principal/principalRefundsManage'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/all/refunds',
        name: 'principalRefunds',
        component: () => import(/* webpackChunkName: "principalRefunds" */
          '../pages/shippingAgency/principal/principalRefunds'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      /* Disbursement Invoice Payment Details IPO */
      {
        path: baseUrl + '/disbursement-invoice-payment-requests',
        name: 'disbursementPaymentList',
        component: () => import(/* webpackChunkName: "disbursementPaymentList" */
          '../pages/shippingAgency/disbursement/sub-disbursment-account/disbursementPaymentRequests'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/sub-disbursement/list',
        name: 'subdisbursemenList',
        component: () => import(/* webpackChunkName: "subdisbursemenList" */
          '../pages/shippingAgency/disbursement/sub-disbursment-account/allSubDisbursement'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/fda/list',
        name: 'fdaList',
        component: () => import(/* webpackChunkName: "fdaList" */
          '../pages/shippingAgency/fda/allFda'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/fda/account/:accountId',
        name: 'fdaAccount',
        component: () => import(/* webpackChunkName: "fdaAccount" */
          '../pages/shippingAgency/fda/fdaAccount'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      /* Disbursement Invoice Payment Details IPO */
      {
        path: baseUrl + '/disbursement-account-deposits',
        name: 'disbursementDeposit',
        component: () => import(/* webpackChunkName: "disbursementDeposit" */
          '../pages/shippingAgency/disbursement/sub-disbursment-account/disbursementDeposit'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/bill-of-lading/bills',
        name: 'billOfLadingBills',
        component: () => import(/* webpackChunkName: "billOfLadingBills" */
          '../pages/revenue/shippingAgency/bills/billOfLadingBilling'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/bill-of-lading/charges',
        name: 'billOfLadingCharges',
        component: () => import(/* webpackChunkName: "billOfLadingCharges" */
          '../pages/revenue/shippingAgency/charges/billOfLadingCharges'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/bill-of-lading/charges/view/:billChargeId',
        name: 'billOfLadingChargesView',
        component: () => import(/* webpackChunkName: "billOfLadingChargesView" */
          '../pages/revenue/shippingAgency/charges/billOfLadingChargesView'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/disbursement-invoice-payment-request-details/:id',
        name: 'disbursementPaymentRequestDetails',
        component: () => import(/* webpackChunkName: "disbursementPaymentRequestDetails" */
          '../pages/shippingAgency/disbursement/sub-disbursment-account/disbursmentPaymentRequestDetails'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      /* Maritime Vessels */
      {
        path: baseUrl + '/vessel/vessel-calls',
        name: 'VesselCalls',
        component: () => import(/* webpackChunkName: "VesselCalls" */
          '../pages/shippingAgency/vesselDetails/VesselCalls'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + '/vessel/vessel-list',
        name: 'VesselList',
        component: () => import(/* webpackChunkName: "VesselList" */
          '../pages/shippingAgency/vesselDetails/VesselList'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },


      {
        path: baseUrl + '/vessel-operations/:vesselId',
        name: 'vesselOperations',
        component: () => import(/* webpackChunkName: "vesselOperations" */
          '../pages/shippingAgency/vesselDetails/vesselOperations.vue'),
        meta: {
          requiresAuth: true,
          permission: 'DISBURSEMENT_SUB_{ACCOUNTID}_GET'
        }
      },

      {
        path: baseUrl + '/booking/application',
        name: 'bookingAplication',
        component: () => import(/* webpackChunkName: "vesselOperations" */
          '../pages/shippingAgency/application/bookingAplication'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + '/billoflading',
        name: 'billoflading',
        component: () => import(/* webpackChunkName: "vesselOperations" */
          '../pages/shippingAgency/billOfLading/billOfLadingList'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + '/booking/shippingorder/list',
        name: 'shippingorderList',
        component: () => import(/* webpackChunkName: "vesselOperations" */
          '../pages/shippingAgency/application/ViewShippingorder'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + '/booking/view/shippingorder/:shippingOrderId',
        name: 'shippingorder',
        component: () => import(/* webpackChunkName: "shippingorder" */
          '../pages/shippingAgency/application/ShippingOrder'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + '/booking/view/shippingorder/cargo/:shippingOrderId',
        name: 'shippingorderCargo',
        component: () => import(/* webpackChunkName: "shippingorderCargo" */
          '../pages/shippingAgency/application/applicationCargo'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + '/booking/view/shippingorder/cargo-details/:cargoId',
        name: 'shippingOrderActivityAssignment',
        component: () => import(/* webpackChunkName: "shippingOrderActivityAssignment" */
          '../pages/shippingAgency/application/shippingOrderCargoActivityAssignment'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + '/booking/view/shippingorder/cargo-details/activity-progress/:activityAssignmentId',
        name: 'shippingOrderActivityProgress',
        component: () => import(/* webpackChunkName: "shippingOrderActivityProgress" */
          '../pages/shippingAgency/application/shippingOrderActivityProgress'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + '/booking/edit/shippingorder/edit/:shippingOrderId',
        name: 'editShippingOrder',
        component: () => import(
          '../pages/shippingAgency/application/editShippingOrder'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + '/booking/assignContainers/shippingorder/:shippingOrderId',
        name: 'assignContainers',
        component: () => import(
          '../pages/shippingAgency/container/assignContainers'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + '/booking/container/:containerId/:shippingOrderId',
        name: 'editContainer',
        component: () => import(
          '../pages/shippingAgency/container/editContainer'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      // myAssignments
      {
        path: baseUrl + '/booking/my-assignments',
        name: 'myAssignments',
        component: () => import(
          '../pages/shippingAgency/application/myAssignments'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      /* Manifest By Voyage No */
      {
        path: baseUrl + '/manifest/voyageDetails/:voyageNo',
        name: 'manifestDetails',
        component: () => import(
          '../pages/shippingAgency/manifest/manifestDetails'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/manifest/bllist/',
        name: 'bllist',
        component: () => import(
          '../pages/shippingAgency/manifest/blList'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },

      /* Delivery Order Operations */
      {
        path: baseUrl + '/delivery-order-requests',
        name: 'deliveryOrdersRequests',
        component: () => import(
          '../pages/shippingAgency/deliveryOrder/deliveryOrderRequests'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },

      {
        path: baseUrl + '/requests/accepted/delivery-orders',
        name: 'acceptedDeliveryOrdersRequests',
        component: () => import(
          '../pages/shippingAgency/deliveryOrder/acceptedDeliveryOrders'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/requests/approve/delivery-orders/',
        name: 'approveDeliveryOrdersRequests',
        component: () => import(
          '../pages/shippingAgency/deliveryOrder/approveDeliveryOrderRequests'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/requests-details/delivery-order/:blNumber',
        name: 'deliveryOrdersRequestsDetails',
        component: () => import(
          '../pages/shippingAgency/deliveryOrder/deliveryOrderRequestDetails'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/requests-details/customer/delivery-order/:blNumbere',
        name: 'deliveryOrdersCustomerRequestsDetails',
        component: () => import(
          '../pages/shippingAgency/customer/deliveryOrserRequestsDetail'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/delivery-orders',
        name: 'deliveryOrders',
        component: () => import(
          '../pages/shippingAgency/deliveryOrder/deliveryOrderList'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/check/:status/delivery-orders/',
        name: 'checkDeliveryOrders',
        component: () => import(
          '../pages/shippingAgency/deliveryOrder/checkDeliveryOrder'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },

      /* Delivery Order Details */
      {
        path: baseUrl + '/delivery-orders/order-details/:doId',
        name: 'deliveryOrderDetails',
        component: () => import(
          '../pages/shippingAgency/deliveryOrder/deliveryOrderDetails'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },

      /* Delivery Order Details */
      {
        path: baseUrl + '/outward-activities',
        name: 'outwardActivities',
        component: () => import(
          '../pages/shippingAgency/outwardActivities/outwardActivitiesList'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      /* shipping Order Assignments */
      {
        path: baseUrl + '/booking/view/shipping-order/activity-assignments/:shippingOrderId',
        name: 'shippingOrderActivities',
        component: () => import(
          '../pages/shippingAgency/application/shippingOrderAllActivities'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },

      {
        path: baseUrl + '/booking/view/shipping-order/shipping-order-attachments/:shippingOrderId',
        name: 'shippingOrderAttachments',
        component: () => import(
          '../pages/shippingAgency/application/shippingOrderAttachments'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + '/bill-of-ladings/bill-of-lading/:id',
        name: 'billOfLadingDetails',
        component: () => import(
          '../pages/shippingAgency/billOfLading/billOfLadingDetails'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/edi/messages',
        name: 'ediMessages',
        component: () => import(
          '../pages/adminLogs/EdiMessages'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/failed/edi/messages',
        name: 'failedEdiMessages',
        component: () => import(
          '../pages/adminLogs/FailedEdiMessages'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      {
        path: baseUrl + '/revenue/messages',
        name: 'revenueMessages',
        component: () => import(
          '../pages/adminLogs/RevenueMessages'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      }
    ]
  }
];
export default routes
