import AGENCY_API from '../../../api/agency'

export default {
  namespaced: true,

  state: {

    vesselParticularId: '',
    requestId: '',
    requests: [],
    serviceCategories: [],
    subDisbursementAccountObject: {},
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    sortBy: '',
    voyageId: '',
    saveDetails: {},
    requests2: [],

    totalElements: "",
    totalPages: "",
    pageNumber: "",
    isFirst: false,
    isLast: false,

    hasSearchedVariable: false,
    searchParameter: '',

  },
  mutations: {

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter,

    VOYAGE_ID: (state, voyageId) => state.voyageId = voyageId,

    SUB_DISBURSMENT_ACCOUNT_OBJECT: (state, subDisbursementAccountObject) => state.subDisbursementAccountObject = subDisbursementAccountObject,

    VESSEL_PARTICULAR_ID: (state, vesselParticularId) => state.vesselParticularId = vesselParticularId,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

    TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

    PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

    IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

    IS_LAST: (state, isLast) => state.isLast = isLast,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    SERVICE_CATEGORY: (state, serviceCategories) => state.serviceCategories = serviceCategories,

    REQUEST2_LIST: (state, requests2) => state.requests2 = requests2,

    REQUEST3_LIST: (state, requests) => state.requests = requests,

    SAVE_DETAILS: (state, saveDetails) => state.saveDetails = saveDetails,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy

  },
  actions: {

    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    hasSearchngParams: ({state, commit}, payload) => commit('HAS_SEARCH_VARIABLE', payload),

    requestStatusing: ({ state, commit }, payload) => commit('REQUEST_STATUS', payload),

    savingDetails: ({ state, commit }, payload) => commit('SAVE_DETAILS', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    searchingParameter: ({ state, commit }, payload) => commit('SEARCH_PARAMETER', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    vesselParticularIding: ({ state, commit }, payload) => commit('VESSEL_PARTICULAR_ID', payload),

    voyageIding: ({ state, commit }, payload) => commit('VOYAGE_ID', payload),

    queryingDate: ({ state, commit }, payload) => commit('QUERY_DATE', payload),

    reloadingRequest: ({ state, commit }, payload) => commit('RELOAD_ALL_APPLICATIONS', payload),

    subdisbursmentaccountobjecting: ({ state, commit }, payload) => commit('SUB_DISBURSMENT_ACCOUNT_OBJECT', payload),

    /* CREATE SUB DA */

    createSubDisbursementAccount({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)

        AGENCY_API.addSubDisbursementAccount(getters.getSubDisbursementSaveDetails).then(
          response => {

            commit('MY_LOADER', false)
            resolve(response)

          }
        ).catch(e => {

          commit('MY_LOADER', false)

          reject(e)

        })
      })

    },

    getFinalDisbursementAccountDetailsById({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('SUB_DISBURSMENT_ACCOUNT_OBJECT', {})

        AGENCY_API.getFinalDisbursementAccountById(getters.getRequestId).then(
          response => {

            if (response.data.status) {

              if (Object.keys(response.data.data).length > 0) {

                commit('SUB_DISBURSMENT_ACCOUNT_OBJECT', response.data.data)
                commit('FIRST_LOADER', false)

              } else {

                commit('FIRST_LOADER', false)

              }
            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    getSubDisbursementAccountDetailsById({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('SUB_DISBURSMENT_ACCOUNT_OBJECT', {})

        AGENCY_API.getSubDisbursementAccountById(getters.getRequestId).then(
          response => {

            if (response.data.status) {

              if (Object.keys(response.data.data).length > 0) {

                commit('SUB_DISBURSMENT_ACCOUNT_OBJECT', response.data.data)
                commit('FIRST_LOADER', false)

              } else {

                commit('FIRST_LOADER', false)

              }
            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    saveSubDisbursmentAccountEstimations({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)

        AGENCY_API.saveSubDisbursmentAccountEstmateForManyServices(getters.getSubDisbursementSaveDetails).then(
          response => {

            commit('MY_LOADER', false)
            resolve(response)
          }
        ).catch(e => {

          commit('MY_LOADER', false)
          reject(e)
        })
      })
    },

    saveSubDisbursmentFinalAccountEstimation({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)

        AGENCY_API.saveSubDisbursmentFinalAccountEstmateForSingleService(getters.getSubDisbursementSaveDetails).then(
          response => {

            commit('MY_LOADER', false)
            resolve(response)
          }
        ).catch(e => {

          commit('MY_LOADER', false)
          reject(e)
        })
      })
    },

    saveSubDisbursmentAccountEstimation({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)

        AGENCY_API.saveSubDisbursmentAccountEstmateForSingleService(getters.getSubDisbursementSaveDetails).then(
          response => {

            commit('MY_LOADER', false)
            resolve(response)
          }
        ).catch(e => {

          commit('MY_LOADER', false)
          reject(e)
        })
      })
    },

    deleteFinalDisbursementInvoiceItem({ commit, getters }) {

      return new Promise((resolve, reject) => {

        // commit('FIRST_LOADER', true)

        AGENCY_API.deleteFinalDisbursementInvoiceItemById(getters.getRequestId).then(
          response => {

            // commit('MY_LOADER', false)
            resolve(response)
          }

        ).catch((e) => {

          // commit('MY_LOADER', false)
          reject(e)

        })
      })
    },

    deleteDisbursementInvoiceItem({ commit, getters }) {

      return new Promise((resolve, reject) => {

        // commit('FIRST_LOADER', true)

        AGENCY_API.deleteDisbursementInvoiceItemById(getters.getRequestId).then(
          response => {

            // commit('MY_LOADER', false)
            resolve(response)
          }

        ).catch((e) => {

          // commit('MY_LOADER', false)
          reject(e)

        })
      })
    },

    deleteDisbursementInvoice({ commit, getters }) {

      return new Promise((resolve, reject) => {

        // commit('FIRST_LOADER', true)

        AGENCY_API.deleteDisbursementInvoiceByInvoiceId(getters.getRequestId).then(
          response => {

            // commit('MY_LOADER', false)
            resolve(response)
          }

        ).catch((e) => {

          // commit('MY_LOADER', false)
          reject(e)

        })
      })
    },

    updateDisbursementInvoiceItem({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)

        AGENCY_API.updateDisbursementInvoiceItem(getters.getSubDisbursementSaveDetails).then(
          response => {

            if (response.data.status) {

              commit('MY_LOADER', false)

            } else {

              commit('MY_LOADER', false)

            }

            resolve(response)
          }
        ).catch(e => {

          commit('MY_LOADER', false)

          reject(e)
        })
      })
    },

    approveSubDisbursement({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)

        AGENCY_API.approveSubDisbursementAccount(getters.getRequestId).then(
          response => {

            if (response.data.status) {

              commit('MY_LOADER', false)

            } else {

              commit('MY_LOADER', false)

            }

            resolve(response)
          }
        ).catch(e => {

          commit('MY_LOADER', false)

          reject(e)
        })
      })
    },
    approveFinalDisbursement({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)

        AGENCY_API.approveFDA(getters.getRequestId).then(
            response => {

              if (response.data.status) {

                commit('MY_LOADER', false)

              } else {

                commit('MY_LOADER', false)

              }

              resolve(response)
            }
        ).catch(e => {

          commit('MY_LOADER', false)

          reject(e)
        })
      })
    },

    getAllDisbursementInvoicePaymentByInvoiceNo({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.listAllDisbursementInvoicePaymentByInvoiceNo(getters.getSubDisbursementRequestId).then(
          response => {

            if (response.data.status) {

              if (response.data.data.length > 0) {

                commit('REQUEST_LIST', response.data.data)
                commit('PAGE_COUNT', 1)
                commit('FIRST_LOADER', false)

              } else {

                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)

              }

            } else {}

            resolve()
          }
        ).catch(e => {

          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    addDisbursementInvoicePayment({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)

        AGENCY_API.addDisbursementInvoicePayment(getters.getSubDisbursementSaveDetails).then(
          response => {

            if (response.data.status) {

              commit('MY_LOADER', false)

            } else {

              commit('MY_LOADER', false)

            }

            resolve(response)
          }
        ).catch(e => {

          commit('MY_LOADER', false)

          reject(e)
        })
      })
    },

    loadDisbursementDepositsOnCreated ({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_API.loadDisbursementDeposits(getters.getSearchParameter).then(
          response => {

            if (response.data.data.content.length > 0) {

              commit('REQUEST_LIST', response.data.data.content);
              commit('FIRST_LOADER', false);
              commit('TOTAL_ELEMENTS', response.data.data.totalElements);
              commit('TOTAL_PAGES', response.data.data.totalPages);
              commit('PAGE_NUMBER', response.data.data.number);
              commit('IS_FIRST', response.data.data.first);
              commit('IS_LAST', response.data.data.last);
              commit('NO_DATA_FOUND', false);

            } else {

              commit('NO_DATA_FOUND', true);
              commit('TOTAL_ELEMENTS', response.data.data.totalElements);
              commit('TOTAL_PAGES', response.data.data.totalPages);
              commit('PAGE_NUMBER', response.data.data.number);
              commit('IS_FIRST', response.data.data.first);
              commit('IS_LAST', response.data.data.last);
              commit('FIRST_LOADER', false);

            }

            resolve();
          }
        ).catch(() => {

          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },

    loadDisbursementDepositsAfterCreated ({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_API.loadDisbursementDeposits(getters.getSearchParameter).then(
          response => {

            if (response.data.data.content.length > 0) {

              commit('REQUEST_LIST', response.data.data.content);
              commit('MY_LOADER', false);
              commit('TOTAL_ELEMENTS', response.data.data.totalElements);
              commit('TOTAL_PAGES', response.data.data.totalPages);
              commit('PAGE_NUMBER', response.data.data.number);
              commit('IS_FIRST', response.data.data.first);
              commit('IS_LAST', response.data.data.last);
              commit('NO_DATA_FOUND', false);

            } else {

              commit('NO_DATA_FOUND', true);
              commit('TOTAL_ELEMENTS', response.data.data.totalElements);
              commit('TOTAL_PAGES', response.data.data.totalPages);
              commit('PAGE_NUMBER', response.data.data.number);
              commit('IS_FIRST', response.data.data.first);
              commit('IS_LAST', response.data.data.last);
              commit('MY_LOADER', false);

            }

            resolve();
          }
        ).catch(() => {

          commit('MY_LOADER', false);

          reject(e);
        });
      });
    },

    loadDisbursementDepositsOnSearch ({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        commit('HAS_SEARCH_VARIABLE', true)

        AGENCY_API.loadDisbursementDeposits(getters.getSearchParameter).then(
          response => {

            if (response.data.data.content.length > 0) {

              commit('REQUEST_LIST', response.data.data.content);
              commit('FIRST_LOADER', false);
              commit('TOTAL_ELEMENTS', response.data.data.totalElements);
              commit('TOTAL_PAGES', response.data.data.totalPages);
              commit('PAGE_NUMBER', response.data.data.number);
              commit('IS_FIRST', response.data.data.first);
              commit('IS_LAST', response.data.data.last);
              commit('NO_DATA_FOUND', false);

            } else {

              commit('NO_DATA_FOUND', true);
              commit('TOTAL_ELEMENTS', response.data.data.totalElements);
              commit('TOTAL_PAGES', response.data.data.totalPages);
              commit('PAGE_NUMBER', response.data.data.number);
              commit('IS_FIRST', response.data.data.first);
              commit('IS_LAST', response.data.data.last);
              commit('FIRST_LOADER', false);

            }

            resolve();
          }
        ).catch(() => {

          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },


















































    loadDisbursementCategoriesOnCreated({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('SERVICE_CATEGORY', [])

        AGENCY_API.loadDisbursementDeposits(getters.getPageNo, getters.getPageSize).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('SERVICE_CATEGORY', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('FIRST_LOADER', false)

          reject(e)
        })

      })

    },

    getSubDisBursemntAccountsByVessel({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.listAllDisbursementAccountsByVesselId(getters.getVesselParticularId).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    loadDisbursementCategoriesById({ commit, getters }) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.getServiceCategoryById(getters.getSubDisbursementList).then(
          response => {

            if (response.data.status) {

              if (Object.keys(response.data.data).length > 0) {

                commit('REQUEST_LIST', response.data.data)
                commit('PAGE_COUNT', 1)
                commit('FIRST_LOADER', false)

              } else {

                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)

              }

            }

            resolve()

          }

        ).catch(e => {

          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    // END - MAIN *******

    addDisbursementCategory({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.addServiceCategory(getters.getSubDisbursementSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data.content);
                commit('PAGE_COUNT', response.data.data.totalPages)
                commit('FIRST_LOADER', false)
                commit('FIRST_LOADER', false)
                // commit('ERROR_MESSAGE', ", Requests Not loaded, please contact system administrator");
                // commit('SUCCESS_MESSAGE', "");
                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    updateDisbursementCategory({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.updateServiceCategory(getters.getSubDisbursementSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data);
                // commit('PAGE_COUNT', response.data.message);
                commit('SUCCESS_MESSAGE', 'Successfully updated')
                commit('FIRST_LOADER', false)
                commit('SUCCESS_ALERT', true)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
                commit('ERROR_ALERT', true)
                commit('ERROR_MESSAGE', 'Sorry, something went wrong, please contact your System Administrator')
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    addDisbursementItem({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.addServiceItem(getters.getSubDisbursementSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data.content);
                commit('PAGE_COUNT', response.data.data.totalPages)
                commit('FIRST_LOADER', false)
                commit('FIRST_LOADER', false)
                // commit('ERROR_MESSAGE', ", Requests Not loaded, please contact system administrator");
                // commit('SUCCESS_MESSAGE', "");
                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    updateDisbursementItem({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.updateServiceItem(getters.getSubDisbursementSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data);
                // commit('PAGE_COUNT', response.data.message);
                commit('SUCCESS_MESSAGE', 'Successfully updated')
                commit('FIRST_LOADER', false)
                commit('SUCCESS_ALERT', true)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
                commit('ERROR_ALERT', true)
                commit('ERROR_MESSAGE', 'Sorry, something went wrong, please contact your System Administrator')
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    //  from disbursement Controller

    registerDisbursementAccount({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.addDisbursementAccount(getters.getSubDisbursementSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('FIRST_LOADER', false)
                commit('FIRST_LOADER', false)

                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
              } else {
                commit('ERROR_ALERT', true)
                commit('SUCCESS_ALERT', false)

                // commit('NO_DATA_FOUND', true);
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    updateDisbursementAccount({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.updateDisbursementAccount(getters.getSubDisbursementSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('FIRST_LOADER', false)
                commit('FIRST_LOADER', false)

                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
              } else {
                commit('ERROR_ALERT', true)
                commit('SUCCESS_ALERT', false)

                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    addSubDisbursementInvoice({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])
        AGENCY_API.addDisbursementInvoice(getters.getSubDisbursementSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('FIRST_LOADER', false)
                commit('FIRST_LOADER', false)

                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('ERROR_ALERT', true)
                commit('SUCCESS_ALERT', false)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('ERROR_ALERT', true)
              commit('SUCCESS_ALERT', false)
              commit('FIRST_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)
          console.log(e)
          reject(e)
        })
      })
    },

    // deleteDisbursementInvoiceItem({ commit, getters }) {
    //   return new Promise((resolve, reject) => {
    //     commit('FIRST_LOADER', true)
    //     AGENCY_API.deleteDisbursementInvoiceItemById(getters.getSubDisbursementRequestId).then(
    //       response => {
    //         if (response.data.status && response.data.message === 'Success') {
    //           commit('FIRST_LOADER', false)
    //           commit('SUCCESS_ALERT', true)
    //           commit('ERROR_ALERT', false)
    //           resolve()
    //         } else {
    //           commit('SUCCESS_ALERT', false)
    //           commit('FIRST_LOADER', false)
    //           commit('ERROR_ALERT', true)
    //           resolve()
    //         }
    //       }
    //     ).catch((e) => {
    //       commit('FIRST_LOADER', false)
    //       commit('SUCCESS_ALERT', false)
    //       commit('ERROR_ALERT', true)
    //       reject(e)
    //     })
    //   })
    // },

    // deleteDisbursementInvoice({ commit, getters }) {
    //   return new Promise((resolve, reject) => {
    //     commit('FIRST_LOADER', true)
    //     AGENCY_API.deleteDisbursementInvoiceByInvoiceId(getters.getSubDisbursementRequestId).then(
    //       response => {
    //         if (response.data.status && response.data.message === 'Deleted') {
    //           commit('FIRST_LOADER', false)
    //           commit('SUCCESS_ALERT', true)
    //           commit('ERROR_ALERT', false)
    //           resolve()
    //         } else {
    //           commit('SUCCESS_ALERT', false)
    //           commit('FIRST_LOADER', false)
    //           commit('ERROR_ALERT', true)
    //           resolve()
    //         }
    //       }
    //     ).catch((e) => {
    //       commit('FIRST_LOADER', false)
    //       commit('SUCCESS_ALERT', false)
    //       commit('ERROR_ALERT', true)
    //       reject(e)
    //     })
    //   })
    // },
    getDisbursementAccountByInvoiceNo({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])
        AGENCY_API.getDisdbursementAccountByInvoiceNo(getters.getSearchParameter).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true)

                commit('FIRST_LOADER', false)
                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('NO_DATA_FOUND', true)

              commit('FIRST_LOADER', false)
              commit('SUCCESS_ALERT', false)
              commit('ERROR_ALERT', true)
              commit('FIRST_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    getDisbursementAccountPDF({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.getDisbursementAccountPDF(getters.getSubDisbursementRequestId).then(
          response => {
            this.myloader = false
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'PROFORMA DISBURSEMENT ACCOUNT' + getters.getQueryDate + '.pdf')
            document.body.appendChild(link)
            link.click()

            commit('SUCCESS_ALERT', true)
            commit('ERROR_ALERT', false)
            commit('FIRST_LOADER', false)

            resolve()
          }
        ).catch(e => {
          console.log(e)
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    loadAllDisbursementAccounts({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])
        commit('NO_DATA_FOUND', false)
        AGENCY_API.listAllDisbursementAccount(getters.getPageNo, getters.getPageSize).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                // commit('PAGE_COUNT', response.data.data.totalPages);
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.data.totalPages)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    getDisbursementAccountByVesselId({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST2_LIST', [])

        AGENCY_API.getDisbursementAccountByVesselId(getters.getSubDisbursementRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST2_LIST', response.data.data)
                commit('PAGE_COUNT', 1)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('REQUEST2_LIST', [])
              commit('FIRST_LOADER', false)
              commit('NO_DATA_FOUND', true)
            }

            resolve()
          }
        ).catch(e => {
          commit('NO_DATA_FOUND', true)
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    // from disbursement Invoice Controller

    // listAllDisbursementInvoicePayment({ commit, getters }) {
    //   return new Promise((resolve, reject) => {
    //     commit('FIRST_LOADER', true)
    //     commit('REQUEST_LIST', [])

    //     AGENCY_API.listAllDisbursementInvoicePayment(getters.getPageNo, getters.getPageSize).then(
    //       response => {
    //         if (response.data.status) {
    //           if (Object.keys(response.data.data).length > 0) {
    //             commit('REQUEST_LIST', response.data.data)
    //             commit('PAGE_COUNT', 1)
    //             commit('FIRST_LOADER', false)
    //           } else {
    //             commit('NO_DATA_FOUND', true)
    //             commit('PAGE_COUNT', response.data.message)
    //             commit('FIRST_LOADER', false)
    //           }
    //         } else {

    //         }

    //         resolve()
    //       }
    //     ).catch(e => {
    //       commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
    //       commit('SUCCESS_MESSAGE', '')
    //       commit('SUCCESS_ALERT', false)
    //       commit('ERROR_ALERT', true)
    //       commit('FIRST_LOADER', false)

    //       reject(e)
    //     })
    //   })
    // },
    // listAllDisbursementInvoicePaymentByInvoiceNo({ commit, getters }) {
    //   return new Promise((resolve, reject) => {
    //     commit('FIRST_LOADER', true)
    //     commit('REQUEST2_LIST', [])
    //     commit('NO_DATA_FOUND', false)

    //     AGENCY_API.listAllDisbursementInvoicePaymentByInvoiceNo(getters.getSubDisbursementRequestId).then(
    //       response => {
    //         if (response.data.status) {
    //           if (response.data.data.length > 0) {
    //             commit('REQUEST2_LIST', response.data.data)
    //             commit('PAGE_COUNT', 1)
    //             commit('FIRST_LOADER', false)
    //           } else {
    //             commit('NO_DATA_FOUND', true)
    //             commit('PAGE_COUNT', response.data.message)
    //             commit('FIRST_LOADER', false)
    //           }
    //         } else {

    //         }

    //         resolve()
    //       }
    //     ).catch(e => {
    //       commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
    //       commit('SUCCESS_MESSAGE', '')
    //       commit('SUCCESS_ALERT', false)
    //       commit('ERROR_ALERT', true)
    //       commit('FIRST_LOADER', false)

    //       reject(e)
    //     })
    //   })
    // },
    listAllDisbursementInvoicePaymentByReferenceNo({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.getDisbursementInvoicePaymentByReference(getters.getSubDisbursementRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('PAGE_COUNT', 1)
                commit('FIRST_LOADER', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    // addDisbursementInvoicePayment({ commit, getters }) {
    //   return new Promise((resolve, reject) => {
    //     commit('FIRST_LOADER', true)
    //     commit('REQUEST_LIST', [])

    //     AGENCY_API.addDisbursementInvoicePayment(getters.getSubDisbursementSaveDetails).then(
    //       response => {
    //         if (response.data.status) {
    //           if (Object.keys(response.data.data).length > 0) {
    //             commit('REQUEST_LIST', response.data.data)
    //             commit('PAGE_COUNT', 1)
    //             commit('FIRST_LOADER', false)
    //             commit('SUCCESS_ALERT', true)
    //             commit('ERROR_ALERT', false)
    //           } else {
    //             commit('NO_DATA_FOUND', true)
    //             commit('PAGE_COUNT', response.data.message)
    //             commit('FIRST_LOADER', false)
    //             commit('SUCCESS_ALERT', false)
    //             commit('ERROR_ALERT', true)
    //           }
    //         } else {

    //         }

    //         resolve()
    //       }
    //     ).catch(e => {
    //       commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
    //       commit('SUCCESS_MESSAGE', '')
    //       commit('SUCCESS_ALERT', false)
    //       commit('ERROR_ALERT', true)
    //       commit('FIRST_LOADER', false)

    //       reject(e)
    //     })
    //   })
    // },
    editDisbursementInvoicePayment({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.updateDisbursementInvoicePayment(getters.getSubDisbursementSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('PAGE_COUNT', 1)
                commit('FIRST_LOADER', false)
                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
              } else {
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
                commit('SUCCESS_ALERT', false)
                commit('ERROR_ALERT', true)
              }
            } else {
              commit('FIRST_LOADER', false)
              commit('SUCCESS_ALERT', false)
              commit('ERROR_ALERT', true)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    // approveDisbursementInvoicePayment({ commit, getters }) {
    //   return new Promise((resolve, reject) => {
    //     commit('FIRST_LOADER', true)
    //     commit('REQUEST_LIST', [])

    //     AGENCY_API.approveDisbursementInvoicePayment(getters.getSubDisbursementRequestId).then(
    //       response => {
    //         if (response.data.status) {
    //           if (Object.keys(response.data.data).length > 0) {
    //             // commit('REQUEST_LIST', response.data.data);
    //             commit('PAGE_COUNT', 1)
    //             commit('FIRST_LOADER', false)

    //             commit('ERROR_MESSAGE', '')
    //             commit('SUCCESS_MESSAGE', 'Payment Approved Succesfully')
    //             commit('SUCCESS_ALERT', true)
    //             commit('ERROR_ALERT', false)
    //           } else {
    //             commit('ERROR_MESSAGE', 'Payment not approved, please contact system administrator')
    //             commit('SUCCESS_MESSAGE', '')
    //             commit('SUCCESS_ALERT', false)
    //             commit('ERROR_ALERT', true)
    //             commit('NO_DATA_FOUND', true)
    //             commit('PAGE_COUNT', response.data.message)
    //             commit('FIRST_LOADER', false)
    //           }
    //         } else {

    //         }

    //         resolve()
    //       }
    //     ).catch(e => {
    //       commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
    //       commit('SUCCESS_MESSAGE', '')
    //       commit('SUCCESS_ALERT', false)
    //       commit('ERROR_ALERT', true)
    //       commit('FIRST_LOADER', false)

    //       reject(e)
    //     })
    //   })
    // }

  },
  getters: {

    getSubDisbursementVoyageId: state => state.voyageId,

    getRequestList: state => state.requests,

    getSubDisbursementRequestList2: state => state.requests2,

    getSubDisbursementSaveDetails: state => state.saveDetails,

    getPageCount: state => state.pageCount,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSubDisbursementSuccessAlert: state => state.SuccessAlert,

    getSubDisbursementErrorMessage: state => state.ErrorMessage,

    getSubDisbursementErrorAlert: state => state.ErrorAlert,

    getSubDisbursementSuccessMessage: state => state.SuccessMessage,

    getVesselParticularId: state => state.vesselParticularId,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyLoader: state => state.myLoader,

    getSortBy: state => state.sortBy,

    getTotalElement: state => state.totalElements,

    getTotalPage: state => state.totalPages,

    getPageNumber: state => state.pageNumber,

    getIsFirst: state => state.isFirst,

    getIsLast: state => state.isLast,

    getRequestId: state => state.requestId,

    getServiceCategories: state => state.serviceCategories,

    getSubDisbursementAccountObject: state => state.subDisbursementAccountObject,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParameter: state => state.searchParameter,

  }

}
