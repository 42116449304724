import AGENCY_APi from '../../../api/agency'
export default {
  namespaced: true,
  state: {
    statementOfFacts:[],
    vesselId:'',
    requestid:'',
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    saveDetails:{}
  },
  mutations:{
    VESSEL_ID: (state, vesselId) => state.vesselId = vesselId,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    SAVE_DETAILS: (state, saveDetails) => state.saveDetails = saveDetails,

    STATEMENT_OF_FACTS: (state, statementOfFacts) => state.statementOfFacts = statementOfFacts,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

  },
  actions:{

    savingDetails: ({ state, commit }, payload) => commit('SAVE_DETAILS', payload),

    vesselId: ({ state, commit }, payload) => commit('VESSEL_ID', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    getStatementOfFactsByVesselParticularId ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('STATEMENT_OF_FACTS', [])

        AGENCY_APi.listAllVesselParticularActivityByVesselPArticularId(getters.getSOFVesselId).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('STATEMENT_OF_FACTS', response.data.data)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)

              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
              commit('SUCCESS_MESSAGE', '')
              commit('SUCCESS_ALERT', false)
              commit('ERROR_ALERT', true)
              commit('NO_DATA_FOUND', true)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    addStatementOfFacts ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        AGENCY_APi.addVesselParticularActivity(getters.getSOFSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('FIRST_LOADER', false)
                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
              } else {
                commit('SUCCESS_ALERT', false)
                commit('ERROR_ALERT', true)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('SUCCESS_ALERT', false)
              commit('ERROR_ALERT', true)
              commit('FIRST_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {
          commit('NO_DATA_FOUND', true)
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    updateStatementOfFacts ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        AGENCY_APi.editVesselParticularActivity(getters.getSOFSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('FIRST_LOADER', false)
                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)
              } else {
                commit('SUCCESS_ALERT', false)
                commit('ERROR_ALERT', true)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('SUCCESS_ALERT', false)
              commit('ERROR_ALERT', true)
              commit('FIRST_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {
          commit('NO_DATA_FOUND', true)
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    getStatementOfFactsByStatementOfFactsId ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('STATEMENT_OF_FACTS', [])

        AGENCY_APi.getVesselParticularActivityById(getters.getSOFRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('STATEMENT_OF_FACTS', response.data.data)
                commit('PAGE_COUNT', 1)
                commit('FIRST_LOADER', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
              commit('SUCCESS_MESSAGE', '')
              commit('SUCCESS_ALERT', false)
              commit('ERROR_ALERT', true)
              commit('NO_DATA_FOUND', true)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

  },
  getters:{

    getStatementOfFacts: state => state.statementOfFacts,

    getSOFVesselId: state => state.vesselId,

    getSOFSaveDetails: state => state.saveDetails,

    getSOFMyFirstLoader: state => state.myFirstLoader,

    getSOFNoDataFound: state => state.noDataFound,

    getSOFSuccessAlert: state => state.SuccessAlert,

    getSOFErrorMessage: state => state.ErrorMessage,

    getSOFErrorAlert: state => state.ErrorAlert,

    getSOFSuccessMessage: state => state.SuccessMessage,

    getSOFRequestId: state => state.requestId,

  }
}
