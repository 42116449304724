export default {

  namespaced: true,

  state: {

    valuesBeforeEdited: [],
    nameBeforeDeleted: ''

  },

  mutations: {

    VALUES_BEFORE_EDIT: (state, valuesBeforeEdited) => state.valuesBeforeEdited = valuesBeforeEdited,

    NAME_BEFORE_DELETE: (state, nameBeforeDeleted) => state.nameBeforeDeleted = nameBeforeDeleted

  },

  actions: {

    valuesBeforeEdit: ({ state, commit }, payload) => commit('VALUES_BEFORE_EDIT', payload),

    nameBeforeDelete: ({ state, commit }, payload) => commit('NAME_BEFORE_DELETE', payload)

  },

  getters: {

    getValuesBeforeEdit: state => state.valuesBeforeEdited,

    getNameBeforeDelete: state => state.nameBeforeDeleted

  }

}
