const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "DashboardLayout" */
      '../layout/DashboardLayout.vue'),
    meta: {
      requiresAuth: true,
      permission: 'DASHBOARD_VIEW'
    },
    children: [
      {
        path: '/overview/revenue',
        name: 'OverviewRevenue',
        component: () => import(/* webpackChunkName: "OverviewRevenue" */
          '../pages/OverviewRevenue'),
        meta: {
          requiresAuth: true,
          permission: 'DASHBOARD_VIEW'
        }
      },

      /* Revenue */
      {
        path: 'clearing-and-forwarding/application-charges',
        name: 'ChargedApplications',
        component: () => import(/* webpackChunkName: "ChargedApplications" */
          '../pages/revenue/clearingAndForwarding/charges/chargedApplications'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_CHARGED-REQUESTS_GET'
        }
      },
      {
        path: 'clearing-and-forwarding/application-bills/',
        name: 'CFABillsWaitingApproval',
        component: () => import(/* webpackChunkName: "CFABillsWaitingApproval" */
          '../pages/revenue/clearingAndForwarding/bills/billsWaitingApproval'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET'
        }
      },
      {
        path: 'shipping-tally/tally-sheet-charges',
        name: 'ChargedTallySheets',
        component: () => import(/* webpackChunkName: "ChargedTallySheets" */
          '../pages/revenue/shippingTallying/charges/chargedTallySheets'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_CHARGED-REQUESTS_GET'
        }
      },
      {
        path: 'shipping-tally/tally-sheet-bills/',
        name: 'TallySheetBillsWaitingApproval',
        component: () => import(/* webpackChunkName: "TallySheetBillsWaitingApproval" */
          '../pages/revenue/shippingTallying/bills/billsWaitingApproval'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET'
        }
      },
      {
        path: 'clearing-and-forwarding/bulk-billing',
        name: 'bulkBilling',
        component: () => import(/* webpackChunkName: "bulkBilling" */
          '../pages/revenue/clearingAndForwarding/charges/subComponents/bulkBilling'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_CHARGED-REQUESTS_GET'
        }
      },
      {
        path: 'clearing-and-forwarding/application-charge-view/:requestId',
        name: 'ApplicationsCharges',
        component: () => import(/* webpackChunkName: "ApplicationsCharges" */
          '../pages/revenue/clearingAndForwarding/charges/application'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_CHARGED-REQUESTS_GET'
        }
      },
      {
        path: 'shipping-tally/tally-sheet-charge-view/:tallySheetChargeId',
        name: 'TallySheetChargeDetails',
        component: () => import(/* webpackChunkName: "TallySheetChargeDetails" */
          '../pages/revenue/shippingTallying/charges/application'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_CHARGED-REQUESTS_GET'
        }
      },
      {
        path: 'clearing-and-forwarding/application-bill-view/:billId',
        name: 'ApplicationsCBills',
        component: () => import(/* webpackChunkName: "ApplicationsCBills" */
          '../pages/revenue/clearingAndForwarding/bills/application'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_CHARGED-REQUESTS_GET'
        }
      },
      {
        path: 'document-control/manifest-bill-view/:billId',
        name: 'ManifestsCBills',
        component: () => import(/* webpackChunkName: "ApplicationsCBills" */
          '../pages/revenue/clearingAndForwarding/bills/application'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_CHARGED-REQUESTS_GET'
        }
      },
      {
        path: 'bills/customer/clearing-and-forwarding/bills',
        name: 'CFACustomerBillsList',
        component: () => import(/* webpackChunkName: "CustomerBills" */
          '../pages/revenue/subComponents/CustomerBills'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET'
        }
      },
      {
        path: 'document-control/manifest-bills/',
        name: 'DCBillsWaitingApproval',
        component: () => import(/* webpackChunkName: "DCBillsWaitingApproval" */
          '../pages/revenue/documentControl/bills/billsWaitingApproval'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET'
        }
      },
      {
        path: 'shipping-tallying/charges',
        name: 'TallyCharges',
        component: () => import(/* webpackChunkName: "TallyCharges" */
          '../pages/revenue/shippingTallying/charges/tallyCharges'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET'
        }
      },
      {
        path: 'shipping-tallying/stuffing-charges',
        name: 'TallyStuffingCharges',
        component: () => import(/* webpackChunkName: "TallyStuffingCharges" */
          '../pages/revenue/shippingTallying/charges/stuffingCharges'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET'
        }
      },
      {
        path: 'shipping-tallying/destuffing-charges',
        name: 'TallyDestuffingCharges',
        component: () => import(/* webpackChunkName: "TallyDestuffingCharges" */
          '../pages/revenue/shippingTallying/charges/destuffingCharges'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET'
        }
      },
      {
        path: 'shipping-tallying/generate-bill/:operationType/:chargeId',
        name: 'TallyChargeBillGenerate',
        component: () => import(/* webpackChunkName: "TallyChargeBillGenerate" */
          '../pages/revenue/shippingTallying/charges/generateBill'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET'
        }
      },
      {
        path: 'shipping-tallying/bill-list-waiting-approval/',
        name: 'TallyBillsWaitingApproval',
        component: () => import(/* webpackChunkName: "TallyBillsWaitingApproval" */
          '../pages/revenue/shippingTallying/bills/billsWaitingApproval'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET'
        }
      },

      {
        path: 'shipping-agency/charges',
        name: 'AgencyCharges',
        component: () => import(/* webpackChunkName: "AgencyCharges" */
          '../pages/revenue/shippingAgency/charges/agencyCharges'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET'
        }
      },
      {
        path: 'shipping-agency/generate-bill/:chargeId',
        name: 'AgencyChargeBillGenerate',
        component: () => import(/* webpackChunkName: "AgencyChargeBillGenerate" */
          '../pages/revenue/shippingAgency/charges/generateBill'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET'
        }
      },
      {
        path: 'shipping-agency/bill-list-waiting-approval/',
        name: 'AgencyBillsWaitingApproval',
        component: () => import(/* webpackChunkName: "AgencyBillsWaitingApproval" */
          '../pages/revenue/shippingAgency/bills/billsWaitingApproval'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET'
        }
      },

      {
        path: '/revenue/customer/statement/',
        name: 'customerStatement',
        component: () => import(/* webpackChunkName: "customerStatement" */
          '../pages/report/revenue/customerStatement'),
        meta: {
          requiresAuth: true,
          permission: 'STATEMENT_GET_POST'
        }
      },

      {
        path: '/revenue/report/aged-receivable/',
        name: 'AgedReceivable',
        component: () => import(/* webpackChunkName: "AgedReceivable" */
          '../pages/report/revenue/agedReceivable'),
        meta: {
          requiresAuth: true,
          permission: 'STATEMENT_GET_POST'
        }
      }


    ]
  }
];

export default routes;
