/* import axios */
import axios from 'axios'
/* import authentication methods to be used in AUTH_SERVER_URL axios instance */
import auth from './auth'
/* require qs package to be used in serializing token request parameters */
const qs = require('qs');

import {
  DC_SERVER_URL,
  AUTH_SERVER_URL,
  CUST_AUTH_SERVER_URL,
  CFA_SERVER_URL,
  CONFIG_SERVER_URL,
  AGENCY_SERVER_URL
} from "../api/settings";


import agency  from './agency';
import dc from './dc';
import tally from './tally';
import revenue from './rev'
import cfa from './cfa'

/* axios default headers for the below initiated instances */
axios.defaults.headers['access-control-allow-Origin'] = "*";
axios.defaults.headers['Access-Control-Allow-Methods'] = 'POST, PUT, GET, OPTIONS, DELETE'
axios.defaults.headers['Access-Control-Allow-Credentials'] = true
axios.defaults.headers['Cache-Control'] = 'no-cache';
axios.defaults.timeout = 120 * 1000 //  120 seconds
axios.defaults.headers['Set-Cookie'] = 'SameSite=Strict';
// axios.defaults.headers['Feature-Policy'] = 'fullscreen=self'

/* axios instance for document control microservice server */
const HTTP = axios.create({

  'X-Requested-With': 'XMLHttpRequest',

  baseURL: DC_SERVER_URL,

  headers: {

    'Content-Type': 'application/json'

  }

});

/* axios instance for authentication microservice server */
const HTTP_UAA = axios.create({

  'X-Requested-With': 'XMLHttpRequest',

  baseURL: AUTH_SERVER_URL

})

/* axios instance for agency microservice server */
const HTTP_AGENCY = axios.create({

  'X-Requested-With': 'XMLHttpRequest',

  baseURL: AGENCY_SERVER_URL

})

/* axios instance for customer authentication microservice server */
const HTTP_CUST_UAA = axios.create({

  'X-Requested-With': 'XMLHttpRequest',

  baseURL: CUST_AUTH_SERVER_URL

})

/* axios instance for staff authentication microservice server */
const HTTP_UAA_NO_TOKEN = axios.create({

  'X-Requested-With': 'XMLHttpRequest',

  baseURL: AUTH_SERVER_URL

})

/* axios instance for clearing and forwarding microservice server */
const HTTP_CFA = axios.create({

  'X-Requested-With': 'XMLHttpRequest',

  baseURL: CFA_SERVER_URL

})

/* axios instance for configuration microservice server */
const HTTP_CONFIG = axios.create({

  'X-Requested-With': 'XMLHttpRequest',

  baseURL: CONFIG_SERVER_URL,

  headers: {

    'Content-Type': 'application/json'

  }

})

export default {

  /*                                     *******************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **  START AUTHENTICATION APIs METHODS  **************************************************************************** */

  /* response interceptors */
  handle401 (success, error) {

    HTTP_UAA.interceptors.response.use(success, error)
    HTTP_CUST_UAA.interceptors.response.use(success, error)
    HTTP.interceptors.response.use(success, error)
    HTTP_CFA.interceptors.response.use(success, error)
    HTTP_CONFIG.interceptors.response.use(success, error)
    HTTP_AGENCY.interceptors.response.use(success, error)

    agency.handle401(success, error)
    dc.handle401(success, error)
    tally.handle401(success, error)
    cfa.handle401(success, error)
    revenue.handle401(success, error)

  },

  /* authenticate token */
  authenticate (token, refreshToken, expiresIn) {
    const date = new Date()
    const newExpiresIn = date.setTime(date.getTime() + (expiresIn * 1000))

    auth.setToken({ token }, { refreshToken }, { newExpiresIn })

    HTTP_UAA.defaults.headers.common.Authorization = 'Bearer ' + token

    HTTP_CONFIG.defaults.headers.common.Authorization = 'Bearer ' + token

    HTTP_CFA.defaults.headers.common.Authorization = 'Bearer ' + token

    HTTP.defaults.headers.common.Authorization = 'Bearer ' + token

    HTTP_CUST_UAA.defaults.headers.common.Authorization = 'Bearer ' + token

    HTTP_AGENCY.defaults.headers.common.Authorization = 'Bearer ' + token

    revenue.authenticate(token)
    dc.authenticate(token)
    tally.authenticate(token)
    cfa.authenticate(token)
    agency.authenticate(token)

  },

  /* check if user is authenticated everytime request is done*/
  isAuthenticated () {

    if (auth.isAuthenticated()) {

      const token = window.$cookies.get('token').token

      HTTP_UAA.defaults.headers.common.Authorization = 'Bearer ' + token

      HTTP_CONFIG.defaults.headers.common.Authorization = 'Bearer ' + token

      HTTP_CFA.defaults.headers.common.Authorization = 'Bearer ' + token

      HTTP.defaults.headers.common.Authorization = 'Bearer ' + token

      HTTP_CUST_UAA.defaults.headers.common.Authorization = 'Bearer ' + token

      HTTP_AGENCY.defaults.headers.common.Authorization = 'Bearer ' + token

      revenue.isAuthenticated(token)
      dc.isAuthenticated(token)
      tally.isAuthenticated(token)
      cfa.isAuthenticated(token)
      agency.isAuthenticated(token)

      return true
    }

    return false
  },

  /* staff login */
  getToken (username, password) {

    return HTTP_UAA.post(AUTH_SERVER_URL + '/oauth/token',

      qs.stringify({...auth.authTokenPayload(),username: username,password: password}),
      {headers: {'Content-Type': 'application/x-www-form-urlencoded'}

      }
    )

  },

  /* customer login */
  getCustomerToken (username, password) {
    return HTTP_CUST_UAA.post(CUST_AUTH_SERVER_URL + '/oauth/token',qs.stringify({...auth.authTokenPayload(),username: username,password: password}), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
  },

  /* user logout */
  logout () {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/oauth/logout',

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  /* refresh token */
  refreshToken () {
    return HTTP_UAA.post(AUTH_SERVER_URL + '/oauth/token',

      qs.stringify({

        ...auth.refreshTokenPayload()

      }),
      {

        headers: {

          'Content-Type': 'application/x-www-form-urlencoded'

        }

      })
  },

  /* get all of the authorized tokens for the user */
  getTokens () {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/oauth/tokens',

      {

        headers: {

          'Content-Type': 'application/x-www-form-urlencoded'

        }

      })
  },

  // Post all user.
  postUser (data) {
    return HTTP_UAA.post(AUTH_SERVER_URL + '/users/add', data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  postCustomerToUser (data) {
    return HTTP_UAA.post(AUTH_SERVER_URL + '/users/addCustomer', data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  changeUserPassword (data) {
    return HTTP_UAA.post(AUTH_SERVER_URL + '/users/user/updatePassword', data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  sendRequestResetPassword (data) {
    return HTTP_UAA_NO_TOKEN.post(AUTH_SERVER_URL + '/users/resetPasswordRequest', data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  checkTokenForResetPassword (data) {
    return HTTP_UAA_NO_TOKEN.get(AUTH_SERVER_URL + '/users/resetPasswordGetEmail/' + data)
  },

  resetUserPassword (data) {
    return HTTP_UAA_NO_TOKEN.post(AUTH_SERVER_URL + '/users/resetPassword', data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  verifyAccount (data) {
    return HTTP_UAA_NO_TOKEN.post(AUTH_SERVER_URL + '/users/resetOneTimePassword', data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  // Get all users.
  getUsers () {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/users/all')
  },
  // Get all customers
  getCustomers () {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/users/all-customers')
  },

  searchPagebleUser (user, pageNo, pageSize, sortBy) {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/users/allUsers/search?pageNo=' + pageNo + '&pageSize=' +
        pageSize + '&searchText=' + user + '&sortBy=' + sortBy)
  },

  getPagebleUsers (pageNo, pageSize, sortBy) {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/users/allUsers?pageNo=' + pageNo + '&pageSize=' +
        pageSize + '&sortBy=' + sortBy)
  },

  // Get a user.
  getUser (data) {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/users/' + data)
  },

  // Get authenticated user
  getAuthenticatedUser () {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/users/principle')
  },

  // Logout authenticated user
  logoutAuthenticatedUser () {
    return HTTP_UAA.post(AUTH_SERVER_URL + '/users/logout')
  },

  updateUserPassword(data) {
    try {
      return HTTP_UAA.post(AUTH_SERVER_URL + '/users/change-password', data,

        {

          headers: {

            'Content-Type': 'application/json'

          }

        })
    }catch (e) {

    }

  },

  // Get a user.
  getUserRoles (data) {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/users/roles/' + data)
  },

  getPageableRoles (pageNo, pageSize, sortBy) {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/roles/all/pageable?pageNo=' + pageNo + '&pageSize=' +
        pageSize + '&sortBy=' + sortBy)
  },

  getSearchPageableRoles (pageNo, pageSize, searchParameter) {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/users/roles/search?pageNo=' + pageNo + '&pageSize=' +
        pageSize + '&searchTerm=' + searchParameter)
  },

  // post user roles.
  postUserRoles (id, data) {
    return HTTP_UAA.post(AUTH_SERVER_URL + '/users/roles/' + id, data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  // Get a user.
  updateUser (id, data) {
    return HTTP_UAA.put(AUTH_SERVER_URL + '/users/' + id, data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  // Get a user.
  deleteUser (id) {
    return HTTP_UAA.delete(AUTH_SERVER_URL + '/users/' + id)
  },

  // post role.
  postRole (data) {
    return HTTP_UAA.post(AUTH_SERVER_URL + '/roles/add', data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  // Get all Roles.
  getAllRoles () {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/roles/all')
  },

  // Get a role.
  getRole (id) {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/roles/' + id)
  },

  // update role.
  updateRole (id, data) {
    return HTTP_UAA.put(AUTH_SERVER_URL + '/roles/' + id, data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  // Delete role.
  deleteRole (id) {
    return HTTP_UAA.delete(AUTH_SERVER_URL + '/roles/' + id)
  },

  /* all permissions */
  getAllPermissions () {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/roles/privilege/all')
  },

  /* role permissions */
  getRolePermissions (data) {
    return HTTP_UAA.get(AUTH_SERVER_URL + '/roles/privilege/' + data)
  },

  /* post role's permissions */
  postRolePermissions (data) {
    return HTTP_UAA.post(AUTH_SERVER_URL + '/roles/privilege/add', data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  /*  END AUTHENTICATION APIs METHODS      *****************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **  START DOCUMENT CONTROL APIs METHODS  *************************************************************************** */

  /* send mail for document download link */

  /* Import Document */
  getDocument () {
    return HTTP.get(DC_SERVER_URL + '/documents/all')
  },
  postDocument (data) {
    return HTTP.post(DC_SERVER_URL + '/documents/config', data)
  },

  updateDocument (data) {
    return HTTP.put(DC_SERVER_URL + '/documents/config', data)
  },
  getIndividualDocument (data) {
    return HTTP.get(DC_SERVER_URL + '/documents/config/' + data)
  },
  uploadDocument (data) {
    return HTTP.post(DC_SERVER_URL + '/documents/upload', data)
  },

  /* Export Document */
  getPagableExportDocuments (pageNo, pageSize, sortBy) {
    return HTTP.get(DC_SERVER_URL + '/loadingPermission/all/pageable/?pageNo=' + pageNo + '&pageSize=' +
        pageSize + '&sortBy=' + sortBy)
  },

  uploadExportDocument (data) {
    return HTTP.post(DC_SERVER_URL + '/loadingPermission/upload', data)
  },

  searchExportDocument (pageNo, pageSize, searchTerm) {
    return HTTP.post(DC_SERVER_URL + '/loadingPermission/search-pageable?pageNo=' + pageNo + '&pageSize=' +
        pageSize + '&searchTerm=' + searchTerm)
  },

  downloadExportDocument (data) {
    return HTTP.get(DC_SERVER_URL + '/loadingPermission/download/' + data)
  },

  /* reconciliation */
  getSearchPageableAddTancisExtractedCargoManifest (pageNo, pageSize, searchTerm) {
    return HTTP.post(DC_SERVER_URL + '/tancis/search-tancis-documents?searchTerm=' + searchTerm + '&pageNo=' +
        pageNo + '&pageSize=' + pageSize)
  },

  getPageableAddTancisExtractedCargoManifest (pageNo, pageSize) {
    return HTTP.get(DC_SERVER_URL + '/tancis/tancis-documents-pageable?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getTancisDocumentsCountDiscrepancies (voyage) {
    return HTTP.get(DC_SERVER_URL + '/tancis/count/discrepancies/' + voyage)
  },
  deleteTancisDocument (data) {
    return HTTP.get(DC_SERVER_URL + '/tancis/documents/delete/' + data)
  },

  getTancisReconciliationDocument (data) {
    return HTTP.get(DC_SERVER_URL + '/tancis/reconciliation/' + data)
  },

  getTancisReconciliationSummaryForVoyage (voyage) {
    return HTTP.get(DC_SERVER_URL + '/tancis/summary/discrepancies/' + voyage)
  },

  getTancisReconciliationIndividualSummary (voyage, fieldName) {
    return HTTP.get(DC_SERVER_URL + '/tancis/details/reconciliation/' + voyage + '/' + fieldName)
  },

  getTancisIndividualReconciliationDocumentAll (voyage) {
    return HTTP.get(DC_SERVER_URL + '/tancis/reconciliation/' + voyage)
  },

  getTancisIndividualReconciliationSummationByGroup (voyage) {
    return HTTP.get(DC_SERVER_URL + '/tancis/groupCounted/discrepancies/' + voyage)
  },

  uploadTancisDocument (data) {
    return HTTP.post(DC_SERVER_URL + '/tancis/tancisDocument', data)
  },

  getTancisDocuments (pageNo, pageSize) {
    if (pageSize === 0) {
      return HTTP.get(DC_SERVER_URL + '/tancis/tancisDocuments')
    }
    return HTTP.get(DC_SERVER_URL + '/tancis/tancisDocuments?page=' + pageNo + '&size=' + pageSize)
  },

  getPageableImportReconciliationSummary (pageNo, pageSize) {
    return HTTP.get(DC_SERVER_URL + '/tancis/tancis-documents-pageable?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getSearchPageableExportReconciliationSummary (pageNo, pageSize, searchTerm) {
    return HTTP.post(DC_SERVER_URL + '/tancis/search-tancis-documents?searchTerm=' + searchTerm + '&pageNo=' +
        pageNo + '&pageSize=' + pageSize)
  },

  /* EXPORT TANCIS MANIFEST */

  getTancisExportDocuments (pageNo, pageSize) {
    if (pageSize === 0) {
      return HTTP.get(DC_SERVER_URL + '/tancis/export/documents/tancisDocuments/pageable/')
    }
    return HTTP.get(DC_SERVER_URL + '/tancis/export/documents/tancisDocuments/pageable/?page=' + pageNo +
        '&size=' + pageSize)
  },

  getTancisExportReconciliationDocument (data) {
    return HTTP.get(DC_SERVER_URL + '/tancis/export/documents/reconciliation/' + data)
  },

  uploadTancisExportDocument (data) {
    return HTTP.post(DC_SERVER_URL + '/tancis/export/documents/add', data)
  },

  /* Origin Manifest */
  getManifest () {
    return HTTP.get(DC_SERVER_URL + '/manifests/all')
  },
  getManifestByDocumentId (data) {
    return HTTP.get(DC_SERVER_URL + '/manifests/config/single/' + data)
  },
  getManifestPageableByDocumentId (data, pageNo, pageSize, sortBy) {
    return HTTP.get(DC_SERVER_URL + '/manifests/config/single/pageable/' + data + '?pageNo=' + pageNo +
        '&pageSize=' + pageSize + '&sortBy=' + sortBy)
  },
  getManifestSpecifiedByDocumentId (data, pageNo, pageSize) {
    return HTTP.get(DC_SERVER_URL + '/manifests/specified/' + data + '?pageNo=' + pageNo +
        '&pageSize=' + pageSize)
  },

  /* Approved Manifest */
  getApprovedManifestList (pageNo, pageSize) {
    return HTTP.get(DC_SERVER_URL + '/approved/manifest/list?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  sendMailManifest (data) {
    return HTTP.get(DC_SERVER_URL + '/v2/manifest/mail/' + data)
  },

  /* DischargeLIst */
  getDischargeList () {
    return HTTP.get(DC_SERVER_URL + '/dischargeLists/all')
  },
  getDischargeListByDocumentId (data) {
    return HTTP.get(DC_SERVER_URL + '/dischargeLists/config/single/' + data)
  },

  DischargeListPdf (data) {
    return HTTP.get(DC_SERVER_URL + '/documents/dischargeListPdf/' + data)
  },

  getDischargeListPageableByDocumentId (data, pageNo, pageSize, sortBy) {
    return HTTP.get(DC_SERVER_URL + '/dischargeLists/config/single/pageable/' + data + '?pageNo=' + pageNo +
        '&pageSize=' + pageSize + '&sortBy=' + sortBy)
  },

  /* Dangerous Good */
  getDangerousGoods () {
    return HTTP.get(DC_SERVER_URL + '/dangerousGoods/all')
  },
  getDangerousGoodsByDocumentId (data) {
    return HTTP.get(DC_SERVER_URL + '/dangerousGoods/config/all/' + data)
  },
  getDangerousGoodsPageableByDocumentId (data, pageNo, pageSize, sortBy) {
    return HTTP.get(DC_SERVER_URL + '/dangerousGoods/config/all/pageable/' + data + '?pageNo=' + pageNo +
        '&pageSize=' + pageSize + '&sortBy=' + sortBy)
  },

  /* BayPlan */
  getBayPlan () {
    return HTTP.get(DC_SERVER_URL + '/bayPlan/all')
  },
  getBayPlanByDocumentId (data) {
    return HTTP.get(DC_SERVER_URL + '/bayPlan/config/' + data)
  },
  getBayPlanPageableByDocumentId (data, pageNo, pageSize, sortBy) {
    return HTTP.get(DC_SERVER_URL + '/bayPlan/config/pageable/' + data + '?pageNo=' + pageNo + '&pageSize=' +
        pageSize + '&sortBy=' + sortBy)
  },

  /* EDI Message */
  getEDIMessage () {
    return HTTP.get(DC_SERVER_URL + '/ediMessage/all')
  },

  /* EDI Message */
  getFailedEDIMessages () {
    return HTTP.get(DC_SERVER_URL + '/failed-edis/all')
  },

  getEDIMessageByControlRefNo (data) {
    return HTTP.get(DC_SERVER_URL + '/ediMessage/config/all/' + data)
  },

  getSearchPageableExportEdiDocuments (pageNo, pageSize, isImport, searchTerm) {
    return HTTP.post(DC_SERVER_URL + '/documents/search-with-type?searchTerm=' + searchTerm +
        '&pageNo=' + pageNo + '&pageSize=' + pageSize + '&isImport=' + isImport)
  },

  /* Download files */
  manifestExcel (data) {
    return HTTP.get(DC_SERVER_URL + '/documents/manifestExcel/' + data
      ,

      {

        headers: {

          'Content-Type': 'application/octet-stream'

        },

        responseType: 'blob'

      }
    )
  },

  manifestData (data) {
    return HTTP.get(DC_SERVER_URL + '/documents/manifest/' + data)
  },

  dischargeListExcel (data) {
    return HTTP.get(DC_SERVER_URL + '/documents/dischargeListExcel/' + data
      ,

      {

        headers: {

          'Content-Type': 'application/octet-stream'

        },

        responseType: 'blob'

      }
    )
  },

  dischargeListData (data) {
    return HTTP.get(DC_SERVER_URL + '/documents/dischargeList/' + data)
  },

  bayPlanExcel (data) {
    return HTTP.get(DC_SERVER_URL + '/documents/bayPlanExcel/' + data
      ,

      {

        headers: {

          'Content-Type': 'application/octet-stream'

        },

        responseType: 'blob'

      }
    )
  },

  bayPlanData (data) {
    return HTTP.get(DC_SERVER_URL + '/documents/bayPlan/' + data)
  },

  dangerousGoodExcel (data) {
    return HTTP.get(DC_SERVER_URL + '/documents/dangerousGoodExcel/' + data
      ,

      {

        headers: {

          'Content-Type': 'application/octet-stream'

        },

        responseType: 'blob'

      }
    )
  },

  dangerousGoodData (data) {
    return HTTP.get(DC_SERVER_URL + '/documents/dangerousGood/' + data)
  },

  /*  END DOCUMENT CONTROL APIs METHODS  *******************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **  START CONFIGURATION APIs METHODS   ***************************************************************************** */

  /* document type */
  getDocumentType () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/docType/all')
  },
  postDocumentType (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/docType/config', data)
  },
  deleteDocumentType (data) {
    return HTTP.delete(CONFIG_SERVER_URL + '/docType/config/' + data)
  },
  updateDocumentType (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/docType/config', data)
  },
  getIndividualDocumentType (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/docType/config/' + data)
  },

  /* vessel type */
  getSearchPageableVesselTypeList (pageNo, pageSize, searchParameter) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/vesselType/search-pageable?searchTerm=' + searchParameter +
        '&pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getPageableVesselTypeList (pageNo, pageSize, sortBy) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/vesselType/all-pageable?pageNo=' + pageNo + '&pageSize=' +
        pageSize)
  },

  getVesselType () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/vesselType/all')
  },
  postVesselType (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/vesselType/config', data)
  },
  deleteVesselType (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/vesselType/config/' + data)
  },
  updateVesselType (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/vesselType/config', data)
  },
  getIndividualVesselType (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/vesselType/config/' + data)
  },

  /* vessel schedule */
  getSearchPageableVesselSchedule (pageNo, pageSize, searchParameter) {
    return HTTP.post(DC_SERVER_URL + '/vesselSchedule/search-pageable?searchText=' + searchParameter + '&pageNo=' +
        pageNo + '&pageSize=' + pageSize)
  },

  getPageableVesselSchedule (pageNo, pageSize) {
    return HTTP.get(DC_SERVER_URL + '/vesselSchedule/all-pageable?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getVesselSchedule () {
    return HTTP.get(DC_SERVER_URL + '/vesselSchedule/all')
  },
  postVesselSchedule (data) {
    return HTTP.post(DC_SERVER_URL + '/vesselSchedule/config', data)
  },
  deleteVesselSchedule (data) {
    return HTTP.delete(DC_SERVER_URL + '/vesselSchedule/config/' + data)
  },
  updateVesselSchedule (data) {
    return HTTP.put(DC_SERVER_URL + '/vesselSchedule/config', data)
  },
  getIndividualVesselSchedule (data) {
    return HTTP.get(DC_SERVER_URL + '/vesselSchedule/config/' + data)
  },

  /* cargo type */
  getCargoType () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/cargoType/all')
  },
  postCargoType (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/cargoType/config', data)
  },
  deleteCargoType (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/cargoType/config/' + data)
  },
  updateCargoType (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/cargoType/config', data)
  },
  getIndividualCargoType (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/cargoType/config/' + data)
  },

  /* sector */
  getSearchPageableSectorList (pageNo, pageSize, searchParameter) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/sector/search-pageable?searchTerm=' + searchParameter +
        '&pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getPageableSectorList (pageNo, pageSize) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/sector/all-pagealbe?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getSector () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/sector/all')
  },
  postSector (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/sector/config', data)
  },
  deleteSector (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/sector/config/' + data)
  },
  updateSector (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/sector/config', data)
  },
  getIndividualSector (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/sector/config/' + data)
  },

  /* country */
  getPageableCountries (pageNo, pageSize) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/country/all-pageable?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getSearchPageableCountries (pageNo, pageSize, searchParameter) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/country/search-pageable?searchTerm=' + searchParameter +
        '&pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getCountry () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/country/all')
  },
  postCountry (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/country/config', data)
  },
  deleteCountry (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/country/config/' + data)
  },
  updateCountry (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/country/config', data)
  },
  getIndividualCountry (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/country/config/' + data)
  },
  uploadCountryExcel (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/country/upload', data)
  },

  /* Cargo Station Delivery */
  getSearchPageableStation (pageNo, pageSize, searchParameter) {
    return HTTP_CONFIG.post(CFA_SERVER_URL + '/stations/search-pageable?searchTerm=' + searchParameter +
        '&pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getPageableStation (pageNo, pageSize) {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/stations/all-pageable?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getStation () {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/stations/all')
  },
  postStation (data) {
    return HTTP_CONFIG.post(CFA_SERVER_URL + '/stations/add', data)
  },

  deleteStation (data) {
    return HTTP_CONFIG.delete(CFA_SERVER_URL + '/stations/delete/' + data)
  },

  updateStation (data) {
    return HTTP_CONFIG.put(CFA_SERVER_URL + '/stations/update', data)
  },

  getIndividualStation (data) {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/stations/get/' + data)
  },

  /* Cargo TransportMethod Delivery */

  getSearchPageableTransportMethod (pageNo, pageSize, searchParameter) {
    return HTTP_CONFIG.post(CFA_SERVER_URL + '/transport-method/search-pageable?searchTerm=' +
        searchParameter + '&pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getPageableTransportMethod (pageNo, pageSize) {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/transport-method/all-pageable?pageNo=' + pageNo +
        '&pageSize=' + pageSize)
  },

  geTransportMethod () {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/transport-method/all')
  },
  postTransportMethod (data) {
    return HTTP_CONFIG.post(CFA_SERVER_URL + '/transport-method/add', data)
  },

  deleteTransportMethod (data) {
    return HTTP_CONFIG.delete(CFA_SERVER_URL + '/transport-method/delete/' + data)
  },

  updateTransportMethod (data) {
    return HTTP_CONFIG.put(CFA_SERVER_URL + '/transport-method/update', data)
  },

  getIndividualTransportMethod (data) {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/transport-method/get/' + data)
  },

  /* cargo class */
  getCargoClass () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/cargoClass/all')
  },
  postCargoClass (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/cargoClass/config', data)
  },
  deleteCargoClass (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/cargoClass/config/' + data)
  },
  updateCargoClass (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/cargoClass/config', data)
  },
  getIndividualCargoClass (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/cargoClass/config/' + data)
  },

  /* city */
  getSearchPageableCityList (pageNo, pageSize, searchParameter) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/city/search-pageable?searchTerm=' + searchParameter +
        '&pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getPageableCityList (pageNo, pageSize) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/city/all-pageable?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getCity () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/city/all')
  },
  postCity (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/city/config', data)
  },
  deleteCity (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/city/config/' + data)
  },
  updateCity (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/city/config', data)
  },
  getIndividualCity (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/city/config/' + data)
  },
  uploadCityExcel (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/city/upload', data)
  },

  /* Status List */

  getStatus () {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/status/all')
  },

  postStatus (data) {
    return HTTP_CONFIG.post(CFA_SERVER_URL + '/status/config'
      , data

      , {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  deleteStatus (data) {
    return HTTP_CONFIG.delete(CFA_SERVER_URL + '/status/config/' + data)
  },

  updateStatus (data) {
    return HTTP_CONFIG.put(CFA_SERVER_URL + '/status/config', data)
  },

  getIndividualStatus (data) {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/status/config/' + data)
  },

  /* hs code */
  getSearchPageableHsCodeList (pageNo, pageSize, searchParameter) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/hscode/search-pageable?searchTerm=' + searchParameter +
        '&pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getPageableHsCodeList (pageNo, pageSize) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/hscode/all-pageable?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getHsCode () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/hscode/all')
  },
  postHsCode (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/hscode/config', data)
  },
  deleteHsCode (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/hscode/config/' + data)
  },
  updateHsCode (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/hscode/config', data)
  },
  getIndividualHsCode (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/hscode/config/' + data)
  },

  /* company type */
  getSearchPageableCompanyList (pageNo, pageSize, searchParameter) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/company/search?searchTerm=' + searchParameter + '&pageNo=' +
        pageNo + '&pageSize=' + pageSize)
  },

  getPageableCompanyList (pageNo, pageSize) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/company/all/pageable?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getCompanyType () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/companyType/all')
  },
  postCompanyType (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/companyType/config', data)
  },
  deleteCompanyType (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/companyType/configs/' + data)
  },
  updateCompanyType (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/companyType/config', data)
  },
  getIndividualCompanyType (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/companyType/config/' + data)
  },
  uploadCompanyTypeExcel (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/companyType/upload', data)
  },

  /* company */
  getCompany () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/company/all')
  },
  postCompany (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/company/config', data)
  },
  deleteCompany (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/company/config/' + data)
  },
  updateCompany (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/company/config', data)
  },
  getIndividualCompany (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/company/config/' + data)
  },
  uploadCompanyExcel (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/company/upload', data)
  },

  /* department */
  getPageableDepartmentList (pageNo, pageSize) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/department/all-pageable?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getSearchPageableDepartmentList (pageNo, pageSize, searchParameter) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/department/search-pageable?searchTerm=' + searchParameter +
        '&pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getDepartment () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/department/all')
  },

  postDepartment (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/department/config', data)
  },
  deleteDepartment (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/department/config/' + data)
  },
  updateDepartment (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/department/config', data)
  },
  getIndividualDepartment (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/department/config/' + data)
  },
  uploadDepartmentExcel (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/department/upload', data)
  },

  /* vessel */
  getVessel () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/vessel/all')
  },

  getSearchPageableVesselList (pageNo, pageSize, searchParamenter) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/vessel/search?searchTerm=' + searchParamenter + '&pageNo=' +
        pageNo + '&pageSize=' + pageSize)
  },

  getPageableVesselList (pageNo, pageSize) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/vessel/all/pageable?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  postVessel (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/vessel/config', data)
  },
  deleteVessel (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/vessel/config/' + data)
  },
  updateVessel (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/vessel/config', data)
  },
  getIndividualVessel (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/vessel/config/' + data)
  },
  uploadVesselExcel (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/vessel/upload', data)
  },

  /* cargo */
  getCargo () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/cargoes/all')
  },
  postCargo (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/cargoes/config', data)
  },
  deleteCargo (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/cargoes/config/' + data)
  },
  updateCargo (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/cargoes/config', data)
  },
  getIndividualCargo (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/cargoes/config/' + data)
  },
  uploadCargoExcel (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/cargoes/upload', data)
  },

  /* port */
  getPageablePorts (pageNo, pageSize) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/port/all-pageable?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getSearchPageablePorts (pageNo, pageSize, searchParameter) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/port/search-pageable?searchTerm=' + searchParameter +
        '&pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getPort () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/port/all')
  },
  postPort (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/port/config', data)
  },
  deletePort (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/port/config/' + data)
  },
  updatePort (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/port/config', data)
  },
  getIndividualPort (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/port/config/' + data)
  },

  /* designation */
  getDesignation () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/designation/all')
  },
  postDesignation (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/designation/config', data)
  },
  deleteDesignation (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/designation/config/' + data)
  },
  updateDesignation (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/designation/config', data)
  },
  getIndividualDesignation (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/designation/config/' + data)
  },

  /* function */
  getFunction () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/function/all')
  },
  postFunction (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/function/config', data)
  },
  deleteFunction (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/function/config/' + data)
  },
  updateFunction (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/function/config', data)
  },
  getIndividualFunction (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/function/config/' + data)
  },

  /* Route */
  getSearchPageableRouteList (pageNo, pageSize, searchParameter) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/route/search-pageable?searchTerm=' + searchParameter +
        '&pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getPageableRouteList (pageNo, pageSize) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/route/all-pageable?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getRoute () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/route/all')
  },
  postRoute (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/route/config', data)
  },
  deleteRoute (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/route/config/' + data)
  },
  updateRoute (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/route/config', data)
  },
  getIndividualRoute (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/route/config/' + data)
  },

  /* PackagingType */
  getPackagingType () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/packagingType/all')
  },
  postPackagingType (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/packagingType/config', data)
  },
  deletePackagingType (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/packagingType/config/' + data)
  },
  updatePackagingType (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/packagingType/config', data)
  },
  getIndividualPackagingType (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/packagingType/config/' + data)
  },

  /* Resource Group */
  getResourceGroup () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/resources/grouplist')
  },
  postResourceGroup (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/resources/group', data)
  },
  deleteResourceGroup (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/resources/resourceGroup/' + data)
  },
  updateResourceGroup (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/resources/group', data)
  },
  getIndividualResourceGroup (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/resources/group/' + data)
  },

  /* Resource Plan */
  getPlanResource () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/resources/list')
  },
  getPlanResourceByGroup (groupId) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/resources/resource/' + groupId)
  },
  postPlanResource (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/resources/config', data)
  },
  deletePlanResource (data) {
    return HTTP_CONFIG.delete(CONFIG_SERVER_URL + '/resources/resource/' + data)
  },
  updatePlanResource (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/resources/config', data)
  },
  getIndividualPlanResource (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/resources/config/' + data)
  },

  /* Request Type */
  getRequestType () {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/request/all',

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },
  postRequestType (data) {
    return HTTP_CONFIG.post(CFA_SERVER_URL + '/request/config', data)
  },
  deleteRequestType (data) {
    return HTTP_CONFIG.delete(CFA_SERVER_URL + '/request/config/' + data)
  },
  updateRequestType (data) {
    return HTTP_CONFIG.put(CFA_SERVER_URL + '/request/config', data)
  },
  getIndividualRequestType (data) {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/request/config/' + data)
  },

  /* activity For Application */

  getActivity () {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/activity/all')
  },

  getPageableActivities (pageNo, pageSize, sortBy) {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/activity/all/pageable?pageNo=' + pageNo + '&pageSize=' +
        pageSize + '&sortBy' + sortBy)
  },

  getSearchPageableActivities (pageNo, pageSize, searchParameter) {
    return HTTP_CONFIG.post(CFA_SERVER_URL + '/activity/search?searchTerm=' + searchParameter + '&pageNo=' +
        pageNo + '&pageSize=' + pageSize)
  },

  postActivity (data) {
    return HTTP_CONFIG.post(CFA_SERVER_URL + '/activity/config', data)
  },
  deleteActivity (data) {
    return HTTP_CONFIG.delete(CFA_SERVER_URL + '/activity/config/' + data)
  },
  updateActivity (data) {
    return HTTP_CONFIG.put(CFA_SERVER_URL + '/activity/config', data)
  },

  getIndividualActivity (data) {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/activity/config/' + data)
  },

  searchActivity (data) {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/activity/search/' + data)
  },

  /*  END CONFIGURATION APIs METHODS  **********************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **  START REVENUE APIs METHODS      ******************************************************************************** */

  /* document control import billing */


  /*  END REVENUE APIs METHODS  ****************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **  START CLEARING AND FORWARDING APIs METHODS  ******************************************************************** */

  /* Customer Account Creation */
  getCustomerAll () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/customers/all');
  },

  loadAllGenerated(pageNo,pageSize){

    return HTTP_CFA.get(CFA_SERVER_URL + '/cfareport/all?' + 'page=' + pageNo + '&size=' + pageSize);


  },

  loadAllGeneratedAssignmentReports(){

    return HTTP_CFA.get(CFA_SERVER_URL + '/cfareport/assignment/all');


  },

  generateAsyncReport(params){
    return HTTP_CFA.get(CFA_SERVER_URL + '/cfareport/async/report?' + params);

  },

  getRequestsByFileNumberCustomer(fileNumber,customerId){
    return HTTP_CFA.post(CFA_SERVER_URL + "/clearingforwarding/request-by-filenumber?fileNumber=" + fileNumber +"&customerId=" + customerId);
  },

  getSingleCustomer (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/customers/get/' + data)
  },

  deleteCustomer (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/customers/delete/' + data)
  },

  markDuplicateRequest (requestId,comment,) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/request/mark-duplicate/' + requestId + '?reason=' + comment)
  },

  dismissApplication(requestId,comment,selectedDismissalCase){
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/request/dismiss?requestId=' + requestId + '&reason=' + comment + '&dismissCase=' + selectedDismissalCase);
  },

  updateSingleCustomer (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/customers/update',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  postCustomer (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/customers/add',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  getSearchPageableCustomer (searchParameter, pageNo, pageSize) {

    return HTTP_CFA.post(CFA_SERVER_URL + '/customers/search-pageable?searchTerm=' + searchParameter + '&page=' + pageNo + '&size=' + pageSize)
  },

  getPageableCustomer (pageNo, pageSize) {

    return HTTP_CFA.get(CFA_SERVER_URL + '/customers/all-pageable?page=' + pageNo + '&size=' + pageSize)
  },

  getCustomerAttachments (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/customer-attachment/all/' + data)
  },

  uploadCustomerAttachment (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/customer-attachment/add', data)
  },

  deleteCustomerAttachment (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/customer-attachment/delete/' + data)
  },

  attachmentPerRequestAndAll (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/request-attachments/list-all/' + data)
  },

  /* MESSAGE CHAT ROOM */
  sendNewMessageOnChatRoom (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/request-comments/add', data

      , {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  /* GET MESSAGES PER REQUEST */
  getMessagesOnChatRoomPerRequest (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/request-comments/comments/' + data)
  },

  /* GET SINGLE MESSAGE PER REQUEST */
  getSingleMessageChatPerRequest (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/request-comments/comment/' + data)
  },

  uploadRequestedFile (data, id) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/request-comments/attachment/' + id, data)
  },

  /* Specified Goods */
  getSpecifiedGood (pageNo, pageSize) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/clearingforwarding/specifiedgoods?pageNo=' + pageNo + '&pageSize=' + pageSize)
  },

  getSpecifiedGoodAll () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/clearingforwarding/all-specifiedgoods')
  },

  postSpecifiedGood (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/clearingforwarding/specifiedgoods', data)
  },

  getAllCurrencies () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/currency/all-currencies')
  },

  // deleteSpecifiedGood(data) {
  //
  //   return HTTP_CONFIG.delete(CONFIG_SERVER_URL + "/vesselType/config/" + data)
  // },

  updateSpecifiedGood (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/clearingforwarding/specifiedgoods/', data)
  },

  getIndividualSpecifiedGood (data) {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/clearingforwarding/specifiedgoods/' + data)
  },

  /* Regime */
  getRegime () {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/regimes/list')
  },

  getRegimePageable () {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/regimes/list/pageable')
  },

  postRegime (data) {
    return HTTP_CONFIG.post(CFA_SERVER_URL + '/regimes/config'
      , data

      , {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  deleteRegime (data) {
    return HTTP_CONFIG.delete(CFA_SERVER_URL + '/regimes/config/' + data)
  },

  updateRegime (data) {
    return HTTP_CONFIG.put(CFA_SERVER_URL + '/regimes/config', data)
  },

  getIndividualRegime (data) {
    return HTTP_CONFIG.get(CFA_SERVER_URL + '/regimes/config/' + data)
  },

  /* TeSW common codes */
  updateCommonCode (code) {
    return HTTP_CFA.post(CONFIG_SERVER_URL + '/common-code/pull?commonCodeType=' + code)
  },

  getCommonCodeListTypes () {
    return HTTP_CFA.get(CONFIG_SERVER_URL + '/common-code/list-common-types')
  },

  /* Customer Attachment Type */
  getCustomerAttachmentTypes () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/attachments/list')
  },
  postCustomerAttachmentType (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/CustomerAttachmentType-attachment-type/add', data)
  },
  deleteCustomerAttachmentType (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/CustomerAttachmentType-attachment-type/' + data)
  },
  updateCustomerAttachmentType (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/CustomerAttachmentType-attachment-type', data)
  },
  getIndividualCustomerAttachmentType (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/CustomerAttachmentType-attachment-type/' + data)
  },

  /* NonMonetary Activity */

  getNonMonetaryActivity (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/activityLog/all/' + data)
  },
  postNonMonetaryActivity (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/activityLog/config', data)
  },
  downloadNonMonetaryActivity (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/activityLog/download/' + data)
  },
  updateNonMonetaryActivity (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/activityLog/config', data)
  },
  getIndividualNonMonetaryActivity (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/activityLog/config/' + data)
  },

  /* cargo = other request details */

  createOtherRequestDetails (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/cargoes/add', data, {

      headers: {

        'Content-Type': 'application/json'

      }

    })
  },

  updateOtherRequestDetails (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/cargoes/update', data, {

      headers: {

        'Content-Type': 'application/json'

      }

    })
  },

  getOtherRequestDetail (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/cargoes/get-requestId/' + data)
  },

  /* Charges */

  createCharges (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/request-charge/add', data)
  },

  cancelCharge (ChargeId, reason) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/request-charge/cancel?requestChargeId=' + ChargeId + '&cancelReason=' + reason)
  },

  getChargesByRequestId (requestId) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/request-charge/charges/' + requestId)
  },

  getChargesPerRequest (requestId) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/request-charge/list/' + requestId)
  },

  getAssessedRequests (pageNo, pageSize) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/assessed-requests/' + '?pageNo=' +
        pageNo + '&pageSize=' + pageSize)
  },



  /* BULK CHARGES */
  getCustomerBulkCharges (customerId) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/new/charged-requests-by-customer/' + customerId)
  },

  getDCCustomerBulkCharges (customerId) {
    return HTTP_CFA.get(DC_SERVER_URL + '/document-charge/get-all-unbilled-by-company/' + customerId)
  },

  /* Assignments */

  getAllAssignments () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/assignment/all')
  },

  getAllAssignmentByRequest (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/assignment/all/' + data)
  },

  getAllAssignmentDetailedByRequest (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/assignment/allDetailed/' + data)
  },

  reasignActivity(assignmentId,userId, remarks){
    return HTTP_CFA.get(CFA_SERVER_URL + '/assignment/reassign/' + assignmentId + '/' + userId + '?remarks=' + remarks);
  },
  postAssignment (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/assignment/config', data, {

      headers: {

        'Content-Type': 'application/json'

      }

    })
  },
  deleteAssignment (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/assignment/config/' + data)
  },
  updateAssignment (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/assignment/config', data, {

      headers: {

        'Content-Type': 'application/json'

      }

    })
  },
  getIndividualAssignment (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/assignment/config/' + data)
  },

  assignmentNotificationList () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/assignment/all-notifications')
  },

  /* Packing Category */
  getPackingCategory () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/packingCategory/all')
  },
  postPackingCategory (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/packingCategory/config', data)
  },
  deletePackingCategory (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/packingCategory/config/' + data)
  },
  updatePackingCategory (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/packingCategory/config', data)
  },
  getIndividualPackingCategory (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/packingCategory/config/' + data)
  },

  /* Release Order */
  getReleaseOrder () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/releaseOrder/all')
  },
  postReleaseOrder (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/releaseOrder/config', data)
  },
  deleteReleaseOrder (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/releaseOrder/config/' + data)
  },
  updateReleaseOrder (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/releaseOrder/config', data)
  },
  getIndividualReleaseOrder (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/releaseOrder/config/' + data)
  },

  /* Packing List */
  getPackingList () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/packingList/all')
  },
  postPackingList (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/packingList/config', data)
  },
  deletePackingList (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/packingList/config/' + data)
  },
  updatePackingList (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/packingList/config', data)
  },
  getIndividualPackingList (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/packingList/config/' + data)
  },

  /* Payment Deposit */
  getPaymentDeposit () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/paymentdeposit/all')
  },
  postPaymentDeposit (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/paymentdeposit/add', data)
  },
  deletePaymentDeposit (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/paymentdeposit/delete/' + data)
  },
  updatePaymentDeposit (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/paymentdeposit/update', data)
  },
  getIndividualPaymentDeposit (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/paymentdeposit/get/' + data)
  },

  /* Transport */
  getTransport () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/transports')
  },
  postTransport (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/transports', data)
  },
  deleteTransport (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/clearingforwarding/transports/' + data)
  },
  updateTransport (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/clearingforwarding/transports/', data)
  },
  getIndividualTransport (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/transports/' + data)
  },

  /* InsuranceType */
  getAllInsuranceTypes () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/insurancetype/all')
  },
  postInsuranceType (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/insurancetype/config', data)
  },
  deleteInsuranceType (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/insurancetype/config/' + data)
  },
  updateInsuranceType (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/insurancetype/config', data)
  },
  getIndividualInsuranceType (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/insurancetype/config/' + data)
  },

  /* Insurances */
  getAllInsurances () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/insurance/all')
  },
  postInsurance (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/insurance/config', data)
  },
  deleteInsurance (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/insurance/config/' + data)
  },
  updateInsurance (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/insurance/config', data)
  },
  getIndividualInsurance (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/insurance/config/' + data)
  },

  /* DeliveryNote */
  getDeliveryNote () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/deliveryNote/all')
  },
  postDeliveryNote (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/deliveryNote/config', data)
  },
  deleteDeliveryNote (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/deliveryNote/config/' + data)
  },
  updateDeliveryNote (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/deliveryNote/config', data)
  },
  getIndividualDeliveryNote (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/deliveryNote/config/' + data)
  },

  /* Permit */
  getPermit () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/permit/all')
  },
  postPermit (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/permit/config', data)
  },
  deletePermit (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/permit/config/' + data)
  },
  updatePermit (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/permit/config', data)
  },
  getIndividualPermit (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/permit/config/' + data)
  },

  /* ReceiveNote */
  getReceiveNote () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/receiveNote/all')
  },
  postReceiveNote (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/receiveNote/config', data)
  },
  deleteReceiveNote (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/receiveNote/config/' + data)
  },
  updateReceiveNote (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/receiveNote/config', data)
  },
  getIndividualReceiveNote (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/receiveNote/config/' + data)
  },

  /* Cost Estimate List */
  getCostEstimate () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/costEstimate/all',

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  postCostEstimate (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/costEstimate/cost', data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  deleteCostEstimate (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/costEstimate/cost/' + data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  updateCostEstimate (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/costEstimate/cost', data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  getIndividualCostEstimate (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/costEstimate/cost/' + data)
  },

  getIndividualCostEstimateByRequestId (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/costEstimate/costReq/' + data)
  },

  /* Container List */
  getContainer () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/container/all')
  },
  postContainer (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/container/config', data)
  },
  deleteContainer (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/container/config/' + data)
  },
  updateContainer (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/container/config', data)
  },
  getIndividualContainer (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/container/config/' + data)
  },

  /* Container List */
  getAttachments () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/attachments/list')
  },
  postAttachment (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/attachments/config',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },
  deleteAttachment (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/attachments/delete/' + data)
  },

  updateAttachment (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/attachments/config',
      data,
      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  getIndividualAttachment (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/attachments/get/' + data)
  },

  getPagebleAttachments (pageNo, pageSize) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/attachments/list-pageable?page=' + pageNo + '&size=' + pageSize)
  },

  searchAttachment (search, pageNo, pageSize) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/attachments/search?searchText=' + search + '&pageNo=' +
        pageNo + '&pageSize=' + pageSize)
  },

  /* Clearing Request */

  getRequestAll (pageNo, pageSize) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/list/pageable?pageNo=' + pageNo +
        '&pageSize=' + pageSize)
  },

  getRequestparams (params) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/filter-by-request-date' + params)
  },

  getPageableRequest (flag, pageNo, pageSize, status) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/list/' + flag + '?pageNo=' + pageNo +
        '&pageSize=' + pageSize + '&status=' + status)
  },

  searchCustomers (searchTerm) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/customers/search-all?searchTerm=' + searchTerm)
  },

  getPageableRequestForSingleCustomer (pageNo, pageSize, customerId) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/customer-requests/' + customerId + '?pageNo=' +
        pageNo + '&pageSize=' + pageSize)
  },

  getSingleRequest (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/request/' + data)
  },

  updateReceivingPoint (requestId, stationId, transportMethodId) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/add-receiving-point/' + requestId + '?stationId=' + stationId + '&transportMethodId=' + transportMethodId)
  },

  deleteARequest (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/clearingforwarding/request/' + data)
  },

  deleteRequestAttachment (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/clearingforwarding/attachment/' + data)
  },

  updateSingleRequest (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/clearingforwarding/request',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  postRequestAssessmentNotice (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/assessment-notice/add',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  updateRequestAssessmentNotice (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/assessment-notice/update',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  updateRequestAssessmentNoticeV2 (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/assessment-notice/v2/update',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  deleteSingleRequest (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/assessment-notice/delete/' + data)
  },

  getRequestAssessmentNotice (data) {

    return HTTP_CFA.get(CFA_SERVER_URL + '/assessment-notice/getByRequest/' + data)

  },

  getPreviewAssesmentDataFromDeclaration(requestId){
    return HTTP_CFA.get(CFA_SERVER_URL + '/assessment-notice/populate/' + requestId)
  },

  postRequest (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/request',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  createPackagetype (data) {
    return HTTP_CONFIG.post(CONFIG_SERVER_URL + '/package-type/add', data)
  },

  saveThreeReferences (requestId, refNumber, refTin, refYear) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/tesws-references/save/' + requestId + '?referenceSerialNo=' + refNumber + '&referenceTIN=' + refTin + '&referenceYear=' + refYear)
  },

  pullDeclarationFromTesws (refNo, refTin, refYear) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/declaration/pull?referenceSerialNo=' + refNo + '&referenceTIN=' + refTin + '&referenceYear=' + refYear)
  },
  deleteDeclaration (refNo, refTin, refYear, requestId) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/declaration/delete?requestId=' + requestId + '&referenceSerialNo=' + refNo + '&referenceTIN=' + refTin + '&referenceYear=' + refYear)
  },

  getDeclarationFromLocal (refNo, refTin, refYear) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/declaration/get?referenceSerialNo=' + refNo + '&referenceTIN=' + refTin + '&referenceYear=' + refYear)
  },

  getNoticeListLocal (requestId) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/notice/notices/' + requestId)
  },

  getAssessmentNotice (noticeId, requestId) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/notice/assessment?noticeId=' + noticeId + '&requestId=' + requestId)
  },

  getReleaseNotice (noticeId, requestId) {

    return HTTP_CFA.post(CFA_SERVER_URL + '/notice/release?noticeId=' + noticeId + '&requestId=' + requestId)
  },

  editPackagetype (data) {
    return HTTP_CONFIG.put(CONFIG_SERVER_URL + '/package-type/edit', data)
  },

  getPackageTypes () {
    return HTTP_CONFIG.get(CONFIG_SERVER_URL + '/package-type/list-all')
  },

  searchRequest (pageNo, pageSize, sortBy, searchData, flag, status) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/search-flag/' + flag + '?pageNo=' + pageNo +
        '&pageSize=' + pageSize + '&sortBy=' + sortBy + '&searchTerm=' + searchData + '&status=' + status)
  },

  searchRequestActiveTab (pageNo, pageSize, sortBy, searchData, flag, status) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/search-flag-status/' + flag + '?pageNo=' + pageNo +
        '&pageSize=' + pageSize + '&sortBy=' + sortBy + '&searchTerm=' + searchData + '&status=' + status)
  },

  searchAllRequest (pageNo, pageSize, searchData) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/search-all?pageNo=' + pageNo + '&pageSize=' +
        pageSize + '&searchTerm=' + searchData)
  },

  searchCustomerRequest (pageNo, pageSize, sortBy, searchData, userId) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/search/' + userId + '?pageNo=' + pageNo +
        '&pageSize=' + pageSize + '&sortBy=' + sortBy + '&searchTerm=' + searchData)
  },

  requestFilter (params) {

    // if(queryUrl !== undefined){

      return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/search/requests' + params);

    // }else {

    //   return HTTP_CFA.get(CFA_SERVER_URL + `/clearingforwarding/search/requests?size=${pageSize}&page=${pageNo}${status}`);

    // }

  },

  searchCharges (pageNo, pageSize, searchData) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/search-charged-requests?pageNo=' + pageNo +
        '&pageSize=' + pageSize + '&searchTerm=' + searchData)
  },

  postRequestDeliveryInformation (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/request-delivery/add',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  updateRequestStatus (requestId, status) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/request/update-status?requestId=' + requestId + '&status=' + status)
  },

  approveRequest (requestId) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/approve?requestId=' + requestId)
  },

  getRequestDeliveryInformation (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/request-delivery/list/' + data)
  },

  verifyAttachedAttachment (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/clearingforwarding/verify-attachment/' + data)
  },

  deleteRequestReceivingPoint (data) {
    return HTTP_CFA.delete(CFA_SERVER_URL + '/request-delivery/delete/' + data)
  },

  updateRequestReceivingPoint (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/request-delivery/update/',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  /* Notification */

  getRequestFromNotification (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/request-dto/' + data)
  },

  /* Request Attachment */

  getFullRequestDetails (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/full-request/' + data)
  },

  uploadRequestAttachment (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/clearingforwarding/attachment', data)
  },

  /* Cargo Group */

  getCargoGroup () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/cargo-group/all')
  },
  postCargoGroup (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/cargo-group/add'
      , data

      , {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  updateCargoGroup (data) {
    return HTTP_CFA.put(CFA_SERVER_URL + '/cargo-group/update', data)
  },

  getIndividualCargoGroup (data) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/cargo-group/get/' + data)
  },

  /*  END CLEARING AND FORWARDING APIs METHODS    **********************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  **********************************************************************************************************************
  ******************************************************************************************************************** */

  /* DASHBOARD */

  /* CUSTOMER Summary Report */
  getImportOrExportRequestPerCustomer (customerId, requestTypeFlag) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/total-customer-request?customerId=' + customerId +
        '&requestTypeFlag=' + requestTypeFlag)
  },
  getStatusRequestPerCustomer (customerId, status) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/total-customer-request-status?customerId=' +
        customerId + '&status=' + status)
  },

  /* CF Summary Report */
  getImportOrExportRequestCfa (requestTypeFlag) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/total-requests-flag?requestTypeFlag=' + requestTypeFlag)
  },

  getStatusRequestCfa (status) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/total-requests-status?&status=' + status)
  },

  getStatusImportOrExportRequestCfa (status, requestFlag) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/total-requests-status-requestflag?&status=' + status + '&requestFlag=' + requestFlag)
  },

  /* Document Summary Report */
  getTotalDocumentsReceived (isImport, isCancelled) {
    return HTTP.get(DC_SERVER_URL + '/documents/totalDocumentsReceived/' + isImport + '/' + isCancelled)
  },
  getTotalDocumentsReceivedMonthly (year, isImport, isCancelled) {
    return HTTP.get(DC_SERVER_URL + '/documents/totalDocumentsReceivedMonthly/' + year + '/' + isImport + '/' + isCancelled)
  },
  getTotalDocumentsReceivedGroupedYearly (isImport, isCancelled) {
    return HTTP.get(DC_SERVER_URL + '/documents//totalDocumentsReceivedGroupedYearly/' + isImport + '/' + isCancelled)
  },

  /* Vessel Summary Report */
  getTotalVesselArrived () {
    return HTTP.get(DC_SERVER_URL + '/vesselSchedule/totalVesselArrived')
  },
  getTotalVesselArrivedMonthly (year) {
    return HTTP.get(DC_SERVER_URL + '/vesselSchedule/totalVesselArrivedMonthly/' + year)
  },
  getTotalVesselArrivedGroupedYearly () {
    return HTTP.get(DC_SERVER_URL + '/vesselSchedule/totalVesselArrivedGroupedYearly')
  },

  /// bill-report/totalRevenueFor/{status}/{isCfa}
  // billRevenueForStatusAndIsCfa

  /* Request Summary Report */
  getTotalRequestsReceived () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/summary/totalRequestsReceived')
  },
  getTotalRequestsByTarrifAllYear (tarrifItemId) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/summary/totalRequestsByTarrifAllYear/' + tarrifItemId)
  },
  getTotalRequestsByTypeAllYear (requestTypeId) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/summary/getTotalRequestsByTypeAllYear/' + requestTypeId)
  },
  getTotalRequestForYearMonthly (year) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/summary/totalRequestForYearMonthly/' + year)
  },
  getTotalRequestsByTypeYearly (requestTypeId) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/summary/getTotalRequestsByTypeYearly/' + requestTypeId)
  },
  getTotalRequestsForYearRequestTypeId (year, requestTypeId) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/summary/totalRequestsForYearRequestTypeId/' + year + '/' + requestTypeId)
  },
  getTotalRequestByTypeMonthly (year, requestTypeId) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/summary/totalRequestByTypeMonthly/' + year + '/' + requestTypeId)
  },
  getTotalRequestByTarrifMonthly (year, tarrifId) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/summary/totalRequestByTarrifMonthly/' + year + '/' + tarrifId)
  },

  RejectRequestAssessmentNotice (description, requestId, assessmentNoticeId) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/assessment-notice/reject-assessment/' + assessmentNoticeId + '?requestId=' + requestId + '&description=' + description)
  },

  /* REPORTS */

  generateCfaReport (params) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/cfareport/get-cfa-report' + params,

      {

        headers: {

          'Content-Type': 'application/octet-stream'

        },

        responseType: 'blob'

      }
    )
  },

  generateAppStatusReport (params) {
    return HTTP_CFA.get(CFA_SERVER_URL + '/activityLog/activity-loging' + params,

      {

        headers: {

          'Content-Type': 'application/octet-stream'

        },

        responseType: 'blob'

      }
    )
  },

  /* NOTIFICATION */

  autoLoadNotificationsRejectedCustomer () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/new/rejected-requests')
  },

  autoLoadNotificationsRequestMessagesCustomer () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/request-comments/notifications-customer')
  },

  autoLoadNotificationsRequestMessagesStaff () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/request-comments/notifications-staff')
  },

  markChatMessagesAsRead (data) {
    return HTTP_CFA.post(CFA_SERVER_URL + '/request-comments/comment-starred/' + data)
  },

  autoLoadNotificationsCreatedTasac () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/new/request-created')
  },

  autoLoadNotificationsAmendedTasac () {
    return HTTP_CFA.get(CFA_SERVER_URL + '/clearingforwarding/new/request-amended')
  },

  getCfaAgentsUsers(cfaAgentId,type){
    return HTTP_UAA.post( AUTH_SERVER_URL + '/users/customers/' + cfaAgentId +'?customerType=' + type);
  },


}
