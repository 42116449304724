/* import axios */
import axios from 'axios';
import {TALLY_SERVER_URL} from "../api/settings";
// Base URL for tally microservice server

/* axios default headers for the below initiated instances */
axios.defaults.headers['access-control-allow-Origin'] = "*";
axios.defaults.headers['Access-Control-Allow-Methods'] = 'POST, PUT, GET, OPTIONS, DELETE';
axios.defaults.headers['Access-Control-Allow-Credentials'] = true;
axios.defaults.headers['Cache-Control'] = 'no-cache';
axios.defaults.timeout = 120 * 1000; //  120 seconds

const HTTP_TALLY = axios.create({

  'X-Requested-With': 'XMLHttpRequest',

  baseURL: TALLY_SERVER_URL,

  headers: {
    'Content-Type': 'application/json'

  }

});

// HTTP_TALLY.defaults.headers.common.Authorization=localStorage.getItem('appToken');


export default {

  handle401 (success, error) {

    return  HTTP_TALLY.interceptors.response.use(success, error);

  },

  authenticate (token) {

    HTTP_TALLY.defaults.headers.common.Authorization = 'Bearer ' + token

  },

  isAuthenticated (token) {

      HTTP_TALLY.defaults.headers.common.Authorization = 'Bearer ' + token
  },

  assignSingleClerk(data) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-clerk-assignments/', data);
  },

  approveAssignmentsByNotification(notificationId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-clerk-assignments/approve-by-notification/' + notificationId);
  },

  getAssignmentsByActiveNotifications() {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-clerk-assignments/by-active-notifications');
  },

  // Tally Reports
  getTallySheet(shiftId, talliedDocumentId, day, cargoType) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-containers/by-tallied-bl/' + shiftId + '/' + talliedDocumentId + '/' + day + '/' + +'/' + cargoType);
  },

  addTallyCharge(data) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tallied-document-charges/add', data);
  },

  getTallyDocumentCharges(data) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-document-charges/all/pageable/'+ data)
  },

  getTallySheetDocumentCharges() {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-document-charges/all/pageable')
  },

  getTallySheetDocumentChargeById(id) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-document-charges/' + id)
  },

  /* ** configurations ** */
  CreateShift(data) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-operation-shifts', data);
  },

  deleteShift(shiftId) {
    return HTTP_TALLY.delete(TALLY_SERVER_URL + '/tally-operation-shifts/' + shiftId);
  },

  getAllTables(size, number) {
    return HTTP_TALLY.get('http://196.192.79.34:9062/ship-agency/service/category/list?size=' + size + '&page=' + number);
  },

  editShift(data) {
    return HTTP_TALLY.put(TALLY_SERVER_URL + '/tally-operation-shifts', data);
  },

  // *** Tallied Containers By Bls
  getTalliedContainersByBl(BlNumber) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-containers/by-tallied-bl/' + BlNumber);
  },

  // *** Tallied Goods By Bls
  getTalliedGoodsByBl(BlNumber) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-goods/by-tallied-bl/' + BlNumber);
  },

  // *** Tally de-stuffing Controller

  getStuffingDestuffingDocumentsPageable(stuffingType, size, number) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-de-stuffing/all/pageable/' + stuffingType + '/?size=' + size + '&page=' + number);
  },


  // *** Tallying Controller
  getTalliedDocumentsPageable(stuffingType, size, number) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallying-documents/all/pageable/' + stuffingType + '/?size=' + size + '&page=' + number);
  },


  getTallySheetDetails(data) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallying-documents/' + data);
  },

  addCargoBalance(data) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-notifications/balance', data)
  },

  // *** Tallied Bl Controller
  getTalliedBlAllByVoyage(voyageId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-bls/all/' + voyageId);
  },

  getTalliedBlInDayByVoyage(voyageId, queryDate) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-bls/in-day/' + voyageId +
      '?localDateTime=' + queryDate);
  },

  getTalliedGoodById(goodId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-goods/tallied-good/' + goodId);
  },

  getTalliedContainerById(containerId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-containers/container/' + containerId);
  },

  // *** Tallied Container Controller
  getTalliedContainersAllByVoyage(voyageId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-containers/all/' + voyageId);
  },

  getTalliedContainersInDayByVoyage(voyageId, queryDate) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-containers/in-day/' + voyageId +
      '?localDateTime=' + queryDate);
  },

  getTalliedContainersInDayPageable(voyageId, queryDate) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-containers/in-day/pageable' + voyageId +
      '?localDateTime=' + queryDate);
  },

  getTalliedContainersInShiftPageable(voyageId, queryDate, shiftId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-containers/in-shift/' + voyageId + '/' + shiftId +
      '?localDateTime=' + queryDate);
  },

  /* TALLIED STRIPPING AND STUFFING */
  getTalliedStuffingOrStrippingPageable(stuffingType, size, number) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallying-documents/all/pageable/' + stuffingType + '/?size=' + size + '&page=' + number);
  },

  getTalliedStuffingOrStrippingSingle(documentId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-de-stuffing/get-by-id/' + documentId);
  },

  getStuffedOrDestuffedGoodsByBl(documentId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-de-stuffing/stuffed-goods/' + documentId);
  },

  getTalliedContainersStuffingOrStripping(talliedStuffingOrStrippingId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-de-stuffing/stuffed-containers/' + talliedStuffingOrStrippingId);
  },

  getTalliedContainersAllPageableByType(talliedDeStuffingId,stuffingType,pageNo, pageSize){
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-de-stuffing/stuffed-containers/pageable/' + talliedDeStuffingId + '/' + stuffingType + '?size='+ pageSize + '&page=' + pageNo );
  },

  // *** Tallied Good Details Controller
  getTalliedGoodsInDayByVoyage(voyageId, queryDate) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-goods/in-day/' + voyageId +
      '?localDateTime=' + queryDate);
  },

  getTalliedGoodsInDayPageable(voyageId, queryDate) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-goods/in-day/pageable' + voyageId +
      '?localDateTime=' + queryDate);
  },
  getTalliedGoodsInShiftPageable(voyageId, queryDate, shiftId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-goods/in-shift/' + voyageId + '/' + shiftId +
      '?localDateTime=' + queryDate);
  },
  getAllShifts() {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-operation-shifts');
  },

  CreateResource(data) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-resources', data);
  },

  editResource(resource) {
    return HTTP_TALLY.put(TALLY_SERVER_URL + '/tally-resources', resource);
  },

  getResourceById(resourceId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-resources/' + resourceId);
  },

  getAllResources() {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-resources');
  },
  deleteResource(resourceId) {
    return HTTP_TALLY.delete(TALLY_SERVER_URL + '/tally-resources/' + resourceId);
  },

  createShiftClerk(data) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-clerk-shifts/', data);
  },

  getClerksOnShiftOnDay(data) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-clerk-assignments/users/' + data.shiftId + '/' + data.day + '/' + data.notificationId);
  },

  getShiftsByNotificationId(notificationId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-clerk-assignments/by-notification/' + notificationId);
  },

  createResourceClerk(data) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-clerk-devices/', data);
  },

  getTallyClerkResources() {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-clerk-devices/');
  },
  createNotification(data) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-notifications/', data);
  },

  editNotification(data) {
    return HTTP_TALLY.put(TALLY_SERVER_URL + '/tally-notifications/', data);
  },
  getNotification(pageNo, pageSize) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-notifications/all/pageable?size=' + pageSize + '&page=' + pageNo);
  },

  getNotificationByType(pageNo, pageSize, data) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-notifications/all/pageable/'+ data +'?size=' + pageSize + '&page=' + pageNo);
  },

  getNotificationById(notificationId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-notifications/' + notificationId);
  },

  deleteNotification() {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-notifications/all/pageable');
  },
  CreateTallyOperation(operation) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-clerk-assignments/auto/assignments', operation);
  },

  getShiftClerks() {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-clerk-shifts/users');
  },

  getClerksInShift(shiftId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-clerk-shifts/get-users-in-shift/' + shiftId);
  },

  getClerksInShiftOnDay(shiftId, day) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-clerk-shifts/users-shift-day/' + shiftId + '/' + day);
  },

  postVehicleProperties(data) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-vehicle-properties/', data);
  },
  getVehicleProperties() {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-vehicle-properties/');
  },

  deleteVehicleProperty(id) {
    return HTTP_TALLY.delete(TALLY_SERVER_URL + '/tally-vehicle-properties/' + id);
  },

  /* Download shipping tally sheet */


  downloadTallySheet(reportSearchDto) {
    
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-reports/tally-sheet', reportSearchDto,

      {

        headers: {

          // 'Content-Type': 'application/octet-stream'
          'Content-Type': 'application/json'

        },

        responseType: 'blob'

      }
    );
  },

  downloadReconciliationReport(documentId) {
    
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-reports/reconcile/' + documentId,

      {

        headers: {

          // 'Content-Type': 'application/octet-stream'
          'Content-Type': 'application/json'

        },

        responseType: 'blob'

      }
    );
  },

  /* Download shipping tally Daily Report */
  downloadTallyDaily(reportSearchDto) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-reports/tally-daily-report', reportSearchDto,

      {

        headers: {

          'Content-Type': 'application/json'

        },

        responseType: 'blob'

      }
    );
  },

  /* Download shipping tally sheet */
  downloadTallyShift(reportSearchDto) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-reports/tally-shift-report/', reportSearchDto,
      {

        headers: {

          'Content-Type': 'application/json'

        },

        responseType: 'blob'

      }
    );
  },


  /* Download shipping tally sheet */
  downloadVesselPerformance(reportSearchDto) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-reports/ship-performance-report/', reportSearchDto,
      {
        headers: {
          'Content-Type': 'application/json'

        },

        responseType: 'blob'

      }
    );
  },

  /* Download shipping tally sheet */
  downloadGeneralReport(tallyDocumentId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-reports/general/'+ tallyDocumentId,
      {

        responseType: 'blob',

        headers: {
          Accept: 'application/octet-stream'
        }

      }
    );
  },

  /* Mark As Completed Tally */
  markCompletedTallying(talliedDocumentId) {
    return HTTP_TALLY.put(TALLY_SERVER_URL + '/tallying-documents/complete/tallying/' + talliedDocumentId);
  },

  markCompletedStuffingDestuffingTallying(talliedDocumentId) {
    return HTTP_TALLY.put(TALLY_SERVER_URL + '/tallying-documents/de-stuffing/completed/' + talliedDocumentId);
  },

  getTallyDelayRemarks(documentId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallying-documents/delay-remarks/' + documentId);
  },

  tallyTariffConfig(data) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-cargo-tariff-fees/', data);
  },

  getAssignMentById(notificationId) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-clerk-assignments/by-notification/' + notificationId);
  },
  deleteAssignment(assignmentId) {
    return HTTP_TALLY.delete(TALLY_SERVER_URL + '/tally-clerk-assignments/' + assignmentId);
  },

  getUnbilledTallyCharges() {

    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-document-charges/unbilled/');

  },

  getUnbilledDestuffingTallyCharges() {

    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-document-charges/unbilled-de-stuffing');

  },

  getUnbilledStuffingTallyCharges() {

    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-document-charges/unbilled-ex-de-stuffing');

  },

  getUnbilledTallyChargeDetails(chargeId, operationType) {

    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-document-charges/billing-criteria/' + chargeId + '/' + operationType);

  },

  getUnbilledTallyChargeDetailsStuffing(voyage, operationType) {

    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tallied-document-charges/billing-criteria-destuffing/' + voyage + '/' + operationType);

  },


//  Tally Event Codes Controllers


  getTallyEventsPageable(pageNo, pageSize) {
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-event-codes/all-pageable?size=' + pageSize + '&page=' + pageNo);
  },

  addTallyEvent(data) {
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-event-codes/add',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  },
  updateTallyEvent(data) {
    return HTTP_TALLY.put(TALLY_SERVER_URL + '/tally-event-codes/edit',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  },

  deleteTallyEvent(tallyEventCodeId) {
    return HTTP_TALLY.delete(TALLY_SERVER_URL + '/tally-event-codes/remove/' + tallyEventCodeId);
  },


//  TALLY TARIFF CONFIGURATION

  getAllTallyCargoTariffFeesPageable (pageNo, pageSize){
      return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-cargo-tariff-fees/all-mapped/pageable?size=' + pageSize + '&page='+ pageNo)
  },

  getAllTallyCargoTariffFees (){
    return HTTP_TALLY.get(TALLY_SERVER_URL + '/tally-cargo-tariff-fees/all-mapped')
  },

  updateTallyCargoTariffFeeDefault(data){
    return HTTP_TALLY.put(TALLY_SERVER_URL + '/tally-cargo-tariff-fees',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });

  },

  addTallyCargoTariffFeeDefault(data){
    return HTTP_TALLY.post(TALLY_SERVER_URL + '/tally-cargo-tariff-fees',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });

  },

  deleteTallyCargoTariffFeeDefault(tallyCargoTariffFeeId){
    return HTTP_TALLY.delete(TALLY_SERVER_URL + '/tally-cargo-tariff-fees/' + tallyCargoTariffFeeId);
  },


};
