import API from '../../../api/dc'

export default {
  namespaced: true,

  state: {

    requestId: '',

    requests: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',

    requestFlag: '',
    pageNo: 0,
    status: '',
    pageSize: 15,
    myLoader: false,
    reloadAllRequest: false,
    hasSearchedVariable: false,
    searchParameter: '',
    sortBy: '',
    blNumber: '',
    customerName: ''

  },
  mutations: {

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    BL_NUMBER: (state, blNumber) => state.blNumber = blNumber,

    CUSTOMER_NAME: (state, customerName) => state.customerName = customerName,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    REQUEST_FLAG: (state, requestFlag) => state.requestFlag = requestFlag,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    REQUEST_STATUS: (state, status) => state.status = status,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    RELOAD_ALL_APPLICATIONS: (state, reloadAllRequest) => state.reloadAllRequest = reloadAllRequest,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy
  },
  actions: {

    requestFlagging: ({ state, commit }, payload) => commit('REQUEST_FLAG', payload),

    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    requestStatusing: ({ state, commit }, payload) => commit('REQUEST_STATUS', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    searchingParameter: ({ state, commit }, payload) => commit('SEARCH_PARAMETER', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    blNumbering: ({ state, commit }, payload) => commit('BL_NUMBER', payload),

    customerNaming: ({ state, commit }, payload) => commit('CUSTOMER_NAME', payload),

    // loadApprovedLoadingListsOnCreated({commit, getters}) {
    //     return new Promise((resolve, reject) => {
    //         commit('FIRST_LOADER', true);
    //         commit('REQUEST_LIST', []);

    //         API.getPagableLoadingList(getters.getPageNo, getters.getPageSize).then(
    //             response => {
    //                 if (response.data.status) {
    //                     if (response.data.data.length > 0) {
    //                         commit('REQUEST_LIST', response.data.data);
    //                         commit('PAGE_COUNT', response.data.message);
    //                         commit('FIRST_LOADER', false);

    //                     }else {

    //                         commit('NO_DATA_FOUND', true);
    //                         commit('PAGE_COUNT', response.data.message);
    //                         commit('FIRST_LOADER', false);

    //                     }
    //                 }else {

    //                 }

    //                 resolve();
    //             }
    //         ).catch(e => {
    //             commit('ERROR_MESSAGE', ", Requests Not loaded, please contact system administrator");
    //             commit('SUCCESS_MESSAGE', "");
    //             commit('SUCCESS_ALERT', false);
    //             commit('ERROR_ALERT', true);
    //             commit('FIRST_LOADER', false);

    //             reject(e);
    //         });
    //     });
    // },

    loadApprovedLoadingListsOnCreated ({ commit, getters }) {
      console.log('stage 2 debug  :}')
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        API.getPagableLoadingList(getters.getPageNo, getters.getPageSize).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },
    loadApprovedLoadingLists ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('MY_LOADER', true)
        commit('REQUEST_LIST', [])

        API.getPagableLoadingList(getters.getPageNo, getters.getPageSize).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('PAGE_COUNT', response.data.message)
                commit('MY_LOADER', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('MY_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    loadAllApprovedLoadingLists ({ commit }) {
      commit('NO_DATA_FOUND', false)
      commit('RELOAD_ALL_APPLICATIONS', false)
      commit('HAS_SEARCH_VARIABLE', false)
      commit('SEARCH_PARAMETER', '')
    }

  },
  getters: {

    getRequestList: state => state.requests,

    getPageCount: state => state.pageCount,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getRequestId: state => state.requestId,

    getRequestFlag: state => state.requestFlag,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getRequestStatus: state => state.status,

    getMyLoader: state => state.myLoader,

    getReloadAllRequest: state => state.reloadAllRequest,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParameter: state => state.searchParameter,

    getSortBy: state => state.sortBy,

    getBlNumber: state => state.blNumber,

    getCustomerName: state => state.customerName

  }

}
