const routes = [

  {
    path: '/',
    component: () => import(/* webpackChunkName: "DashboardLayout" */
      '../layout/DashboardLayout.vue'),
    meta: {
      requiresAuth: true,
      permission: 'DASHBOARD_VIEW'
    },
    children: [
      {
        path: '/overview/document-control',
        name: 'OverviewDc',
        component: () => import(/* webpackChunkName: "OverviewDc" */
          '../pages/OverviewDC'),
        // alias: '/',
        meta: {
          requiresAuth: true,
          permission: 'DC_DASHBOARD_VIEW'
        }
      },

      /* Document Control Import Cycle */
      {
        path: '/document-control/import/cargo-document',
        name: 'ImportAddCargoDocument',
        component: () => import(/* webpackChunkName: "ImportAddCargoDocument" */
          '../pages/documentControl/import/ImportAddCargoDocument'),
        meta: {
          requiresAuth: true,
          permission: 'DC_IMPORT'
        }
      },
      {
        path: '/document-control/export/cargo-document',
        name: 'ExportAddCargoDocument',
        component: () => import(/* webpackChunkName: "ExportAddCargoDocument" */
          '../pages/documentControl/export/ExportAddCargoDocument'),
        meta: {
          requiresAuth: true,
          permission: 'DC_EXPORT'
        }
      },
      {
        path: '/document-control/nw/report',
        name: 'dcNwReprt',
        component: () => import(/* webpackChunkName: "CfaDsb" */
          '../pages/documentControl/report/dcReport'),
        meta: {
          requiresAuth: true,
          permission: 'CFAREPORT_GET-CFA-REPORT_GET'
        }
      },
      // {
      //   path: '/document-control/import/edi-documents',
      //   name: 'ImportEdiDocument',
      //   component: () => import(/* webpackChunkName: "EdiDocument" */
      //     '../pages/documentControl/import/EdiDocument'),
      //   meta: {
      //     requiresAuth: true,
      //     permissions: 'DC_IMPORT'
      //   }
      // },
      {
        path: '/document-control/import/edi-documents',
        name: 'ImportEdiDocuments',
        component: () => import(/* webpackChunkName: "EdiDocuments" */
          '../pages/documentControl/import/EdiDocuments'),
        meta: {
          requiresAuth: true,
          permissions: 'DC_IMPORT'
        }
      },
      {
        path: '/document-control/export/edi-documents',
        name: 'ExportEdiDocuments',
        component: () => import(/* webpackChunkName: "EdiDocuments" */
          '../pages/documentControl/export/EdiDocuments'),
        meta: {
          requiresAuth: true,
          permissions: 'DC_IMPORT'
        }
      },
      {
        path: '/document-control/export/edi-documents',
        name: 'ExportEdiDocument',
        component: () => import(/* webpackChunkName: "EdiDocument" */
          '../pages/documentControl/export/EdiDocument'),
        meta: {
          requiresAuth: true,
          permissions: 'DC_IMPORT'
        }
      },
      {
        path: '/v2/document-control/:direction/edi-documents',
        name: 'v2ExportEdiDocument',
        component: () => import(/* webpackChunkName: "v2ExportEdiDocument" */
          '../pages/documentControl/v2/ManifestList'),
        meta: {
          requiresAuth: true,
          permissions: 'DC_EXPORT'
        }
      },
      {
        path: '/v2/document-control/:direction/edi-documents',
        name: 'v2ImportEdiDocument',
        component: () => import(/* webpackChunkName: "v2ImportEdiDocument" */
          '../pages/documentControl/v2/ManifestList'),
        meta: {
          requiresAuth: true,
          permissions: 'DC_IMPORT'
        }
      },

      {
        path: '/document-control/document-view/:direction/:documentId',
        name: 'v2EdiDocumentDetails',
        component: () => import(/* webpackChunkName: "EdiDocument" */
          '../pages/documentControl/v2/manifestDetails'),
        meta: {
          requiresAuth: true,
          permissions: 'DC_IMPORT'
        }
      },
      {
        path: '/document-control/document-view/:flag/:documentId/:documentType',
        name: 'EdiDocument',
        component: () => import(/* webpackChunkName: "EdiDocument" */
          '../pages/documentControl/subComponents/document'),
        meta: {
          requiresAuth: true,
          permissions: 'DC_IMPORT'
        }
      },
      {
        path: '/document-control/document-view/:flag/:documentId/:documentType',
        name: 'EdiDocument1',
        component: () => import(/* webpackChunkName: "EdiDocument" */
          '../pages/documentControl/subComponents/document'),
        meta: {
          requiresAuth: true,
          permissions: 'DC_IMPORT'
        }
      },
      {
        path: '/document-control/import/tancis-documents-discrepancy',
        name: 'ImportReconciliationSummary',
        component: () => import(/* webpackChunkName: "ImportReconciliationSummary" */
          '../pages/documentControl/import/ImportReconciliationSummary'),
        meta: {
          requiresAuth: true,
          permission: 'DC_IMPORT'
        }
      },
      {
        path: '/document-control/export/tancis-documents-discrepancy',
        name: 'ExportReconciliationSummary',
        component: () => import(/* webpackChunkName: "ExportReconciliationSummary" */
          '../pages/documentControl/export/ExportReconciliationSummary'),
        meta: {
          requiresAuth: true,
          permission: 'DC_EXPORT'
        }
      },
      {
        path: '/document-control/import/tancis-extracted-cargo-manifest-document',
        name: 'ImportAddTancisExtractedCargoManifest',
        component: () => import(/* webpackChunkName: "ImportAddTancisExtractedCargoManifest" */
          '../pages/documentControl/import/AddTancisExtractedCargoManifest'),
        meta: {
          requiresAuth: true,
          permission: 'DC_IMPORT'
        }
      },
      {
        path: '/document-control/manifest-charges',
        name: 'ManifestDocumentBillImport',
        component: () => import(/* webpackChunkName: "ManifestDocumentBill" */
          '../pages/revenue/documentControl/charges/chargedDocuments'),
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-PAGEABLE-ISCFA_GET'
        }
      },
      {
        path: '/document-control/manifest-charge-view/:documentChargeId',
        name: 'ManifestCharge',
        component: () => import(/* webpackChunkName: "ManifestCharge" */
          '../pages/revenue/documentControl/charges/chargedDocumentDetails'),
        props: true,
        meta: {
          requiresAuth: true,
          permission: 'BILLS_ALL-PAGEABLE-ISCFA_GET'
        }
      },
      {
        path: '/document-control/import/tancis-documents-discrepancy-summary/:voyage',
        name: 'ImportReconciliationSummaryVoyage',
        component: () => import(/* webpackChunkName: "ImportReconciliationSummaryVoyage" */
          '../pages/documentControl/import/ImportReconciledSummaryInd'),
        meta: {
          requiresAuth: true,
          permission: 'DC_IMPORT'
        }
      },
      {
        path: '/document-control/import/reconciliation/:voyage',
        name: 'ImportReconciliation',
        component: () => import(/* webpackChunkName: "ImportReconciliation" */
          '../pages/documentControl/import/ImportReconciledDoc'),
        meta: {
          requiresAuth: true,
          permission: 'DC_IMPORT'
        }
      },
      {
        path: '/document-control/export/reconciliation/:TPACallId',
        name: 'ExportReconciliation',
        component: () => import(/* webpackChunkName: "ExportReconciliation" */
          '../pages/documentControl/export/ExportReconciledDoc'),
        meta: {
          requiresAuth: true,
          permission: 'DC_EXPORT'
        }
      }

    ]
  }
]

export default routes
