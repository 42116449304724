import API_REVENUE from '../../../api/rev'

export default {
  namespaced: true,
  state: {
    stateTariffItems:[],
    stateTariffFees:[],

    TariffDetailsSuccessAlert: false,
    TariffDetailsErrorAlert: false,

    loadingTariffItems:false,
    loadingTariffFees: false,

  },
  mutations:{
    TARIFF_ITEM_DETAILS: (state, stateTariffItems) => state.stateTariffItems = stateTariffItems,
    TARIFF_FEE_DETAILS: (state, stateTariffFees) => state.stateTariffFees = stateTariffFees,
    TARIFF_ERROR_ALERT:(state, TariffDetailsErrorAlert) => state.TariffDetailsErrorAlert = TariffDetailsErrorAlert,
    TARIFF_SUCCESS_ALERT: (state, TariffDetailsSuccessAlert) => state.TariffDetailsSuccessAlert = TariffDetailsSuccessAlert,
    LOADING_TARIFF_ITEMS: (state, loadingTariffFees) => state.loadingTariffFees = loadingTariffFees,
    LOADING_TARIFF_FEES: (state, TariffDetailsSuccessAlert) => state.TariffDetailsSuccessAlert = TariffDetailsSuccessAlert,

  },
  actions:{
    getTariffItems ({commit}) {

      return new Promise((resolve,reject) =>{
        commit('TARIFF_ITEM_DETAILS', []);
        commit('TARIFF_ERROR_ALERT', false);
        commit('TARIFF_SUCCESS_ALERT', false);
        commit('LOADING_TARIFF_ITEMS', false);


          API_REVENUE.getTariffItemsActive().then (
            response =>{
              if(response.data.status){
                if(response.data.data.length > 0){
                  commit( 'TARIFF_ITEM_DETAILS',JSON.parse(JSON.stringify(response.data.data)));
                  commit('TARIFF_SUCCESS_ALERT', true);
                  commit('LOADING_TARIFF_ITEMS', true);

                }else{

                }
                  commit('TARIFF_ERROR_ALERT', true);
                commit('LOADING_TARIFF_ITEMS', true);

              }else{
                commit('TARIFF_ERROR_ALERT', true);
                commit('LOADING_TARIFF_ITEMS', true);

              }
              resolve()
            }
          ).catch(e =>{
            commit('TARIFF_ERROR_ALERT', true);
            commit('LOADING_TARIFF_ITEMS', true);

            reject(e)
          })

      })


    },

    getTariffFees ({commit}) {

      return new Promise((resolve,reject) =>{
        commit('TARIFF_FEE_DETAILS', []);
        commit('TARIFF_ERROR_ALERT', false);
        commit('TARIFF_SUCCESS_ALERT', false);
        commit('LOADING_TARIFF_FEES', false);
        API_REVENUE.getActiveTariffFee().then (
          response =>{
            if(response.data.status){
              if(response.data.data.length > 0){
                commit( 'TARIFF_FEE_DETAILS',JSON.parse(JSON.stringify(response.data.data)));
                commit('TARIFF_SUCCESS_ALERT', true);
                commit('LOADING_TARIFF_FEES', true);

              }else{

              }
              commit('TARIFF_ERROR_ALERT', true);
              commit('LOADING_TARIFF_FEES', true);

            }else{
              commit('TARIFF_ERROR_ALERT', true);
              commit('LOADING_TARIFF_FEES', true);

            }
            resolve()
          }
        ).catch(e =>{
          commit('TARIFF_ERROR_ALERT', true);
          commit('LOADING_TARIFF_FEES', true);

          reject(e)
        })

      })


    },


  },
  getters:{
    getTariffFeeDetails: state => state.stateTariffFees,
    getTariffItemsDetails: state => state.stateTariffItems,
    loadingTariffItemsDetails: state => state.loadingTariffItems,
    loadingTariffFeeDetails: state => state.loadingTariffFees,
    getTariffSuccessAlert: state => state.TariffDetailsSuccessAlert,
    getTariffErrorAlert: state => state.TariffDetailsErrorAlert

  }
}
