import AGENCY_API from '../../../api/agency'

export default {
  namespaced: true,

  state: {

    requestId: '',
    requests: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    searchParameter: '',

    totalElements: "",
    totalPages: "",
    pageNumber: "",
    isFirst: false,
    isLast: false,
    params: '',
    hasSearchedVariable: false

  },
  mutations: {

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMS: (state, params) => state.params = params,

    TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

    TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

    PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

    IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

    IS_LAST: (state, isLast) => state.isLast = isLast,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter

  },
  actions: {

    hasSearchngParams: ({state, commit}, payload) => commit('HAS_SEARCH_VARIABLE', payload),

    paramsSearching: ({state, commit}, payload) => commit('SEARCH_PARAMS', payload),

    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    requestStatusing: ({ state, commit }, payload) => commit('REQUEST_STATUS', payload),

    savingDetails: ({ state, commit }, payload) => commit('SAVE_DETAILS', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    searchingParameter: ({ state, commit }, payload) => commit('SEARCH_PARAMETER', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    voyageIding: ({ state, commit }, payload) => commit('VOYAGE_ID', payload),

    queryingDate: ({ state, commit }, payload) => commit('QUERY_DATE', payload),


    loadDeliveryOrderRequestOnCreated({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.getDeliveryOrderRequests(getters.getSearchParams).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

    loadDeliveryOrderRequestAfterCreated({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.getDeliveryOrderRequests(getters.getSearchParams).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('MY_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('MY_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('MY_LOADER', false);

          reject(e)
        })
      })
    },


    searchWithGeneralChargeParams({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])
        commit('HAS_SEARCH_VARIABLE', true)

        AGENCY_API.getDeliveryOrderRequests(getters.getSearchParams).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

  },
  getters: {

    getRequestList: state => state.requests,

    getPageCount: state => state.pageCount,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getRequestId: state => state.requestId,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyLoader: state => state.myLoader,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParameter: state => state.searchParameter,

    getSearchParams: state => state.params,

    getTotalElement: state => state.totalElements,

    getTotalPage: state => state.totalPages,

    getPageNumber: state => state.pageNumber,

    getIsFirst: state => state.isFirst,

    getIsLast: state => state.isLast,

  }

}
