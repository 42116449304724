import { CLIENT_ID, CLIENT_SECRET, ROLE_PROFILE, REFRESH_GRANT_TYPE, TOKEN_GRANT_TYPE } from "../api/settings";


export default {

  authTokenPayload () {

    return {

      grant_type: TOKEN_GRANT_TYPE,

      client_id: CLIENT_ID,

      client_secret: CLIENT_SECRET,

      scope: ROLE_PROFILE

    }

  },

  refreshTokenPayload () {

    return {

      grant_type: REFRESH_GRANT_TYPE,

      client_id: CLIENT_ID,

      client_secret: CLIENT_SECRET,

      refresh_token: window.$cookies.get('refresh_token').refreshToken,

      scope: ROLE_PROFILE

    }

  },

  // set token
  setToken (token, refreshToken, expires_in) {

    document.cookie = 'token=' + JSON.stringify(token) + ';Path = /;SameSite = Strict'

    if (!window.$cookies.isKey('refresh_token')) {

      document.cookie = 'refresh_token=' + JSON.stringify(refreshToken) + ';Path = /;SameSite = Strict'

    }

    document.cookie = 'expires_in=' + JSON.stringify(expires_in) + ';Path = /;SameSite = Strict'

  },

  // check if authenticated
  isAuthenticated () {

    if (window.$cookies.isKey('token')) {

      return !!window.$cookies.get('token').token

    } else {

      return false
      
    }
  }

}
