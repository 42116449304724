import AGENCY_API from '../../../api/agency';

export default {
  namespaced: true,

  state: {

    requestId: '',
    requests: [],
    requests2: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    attachmentResult: false,
    hasSearchedVariable: false,
    searchParameter: '',
    sortBy: '',
    voyageId: '',
    queryDate: '',
    shippingOrderId: '',
    goodsId: '',
    activityId: '',
    saveDetails: {},
    progressDetails: [],
    attachmentResults: []

  },
  mutations: {

    GOODS_ID: (state, goodsId) => state.goodsId = goodsId,

    ACTIVITY_ID: (state, activityId) => state.activityId = activityId,

    VOYAGE_ID: (state, voyageId) => state.voyageId = voyageId,

    QUERY_DATE: (state, queryDate) => state.queryDate = queryDate,

    SHIPPING_ORDER_ID: (state, shippingOrderId) => state.shippingOrderId = shippingOrderId,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    REQUEST_LIST2: (state, requests2) => state.requests2 = requests2,

    PROGRESS_DETAILS: (state, progressDetails) => state.progressDetails = progressDetails,

    SAVE_DETAILS: (state, saveDetails) => state.saveDetails = saveDetails,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    ATTACHMENT_RESULTS: (state, attachmentResult) => state.attachmentResult = attachmentResult,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy

  },
  actions: {
    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    requestStatusing: ({ state, commit }, payload) => commit('REQUEST_STATUS', payload),

    savingDetails: ({ state, commit }, payload) => commit('SAVE_DETAILS', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    searchingParameter: ({ state, commit }, payload) => commit('SEARCH_PARAMETER', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    voyageIding: ({ state, commit }, payload) => commit('VOYAGE_ID', payload),

    queryingDate: ({ state, commit }, payload) => commit('QUERY_DATE', payload),

    shippingOrderId: ({ state, commit }, payload) => commit('SHIPPING_ORDER_ID', payload),

    goodsId: ({ state, commit }, payload) => commit('GOODS_ID', payload),

    activityId: ({ state, commit }, payload) => commit('ACTIVITY_ID', payload),

    attachmentResults: ({ state, commit }, payload) => commit('ATTACHMENT_RESULTS', payload),
    // ** *** *** ** ==>

    loadCargoForShippingOrder ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_API.getCargoforshippingOrder(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', 1);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    addCargoForShippingOrder ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        AGENCY_API.addCargoToShippingOrder(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              console.log(response.data);
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data.content);
                // commit('PAGE_COUNT', response.data.data.totalPages);
                commit('FIRST_LOADER', false);
                // commit('FIRST_LOADER', false);
                // commit('ERROR_MESSAGE', "");
                // commit('SUCCESS_MESSAGE', ", outward activity saved successfully");
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Something went wrong, please contact the system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('NO_DATA_FOUND', true);
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    updateCargoForShippingOrder ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        // commit('REQUEST_LIST', []);
        AGENCY_API.updateCargoToShippingOrder(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              console.log(response.data);
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data.content);
                // commit('PAGE_COUNT', response.data.data.totalPages);
                commit('FIRST_LOADER', false);
                // commit('FIRST_LOADER', false);
                // commit('ERROR_MESSAGE', "");
                // commit('SUCCESS_MESSAGE', ", outward activity saved successfully");
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Something went wrong, please contact the system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('NO_DATA_FOUND', true);
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    deleteCargoForShippingOrder ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('MY_LOADER', true);
        // commit('REQUEST_LIST', []);

        AGENCY_API.deleteCargoInShippingOrder(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (response.data.message) {
                // commit('REQUEST_LIST', response.data.data);
                // commit('PAGE_COUNT', 1);
                commit('MY_LOADER', false);
                commit('ERROR_MESSAGE', '');
                // commit('SUCCESS_MESSAGE', ", outward activity deleted successfully");
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
              } else {
                commit('ERROR_MESSAGE', '');
                // commit('SUCCESS_MESSAGE', ", outward activity deleted successfully");
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);

                // commit('NO_DATA_FOUND', true);
                // commit('PAGE_COUNT', response.data.message);
                commit('MY_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e);
        });
      });
    },

    //   ** *** ** *** ==>

    deleteShippingOrderCargoActivity ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('MY_LOADER', true);
        // commit('REQUEST_LIST', []);

        AGENCY_API.deleteActivity(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (response.data.message) {
                // commit('REQUEST_LIST', response.data.data);
                // commit('PAGE_COUNT', 1);
                commit('MY_LOADER', false);
                commit('ERROR_MESSAGE', '');
                // commit('SUCCESS_MESSAGE', ", outward activity deleted successfully");
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
              } else {
                commit('ERROR_MESSAGE', '');
                // commit('SUCCESS_MESSAGE', ", outward activity deleted successfully");
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);

                // commit('NO_DATA_FOUND', true);
                // commit('PAGE_COUNT', response.data.message);
                commit('MY_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e);
        });
      });
    },
    getShippingOrderGoodsById ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_API.getShippingOrderGoodsById(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', 1);
                commit('FIRST_LOADER', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    getShippingOrderGoodActivities ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST2', []);

        AGENCY_API.getShippingOderGoodActivities(getters.getRequestId, getters.getShippingOrderId).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST2', response.data.data);
                commit('PAGE_COUNT', 1);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
                commit('SUCCESS_ALERT', false);
                commit('ERROR_ALERT', true);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    assignShippingOrderCargoActivity ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        // commit('REQUEST_LIST', []);
        AGENCY_API.assignShippingOrderCargoActivity(getters.getSaveDetails).then(
          response => {
            console.log('niko hapa')
            console.log(response.data)
            // console.log(JSON.parse(response.data))
            // let a = JSON.parse(response.data)
            if (response.data.status) {
              console.log(response.data);
              if (Object.keys(response.data.data).length > 0 || response.data.data.length > 0) {
                // commit('REQUEST_LIST', response.data.data.content);
                // commit('PAGE_COUNT', response.data.data.totalPages);
                commit('FIRST_LOADER', false);
                // commit('FIRST_LOADER', false);
                // commit('ERROR_MESSAGE', "");
                // commit('SUCCESS_MESSAGE', ", outward activity saved successfully");
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Something went wrong, please contact the system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('NO_DATA_FOUND', true);
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    updateShippingOrderCargoActivityAssignment ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        // commit('REQUEST_LIST', []);
        AGENCY_API.updateShippingOrderCargoActivity(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              console.log(response.data);
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data.content);
                // commit('PAGE_COUNT', response.data.data.totalPages);
                commit('FIRST_LOADER', false);
                // commit('FIRST_LOADER', false);
                // commit('ERROR_MESSAGE', "");
                // commit('SUCCESS_MESSAGE', ", outward activity saved successfully");
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Something went wrong, please contact the system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('NO_DATA_FOUND', true);
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    getShippingOrderActivityAssignmentByActivityAssignmentId ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_API.getShippingOrderActivityAssignmentByActivityAssignmentId(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', 1);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    getMyShippingOrderActivityAssignments ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_API.getMyShippingOrderActivityAssignments().then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                console.log(response.data.data);
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', 1);
                commit('FIRST_LOADER', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    getAllShippingOrderActivityAssignmentByShippingOrderId ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_API.getAllShippingOrderActivityAssignmentByShippingOrderId(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', 1);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    getShippingOrderAttachmentByShippingOrderId ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_API.listShippingOrderAttachmentByShippingOrderId(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', 1);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },

    // *** *** *** *** ==>

    addShippingOrderActivityAssignmentProgress ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        // commit('REQUEST_LIST', []);
        AGENCY_API.addShippingOrderActivityAssignmentProgress(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              console.log(response.data);
              if (Object.keys(response.data.data).length > 0) {
                commit('ATTACHMENT_RESULTS', response.data.data);
                commit('FIRST_LOADER', false);
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Something went wrong, please contact the system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('NO_DATA_FOUND', true);
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    getShippingOrderActivityAssignmentProgressById ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_API.getShippingOrderActivityAssignmentProgressById(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', 1);
                commit('FIRST_LOADER', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    getShippingOrderProgressByShippingOrderIdAndActivityIdAndGoodId ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('PROGRESS_DETAILS', []);

        AGENCY_API.getShippingOrderProgressByShippingOrderIdAndActivityIdAndGoodId(getters.getShippingOrderId, getters.getActivityId, getters.getGoodsId).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('PROGRESS_DETAILS', response.data.data);
                commit('PAGE_COUNT', 1);
                commit('FIRST_LOADER', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    addShippingOrderActivityAttachment ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_API.addShippingOrderActivityAttachment(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              console.log(response.data);
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data);
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
                commit('FIRST_LOADER', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    updateShippingOrderActivityAttachment ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_API.updateShippingOrderActivityAttachment(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              console.log(response.data);
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data);
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
                commit('FIRST_LOADER', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    }

  },
  getters: {

    getVoyageId: state => state.voyageId,

    getQueryDate: state => state.queryDate,

    getShippingOrderId: state => state.shippingOrderId,

    getGoodsId: state => state.goodsId,

    getActivityId: state => state.activityId,

    getRequestList: state => state.requests,

    getRequestList2: state => state.requests2,

    getProgressDetails: state => state.progressDetails,

    getSaveDetails: state => state.saveDetails,

    getPageCount: state => state.pageCount,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getRequestId: state => state.requestId,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyLoader: state => state.myLoader,

    getAttachmentResults: state => state.attachmentResult,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParameter: state => state.searchParameter,

    getSortBy: state => state.sortBy

  }

};
