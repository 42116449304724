import BaseInput from './components/Inputs/BaseInput.vue'
import BaseCheckbox from './components/Inputs/BaseCheckbox.vue'
import BaseRadio from './components/Inputs/BaseRadio.vue'
import BaseDropdown from './components/BaseDropdown.vue'
import BaseNav from './components/BaseNav.vue'
import BaseButton from './components/BaseButton.vue'
// import NavbarToggleButton from './components/NavbarToggleButton.vue'
import Card from './components/Cards/Card.vue'
import Modal from './components/Modal.vue'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseRadio.name, BaseRadio)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(BaseNav.name, BaseNav)
    Vue.component(BaseButton.name, BaseButton)
    // Vue.component(NavbarToggleButton.name, NavbarToggleButton)
    Vue.component('card', Card)
    Vue.component(Modal.name, Modal)
  }
}

export default GlobalComponents
