import AGENCY_APi from '../../../api/agency';

export default {
  namespaced: true,

  state: {

    requestId: '',
    requests: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    reloadAllRequest: false,
    hasSearchedVariable: false,
    searchParameter: '',
    sortBy: '',
    voyageId: '',
    queryDate: '',
    categoryId: '',
    saveDetails: {}

  },
  mutations: {

    VOYAGE_ID: (state, voyageId) => state.voyageId = voyageId,

    QUERY_DATE: (state, queryDate) => state.queryDate = queryDate,

    CATEGORY_ID: (state, categoryId) => state.categoryId = categoryId,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    SAVE_DETAILS: (state, saveDetails) => state.saveDetails = saveDetails,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    RELOAD_ALL_APPLICATIONS: (state, reloadAllRequest) => state.reloadAllRequest = reloadAllRequest,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy

  },
  actions: {
    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    requestStatusing: ({ state, commit }, payload) => commit('REQUEST_STATUS', payload),

    savingDetails: ({ state, commit }, payload) => commit('SAVE_DETAILS', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    searchingParameter: ({ state, commit }, payload) => commit('SEARCH_PARAMETER', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    voyageIding: ({ state, commit }, payload) => commit('VOYAGE_ID', payload),

    queryingDate: ({ state, commit }, payload) => commit('QUERY_DATE', payload),

    categoryIding: ({ state, commit }, payload) => commit('CATEGORY_ID', payload),

    reloadingRequest: ({ state, commit }, payload) => commit('RELOAD_ALL_APPLICATIONS', payload),

    loadOutwardActivityPageable ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_APi.getOutwardActivityPageable(getters.getPageNo, getters.getPageSize).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('FIRST_LOADER', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.data.totalPages);
                commit('FIRST_LOADER', false);
              }
            } else {

            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    getOutwardActivityByActivityId ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_APi.getOutwardActivity(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', 1);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    deleteOutwardActivity ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_APi.deleteOutwardActivity(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (response.data.message) {
                commit('NO_DATA_FOUND', false);
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', 1);
                commit('FIRST_LOADER', false);
                commit('ERROR_MESSAGE', '');
                commit('SUCCESS_MESSAGE', ', outward activity deleted successfully');
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
              } else {
                commit('ERROR_MESSAGE', '');
                commit('SUCCESS_MESSAGE', ', outward activity deleted successfully');
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);

                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('NO_DATA_FOUND', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },

    addOutwardActivity ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        AGENCY_APi.addOutwardActivity(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data.content);
                commit('PAGE_COUNT', response.data.data.totalPages);
                commit('FIRST_LOADER', false);
                commit('FIRST_LOADER', false);
                commit('ERROR_MESSAGE', '');
                commit('SUCCESS_MESSAGE', ', outward activity saved successfully');
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('ERROR_MESSAGE', ', Something went wrong, please contact the system administrator');
              commit('SUCCESS_MESSAGE', '');
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
            }

            resolve();
          }
        ).catch(e => {
          commit('NO_DATA_FOUND', true);
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },

    updateOutwardActivity ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        AGENCY_APi.updateOutwardActivity(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data.content);
                commit('PAGE_COUNT', response.data.data.totalPages);
                commit('FIRST_LOADER', false);
                commit('FIRST_LOADER', false);
                commit('ERROR_MESSAGE', '');
                commit('SUCCESS_MESSAGE', ', outward activity edited successfully');
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {

            }

            resolve();
          }
        ).catch(e => {
          commit('NO_DATA_FOUND', true);
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    }

  },
  getters: {

    getVoyageId: state => state.voyageId,

    getQueryDate: state => state.queryDate,

    getCategoryId: state => state.categoryId,

    getRequestList: state => state.requests,

    getRequestList2: state => state.requests,

    getSaveDetails: state => state.saveDetails,
    getPageCount: state => state.pageCount,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getRequestId: state => state.requestId,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyLoader: state => state.myLoader,

    getReloadAllRequest: state => state.reloadAllRequest,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParameter: state => state.searchParameter,

    getSortBy: state => state.sortBy

  }

};
