const routes = [

  {
    path: '/',
    component: () => import(/* webpackChunkName: "DashboardLayout" */
      '../layout/DashboardLayout.vue'),
    meta: {
      requiresAuth: true,
      permission: 'DASHBOARD_VIEW'
    },
    children: [
      {
        path: '/overview/clearing-and-forwarding-other',
        name: 'OverviewAdmisn',
        component: () => import(/* webpackChunkName: "OverviewAdmin" */
          '../pages/dashboardChartComponents/testDashboard'),
        // alias: '/',
        // meta: {
        //   requiresAuth: true,
        //   permission: 'DASHBOARD_VIEW'
        // }
      },
      {
        path: '/overview/clearing-and-forwarding',
        name: 'OverviewCfa',
        component: () => import(/* webpackChunkName: "OverviewCfa" */
          '../pages/OverviewCfa'),
        meta: {
          requiresAuth: true,
          permission: 'CFA_DASHBOARD_VIEW'
        }
      },

      /* IMPORT CFA */

      // {
      //   path: '/clearing-and-forwarding/apr/:requestType/application/:status',
      //   name: 'application',
      //   component: () => import(/* webpackChunkName: "AcceptedApplication" */
      //     '../pages/clearingAndForwarding/applications'),
      //   meta: {
      //     requiresAuth: true,
      //     permission: 'CFA_IMPORT'
      //   }
      // },

      {
        path: '/clearing-and-forwarding/:requestType-:status-applications',
        name: 'application',
        component: () => import(/* webpackChunkName: "AcceptedApplication" */
          '../pages/clearingAndForwarding/subComponents/applications'),
        meta: {
          requiresAuth: true,
          permission: 'CFA_IMPORT'
        }
      },

      {
        path: '/clearing-and-forwarding/import/my-assigned-activities',
        name: 'MyAssignedActivity',
        component: () => import(/* webpackChunkName: "MyAssignedActivity" */
          '../pages/clearingAndForwarding/import/MyAssignment'),
        meta: {
          requiresAuth: true,
          permission: 'CFA_IMPORT'
        }
      },

      {
        path: '/clearing-and-forwarding/import/assigned-activities-list',
        name: 'AllAssignedActivity',
        component: () => import(/* webpackChunkName: "MyAssignedActivity" */
          '../pages/clearingAndForwarding/subComponents/allAssignment.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['CFA_IMPORT', 'CFA_IMPORT']
        }
      },

      /* EXPORT CFA */
      {
        path: '/clearing-and-forwarding/apr/:requestType/application/:status',
        name: 'exportApplication',
        component: () => import(/* webpackChunkName: "AcceptedApplication" */
          '../pages/clearingAndForwarding/applications'),
        meta: {
          requiresAuth: true,
          permission: 'CFA_EXPORT'
        }
      },

      {
        path: '/clearing-and-forwarding/export/my-assigned-activities',
        name: 'MyAssignedActivity2',
        component: () => import(/* webpackChunkName: "MyAssignedActivity2" */
          '../pages/clearingAndForwarding/export/MyAssignment'),
        meta: {
          requiresAuth: true,
          permission: 'CFA_EXPORT'
        }
      },

      {
        path: 'clearing-and-forwarding/application-bill/:billId',
        name: 'ApplicationsCFABills',
        component: () => import(/* webpackChunkName: "ApplicationsCFABills" */
          '../pages/clearingAndForwarding/subComponents/bill.vue'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'
        }
      },

      /* COMMON FILES */

      {
        path: '/clearing-and-forwarding/conversion-room/:requestId',
        name: 'TasacChatRoom',
        component: () => import(/* webpackChunkName: "TasacChatRoom" */
          '../pages/clearingAndForwarding/subComponents/chatRoom'),
        meta: {
          requiresAuth: true,
          permission: 'REQUEST-COMMENTS_ADD_POST'
        }
      },

      {
        path: '/clearing-and-forwarding/declaration/:requestId',
        name: 'Declaration',
        component: () => import(/* webpackChunkName: "Declaration" */
          '../pages/clearingAndForwarding/subComponents/declarationView'),
        meta: {
          requiresAuth: true,
          permission: 'DECLARATION_PULL_POST'
        }
      },

      {
        path: '/clearing-and-forwarding/notice/list/:requestId',
        name: 'ListViewNotice',
        component: () => import(/* webpackChunkName: "ListViewNotice" */
          '../pages/clearingAndForwarding/subComponents/listView'),
        meta: {
          requiresAuth: true,
          permission: 'DECLARATION_PULL_POST'
        }
      },

      {
        path: '/clearing-and-forwarding/:noticeType/view/:noticeId/:requestId',
        name: 'AssessmentViewNotice',
        component: () => import(/* webpackChunkName: "AssessmentViewNotice" */
          '../pages/clearingAndForwarding/subComponents/AssessmentView'),
        meta: {
          requiresAuth: true,
          permission: 'DECLARATION_PULL_POST'
        }
      },

      {
        path: 'clearing-and-forwarding/updated-application/:requestId',
        name: 'TASACEditApplication',
        component: () => import(/* webpackChunkName: "TASACEditApplication" */
          '../pages/clearingAndForwarding/subComponents/editApplication.vue'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'
        },
        // props: true

      },
      //
      // {
      //   path: '/clearing-and-forwarding/application-view/:requestId',
      //   name: 'ApplicationPage',
      //   component: () => import(/* webpackChunkName: "ApplicationPage" */
      //     '../pages/clearingAndForwarding/subComponents/application'),
      //   meta: {
      //     requiresAuth: true,
      //     permission: 'CLEARINGFORWARDING_LIST_PAGEABLE_GET'
      //   }
      // },
      {
        path: '/clearing-and-forwarding/:applicationType-:status-application-view/:requestId',
        name: 'ApplicationPage',
        component: () => import(/* webpackChunkName: "ApplicationPage" */
          '../pages/clearingAndForwarding/subComponents/application'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_LIST_PAGEABLE_GET'
        }
      },
      {
        path: '/clearing-and-forwarding/tasac/applications',
        name: 'TasacClearingRequest',
        component: () => import(/* webpackChunkName: "TasacClearingRequest" */
          '../pages/clearingAndForwarding/subComponents/TasacClearingRequest'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_LIST_PAGEABLE_GET'
        }
      }

    ]
  }
]

export default routes
