import API from '../../../api/index';
import TALLY_API from '../../../api/tally';

export default {

  namespaced: true,

  state: {
    requestId: "",
    requests: [],
    requests2: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: "",
    ErrorAlert: false,
    SuccessMessage: "",
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    reloadAllRequest: false,
    hasSearchedVariable: false,
    searchParameter: "",
    sortBy: "",
    voyageId: "",
    queryDate: "",
    shiftId: "",
    stuffingType: "",
    totalElements: "",
    totalPages: "",
    pageNumber: "",
    isFirst: false,
    isLast: false,
    talliedDocumentId: "",
    operationType: ''
  },

  mutations: {

    TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

    TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

    PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

    IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

    IS_LAST: (state, isLast) => state.isLast = isLast,

    STUFFING_TYPE: (state, stuffingType) => state.stuffingType = stuffingType,

    VOYAGE_ID: (state, voyageId) => state.voyageId = voyageId,

    OPERATION_TYPE: (state, operationType) => state.operationType = operationType,

    QUERY_DATE: (state, queryDate) => state.queryDate = queryDate,

    SHIFT_ID: (state, shiftId) => state.shiftId = shiftId,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    REQUEST_LIST_2: (state, requests2) => state.requests2 = requests2,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    RELOAD_ALL_APPLICATIONS: (state, reloadAllRequest) => state.reloadAllRequest = reloadAllRequest,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy,

    TALLIED_DOC_ID: (state, talliedDocumentId) => state.talliedDocumentId = talliedDocumentId,

  },

  actions: {

    pageNumbering: ({state, commit}, payload) => commit('PAGE_NO', payload),

    requestStatusing: ({state, commit}, payload) => commit('REQUEST_STATUS', payload),

    pageSizing: ({state, commit}, payload) => commit('PAGE_SIZE', payload),

    searchingParameter: ({state, commit}, payload) => commit('SEARCH_PARAMETER', payload),

    stuffingtyping: ({state, commit}, payload) => commit('STUFFING_TYPE', payload),

    sortingBy: ({state, commit}, payload) => commit('SORT_BY', payload),

    requestid: ({state, commit}, payload) => commit('REQUEST_ID', payload),

    voyageIding: ({state, commit}, payload) => commit('VOYAGE_ID', payload),

    operationTypeAction: ({state, commit}, payload) => commit('OPERATION_TYPE', payload),

    queryingDate: ({state, commit}, payload) => commit('QUERY_DATE', payload),

    shiftIding: ({state, commit}, payload) => commit('SHIFT_ID', payload),

    reloadingRequest: ({state, commit}, payload) => commit('RELOAD_ALL_APPLICATIONS', payload),

    tallieddocumentiding: ({state, commit}, payload) => commit('TALLIED_DOC_ID', payload),

    successalerting: ({state, commit}, payload) => commit('SUCCESS_ALERT', payload),

    erroralerting: ({state, commit}, payload) => commit('ERROR_ALERT', payload),


    getTallyNotification({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        TALLY_API.getNotificationByType(getters.getPageNo, getters.getPageSize, getters.getOperationType)
          .then(response => {
            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('FIRST_LOADER', false);
                reject();

              }

            } else {

              commit('FIRST_LOADER', false);

            }

            resolve()
          }
        ).catch(e => {
          
          commit('FIRST_LOADER', false);
          reject(e)

        })
      })
    },

    loadShippingTallyingApprovedManifestListOnCreated({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        API.getApprovedManifestList(getters.getPageNo, getters.getPageSize).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

    loadShippingTallyingApprovedManifestList({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('MY_LOADER', true);
        commit('REQUEST_LIST', []);

        API.getPageableRequest(getters.getRequestFlag, getters.getPageNo, getters.getPageSize, getters.getRequestStatus).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', response.data.message);
                commit('MY_LOADER', false);
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('MY_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

    loadAllShippingTallyingApprovedManifestList({commit}) {
      commit('NO_DATA_FOUND', false);
      commit('RELOAD_ALL_APPLICATIONS', false);
      commit('HAS_SEARCH_VARIABLE', false);
      commit('SEARCH_PARAMETER', '')
    },

    searchShippingTallyingApprovedManifestList({commit, getters}) {
      return new Promise((resolve, reject) => {
        if (getters.getSearchParameter === '') {

        } else {
          commit('MY_LOADER', true);
          commit('RELOAD_ALL_APPLICATIONS', true);
          commit('HAS_SEARCH_VARIABLE', true);
          commit('NO_DATA_FOUND', false);
          commit('REQUEST_LIST', []);

          API.searchRequestActiveTab(getters.getPageNo, getters.getPageSize, getters.getSortBy, getters.getSearchParameter, getters.getRequestFlag, getters.getRequestStatus).then(
            response => {
              if (response.data.status) {
                if (response.data.data.length > 0) {
                  commit('REQUEST_LIST', response.data.data);
                  commit('PAGE_COUNT', response.data.message);
                  commit('MY_LOADER', false);
                  commit('NO_DATA_FOUND', false)
                } else {
                  commit('NO_DATA_FOUND', true);
                  commit('PAGE_COUNT', response.data.message);
                  commit('MY_LOADER', false)
                }
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('MY_LOADER', false)
              }

              resolve()
            }
          ).catch((e) => {
            commit('MY_LOADER', false);
            reject(e)
          })
        }
      })
    },

    // *** *** *** ***
    loadShippingTallySheetsOnCreated({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        TALLY_API.getTalliedDocumentsPageable(getters.getStuffingType, getters.getPageSize, getters.getPageNo).then(response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);

              }

            } else {


            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ", Request Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);

        });

      });
    },

    loadShippingTallySheets({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true);
        commit('REQUEST_LIST', []);
        TALLY_API.getTalliedDocumentsPageable(getters.getStuffingType, getters.getPageSize, getters.getPageNo).then(response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('MY_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('MY_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);

              }

            } else {


            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ", Request Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e);

        });

      });
    },

    loadShippingTallySheetsForStuffingType({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true);
        commit('REQUEST_LIST', []);
        TALLY_API.getStuffingDestuffingDocumentsPageable(getters.getStuffingType, getters.getPageSize, getters.getPageNo).then(response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('MY_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('MY_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);

              }

            } else {


            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ", Request Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e);

        });

      });
    },


    loadAllShippingTallyingSheets({commit}) {
      return new Promise((resolve) => {
        commit('NO_DATA_FOUND', false);
        commit('RELOAD_ALL_APPLICATIONS', false);
        commit('HAS_SEARCH_VARIABLE', false);
        commit('SEARCH_PARAMETER', "");
        commit('QUERY_DATE', "");

        resolve();
      })


    },
    getShippingTallySheetsDetails({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        TALLY_API.getTallySheetDetails(getters.getRequestId).then(
          response => {

            if (response.data.status) {
              if (response.data.data) {
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ", Request Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);

        });

      });
    },

    getStuffingOrStrippingDetails({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        TALLY_API.getTalliedStuffingOrStrippingSingle(getters.getRequestId).then(
          response => {

            if (response.data.status) {
              commit('REQUEST_LIST', response.data.data);
              commit('FIRST_LOADER', false);
              commit('NO_DATA_FOUND', false)
            } else {
              commit('NO_DATA_FOUND', true);
              commit('FIRST_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

    getTalliedContainersAllPageable({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST_2', []);

        TALLY_API.getTalliedContainersAllPageableByType(getters.getRequestId, getters.getStuffingType, getters.getPageNo, getters.getPageSize).then(
          response => {

            if (response.data.status) {
              commit('REQUEST_LIST_2', response.data.data);
              commit('FIRST_LOADER', false);
              commit('NO_DATA_FOUND', false)
            } else {
              commit('NO_DATA_FOUND', true);
              commit('FIRST_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

    // *** Tallied Bl Controller
    loadAllShippingTallyBlnDetailsOnCreated({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        TALLY_API.getTalliedBlAllByVoyage(getters.getVoyageId).then(
          response => {

            if (response.data.status) {

              if (response.data.data.length > 0) {

                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve();
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ", Request Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);

        });

      });
    },

    loadShippingTallyBlnDetailsByVoyage({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        TALLY_API.getTalliedBlAllByVoyage(getters.getVoyageId,).then(
          response => {

            if (response.data.status) {

              if (response.data.data.length > 0) {

                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve();
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ", Request Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);

        });

      });


    },

    loadShippingTallyBlnDetailsInDayByVoyage({commit, getters}) {
      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        TALLY_API.getTalliedBlInDayByVoyage(getters.getVoyageId, getters.getQueryDate).then(
          response => {

            if (response.data.status) {

              if (response.data.data.length > 0) {

                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve();
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ", Request Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);

        });

      });


    },

    // *** Tallied Container Controller
    loadShippingTallyContainerAllByVoyage({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        TALLY_API.getTalliedContainersAllByVoyage(getters.getVoyageId,).then(
          response => {

            if (response.data.status) {

              if (response.data.data.length > 0) {

                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve();
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ", Request Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        })
      })

    },


    loadShippingTallyContainerInDayDetails({commit, getters}) {

      return new Promise((resolve, reject) => {

        if (getters.getQueryDate === '') {

        } else {

          commit('FIRST_LOADER', true);
          commit('REQUEST_LIST', []);

          TALLY_API.getTalliedContainersInDayByVoyage(getters.getVoyageId, getters.getQueryDate).then(
            response => {

              if (response.data.status) {

                if (response.data.data.length > 0) {

                  commit('REQUEST_LIST', response.data.data);
                  commit('PAGE_COUNT', response.data.message);
                  commit('FIRST_LOADER', false);
                  commit('NO_DATA_FOUND', false);

                } else {

                  commit('NO_DATA_FOUND', true);
                  commit('PAGE_COUNT', response.data.message);
                  commit('FIRST_LOADER', false);

                }

              } else {

              }
              commit('RELOAD_ALL_APPLICATIONS', true);

              resolve();
            }
          ).catch(e => {

            commit('ERROR_MESSAGE', ", Request Not loaded, please contact system administrator");
            commit('SUCCESS_MESSAGE', "");
            commit('SUCCESS_ALERT', false);
            commit('ERROR_ALERT', true);
            commit('FIRST_LOADER', false);

            reject(e);

          });
        }
      })
    },


    loadShippingTallyContainerInShiftInDayDetails({commit, getters}) {
      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        TALLY_API.getTalliedContainersInShiftPageable(getters.getVoyageId, getters.getQueryDate, getters.getShiftId).then(
          response => {

            if (response.data.status) {

              if (response.data.data.length > 0) {

                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);

              }
            } else {

            }
            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ", Request Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);
          reject(e);
        });
      });
    },


    // *** Tallied Good Details Controller
    loadShippingTallyGoodsInDayDetails({commit, getters}) {
      return new Promise((resolve, reject) => {
        if (getters.getQueryDate === '') {
        } else {
          commit('FIRST_LOADER', true);
          commit('REQUEST_LIST', []);
          TALLY_API.getTalliedGoodsInDayByVoyage(getters.getVoyageId, getters.getQueryDate).then(
            response => {
              if (response.data.status) {
                if (response.data.data.length > 0) {
                  commit('REQUEST_LIST', response.data.data);
                  commit('PAGE_COUNT', response.data.message);
                  commit('FIRST_LOADER', false);
                  commit('NO_DATA_FOUND', false);
                } else {
                  commit('NO_DATA_FOUND', true);
                  commit('PAGE_COUNT', response.data.message);
                  commit('FIRST_LOADER', false);
                }
              } else {

              }
              resolve();
            }
          ).catch(e => {
            commit('ERROR_MESSAGE', ", Request Not loaded, please contact system administrator");
            commit('SUCCESS_MESSAGE', "");
            commit('SUCCESS_ALERT', false);
            commit('ERROR_ALERT', true);
            commit('FIRST_LOADER', false);
            reject(e);
          });
        }
      })
    },

    loadShippingTallyGoodsInShiftInDayDetails({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        TALLY_API.getTalliedGoodsInShiftPageable(getters.getVoyageId, getters.getQueryDate, getters.getShiftId).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('FIRST_LOADER', false);
              }
            } else {

            }
            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ", Request Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);
          reject(e);
        });
      });
    },

    /* Mark as completed tallying */
    markAsCompletedTally({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        TALLY_API.markCompletedTallying(getters.getTalliedDocumentId).then(
          response => {
            if (response.data.status) {
              commit('REQUEST_LIST', response.data.data);
              commit('FIRST_LOADER', false);
              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false);
            } else {
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('FIRST_LOADER', false);
            }
            resolve();
          }
        ).catch(e => {
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);
          reject(e);
        });
      });
    },

    markAsCompletedStuffingDestuffingTally({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        TALLY_API.markCompletedStuffingDestuffingTallying(getters.getTalliedDocumentId).then(response => {
            if (response.data.status) {
              commit('REQUEST_LIST', response.data.data);
              commit('FIRST_LOADER', false);
              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false);

            } else {
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('FIRST_LOADER', false);
            }
            resolve();
          }
        ).catch(e => {
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);
          reject(e);
        });
      });
    }
  },

  getters: {

    getVoyageId: state => state.voyageId,

    getQueryDate: state => state.queryDate,

    getShiftId: state => state.shiftId,

    getRequestList: state => state.requests,

    getRequestList2: state => state.requests2,

    getPageCount: state => state.pageCount,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getRequestId: state => state.requestId,

    getOperationType: state => state.operationType,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyLoader: state => state.myLoader,

    getReloadAllRequest: state => state.reloadAllRequest,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParameter: state => state.searchParameter,

    getSortBy: state => state.sortBy,

    getStuffingType: state => state.stuffingType,

    getTotalElement: state => state.totalElements,

    getTotalPage: state => state.totalPages,

    getPageNumber: state => state.pageNumber,

    getIsFirst: state => state.isFirst,

    getTalliedDocumentId: state => state.talliedDocumentId,

    getIsLast: state => state.isLast
  }
};
