<template>
  <li
    class="dropdown nav-item text-right"
    :is="tag"
    :class="{show: isOpen}"
    aria-haspopup="true"
    :aria-expanded="isOpen"
    @click="toggleDropDown"
    v-click-outside="closeDropDown"
  >
    <a
      class="nav-link dropdown-toggle"
      data-toggle="dropdown"
    >
      <slot name="title">
        <i :class="icon" />
        <span
          class="no-icon"
          style="color: #4990d9 !important;font-weight: bold"
        >{{ title }}</span>
        <b class="caret" />
      </slot>
    </a>
    <div
      class="show my-dropdown-menu"
      v-show="isOpen"
    >
      <slot />
    </div>
  </li>
</template>
<script>
export default {
  name: 'BaseDropdown',
  props: {
    title: String,
    icon: String,
    tag: {
      type: String,
      default: 'li'
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleDropDown () {
      this.isOpen = !this.isOpen
      this.$emit('change', this.isOpen)
    },
    closeDropDown () {
      this.isOpen = false
      this.$emit('change', this.isOpen)
    }
  }
}
</script>
<style scoped>
  .dropdown .dropdown-toggle{
    cursor: pointer;
  }

  .my-dropdown-menu {
    position: absolute;
    right:0;
    z-index: 999999;
    -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    max-height: 850px;
    overflow-y: scroll;
  }
</style>
