import API from '../../../api/index'
import API_CFA from "../../../api/cfa";

export default {

  namespaced: true,

  state: {

    requestId: '',
    requests: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    requestFlag: '',
    pageNo: 0,
    status: '',
    pageSize: 15,
    totalElements: "",
    totalPages: "",
    pageNumber: "",
    isFirst: false,
    isLast: false,
    myLoader: false,
    reloadAllRequest: false,
    hasSearchedVariable: false,
    searchParameter: '',
    sortBy: '',
    blNumber: '',
    customerName: '',
    params: '',


  },

  mutations: {
    SEARCH_PARAMS: (state, params) => state.params = params,

    TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

    TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

    PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

    IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

    IS_LAST: (state, isLast) => state.isLast = isLast,
    
    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    BL_NUMBER: (state, blNumber) => state.blNumber = blNumber,

    CUSTOMER_NAME: (state, customerName) => state.customerName = customerName,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    REQUEST_FLAG: (state, requestFlag) => state.requestFlag = requestFlag,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    REQUEST_STATUS: (state, status) => state.status = status,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    RELOAD_ALL_APPLICATIONS: (state, reloadAllRequest) => state.reloadAllRequest = reloadAllRequest,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy

  },

  actions: {
    hasSearchngParams: ({state, commit}, payload) => commit('HAS_SEARCH_VARIABLE', payload),

    requestFlagging: ({ state, commit }, payload) => commit('REQUEST_FLAG', payload),

    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    requestStatusing: ({ state, commit }, payload) => commit('REQUEST_STATUS', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    paramsSearching: ({ state, commit }, payload) => commit('SEARCH_PARAMS', payload),


    searchingParameter: ({ state, commit }, payload) => commit('SEARCH_PARAMETER', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    blNumbering: ({ state, commit }, payload) => commit('BL_NUMBER', payload),

    customerNaming: ({ state, commit }, payload) => commit('CUSTOMER_NAME', payload),


    // searchWithGeneralChargeParams({commit , getters}) {

    //   return new Promise((resolve, reject) => {

    //     commit('FIRST_LOADER', true);
    //     commit('REQUEST_LIST', []);
    //     commit('HAS_SEARCH_VARIABLE', true);

    //     API.requestFilter(getters.getSearchParams).then(
    //       response => {

    //         if (response.data.status) {

    //           if (response.data.data.content.length > 0) {

    //             commit('REQUEST_LIST', response.data.data.content);
    //             commit('FIRST_LOADER', false);
    //             commit('TOTAL_ELEMENTS', response.data.data.totalElements);
    //             commit('TOTAL_PAGES', response.data.data.totalPages);
    //             commit('PAGE_NUMBER', response.data.data.number);
    //             commit('IS_FIRST', response.data.data.first);
    //             commit('IS_LAST', response.data.data.last);
    //             commit('NO_DATA_FOUND', false);

    //           } else {

    //             commit('NO_DATA_FOUND', true);
    //             commit('REQUEST_LIST', response.data.data.content);
    //             commit('TOTAL_ELEMENTS', response.data.data.totalElements);
    //             commit('TOTAL_PAGES', response.data.data.totalPages);
    //             commit('PAGE_NUMBER', response.data.data.number);
    //             commit('IS_FIRST', response.data.data.first);
    //             commit('IS_LAST', response.data.data.last);
    //             commit('FIRST_LOADER', false);

    //           }

    //         } else {

    //         }

    //         resolve()
    //       }
    //     ).catch(e => {

    //       commit('ERROR_MESSAGE', ', Requests Not found, please contact system administrator');
    //       commit('SUCCESS_MESSAGE', '');
    //       commit('SUCCESS_ALERT', false);
    //       commit('ERROR_ALERT', true);
    //       commit('FIRST_LOADER', false);

    //       reject(e)
    //     })
    //   })
    // },

    // loadClearingAndForwardingApplicationsOnCreated ({ commit, getters }) {
    //   return new Promise((resolve, reject) => {

    //     commit('FIRST_LOADER', true);
    //     commit('REQUEST_LIST', []);

    //     API.requestFilter(getters.getSearchParams).then(
    //       response => {

    //         if (response.data.status) {

    //           if (response.data.data.content.length > 0) {

    //             commit('REQUEST_LIST', response.data.data.content);
    //             commit('FIRST_LOADER', false);
    //             commit('TOTAL_ELEMENTS', response.data.data.totalElements);
    //             commit('TOTAL_PAGES', response.data.data.totalPages);
    //             commit('PAGE_NUMBER', response.data.data.number);
    //             commit('IS_FIRST', response.data.data.first);
    //             commit('IS_LAST', response.data.data.last);
    //             commit('NO_DATA_FOUND', false);

    //           } else {

    //             commit('NO_DATA_FOUND', true);
    //             commit('REQUEST_LIST', response.data.data.content);
    //             commit('TOTAL_ELEMENTS', response.data.data.totalElements);
    //             commit('TOTAL_PAGES', response.data.data.totalPages);
    //             commit('PAGE_NUMBER', response.data.data.number);
    //             commit('IS_FIRST', response.data.data.first);
    //             commit('IS_LAST', response.data.data.last);
    //             commit('FIRST_LOADER', false);

    //           }

    //         } else {

    //         }

    //         resolve()
    //       }
    //     ).catch(e => {

    //       commit('FIRST_LOADER', false)

    //       reject(e)
    //     })
    //   })

    // },

    // loadClearingAndForwardingApplications ({ commit, getters }) {
      
    //   return new Promise((resolve, reject) => {

    //     commit('MY_LOADER', true);
    //     commit('REQUEST_LIST', []);

    //     API.requestFilter(getters.getSearchParams).then(
    //       response => {

    //         if (response.data.status) {

    //           if (response.data.data.content.length > 0) {

    //             commit('REQUEST_LIST', response.data.data.content);
    //             commit('MY_LOADER', false);
    //             commit('TOTAL_ELEMENTS', response.data.data.totalElements);
    //             commit('TOTAL_PAGES', response.data.data.totalPages);
    //             commit('PAGE_NUMBER', response.data.data.number);
    //             commit('IS_FIRST', response.data.data.first);
    //             commit('IS_LAST', response.data.data.last);
    //             commit('NO_DATA_FOUND', false);

    //           } else {

    //             commit('NO_DATA_FOUND', true);
    //             commit('REQUEST_LIST', response.data.data.content);
    //             commit('TOTAL_ELEMENTS', response.data.data.totalElements);
    //             commit('TOTAL_PAGES', response.data.data.totalPages);
    //             commit('PAGE_NUMBER', response.data.data.number);
    //             commit('IS_FIRST', response.data.data.first);
    //             commit('IS_LAST', response.data.data.last);
    //             commit('MY_LOADER', false);

    //           }

    //         } else {

    //         }

    //         resolve()
    //       }
    //     ).catch(e => {

    //       commit('MY_LOADER', false)

    //       reject(e)
    //     })
    //   })
    // },

    searchWithGeneralChargeParams({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);
        commit('HAS_SEARCH_VARIABLE', true);

        API_CFA.filterCfaApplicationsWithMultipleParameters(getters.getSearchParams, getters.getPageSize, getters.getPageNo).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not found, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })

      })

    },

    loadClearingAndForwardingApplicationsOnCreated ({ commit, getters }) {
      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        API_CFA.filterCfaApplicationsWithMultipleParameters(getters.getSearchParams, getters.getPageSize, getters.getPageNo).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('FIRST_LOADER', false)

          reject(e)
        })
      })

    },

    loadClearingAndForwardingApplications ({ commit, getters }) {
      
      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true);
        commit('REQUEST_LIST', []);

        API_CFA.filterCfaApplicationsWithMultipleParameters(getters.getSearchParams, getters.getPageSize, getters.getPageNo).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('MY_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('MY_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('MY_LOADER', false)

          reject(e)
        })
      })
    },



    markDuplicateApplication ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('MY_LOADER', true)

        API.markDuplicateRequest(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              commit('MY_LOADER', false)
            } else {
              commit('MY_LOADER', false)
            }

            resolve()
          }
        ).catch((e) => {
          commit('MY_LOADER', false)
          reject(e)
        })
      })
    }

  },

  getters: {

    getTotalPage: state => state.totalPages,

    getTotalElement: state => state.totalElements,

    getSearchParams: state => state.params,

    getRequestList: state => state.requests,

    getPageCount: state => state.pageCount,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getRequestId: state => state.requestId,

    getRequestFlag: state => state.requestFlag,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getRequestStatus: state => state.status,

    getMyLoader: state => state.myLoader,

    getReloadAllRequest: state => state.reloadAllRequest,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParameter: state => state.searchParameter,

    getSortBy: state => state.sortBy,

    getBlNumber: state => state.blNumber,

    getCustomerName: state => state.customerName,

    getPageNumber: state => state.pageNumber,

    getIsFirst: state => state.isFirst,

    getIsLast: state => state.isLast,

  }

}
