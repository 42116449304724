const baseUrl = '/configurations/'

const routes = [

  {
    path: '/',
    component: () => import(/* webpackChunkName: "DashboardLayout" */
      '../layout/DashboardLayout.vue'),
    meta: {
      requiresAuth: true,
      permission: 'DASHBOARD_VIEW'
    },
    children: [
      {
        path: '/overview',
        name: 'Overview',
        component: () => import(/* webpackChunkName: "managment" */
          '../pages/dashboard/management.vue'),
        // alias: '/',
        meta: {
          requiresAuth: true,
          permission: 'DASHBOARD_VIEW'
        }
      },
      {
        path: '/user-profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "Profile" */
          '../pages/user/Profile.vue'),
        meta: {
          requiresAuth: true,
          permission: 'PERMISSION_PROFILE'
        }
      },
      /* Tariff */
      {
        path: baseUrl + 'request-type',
        name: 'Tariffs',
        component: () => import(/* webpackChunkName: "Tariffs" */
          '../pages/revenue/tasacTariff/Tariffs'),
        meta: {
          requiresAuth: true,
          permission: 'REQUEST_CONFIG_POST'
        }
      },
      {
        path: baseUrl + 'tariff/fees/:id',
        name: 'tariffFess',
        component: () => import(/* webpackChunkName: "TasacTarifFees" */
          '../pages/revenue/tasacTariff/TariffFees'),
        meta: {
          requiresAuth: true,
          permission: 'TARIFF-FEES_LIST_{TARIFFID}_GET'
        }
      },
      {
        path: baseUrl + 'tariff/item',
        name: 'tariffItem',
        component: () => import(/* webpackChunkName: "TasacTarifItem" */
          '../pages/revenue/tasacTariff/TariffItem'),
        meta: {
          requiresAuth: true,
          permission: 'TARIFF-ITEMS_CREATE_POST'
        }
      },
      {
        path: baseUrl + 'tariff/rule/:tariffFeeId',
        name: 'tariffRule',
        component: () => import(/* webpackChunkName: "TasacRule" */
          '../pages/revenue/tasacTariff/TasacRule'),
        meta: {
          requiresAuth: true,
          permission: 'TARIFF-RULES_LIST-BY-FEE_{TARIFFFEEID}_GET'
        }
      },
      {
        path: baseUrl + 'tariff/rate2/:id',
        name: 'tariffRate',
        component: () => import(/* webpackChunkName: "TasacRate" */
          '../pages/revenue/tasacTariff/TasacRate'),
        meta: {
          requiresAuth: true,
          permission: 'TARIFF_RULES_VIEW_BY_FEE'
        }
      },

      /* configuration */

      /* Document Type IPO */
      {
        path: baseUrl + 'document-type',
        name: 'DocumentTypeList',
        component: () => import(/* webpackChunkName: "DocumentTypeList" */
          '../pages/configuration/DocumentType/DocumentTypeList'),
        meta: {
          requiresAuth: true,
          permission: 'DOCTYPE_CONFIG_POST'
        }
      },

      {
        path: baseUrl + 'common-code',
        name: 'CommonCode',
        component: () => import(/* webpackChunkName: "CommonCode" */
          '../pages/configuration/CommonCode/CommonCode'),
        meta: {
          requiresAuth: true,
          permission: 'COMMON-CODE_PULL_POST'
        }
      },

      {
        path: baseUrl + 'clearing-forwarding-agents',
        name: 'CFAgent',
        component: () => import(/* webpackChunkName: "CFAgent" */
          '../pages/configuration/CFAgent/CFAgent'),
        meta: {
          requiresAuth: true,
          permission: 'CF-AGENT_ALL_GET'
        }
      },

      {
        path: baseUrl + 'clearing-forwarding-agents/users/:cfAgentId',
        name: 'CFAgentUsers',
        component: () => import(/* webpackChunkName: "CFAgent" */
          '../pages/configuration/CFAgent/CFAgentUsers'),
        meta: {
          requiresAuth: true,
          permission: 'CF-AGENT_ALL_GET'
        }
      },


      {
        path: baseUrl + 'charges',
        name: 'charges',
        component: () => import(/* webpackChunkName: "Charges" */
          '../pages/configuration/Charges/Charges'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_ASSESSED-REQUESTS_GET'
        }
      },

      /* Transport Method IPO */
      {
        path: baseUrl + 'transport-method',
        name: 'TransportMethod',
        component: () => import(/* webpackChunkName: "TransportMethod" */
          '../pages/configuration/TransportMethod/TransportMethod'),
        meta: {
          requiresAuth: true,
          permission: 'TRANSPORT-METHOD_ADD_POST'
        }
      },

      /* Cargo Delivery Station IPO */
      {
        path: baseUrl + 'station',
        name: 'StationList',
        component: () => import(/* webpackChunkName: "StationList" */
          '../pages/configuration/Station/Station'),
        meta: {
          requiresAuth: true,
          permission: 'STATIONS_ADD_POST'
        }
      },
      {
        path: baseUrl + 'sa/shipping-fee',
        name: 'shippingFee',
        component: () => import(/* webpackChunkName: "ActivityList" */
          '../pages/configuration/SA/shippingFeeConfigurations'),
        meta: {
          requiresAuth: true,
          permission: 'VESSEL_ATTACHMENTS_ADD_POST'
        }
      },

      {
        path: baseUrl + 'sa/attachment',
        name: 'saAttachment',
        component: () => import(/* webpackChunkName: "ActivityList" */
          '../pages/configuration/SA/attachmentTypes'),
        meta: {
          requiresAuth: true,
          permission: 'VESSEL_ATTACHMENTS_ADD_POST'
        }
      },
      {
        path: baseUrl + 'do/attachment',
        name: 'doAttachment',
        component: () => import(/* webpackChunkName: "ActivityList" */
          '../pages/configuration/SA/deliveryOrderAttachmentType'),
        meta: {
          requiresAuth: true,
          permission: 'DELIVERY-ORDER-ATTACHMENT-TYPE_ADD_POST'
        }
      },

      /* Activity IPO */
      {
        path: baseUrl + 'activity',
        name: 'ActivityList',
        component: () => import(/* webpackChunkName: "ActivityList" */
          '../pages/configuration/Activity/Activity'),
        meta: {
          requiresAuth: true,
          permission: 'ACTIVITY_CONFIG_POST'
        }
      },

      /* Request Type IPO */
      {
        path: baseUrl + 'request-type',
        name: 'RequestTypeList',
        component: () => import(/* webpackChunkName: "RequestTypeList" */
          '../pages/configuration/RequestType/RequestTypeList'),
        meta: {
          requiresAuth: true,
          permission: 'REQUEST_CONFIG_POST'
        }
      },

      /* Specified Goods IPO */
      {
        path: baseUrl + 'specified-goods',
        name: 'SpecifiedGoodList',
        component: () => import(/* webpackChunkName: "SpecifiedGoodList" */
          '../pages/configuration/SpecifiedGoods/SpecifiedGoods'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_SPECIFIEDGOODS_POST'
        }
      },

      /* Vessel Type IPO */
      {
        path: baseUrl + 'vessel-type',
        name: 'VesselTypeList',
        component: () => import(/* webpackChunkName: "VesselTypeList" */
          '../pages/configuration/VesselType/VesselTypeList'),
        meta: {
          requiresAuth: true,
          permission: 'VESSELTYPE_ALL-PAGEABLE_GET'
        }
      },

      /* Sector IPO */
      {
        path: baseUrl + 'sector',
        name: 'SectorList',
        component: () => import(/* webpackChunkName: "SectorList" */
          '../pages/configuration/Sector/SectorList'),
        meta: {
          requiresAuth: true,
          permission: 'SECTOR_CONFIG_POST'
        }
      },

      /* Country IPO */
      {
        path: baseUrl + 'country',
        name: 'CountryList',
        component: () => import(/* webpackChunkName: "CountryList" */
          '../pages/configuration/Country/CountryList'),
        meta: {
          requiresAuth: true,
          permission: 'COUNTRY_CONFIG_POST'
        }
      },

      /* Regime IPO */
      {
        path: baseUrl + 'regime',
        name: 'RegimeList',
        component: () => import(/* webpackChunkName: "RegimeList" */
          '../pages/configuration/Regime/Regime'),
        meta: {
          requiresAuth: true,
          permission: 'REGIMES_CONFIG_POST'
        }
      },

      /* City IPO */
      {
        path: baseUrl + 'city',
        name: 'CityList',
        component: () => import(/* webpackChunkName: "CityList" */
          '../pages/configuration/City/CityList'),
        meta: {
          requiresAuth: true,
          permission: 'CITY_CONFIG_POST'
        }
      },

      /* Status IPO */
      {
        path: baseUrl + 'status',
        name: 'StatusList',
        component: () => import(/* webpackChunkName: "StatusList" */
          '../pages/configuration/Status/Status'),
        meta: {
          requiresAuth: true,
          permission: 'STATUS_CONFIG_POST'
        }
      },

      /* HS-Code IPO */
      {
        path: baseUrl + 'hs-code',
        name: 'HsCodeList',
        component: () => import(/* webpackChunkName: "HsCodeList" */
          '../pages/configuration/HsCode/HsCodeList'),
        meta: {
          requiresAuth: true,
          permission: 'HSCODE_CONFIG_POST'
        }
      },

      /* Company Type IPO */
      {
        path: baseUrl + 'company-type',
        name: 'CompanyTypeList',
        component: () => import(/* webpackChunkName: "CompanyTypeList" */
          '../pages/configuration/CompanyType/CompanyTypeList'),
        meta: {
          requiresAuth: true,
          permission: 'COMPANYTYPE_CONFIG_POST'
        }
      },

      /* Company IPO */
      {
        path: baseUrl + 'company',
        name: 'CompanyList',
        component: () => import(/* webpackChunkName: "CompanyList" */
          '../pages/configuration/Company/CompanyList'),
        meta: {
          requiresAuth: true,
          permission: 'COMPANY_CONFIG_POST'
        }
      },

      /* Exchange Rate IPO */
      {
        path: baseUrl + 'exchange-rate',
        name: 'ExchangeRateList',
        component: () => import(/* webpackChunkName: "ExchangeRateList" */
          '../pages/configuration/ExchangeRate/ExchangeRateList'),
        meta: {
          requiresAuth: true,
          permission: 'EXCHANGE_CONFIG_POST'
        }
      },

      /* Department IPO */
      {
        path: baseUrl + 'department',
        name: 'DepartmentList',
        component: () => import(/* webpackChunkName: "DepartmentList" */
          '../pages/configuration/Department/DepartmentList'),
        meta: {
          requiresAuth: true,
          permission: 'DEPARTMENT_CONFIG_POST'
        }
      },
      /* Disbursement  IPO */
      {
        path: baseUrl + 'disbursement',
        name: 'Disbursement',
        component: () => import(/* webpackChunkName: "Disbursement" */
          '../pages/configuration/Disbursement/Disbursement'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },
      /* Disbursement Items IPO */
      {
        path: baseUrl + 'disbursement-service-category-and-items/:categoryId',
        name: 'DisbursementItems',
        component: () => import(/* webpackChunkName: "DisbursementItems" */
          '../pages/configuration/Disbursement/DisbursementItems'),
        meta: {
          requiresAuth: true
          // permission: 'DISBURSEMENT_CONFIG_POST'
        }
      },

      /* Package Types */
      {
        path: baseUrl + 'packagetype',
        name: 'PackageType',
        component: () => import(/* webpackChunkName: "PackageType" */
          '../pages/configuration/Package/PackageType'),
        meta: {
          requiresAuth: true,
          permission: 'PACKAGE-TYPE_ADD_POST'
        }
      },

      /* Vessel Schedule */
      {
        path: baseUrl + 'vessel-schedule',
        name: 'VesselSchedule',
        component: () => import(/* webpackChunkName: "VesselSchedule" */
          '../pages/configuration/VesselSchedule/VesselSchedule'),
        meta: {
          requiresAuth: true,
          permission: 'VESSELSCHEDULE_CONFIG_POST'
        }
      },

      /* Attachment Type */
      {
        path: baseUrl + 'shipping-fee',
        name: 'ShippingFee',
        component: () => import(/* webpackChunkName: "ShippingFee" */
          '../pages/configuration/ShippingFee/shippingFee'),
        meta: {
          requiresAuth: true,
          permission: 'ATTACHMENTS_CONFIG_POST'
        }
      },

      /* Attachment Type */
      {
        path: baseUrl + 'attachment-type',
        name: 'AttachmentType',
        component: () => import(/* webpackChunkName: "AttachmentType" */
          '../pages/configuration/AttachmentType/AttachmentType'),
        meta: {
          requiresAuth: true,
          permission: 'ATTACHMENTS_CONFIG_POST'
        }
      },

      /* Tally Event Codes */
      {
        path: baseUrl + 'tally-events',
        name: 'TallyEvents',
        component: () => import(/* webpackChunkName: "TallyEvents" */
          '../pages/configuration/tally/TallyEventCodes'),
        meta: {
          requiresAuth: true,
          // permission: 'ATTACHMENTS_CONFIG_POST'
        }
      },

      /* Designation */
      {
        path: baseUrl + 'designation',
        name: 'DesignationList',
        component: () => import(/* webpackChunkName: "DesignationList" */
          '../pages/configuration/Designation/DesignationList'),
        meta: {
          requiresAuth: true,
          permission: 'DESIGNATION_CONFIG_POST'
        }
      },

      /* Port */
      {
        path: baseUrl + 'port',
        name: 'PortList',
        component: () => import(/* webpackChunkName: "PortList" */
          '../pages/configuration/Port/PortList'),
        meta: {
          requiresAuth: true,
          permission: 'PORT_CONFIG_POST'
        }
      },

      /* Port */
      {
        path: baseUrl + 'agency-charges',
        name: 'agencyCharges',
        component: () => import(/* webpackChunkName: "agencyCharges" */
          '../pages/configuration/agencyCharges/agencyChargesList'),
        meta: {
          requiresAuth: true,
          permission: 'PORT_CONFIG_POST'
        }
      },

      {
        path: '/denied',
        name: 'AccessDenied',
        component: () => import(/* webpackChunkName: "AccessDenied" */
          '../pages/AccessDenied'),
        meta: {
          requiresAuth: true
        }
      },

      /* Reports */
      {
        path: baseUrl + 'dcb-report',
        name: 'CfaDsb',
        component: () => import(/* webpackChunkName: "CfaDsb" */
          '../pages/report/CfaDcb'),
        meta: {
          requiresAuth: true,
          permission: 'CFAREPORT_GET-CFA-REPORT_GET'
        }
      },
      {
        path: '/clearing-and-forwarding/applications-report',
        name: 'cfaNwReprt',
        component: () => import(/* webpackChunkName: "cfaNwReprt" */
          '../pages/clearingAndForwarding/report/report'),
        meta: {
          requiresAuth: true,
          permission: 'CFAREPORT_ASYNC_REPORT_GET'
        }
      },
      {
        path: '/clearing-and-forwarding/activity-assignments-report',
        name: 'ActivityAssignmentReport',
        component: () => import(/* webpackChunkName: "ActivityAssignmentReport" */
          '../pages/report/cfa/activityAssignments'),
        meta: {
          requiresAuth: true,
          permission: 'CFAREPORT_ASYNC_REPORT_GET'
        }
      },
      {
        path: '/shipping-agency/vessel-performance/report',
        name: 'vPReport',
        component: () => import(/* webpackChunkName: "CfaDsb" */
          '../pages/shippingAgency/report/vesselPerformanceReport'),
        meta: {
          requiresAuth: true,
          permission: 'REPORTS_ASYNC_REPORT_GET'
        }
      },

      {
        path: baseUrl + 'request-report',
        name: 'requestReport',
        component: () => import(/* webpackChunkName: "RequestReport" */
          '../pages/report/RequestReport'),
        meta: {
          requiresAuth: true,
          permission: 'REPORTS_BILL-REPORT-PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + 'clearing-and-forwaring-bills-status-summary-report',
        name: 'AllBillStatusReport',
        component: () => import(/* webpackChunkName: "AllBillStatusReport" */
          '../pages/report/revenue/AllBillStatusReport'),
        meta: {
          requiresAuth: true,
          permission: 'REPORTS_BILL-REPORT-PAGEABLE_GET'
        }
      },
      {
        path: baseUrl + 'applications-status',
        name: 'ApplicationStatus',
        component: () => import(/* webpackChunkName: "ApplicationStatus" */
          '../pages/report/ApplicationStatus'),
        meta: {
          requiresAuth: true,
          permission: 'ACTIVITYLOG_ACTIVITY-LOGING_GET'
        }
      },
      {
        path: '/document-control/manifest-status',
        name: 'ManifestStatus',
        component: () => import(/* webpackChunkName: "ManifestStatus" */
          '../pages/report/ManifestStatus'),
        meta: {
          requiresAuth: true,
          permission: 'DOCUMENTS_DOCUMENTS-LODGED-LOGS_GET'
        }
      },
      {
        path: '/revenue/report/revenue-and-tax-report',
        name: 'TaxAndRevenue',
        component: () => import(/* webpackChunkName: "TaxAndRevenue" */
          '../pages/report/revenue/TaxAndRevenue'),
        meta: {
          requiresAuth: true,
          permission: 'REPORTS_BILL-REPORT_GET'
        }
      },

      {
        path: baseUrl + 'vehicle/properties',
        name: 'vehicleProperties',
        component: () => import(/* webpackChunkName: "VehicleProperties" */
          '../pages/configuration/tally/VehicleProperties'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-VEHICLE-PROPERTIES_POST'
        }
      },
      {
        path: baseUrl + 'vehicle/view/properties/',
        name: 'vehiclePropertiesView',
        component: () => import(/* webpackChunkName: "VehiclePropertiesView" */
          '../pages/configuration/tally/VehiclePropertiesView'),
        meta: {
          requiresAuth: true,
          permission: 'API_TALLY-VEHICLE-PROPERTIES_GET'
        }
      },

      {
        path: baseUrl + 'service-providers',
        name: 'ShippingAgencyServiceProvider',
        component: () => import(/* webpackChunkName: "ShippingAgencyServiceProvider" */
          '../pages/configuration/ShippingAgencyServiceProvider/shippingAgencyServiceProvider'),
        meta: {
          requiresAuth: true
          // permission: 'VESSEL_ALL_PAGEABLE_GET'
        }
      },

      /* 404 */
      {
        path: '*',
        component: () => import(/* webpackChunkName: "NotFound" */
          '../pages/NotFoundPage'),
        meta: {
          requiresAuth: true
        }
      }

    ]
  }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes
