import TALLYAPI from '../../../api/tally'

export default {

  namespaced: true,

  state: {

    shifts: []

  },

  mutations: {

    GET_SHIFTS (state, shifts) {
      state.shifts = shifts
    }

  },

  actions: {

    getShifts (context, callback) {
      TALLYAPI.getAllShifts().then((response) => {
        context.commit('GET_SHIFTS', response.data)
      })
    },

    createShift (context, data) {
      TALLYAPI.CreateShift(({ startTime: data.startTime, endTime: data.endTime, name: data.name })).then(response => {
        this.loading = false
        context.dispatch('shifts/getShifts', null, { root: true })
      })
    },
    editShift (context, data) {
      TALLYAPI.editShift(({ startTime: data.startTime, endTime: data.endTime, name: data.name, id: data.id })).then(response => {
        this.loading = false
        context.dispatch('shifts/getShifts', null, { root: true })
      })
    },
    deleteShift (context, shiftId) {
      TALLYAPI.deleteShift(shiftId).then(response => {
        this.loading = false
        context.dispatch('shifts/getShifts', null, { root: true })
      })
    }

  },

  getters: {

    getAllShifts: state => state.shifts

  }

}
