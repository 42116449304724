/* import axios */
import axios from 'axios'
import {DC_SERVER_URL} from "../api/settings";

/* require qs package to be used in serializing token request parameters */
const qs = require('qs')

// Base URL for document-control microservice server

/* axios default headers for the below initiated instances */
axios.defaults.headers['access-control-allow-Origin'] = "https://sbms.tasac.go.tz";
axios.defaults.headers['Access-Control-Allow-Methods'] = 'POST, PUT, GET, OPTIONS, DELETE';
axios.defaults.headers['Access-Control-Allow-Credentials'] = true;
axios.defaults.headers['Cache-Control'] = 'no-cache';
axios.defaults.timeout = 120 * 1000 //  120 seconds
axios.defaults.headers['Set-Cookie'] = 'SameSite=Strict';

/* axios instance for document control microservice server */
// @ts-ignore
const HTTP_DC = axios.create({

  'X-Requested-With': 'XMLHttpRequest',

  baseURL: DC_SERVER_URL,

  headers: {

    'Content-Type': 'application/json'

  }

});

export default {

  handle401 (success, error) {

    return HTTP_DC.interceptors.response.use(success, error)

  },

  authenticate (token) {

    return HTTP_DC.defaults.headers.common.Authorization = 'Bearer ' + token

  },

  getTotalDcApplicationForDashboard () {

    return HTTP_DC.get(DC_SERVER_URL + '/v2/manifest/dashboard')

  },

  isAuthenticated (token) {

    return HTTP_DC.defaults.headers.common.Authorization = 'Bearer ' + token
  },

  listAllPageableManifests(pageNo, pageSize, documentType){

    return HTTP_DC.get(DC_SERVER_URL+ '/v2/manifest/all/pageable/?operationType='+documentType +'&size=' + pageSize + '&page=' + pageNo) //+ '&q=' + queryParam

  },

  getManifestDetailsById (manifestId) {

    return HTTP_DC.get(DC_SERVER_URL + '/v2/manifest/get/' + manifestId)

  },

  downloadManifestExcel (manifestId) {

    return HTTP_DC.get(DC_SERVER_URL + '/v2/manifest/manifestExcel/' + manifestId,
      {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob'
      });

  },

  sentManifestMail (manifestId) {

    return HTTP_DC.get(DC_SERVER_URL + '/v2/manifest/mail/' + manifestId)

  },

  uploadApprovedManifestExcel (approvedManifest) {

    return HTTP_DC.post(DC_SERVER_URL + '/v2/manifest/upload',
      approvedManifest,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })

  },

  getDocument () {

    return HTTP_DC.get(DC_SERVER_URL + '/documents/all')

  },

  getBlDetails(blNumber,voyageNo){

    return HTTP_DC.get(DC_SERVER_URL + '/v2/manifest/bl?blNumber=' + blNumber + "&voyageNumber=" + voyageNo);

  },

  postDocument (data) {

    return HTTP_DC.post(DC_SERVER_URL + '/documents/config', data)

  },

  updateDocument (data) {

    return HTTP_DC.put(DC_SERVER_URL + '/documents/config', data)

  },

  getIndividualDocument (data) {

    return HTTP_DC.get(DC_SERVER_URL + '/documents/config/' + data)

  },

  uploadDocument (data) {

    return HTTP_DC.post(DC_SERVER_URL + '/documents/upload', data)

  },

  getPagableExportDocuments (pageNo, pageSize, sortBy) {

    return HTTP_DC.get(DC_SERVER_URL + '/loadingPermission/all/pageable/?pageNo=' + pageNo + '&pageSize=' + pageSize + '&sortBy=' + sortBy)

  },

  uploadExportDocument (data) {

    return HTTP_DC.post(DC_SERVER_URL + '/loadingPermission/upload', data)

  },

  searchExportDocument (pageNo, pageSize, searchTerm) {

    return HTTP_DC.post(DC_SERVER_URL + '/loadingPermission/search-pageable?pageNo=' + pageNo + '&pageSize=' + pageSize + '&searchTerm=' + searchTerm)

  },

  downloadExportDocument (data) {

    return HTTP_DC.get(DC_SERVER_URL + '/loadingPermission/download/' + data)

  },

  getIndividualLoadingList (data) {

    return HTTP_DC.get(DC_SERVER_URL + '/approved/loading-list/' + data)

  },

  getPagableLoadingList (pageNo, pageSize) {

    return HTTP_DC.get(DC_SERVER_URL + '/approved/loading-list/list/?pageNo=' + pageNo + '&pageSize=' + pageSize)

  },

  getIndividualLoadingListByModel (callId) {

    return HTTP_DC.get(DC_SERVER_URL + '/approved/loading-list/model/' + callId)

  },

  searchDcBill (searchTerm) {

    return HTTP_DC.get(DC_SERVER_URL + `/document-charge/v2/search-document-charge?searchTerm=${searchTerm}`);

  },

  createv2ManifestCharge (documentId, tariffItemId, tariffFeeId,vesselType) {

    return HTTP_DC.post(DC_SERVER_URL + '/document-charge/v2/create-charge/' + documentId + '?tariffItemId=' + tariffItemId + '&tariffFeeId=' + tariffFeeId + '&vesselType=' + vesselType );

  },

  createManifestCharge (documentId, tariffItemId, tariffFeeId) {

    return HTTP_DC.post(DC_SERVER_URL + '/document-control/v2/document-charge/create-charge/' + documentId + '?tariffItemId=' + tariffItemId + '&tariffFeeId=' + tariffFeeId)

  },

  searchGeneralDcCharges(params) {

    return HTTP_DC.get(DC_SERVER_URL + '/document-charge/v2/search' + params)

  },

  getV2DocumentChargeDetails (documentId) {

    return HTTP_DC.get(DC_SERVER_URL + '/document-charge/get/v2/' + documentId);

  },

  geDocumentCharges (size , page) {

      return HTTP_DC.get(DC_SERVER_URL + '/document-charge/v2/get-all-pageable?page=' + page + '&size=' + size);

  },

  generateManifestStatusReport (params) {

    return HTTP_DC.get(DC_SERVER_URL + '/documents/documents-lodged-logs' + params,

      {

        headers: {

          'Content-Type': 'application/octet-stream'

        },

        responseType: 'blob'

      })

  },

  getPageableDocument (pageNo, pageSize, sortBy, typeIsImport) {

    return HTTP_DC.get(DC_SERVER_URL + '/documents/all/pageable/?pageNo=' + pageNo + '&pageSize=' + pageSize + '&sortBy=' + sortBy + '&typeIsImport=' + typeIsImport)

  },

  getPageableDocumentV2 (pageNo, pageSize, operationType) {

    return HTTP_DC.get(DC_SERVER_URL + '/documents/all/pageable/v2/?page=' + pageNo + '&size=' + pageSize + '&sortBy=documentId' + '&typeIsImport=' + operationType)

  },

  searchGeneralEdiDocuments(params) {

    return HTTP_DC.post(DC_SERVER_URL + '/v2/manifest/search' + params)

  },

  getAsyncReports(){
    return HTTP_DC.get(DC_SERVER_URL + '/reports/report')

  },

  generateDCAsyncReport(params){
    return HTTP_DC.get(DC_SERVER_URL + '/reports/async/report?' + params)
  }

}
