import AGENCY_API from '../../../api/agency'

export default {
  namespaced: true,

  state: {

    requestId: '',
    requests: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    reloadAllRequest: false,
    hasSearchedVariable: false,
    sortBy: '',
    voyageId: '',
    queryDate: '',
    vesselDetails: [],
    saveDetails: {},

    SuccessAlertPrint: false,
    ErrorAlertPrint: false,

    totalElements: "",
    totalPages: "",
    pageNumber: "",
    isFirst: false,
    isLast: false,
    params: '',


  },
  mutations: {

    VOYAGE_ID: (state, voyageId) => state.voyageId = voyageId,

    SEARCH_PARAMS: (state, params) => state.params = params,

    QUERY_DATE: (state, queryDate) => state.queryDate = queryDate,

    VESSEL_DETAILS: (state, vesselDetails) => state.vesselDetails = vesselDetails,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

    TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

    PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

    IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

    IS_LAST: (state, isLast) => state.isLast = isLast,

    SAVE_DETAILS: (state, saveDetails) => state.saveDetails = saveDetails,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    ERROR_ALERT_PRINT: (state, ErrorAlertPrint) => state.ErrorAlertPrint = ErrorAlertPrint,

    SUCCESS_ALERT_PRINT: (state, SuccessAlertPrint) => state.SuccessAlertPrint = SuccessAlertPrint,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    RELOAD_ALL_APPLICATIONS: (state, reloadAllRequest) => state.reloadAllRequest = reloadAllRequest,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy

  },
  actions: {

    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    hasSearchngParams: ({state, commit}, payload) => commit('HAS_SEARCH_VARIABLE', payload),

    paramsSearching: ({state, commit}, payload) => commit('SEARCH_PARAMS', payload),

    requestStatusing: ({ state, commit }, payload) => commit('REQUEST_STATUS', payload),

    savingDetails: ({ state, commit }, payload) => commit('SAVE_DETAILS', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    voyageIding: ({ state, commit }, payload) => commit('VOYAGE_ID', payload),

    queryingDate: ({ state, commit }, payload) => commit('QUERY_DATE', payload),

    vesselDetailing: ({ state, commit }, payload) => commit('VESSEL_DETAILS', payload),

    reloadingRequest: ({ state, commit }, payload) => commit('RELOAD_ALL_APPLICATIONS', payload),

    loadVesselParticularsPageableOnCreated ({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.searchVesselParticulars(getters.getSearchParams).then(
          response => {
            
            if (response.data.status) {

              if (response.data.data.content.length > 0) {
                console.log(response.data);

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('NO_DATA_FOUND', true);
          commit('FIRST_LOADER', false)

          reject(e)
        })

      })

    },

    loadVesselParticularsPageableAfterCreated ({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.searchVesselParticulars(getters.getSearchParams).then(
          response => {
            
            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('MY_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('MY_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('NO_DATA_FOUND', true);
          commit('MY_LOADER', false)

          reject(e)
        })
      })

    },

    loadSearchedVesselParticulars ({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])
        commit('HAS_SEARCH_VARIABLE', true)

        AGENCY_API.searchVesselParticulars(getters.getSearchParams).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('NO_DATA_FOUND', true);
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })

    },

    // loadVesselParticularsPageableOnCreated ({ commit, getters }) {
    //   return new Promise((resolve, reject) => {
    //     commit('FIRST_LOADER', true)
    //     commit('REQUEST_LIST', [])
    //     AGENCY_API.listOrSearchAllVessels(getters.getPageNo, getters.getPageSize, getters.getQueryDate).then(
    //       response => {
    //         if (response.data.status) {

    //           if (response.data.data.content.length > 0) {

    //             commit('REQUEST_LIST', response.data.data.content);
    //             commit('FIRST_LOADER', false);
    //             commit('TOTAL_ELEMENTS', response.data.data.totalElements);
    //             commit('TOTAL_PAGES', response.data.data.totalPages);
    //             commit('PAGE_NUMBER', response.data.data.number);
    //             commit('IS_FIRST', response.data.data.first);
    //             commit('IS_LAST', response.data.data.last);
    //             commit('NO_DATA_FOUND', false);

    //           } else {

    //             commit('NO_DATA_FOUND', true);
    //             commit('TOTAL_ELEMENTS', response.data.data.totalElements);
    //             commit('TOTAL_PAGES', response.data.data.totalPages);
    //             commit('PAGE_NUMBER', response.data.data.number);
    //             commit('IS_FIRST', response.data.data.first);
    //             commit('IS_LAST', response.data.data.last);
    //             commit('FIRST_LOADER', false);

    //           }

    //         } else {

    //         }

    //         resolve()
    //       }
    //     ).catch(e => {

    //       commit('NO_DATA_FOUND', true);
    //       commit('FIRST_LOADER', false)

    //       reject(e)
    //     })
    //   })
    // },

    // loadVesselParticularsPageableAfterCreated ({ commit, getters }) {
    //   return new Promise((resolve, reject) => {
    //     commit('MY_LOADER', true)
    //     commit('REQUEST_LIST', [])

    //     AGENCY_API.listOrSearchAllVessels(getters.getPageNo, getters.getPageSize, getters.getQueryDate).then(
    //       response => {
    //         if (response.data.status) {

    //           if (response.data.data.content.length > 0) {

    //             commit('REQUEST_LIST', response.data.data.content);
    //             commit('MY_LOADER', false);
    //             commit('TOTAL_ELEMENTS', response.data.data.totalElements);
    //             commit('TOTAL_PAGES', response.data.data.totalPages);
    //             commit('PAGE_NUMBER', response.data.data.number);
    //             commit('IS_FIRST', response.data.data.first);
    //             commit('IS_LAST', response.data.data.last);
    //             commit('NO_DATA_FOUND', false);

    //           } else {

    //             commit('NO_DATA_FOUND', true);
    //             commit('TOTAL_ELEMENTS', response.data.data.totalElements);
    //             commit('TOTAL_PAGES', response.data.data.totalPages);
    //             commit('PAGE_NUMBER', response.data.data.number);
    //             commit('IS_FIRST', response.data.data.first);
    //             commit('IS_LAST', response.data.data.last);
    //             commit('MY_LOADER', false);

    //           }

    //         } else {

    //         }

    //         resolve()
    //       }
    //     ).catch(e => {

    //       commit('NO_DATA_FOUND', true);
    //       commit('MY_LOADER', false)

    //       reject(e)
    //     })
    //   })
    // },

    loadVesselParticularsById ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('VESSEL_DETAILS', [])
        commit('MY_LOADER', true)

        AGENCY_API.getVesselParticularById(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('VESSEL_DETAILS', response.data.data)
                commit('PAGE_COUNT', 1)
                commit('FIRST_LOADER', false)
                commit('MY_LOADER', false)

              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
                commit('MY_LOADER', false)

              }
            } else {
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
              commit('SUCCESS_MESSAGE', '')
              commit('SUCCESS_ALERT', false)
              commit('ERROR_ALERT', true)
              commit('NO_DATA_FOUND', true)
              commit('MY_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)
          commit('MY_LOADER', false)
          reject(e)
        })
      })
    },

    getVesselParticularByVoyageNo ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_API.getVesselParticularByVoyageNo(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('PAGE_COUNT', 1)
                commit('FIRST_LOADER', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('NO_DATA_FOUND', true)
              commit('FIRST_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    addVesselParticulars ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])
        AGENCY_API.addVesselParticular(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                // commit('REQUEST_LIST', response.data.data.content);
                commit('PAGE_COUNT', response.data.data.totalPages);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);

              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.message)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('NO_DATA_FOUND', true)
              commit('FIRST_LOADER', false)

            }

            resolve()
          }
        ).catch(e => {
          console.log(e)
          commit('NO_DATA_FOUND', true)
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    updateVesselParticulars({commit, getters}){
      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        // commit('REQUEST_LIST', []);
        console.log()
        AGENCY_API.editVesselParticular(getters.getSaveDetails).then(
          response => {

            if (response.data.status) {

              if ( Object.keys(response.data.data).length > 0) {
                commit('ERROR_ALERT', false);
                commit('SUCCESS_ALERT', true);
                commit('FIRST_LOADER', false);
              } else {
                commit('SUCCESS_ALERT', false);
                commit('FIRST_LOADER', false);
                commit('ERROR_ALERT', true);
              }

            } else {
              commit('SUCCESS_ALERT', false);
              commit('FIRST_LOADER', false);
              commit('ERROR_ALERT', true);
            }

            resolve();
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ", Requests Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);

        });

      });

    },

    printVesselParticularsAndSOF ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        // if(response.data)
        commit('MY_LOADER', true);
        commit('REQUEST_LIST', []);

        AGENCY_API.printVesselParticularActivityById(getters.getRequestId).then(
          response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'VESSEL_STATEMENT_OF_FACTS_' + getters.getQueryDate + '.pdf');
            document.body.appendChild(link);
            link.click();
            commit('MY_LOADER', false);
            commit('SUCCESS_ALERT_PRINT', true);
            commit('ERROR_ALERT', false);
            resolve();
          }
        ).catch(e => {
          commit('SUCCESS_ALERT_PRINT', false);
          commit('ERROR_ALERT_PRINT', true);
          commit('MY_LOADER', false);

          reject(e);
        });
      });
    },

    cancelVesselNomination({commit, getters}){
      return new Promise((resolve, reject) => {
        commit ('MY_LOADER', true)
        commit('REQUEST_LIST',[])


        AGENCY_API.cancelVesselNomination(getters.getRequestId).then(
          response => {

            if (response.data.status) {

              if ( Object.keys(response.data.data).length > 0) {
                commit('VESSEL_DETAILS', response.data.data)
                commit('ERROR_ALERT', false);
                commit('SUCCESS_ALERT', true);
                commit('MY_LOADER', false);
              } else {
                commit('SUCCESS_ALERT', false);
                commit('MY_LOADER', false);
                commit('ERROR_ALERT', true);
              }

            } else {
              commit('SUCCESS_ALERT', false);
              commit('MY_LOADER', false);
              commit('ERROR_ALERT', true);
            }

            resolve();
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ", Requests Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e);

        });
      })
    },
    closeVesselOperations({commit, getters}){
      return new Promise((resolve, reject) => {
        commit ('MY_LOADER', true)
        commit('REQUEST_LIST',[])
        console.log('in vessel closing operations')

        AGENCY_API.closeMarineVessel(getters.getRequestId).then(
          response => {

            if (response.data.status) {

              if ( Object.keys(response.data.data).length > 0) {
                commit('ERROR_ALERT', false);
                commit('SUCCESS_ALERT', true);
                commit('MY_LOADER', false);
                commit('VESSEL_DETAILS', response.data.data)

              } else {
                commit('SUCCESS_ALERT', false);
                commit('MY_LOADER', false);
                commit('ERROR_ALERT', true);
              }

            } else {
              commit('SUCCESS_ALERT', false);
              commit('MY_LOADER', false);
              commit('ERROR_ALERT', true);
            }

            resolve();
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ", Requests Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e);

        });
      })
    },

    submitDetailsToTesws({commit, getters}){
      return new Promise((resolve, reject) => {
        commit ('MY_LOADER', true)
        // commit('REQUEST_LIST',[])
        console.log('Submiting Details to TESWS')

        AGENCY_API.submitToTESWS(getters.getRequestId).then(
          response => {
            // console.log(response)
            if (response.data.status) {

              if ( Object.keys(response.data.data).length > 0) {
                commit('ERROR_ALERT', false);
                commit('SUCCESS_ALERT', true);
                commit('MY_LOADER', false);
                // commit('VESSEL_DETAILS', response.data.data)

              } else {
                commit('SUCCESS_ALERT', false);
                commit('MY_LOADER', false);
                commit('ERROR_ALERT', true);
              }

            } else {
              commit('SUCCESS_ALERT', false);
              commit('MY_LOADER', false);
              commit('ERROR_ALERT', true);
            }

            resolve();
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ", Requests Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e);

        });
      })

    },



  },
  getters: {

    getSuccess: state => state.SuccessAlert,

    getError: state => state.ErrorAlert,

    getVoyageId: state => state.voyageId,

    getQueryDate: state => state.queryDate,

    getVesselDetails: state => state.vesselDetails,

    getRequestList: state => state.requests,

    getSaveDetails: state => state.saveDetails,
    getPageCount: state => state.pageCount,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getRequestId: state => state.requestId,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyLoader: state => state.myLoader,

    getReloadAllRequest: state => state.reloadAllRequest,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParams: state => state.params,

    getSortBy: state => state.sortBy,

    getSuccessAlertPrint : state => state.SuccessAlertPrint,

    getErrorAlertPrint :  state => state.ErrorAlertPrint,

    getTotalElement: state => state.totalElements,

    getTotalPage: state => state.totalPages,

    getPageNumber: state => state.pageNumber,

    getIsFirst: state => state.isFirst,

    getIsLast: state => state.isLast,

  }

}
