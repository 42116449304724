/* vue object */
import Vue from 'vue';

/* import axios */
import axios from 'axios';

/* import vue-idle */
import IdleVue from 'idle-vue';

/* vue router object */
import VueRouter from 'vue-router';

import NProgress from 'nprogress';

// import '../node_modules/nprogress/nprogress.css'
import '../public/css/nprogress.css';

/* API file */
import api from './api';

/* store file */
import { store } from './store/index';

/* file to handle router links */
import App from './App.vue';

/* vuejs modal */
import VuejsDialog from 'vuejs-dialog';

/* form input validator */
import Vuelidate from 'vuelidate';

/* form input validator css */
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

/* global methods */
import MixinMethods from './mixins/methods';

/* LightBootstrap plugin */
import LightBootstrap from './light-bootstrap-main';

/* router setup */
import mainRoutes from './routes/routes';

/* cfa router setup */
import cfaRoutes from './routes/cfa';

/* dc router setup */
import dcRoutes from './routes/dc';

/* shipping agency routes */
import shippingAgencyRoutes from "./routes/shippingAgencyCustomer";


/* external router setup */
import externalRoutes from './routes/external';

/* revenue router setup */
import revenueRoutes from './routes/revenue';

/* customer router setup */
import customerRoutes from './routes/customer';

/* authorization router setup */
import authorizationRoutes from './routes/authorization';

/* shipping tallying router setup */
import tallyingRoutes from './routes/tallying';

/* shipping agency router setup */
import agencyRoutes from './routes/agency';

/* vue cookies */
import VueCookies from 'vue-cookies';

/* register service worker */
import './registerServiceWorker';

/* vuex mapActions */
import { mapActions } from 'vuex';

/* select search */
import 'vue-search-select/dist/VueSearchSelect.css';

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

/* charts */
// import ApexCharts from 'apexcharts'
import VueApexCharts from 'vue-apexcharts';

import VueGoogleCharts from 'vue-google-charts'

/* vue notifications */
import Notifications from 'vue-notification';

import Paginate from 'vuejs-paginate'

/* font awesome icons */
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faCoffee, faEye, faCheck, faCircle, faTrashAlt, faLuggageCart, faEdit, faPlus, faFileInvoiceDollar,
  faEnvelope, faUserPlus, faUserEdit, faUserShield, faFilePdf, faFileExcel, faAngleDoubleRight, faAngleDoubleLeft,
  faFileContract, faBook, faMinusCircle, faPlusCircle, faExchangeAlt, faTachometerAlt, faBan, faTasks,
  faExclamationTriangle, faDownload, faInfoCircle, faBookOpen, faAngleDown, faAngleUp, faKey, faSignOutAlt,
  faChartPie, faSearch, faSyncAlt, faMinus, faReceipt, faUserSecret, faMoneyCheck, faTimes, faLightbulb,
  faArrowLeft, faTruckLoading, faShip, faFileAlt, faDollarSign, faFileExport, faFileImport, faUndoAlt, faHands,
  faCheckCircle, faCircleNotch, faFlag, faClone, faComment, faSync, faFolder, faHistory, faUndo, faBriefcase,
  faPencilAlt, faListUl, faThumbsUp, faChevronRight, faChevronDown, faHome, faShareSquare, faPrint, faExclamation,
  faCaretUp, faCaretDown, faExpandArrowsAlt, faCompressArrowsAlt, faBell, faComments, faList
}
  from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const fontLibrary = () => import('@fortawesome/fontawesome-svg-core');

const fontAwesomeIcon = () => import('@fortawesome/vue-fontawesome');

library.add(
  faCoffee, faEye, faCheck, faCircle, faTrashAlt, faLuggageCart, faEdit, faPlus, faFileInvoiceDollar,
  faEnvelope, faUserPlus, faUserEdit, faUserShield, faFilePdf, faFileExcel, faAngleDoubleRight, faAngleDoubleLeft,
  faFileContract, faBook, faMinusCircle, faPlusCircle, faExchangeAlt, faTachometerAlt, faBan, faTasks,
  faExclamationTriangle, faDownload, faInfoCircle, faBookOpen, faAngleDown, faAngleUp, faKey, faSignOutAlt,
  faChartPie, faSearch, faSyncAlt, faMinus, faReceipt, faUserSecret, faMoneyCheck, faTimes, faLightbulb,
  faArrowLeft, faTruckLoading, faShip, faFileAlt, faDollarSign, faFileExport, faFileImport, faUndoAlt, faHands,
  faCheckCircle, faCircleNotch, faFlag, faClone, faComment, faSync, faFolder, faHistory, faUndo, faBriefcase,
  faPencilAlt, faListUl, faThumbsUp, faChevronRight, faChevronDown, faHome, faShareSquare, faPrint, faExclamation,
  faCaretUp, faCaretDown, faExpandArrowsAlt, faCompressArrowsAlt, faBell, faComments, faList
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

const eventsHub = new Vue();

/* Tell Vue to use the cookies plugin setup */
Vue.use(VueRouter);
Vue.use(VueMoment, {
  moment
})
moment.tz.setDefault
// console.log('moment.tz.guess()', moment.tz.guess())
// console.log('moment.tz.names()', moment.tz.names())
Vue.use(VueCookies);
Vue.use(LightBootstrap);
Vue.use(VueGoogleCharts)
Vue.use(VuejsDialog);
Vue.use(Vuelidate);
Vue.mixin(MixinMethods);
Vue.use(Notifications);
// Vue.use(VueApexCharts);
Vue.component('paginate', Paginate)
Vue.component('apexchart', VueApexCharts);

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1800000, // 30 minutes active session, 10000 = 10 seconds
  startAtIdle: false
});

// Vue.directive('mask', {
//   bind (el, binding) {
//     const value = el.value;
//     Object.defineProperty(el, 'value', {
//       get: function () {
//         return value;
//       },
//       set: function (newValue) {
//         el.setAttribute('value', newValue);
//       },
//       configurable: true
//     });
//   }
// });

Vue.config.performance = process.env.NODE_ENV !== 'production';

let allRoutes = [];
allRoutes = allRoutes.concat(shippingAgencyRoutes,agencyRoutes, tallyingRoutes, authorizationRoutes, cfaRoutes, dcRoutes, revenueRoutes, externalRoutes, customerRoutes, mainRoutes);

const routes = allRoutes;

/* configure router */
const router = new VueRouter({

  routes, // short for routes: routes

  linkActiveClass: 'nav-item active',

  mode: 'history',

  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }

});

//= ======================================================================================

// Vue.config.productionTip = false;

export default {

  methods: {

    ...mapActions('login',
      ['logout']
    )
  }

};

api.handle401(
  (res) => {
    // console.clear();
    
    store.dispatch('global/noInternet', false)

    // Return a successful response back to the calling service
    return res;
  },
  (err) => {

    const originalRequest = err.config;
    // console.log(err.response.status)
    // if (err.response.status === 400){
    //   MixinMethods.methods.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
    //   return new Promise((resolve, reject) => {
    //     reject(err);
    //   });
    // }

    // Return any error which is not due to authentication back to the calling service
    if ((typeof err.response === undefined || err.response === undefined)) {
      store.dispatch('global/noInternet', true);

      return new Promise((resolve, reject) => {
        reject(err);
      });
    }

    // Return any error which is not due to authentication back to the calling service
    if (err.response.status === 500 && err.response.status !== 401) {
      store.dispatch('global/noInternet', true);

      return new Promise((resolve, reject) => {
        reject(err);
      });
    }

    // Return any error which is due to authorization
    if (err.response.status === 403) {

      store.dispatch('global/erroring403', true)

      return new Promise((resolve, reject) => {
        reject(err);
      });
    }


    // Logout user if token refresh didn't work or user is disabled
    if (originalRequest.url === 'oauth/token' || err.response.message === 'Account is Inactive.') {
      store.dispatch('login/logout');

      router.push({

        path: '/'

      });

      return new Promise((resolve, reject) => {
        reject(err);
      });
    }

    if (window.$cookies.isKey('refresh_token') && err.response.status === 401) {
      store.dispatch('global/noInternet', false);

      // Try request again with new token
      return api.refreshToken().then((response) => {
        if (typeof response === 'undefined') {
          store.dispatch('login/logout');

          router.push({

            path: '/'

          });
        } else {
          store.dispatch('global/noInternet', false);

          api.authenticate(response.data.access_token, response.data.refresh_token, response.data.expires_in);

          originalRequest.headers.Authorization = 'Bearer ' + response.data.access_token;

          return new Promise((resolve, reject) => {
            axios.request(originalRequest).then(response => {
              resolve(response);
            }).catch((err) => {
              reject(err);
            });
          });
        }
      }).catch(() => {
        store.dispatch('login/logout');

        router.push({

          path: '/'

        });
      });
    } else {
      if (err.response.status !== 504 && err.response.status !== 403) {
        store.dispatch('login/logout');

        router.push({

          path: '/'

        });

        return Promise.reject(err);
      }

      // TODO => { CHECK NOT LOG OUT USER IF TIME OUT AND FORBIDDEN ERROR }
    }
  });

// The global route guard
router.beforeEach((to, from, next) => {
  store.dispatch('global/noInternet', false);

  if (to.matched.some(record => record.meta.requiresAuth)) {
    NProgress.start();
    NProgress.inc(0.2);

    if (!api.isAuthenticated()) {
      next({

        path: '/login',

        query: { redirect: to.fullPath }

      });
    } else {
      const userDetails = JSON.parse(localStorage.getItem('browser-local-permits'));

      const permission = to.meta.permission;

      if (typeof permission === 'undefined') {
        next();

        return;
      }
      if (!userDetails.includes(permission)) {
        next({ path: '/denied' });

        return;
      }

      next();
    }
  } else if (to.fullPath === '/login' && api.isAuthenticated()) {
    next({ path: '/overview' });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  NProgress.done();
});

//= ======================================================================================

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
});
