import AGENCY_APi from '../../../api/agency';


export default {
  namespaced: true,

  state: {

    requestId: '',
    agencyChargeDetails: [],
    pageCount: 1,
    loadingAgencyCharges: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    reloadAllRequest: false,
    hasSearchedVariable: false,
    searchParameter: '',
    sortBy: '',
    voyageId: '',
    queryDate: '',
    categoryId: '',
    saveDetails: {},
    requests2: []

  },
  mutations: {

    VOYAGE_ID: (state, voyageId) => state.voyageId = voyageId,

    QUERY_DATE: (state, queryDate) => state.queryDate = queryDate,

    CATEGORY_ID: (state, categoryId) => state.categoryId = categoryId,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    AGENCY_CHARGE_DETAILS: (state, agencyChargeDetails) => state.agencyChargeDetails = agencyChargeDetails,

    REQUEST2_LIST: (state, requests2) => state.requests2 = requests2,

    SAVE_DETAILS: (state, saveDetails) => state.saveDetails = saveDetails,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    LOADING_AGENCY_CHARGE_DETAILS: (state, loadingAgencyCharges) => state.loadingAgencyCharges = loadingAgencyCharges,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    RELOAD_ALL_APPLICATIONS: (state, reloadAllRequest) => state.reloadAllRequest = reloadAllRequest,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy

  },
  actions: {
    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    requestStatusing: ({ state, commit }, payload) => commit('REQUEST_STATUS', payload),

    savingDetails: ({ state, commit }, payload) => commit('SAVE_DETAILS', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    searchingParameter: ({ state, commit }, payload) => commit('SEARCH_PARAMETER', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    voyageIding: ({ state, commit }, payload) => commit('VOYAGE_ID', payload),

    queryingDate: ({ state, commit }, payload) => commit('QUERY_DATE', payload),

    categoryIding: ({ state, commit }, payload) => commit('CATEGORY_ID', payload),

    reloadingRequest: ({ state, commit }, payload) => commit('RELOAD_ALL_APPLICATIONS', payload),

    getAllAgencyCharges ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('LOADING_AGENCY_CHARGE_DETAILS', true);
        commit('AGENCY_CHARGE_DETAILS', []);

        AGENCY_APi.listAgencyCharge().then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('AGENCY_CHARGE_DETAILS', response.data.data);
                commit('LOADING_AGENCY_CHARGE_DETAILS', false);
              } else {
                commit('LOADING_AGENCY_CHARGE_DETAILS', false);
              }
            } else {
              commit('LOADING_AGENCY_CHARGE_DETAILS', false);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('LOADING_AGENCY_CHARGE_DETAILS', false);

          reject(e);
        });
      });
    },
    getAgencyChargesById ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('LOADING_AGENCY_CHARGE_DETAILS', true);
        commit('AGENCY_CHARGE_DETAILS', []);

        AGENCY_APi.getServiceCategoryById(getters.getCategoryId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('NO_DATA_FOUND', false);
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
                commit('LOADING_AGENCY_CHARGE_DETAILS', false);
              } else {
                commit('SUCCESS_ALERT', false);
                commit('ERROR_ALERT', true);
                commit('NO_DATA_FOUND', true);
                commit('PAGE_COUNT', response.data.message);
                commit('LOADING_AGENCY_CHARGE_DETAILS', false);
              }
            } else {

            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('LOADING_AGENCY_CHARGE_DETAILS', false);

          reject(e);
        });
      });
    },
    addAgencyCharge ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('LOADING_AGENCY_CHARGE_DETAILS', true);
        commit('AGENCY_CHARGE_DETAILS', []);

        AGENCY_APi.addAgencyCharge(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                // commit('AGENCY_CHARGE_DETAILS', response.data.data.content);
                // commit('PAGE_COUNT', response.data.data.totalPages);
                commit('MY_LOADER', false);
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
              } else {
                // commit('NO_DATA_FOUND', true);
                commit('SUCCESS_ALERT', false);
                commit('ERROR_ALERT', true);
                // commit('PAGE_COUNT', response.data.message);
                commit('MY_LOADER', false);
              }
            } else {
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('MY_LOADER', false);

            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e);
        });
      });
    },
  },
  getters: {

    getVoyageId: state => state.voyageId,

    getQueryDate: state => state.queryDate,

    getCategoryId: state => state.categoryId,

    getAgencyChargeDetails: state => state.agencyChargeDetails,

    getRequestList2: state => state.requests2,

    getSaveDetails: state => state.saveDetails,

    getPageCount: state => state.pageCount,

    getloadingAgencyCharges: state => state.loadingAgencyCharges,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getRequestId: state => state.requestId,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyLoader: state => state.myLoader,


  }

}
