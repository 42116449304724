import TALLY_API from '../../../api/tally'

export default {

  namespaced: true,

  state: {

    requestId: '',
    requests: [],
    singleRequest: {},
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    reloadAllRequest: false,
    hasSearchedVariable: false,
    searchParameter: '',
    sortBy: '',
    voyageId: '',
    queryDate: '',
    shiftId: '',
    stuffingType: '',
    totalElements: '',
    totalPages: '',
    pageNumber: '',
    isFirst: false,
    isLast: false,
    stuffingOrStrippingId: ''

  },

  mutations: {

    TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

    TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

    PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

    IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

    IS_LAST: (state, isLast) => state.isLast = isLast,

    STUFFING_TYPE: (state, stuffingType) => state.stuffingType = stuffingType,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    STUFFING_OR_STRIPPING_ID: (state, stuffingOrStrippingId) => state.stuffingOrStrippingId = stuffingOrStrippingId,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    REQUEST_OBJECT: (state, singleRequest) => state.singleRequest = singleRequest,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    RELOAD_ALL_APPLICATIONS: (state, reloadAllRequest) => state.reloadAllRequest = reloadAllRequest,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy

  },

  actions: {

    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    requestStatusing: ({ state, commit }, payload) => commit('REQUEST_STATUS', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    searchingParameter: ({ state, commit }, payload) => commit('SEARCH_PARAMETER', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    StuffingOrStrippingId: ({ state, commit }, payload) => commit('STUFFING_OR_STRIPPING_ID', payload),

    stuffingtyping: ({ state, commit }, payload) => commit('STUFFING_TYPE', payload),

    queryingDate: ({ state, commit }, payload) => commit('QUERY_DATE', payload),

    reloadingRequest: ({ state, commit }, payload) => commit('RELOAD_ALL_APPLICATIONS', payload),

    loadTalliedStuffingOrStrippingOnCreated ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        TALLY_API.getTalliedStuffingOrStrippingPageable(getters.getStuffingType, getters.getPageSize, getters.getPageNo).then(
          response => {
            if (response.data.status) {
              if (response.data.data.content.length > 0) {
                commit('REQUEST_LIST', response.data.data.content)
                commit('FIRST_LOADER', false)
                commit('TOTAL_ELEMENTS', response.data.data.totalElements)
                commit('TOTAL_PAGES', response.data.data.totalPages)
                commit('PAGE_NUMBER', response.data.data.number)
                commit('IS_FIRST', response.data.data.first)
                commit('IS_LAST', response.data.data.last)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
                commit('TOTAL_ELEMENTS', response.data.data.totalElements)
                commit('TOTAL_PAGES', response.data.data.totalPages)
                commit('PAGE_NUMBER', response.data.data.number)
                commit('IS_FIRST', response.data.data.first)
                commit('IS_LAST', response.data.data.last)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    loadTalliedStuffingOrStripping ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('MY_LOADER', true)
        commit('REQUEST_LIST', [])

        TALLY_API.getTalliedStuffingOrStrippingPageable(getters.getStuffingType, getters.getPageSize, getters.getPageNo).then(
          response => {
            if (response.data.status) {
              if (response.data.data.content.length > 0) {
                commit('REQUEST_LIST', response.data.data.content)
                commit('MY_LOADER', false)
                commit('TOTAL_ELEMENTS', response.data.data.totalElements)
                commit('TOTAL_PAGES', response.data.data.totalPages)
                commit('PAGE_NUMBER', response.data.data.number)
                commit('IS_FIRST', response.data.data.first)
                commit('IS_LAST', response.data.data.last)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('MY_LOADER', false)
                commit('TOTAL_ELEMENTS', response.data.data.totalElements)
                commit('TOTAL_PAGES', response.data.data.totalPages)
                commit('PAGE_NUMBER', response.data.data.number)
                commit('IS_FIRST', response.data.data.first)
                commit('IS_LAST', response.data.data.last)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    loadTalliedContainersStuffingOrStrippingOnCreated ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        TALLY_API.getTalliedContainersStuffingOrStripping(getters.getStuffingOrStrippingId).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    loadStuffingOrStrippingDocument ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_OBJECT', {})

        TALLY_API.getTalliedStuffingOrStrippingSingle(getters.getStuffingOrStrippingId).then(
          response => {

            if (response.data.status) {
                commit('REQUEST_OBJECT', response.data.data)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
              }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    loadStuffingOrStrippingGoods ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        TALLY_API.getStuffedOrDestuffedGoodsByBl(getters.getStuffingOrStrippingId).then(
          response => {

            if (response.data.status) {
              commit('REQUEST_LIST', response.data.data)
              commit('FIRST_LOADER', false)
              commit('NO_DATA_FOUND', false)
            } else {
              commit('NO_DATA_FOUND', true)
              commit('FIRST_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    }

  },

  getters: {

    getRequestList: state => state.requests,

    getRequestObject: state => state.singleRequest,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getRequestId: state => state.requestId,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyLoader: state => state.myLoader,

    getReloadAllRequest: state => state.reloadAllRequest,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParameter: state => state.searchParameter,

    getSortBy: state => state.sortBy,

    getStuffingType: state => state.stuffingType,

    getTotalElement: state => state.totalElements,

    getTotalPage: state => state.totalPages,

    getPageNumber: state => state.pageNumber,

    getIsFirst: state => state.isFirst,

    getIsLast: state => state.isLast,

    getStuffingOrStrippingId: state => state.stuffingOrStrippingId

  }

}
