<template>
  <div
    class="card"
    :class="[type && `card-${type}`]"
  >
    <div
      class="card-image"
      v-if="$slots.image"
    >
      <slot name="image" />
    </div>
    <div
      class="card-header"
      v-if="$slots.header || title"
      :class="headerClasses"
    >
      <slot name="header">
        <h4 class="card-title">
          {{ title }}
        </h4>
        <p
          class="card-category"
          v-if="subTitle"
        >
          {{ subTitle }}
        </p>
      </slot>
    </div>
    <div
      class="card-body"
      v-if="$slots.default"
      :class="bodyClasses"
    >
      <slot />
    </div>
    <slot name="raw-content" />
    <div
      class="card-footer"
      :class="footerClasses"
      v-if="$slots.footer"
    >
      <slot name="footer" />
    </div>
    <br>
  </div>
</template>
<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      description: 'Card title'
    },
    subTitle: {
      type: String,
      description: 'Card subtitle'
    },
    type: {
      type: String,
      description: 'Card type (e.g primary/danger etc)'
    },
    headerClasses: {
      type: [String, Object, Array],
      description: 'Card header css classes'
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: 'Card body css classes'
    },
    footerClasses: {
      type: [String, Object, Array],
      description: 'Card footer css classes'
    }
  }
}
</script>
<style scoped>
.card {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
</style>
