import API from '../../../api'

export default {

  namespaced: true,

  state: {

    userProfile: {},
    finalUserPermissions: [],
    loginPending: false,
    username: '',
    password: ''

  },

  mutations: {

    UPDATE_DATA: (state, userProfile) => state.userProfile = userProfile,

    UPDATE_PERMISSION: (state, finalUserPermissions) => state.finalUserPermissions = finalUserPermissions,

    LOGIN_PROGRESS: (state) => state.loginPending = true,

    LOGIN_SUCCESS: (state) => state.loginPending = false,

    UPDATE_PASSWORD: (state, password) => state.password = password,

    UPDATE_USERNAME: (state, username) => state.username = username

  },

  actions: {

    loggedInUserPermissions: ({ state, commit }, payload) => commit('UPDATE_PERMISSION', payload),

    loggedInUserData: ({ state, commit }, payload) => commit('UPDATE_DATA', payload),

    logout () {
      if (window.$cookies.isKey('token')) {
        window.$cookies.remove('token')
      }

      if (window.$cookies.isKey('expires_in')) {
        window.$cookies.remove('expires_in')
      }

      if (window.$cookies.isKey('refresh_token')) {
        window.$cookies.remove('refresh_token')
      }

      if (window.$cookies.isKey('operation-type-shift-clerk-assignment')) {
        window.$cookies.remove('operation-type-shift-clerk-assignment')
      }

      if (window.$cookies.isKey('browser-local-cashed-url-back')) {
        window.$cookies.remove('browser-local-cashed-url-back')
      }

      if (window.$cookies.isKey('navbar-custom')) {
        window.$cookies.remove('navbar-custom')
      }

      if (window.$cookies.isKey('browser-cache-agent-custom')) {
        window.$cookies.remove('browser-cache-agent-custom')
      }

      if (window.$cookies.isKey('sidebar-custom-width-pixel')) {
        window.$cookies.remove('sidebar-custom-width-pixel')
      }

      if (window.$cookies.isKey('browser-local-permits-agency')) {
        window.$cookies.remove('browser-local-permits-agency')
      }

      if (window.$cookies.isKey('browser-local-permits-agent')) {
        window.$cookies.remove('browser-local-permits-agent')
      }

      if (window.$cookies.isKey('browser-local-cashed-url-back-to')) {
        window.$cookies.remove('browser-local-cashed-url-back-to')
      }

      if (window.$cookies.isKey('browser-local-cashed-url')) {
        window.$cookies.remove('browser-local-cashed-url')
      }

      if (window.$cookies.isKey('browser-local-cashed-url-agent')) {
        window.$cookies.remove('browser-local-cashed-url-agent')
      }

      if (window.$cookies.isKey('browser-local-cashed-url-back-to-2')) {
        window.$cookies.remove('browser-local-cashed-url-back-to-2')
      }

      localStorage.clear();

      // if (typeof (localStorage.getItem('browser-local-permits')) !== 'undefined') {
      //   localStorage.removeItem('browser-local-permits')
      // }

      // if (typeof (localStorage.getItem('side-bar-dynamism')) !== 'undefined') {
      //   localStorage.removeItem('side-bar-dynamism')
      // }

      // if (typeof (localStorage.getItem('st-import-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('st-import-side-menu')
      // }

      // if (typeof (localStorage.getItem('sa-import-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('sa-import-side-menu')
      // }

      // if (typeof (localStorage.getItem('sa-export-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('sa-export-side-menu')
      // }

      // if (typeof (localStorage.getItem('revenue-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('revenue-side-menu')
      // }

      // if (typeof (localStorage.getItem('report-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('report-side-menu')
      // }

      // if (typeof (localStorage.getItem('dc-import-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('dc-import-side-menu')
      // }

      // if (typeof (localStorage.getItem('dc-export-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('dc-export-side-menu')
      // }

      // if (typeof (localStorage.getItem('configuration-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('configuration-side-menu')
      // }

      // if (typeof (localStorage.getItem('cfa-export-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('cfa-export-side-menu')
      // }

      // if (typeof (localStorage.getItem('cfa-import-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('cfa-import-side-menu')
      // }

      // if (typeof (localStorage.getItem('user-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('user-side-menu')
      // }

      // if (typeof (localStorage.getItem('mr-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('mr-side-menu')
      // }

      // if (typeof (localStorage.getItem('ms-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('ms-side-menu')
      // }

      // if (typeof (localStorage.getItem('as-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('as-side-menu')
      // }

      // if (typeof (localStorage.getItem('nt-side-menu')) !== 'undefined') {
      //   localStorage.removeItem('nt-side-menu')
      // }
    },

    logoutOnSessionExpire () {
      if (window.$cookies.isKey('token')) {
        window.$cookies.remove('token')
      }

      if (window.$cookies.isKey('expires_in')) {
        window.$cookies.remove('expires_in')
      }

      if (window.$cookies.isKey('refresh_token')) {
        window.$cookies.remove('refresh_token')
      }

      if (window.$cookies.isKey('operation-type-shift-clerk-assignment')) {
        window.$cookies.remove('operation-type-shift-clerk-assignment')
      }

      if (window.$cookies.isKey('browser-local-cashed-url-back')) {
        window.$cookies.remove('browser-local-cashed-url-back')
      }

      if (window.$cookies.isKey('navbar-custom')) {
        window.$cookies.remove('navbar-custom')
      }

      if (window.$cookies.isKey('browser-cache-agent-custom')) {
        window.$cookies.remove('browser-cache-agent-custom')
      }

      if (window.$cookies.isKey('sidebar-custom-width-pixel')) {
        window.$cookies.remove('sidebar-custom-width-pixel')
      }

      if (window.$cookies.isKey('browser-local-permits-agency')) {
        window.$cookies.remove('browser-local-permits-agency')
      }

      if (window.$cookies.isKey('browser-local-permits-agent')) {
        window.$cookies.remove('browser-local-permits-agent')
      }

      if (window.$cookies.isKey('browser-local-cashed-url-back-to')) {
        window.$cookies.remove('browser-local-cashed-url-back-to')
      }

      if (typeof (localStorage.getItem('browser-local-permits')) !== 'undefined') {
        localStorage.removeItem('browser-local-permits')
      }

      if (typeof (localStorage.getItem('side-bar-dynamism')) !== 'undefined') {
        localStorage.removeItem('side-bar-dynamism')
      }
    },

    login ({ state, commit }) {
      commit('LOGIN_PROGRESS')

      return new Promise((resolve, reject) => {
        API.getToken(state.username, state.password)

          .then(res => {
            if (typeof res === 'undefined') {

            } else {
              API.authenticate
              (
                res.data.access_token,

                res.data.refresh_token,

                res.data.expires_in

              );

              commit('LOGIN_SUCCESS')
            }

            resolve()
          })
          .catch(err => {
            if (err.response != null) {

            }

            reject(err)
          })
      })
    },

    getUserData () {
      return new Promise((resolve, reject) => {
        API.getAuthenticatedUser()

          .then(res => {
            if (res.data.status) {
              const userDetails = res.data.data

              let permissionGroups = []

              // const fullToken = ([1e7] + -1e3 + -4e3 + -8e3).replace(/[018]/g, c =>
              //   (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)) +
              //       '-' + userDetails.sseToken + '-' + ([1e7] + -1e3 + -4e3 + -1e11).replace(/[018]/g, c =>
              //   (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))

              let customerId = 0

              const fullName = userDetails.lastName + ', ' + userDetails.firstName

              if (userDetails.customerId === null) {
                customerId = 0
              } else {
                customerId = userDetails.customerId
              }

              document.cookie = 'browser-local-permits-agency=' + userDetails.username + ';Path = /;SameSite = Strict'

              document.cookie = 'browser-local-permits-agent=' + userDetails.id + ';Path = /;SameSite = Strict'

              document.cookie = 'sidebar-custom-width-pixel=' + 'px' + customerId + ';Path = /;SameSite = Strict'

              document.cookie = 'browser-cache-agent-custom=' + fullName + ';Path = /;SameSite = Strict'

              for (let i = 0; i < userDetails.roles.length; i++) {
                for (let j = 0; j < userDetails.roles[i].privileges.length; j++) {
                  permissionGroups.push(userDetails.roles[i].privileges[j].name)

                  permissionGroups = Array.from(new Set(permissionGroups))
                }
              }

              localStorage.setItem('browser-local-permits', JSON.stringify(permissionGroups))
            }

            resolve()
          })

          .catch(err => {
            reject(err)
          })
      })
    }

  },

  getters: {

    loginPending: (state) => state.loginPending,

    getUserDetails: (state) => state.userProfile,

    getUserPermissions: (state) => state.finalUserPermissions,

    getUsername: (state) => state.password,

    getPassword: (state) => state.username

  }

}
