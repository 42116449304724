import TALLY_API from '../../../api/tally'

import REVENUE_API from '../../../api/rev'

export default {

  namespaced: true,

  state: {

    requestId: '',
    requests: [],
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    myLoader: false
  },

  mutations: {

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

  },

  actions: {

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    loadTalliedChargesAwaitingBilling ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

      TALLY_API.getUnbilledTalliedDocuments().then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('FIRST_LOADER', false)

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    generateBillForTalliedChargesAwaitingBilling ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        // commit('REQUEST_LIST', [])

        REVENUE_API.generateBillForTalliedCharges(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                // commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)
                commit('SUCCESS_ALERT', true)
                commit('ERROR_ALERT', false)

              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
                commit('SUCCESS_ALERT', false)
                commit('ERROR_ALERT', true)

              }
            } else {
              commit('FIRST_LOADER', false)
              commit('SUCCESS_ALERT', false)
              commit('ERROR_ALERT', true)

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },


  },

  getters: {

    getRequestList: state => state.requests,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getRequestId: state => state.requestId,


  }

}
