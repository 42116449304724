import API_DC from '../../../api/dc'

export default {

  namespaced: true,

  state: {

    requests: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    searchParameter: '',
    sortBy: '',
    documentType: 0,
    documentTypeToString: '',

    totalElements: "",
    totalPages: "",
    pageNumber: "",
    isFirst: false,
    isLast: false,
    params: '',
    hasSearchedVariable: false,

    totalElements: "",
    totalPages: "",
    pageNumber: "",
    isFirst: false,
    isLast: false,

  },

  mutations: {

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMS: (state, params) => state.params = params,

    TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

    TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

    PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

    IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

    IS_LAST: (state, isLast) => state.isLast = isLast,

    DOCUMENT_TYPE: (state, documentType) => state.documentType = documentType,

    DOCUMENT_TYPE_TO_STRING: (state, documentTypeToString) => state.documentTypeToString = documentTypeToString,

    BL_NUMBER: (state, blNumber) => state.blNumber = blNumber,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy

  },

  actions: {

    hasSearchngParams: ({state, commit}, payload) => commit('HAS_SEARCH_VARIABLE', payload),

    paramsSearching: ({state, commit}, payload) => commit('SEARCH_PARAMS', payload),

    documentCategorizing: ({state , commit} , payload) => commit('DOCUMENT_TYPE' , payload),

    documentCategorizingToString: ({state , commit} , payload) => commit('DOCUMENT_TYPE_TO_STRING' , payload),

    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    searchingParameter: ({ state, commit }, payload) => commit('SEARCH_PARAMETER', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    loadNewEdiDocumentsOnCreated({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        API_DC.listAllPageableManifests(getters.getPageNo, getters.getPageSize, getters.getDocumentTypeToString).then(
          response => {
            
            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false)
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false)

              }
            } else {}

            resolve()
          }
        ).catch(e => {

          commit('FIRST_LOADER', false)

          reject(e);
        })
      })
    },

    loadNewEdiDocumentsAfterCreated({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true);
        commit('REQUEST_LIST', []);

        API_DC.listAllPageableManifests(getters.getPageNo, getters.getPageSize, getters.getDocumentTypeToString).then(
          response => {
            
            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('MY_LOADER', false)
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('MY_LOADER', false)

              }
            } else {}

            resolve()
          }
        ).catch(e => {

          commit('MY_LOADER', false)

          reject(e);
        })
      })
    },

    loadEdiDocumentsOnCreated ({ commit, getters }) {

      return new Promise((resolve, reject) => {
        
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        API_DC.getPageableDocumentV2(getters.getPageNo, getters.getPageSize, getters.getDocumentType).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false)
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false)

              }
            } else {}

            resolve()

          }
        ).catch(e => {

          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    loadEdiDocumentsAfterCreated ({ commit, getters }) {

      return new Promise((resolve, reject) => {
        
        commit('MY_LOADER', true)
        commit('REQUEST_LIST', [])

        API_DC.getPageableDocumentV2(getters.getPageNo, getters.getPageSize, getters.getDocumentType).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('MY_LOADER', false)
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('MY_LOADER', false)

              }
            } else {}

            resolve()

          }
        ).catch(e => {

          commit('MY_LOADER', false)

          reject(e)
        })
      })
    },

    /* SEARCH */

    searchWithGeneralParams({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])
        commit('HAS_SEARCH_VARIABLE', true)

        API_DC.searchGeneralEdiDocuments(getters.getSearchParams).then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('FIRST_LOADER', false)
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('NO_DATA_FOUND', true);
                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false)

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

  },

  getters: {

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParams: state => state.params,

    getDocumentType: state => state.documentType,

    getDocumentTypeToString: state => state.documentTypeToString,

    getRequestList: state => state.requests,

    getPageCount: state => state.pageCount,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyLoader: state => state.myLoader,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getTotalElement: state => state.totalElements,

    getTotalPage: state => state.totalPages,

    getPageNumber: state => state.pageNumber,

    getIsFirst: state => state.isFirst,

    getIsLast: state => state.isLast,

    getSearchParameter: state => state.searchParameter,

    getSortBy: state => state.sortBy

  }

}
