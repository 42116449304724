import TALLYAPI from '../../../api/tally'

export default {

  namespaced: true,

  state: {

    requests: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    myLoader: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    searchParameter: '',

    startTime: '',
    endTime: '',
    name: '',
    shiftId: '',
    day: '',
    cargoType: "",
    talliedDocumentId: '',
    reportSearchDto: '',
    payloadDate: '',
    reportName: '',
    operationType: '',
    operationLocation: '',
    tallyShiftDetails: [],
  },

  mutations: {

    REQUEST_LIST: (state, requests) => state.requests = requests,

    SHIFT_ID: (state, shiftId) => state.shiftId = shiftId,

    REPORT_SEARCH_DTO: (state, reportSearchDto) => state.reportSearchDto = reportSearchDto,

    SHIFT_DAY: (state, day) => state.day = day,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    TALLIED_DOC_ID: (state, talliedDocumentId) => state.talliedDocumentId = talliedDocumentId,

    CARGO_TYPE: (state, cargoType) => state.cargoType = cargoType,

    REPORT_NAME: (state, reportName) => state.reportName = reportName,

    PAYLOAD_DATE: (state, payloadDate) => state.payloadDate = payloadDate,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    START_TIME: (state, startTime) => state.startTime = startTime,

    END_TIME: (state, endTime) => state.endTime = endTime,

    SHIFT_NAME: (state, name) => state.name = name,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    OPERATION_TYPE: (state, operationType) => state.operationType = operationType,

    OPERATION_LOCATION: (state, operationLocation) => state.operationLocation = operationLocation,

    TALLY_SHIFT_DETAILS: (state, tallyShiftDetails) => state.tallyShiftDetails = tallyShiftDetails,
  },

  actions: {

    starttiming: ({state, commit}, payload) => commit('START_TIME', payload),

    endtiming: ({state, commit}, payload) => commit('END_TIME', payload),

    reportnaming: ({state, commit}, payload) => commit('REPORT_NAME', payload),

    tallieddocumentiding: ({state, commit}, payload) => commit('TALLIED_DOC_ID', payload),

    cargotyping: ({state, commit}, payload) => commit('CARGO_TYPE', payload),

    patloaddating: ({state, commit}, payload) => commit('PAYLOAD_DATE', payload),

    shiftnaming: ({state, commit}, payload) => commit('SHIFT_NAME', payload),

    shiftiding: ({state, commit}, payload) => commit('SHIFT_ID', payload),

    reportSearchAction: ({state, commit}, payload) => commit('REPORT_SEARCH_DTO', payload),

    shiftdaying: ({state, commit}, payload) => commit('SHIFT_DAY', payload),

    successalerting: ({state, commit}, payload) => commit('SUCCESS_ALERT', payload),

    erroralerting: ({state, commit}, payload) => commit('ERROR_ALERT', payload),

    operationTypeValue: ({state , commit}, payload) => commit('OPERATION_TYPE', payload),

    operationLocation: ({state, commit}, payload) => commit('OPERATION_LOCATION', payload),

    setTallyShifts: ({state, commit}, payload) => commit('TALLY_SHIFT_DETAILS', payload),

    loadShifts({commit, getters}) {

      if (getters.getTallyShiftDetails.length > 0){
        return new Promise((resolve => {
          commit('FIRST_LOADER', false);
          commit('NO_DATA_FOUND', false);
          resolve()
        }))
      }else{
        return new Promise((resolve, reject) => {
          console.log('length lesser than 1');
          commit('FIRST_LOADER', true);
          commit('TALLY_SHIFT_DETAILS', []);

          TALLYAPI.getAllShifts().then(
            response => {
              if (response.data.status) {
                if (response.data.data.length > 0) {
                  commit('TALLY_SHIFT_DETAILS', response.data.data);
                  commit('FIRST_LOADER', false);
                  commit('NO_DATA_FOUND', false);
                } else {
                  commit('NO_DATA_FOUND', true);
                  commit('FIRST_LOADER', false);
                }
              } else {

              }

              resolve()
            }
          ).catch(e => {
            commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
            commit('SUCCESS_MESSAGE', '');
            commit('SUCCESS_ALERT', false);
            commit('ERROR_ALERT', true);
            commit('FIRST_LOADER', false);

            reject(e)
          })
        })
      }

    },

    loadClerkInShift({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        TALLYAPI.getClerksInShift(getters.getShiftId).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('FIRST_LOADER', false);
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

    loadClerkInShiftOnDay({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', []);

        TALLYAPI.getClerksInShift(getters.getShiftId , getters.getShiftDay).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('FIRST_LOADER', false);
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

    createShift({commit, getters}) {
      commit('MY_LOADER', true);

      return new Promise((resolve, reject) => {
        TALLYAPI.CreateShift(({
          startTime: getters.getStartTime,
          endTime: getters.getEndTime,
          name: getters.getShiftName
        })).then(response => {
            if (response.data.status) {
              commit('ERROR_MESSAGE', '');
              commit('SUCCESS_MESSAGE', ', Tally Shift has been created');
              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false);
              commit('MY_LOADER', false)
            } else {

            }

            resolve(response)
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e)
        })
      })
    },

    editShift({commit, getters}) {
      return new Promise((resolve, reject) => {
        TALLYAPI.editShift(({
          startTime: getters.getStartTime,
          endTime: getters.getEndTime,
          name: getters.getShiftName,
          id: getters.getShiftId
        })).then(
          response => {
            if (response.data.status) {
              commit('ERROR_MESSAGE', '');
              commit('SUCCESS_MESSAGE', ', Tally Shift has been edited');
              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false)
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);

          reject(e)
        })
      })
    },

    deleteShift({commit, getters}) {
      return new Promise((resolve, reject) => {
        TALLYAPI.deleteShift(getters.getShiftId).then(
          response => {
            if (response.data.status) {
              commit('ERROR_MESSAGE', '');
              commit('SUCCESS_MESSAGE', ', Tally Shift has been deleted');
              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false)
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);

          reject(e)
        })
      })
    },

    // for LOADING & DISCHARGE OPERATIONS
    downloadTallySheetReport({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('MY_LOADER', true);
        TALLYAPI.downloadTallySheet(getters.getReportSearchDto).then(
          response => {
            if (response.status === 200) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'TALLY-SHEET-FOR' + getters.getReportName + '.pdf');
              document.body.appendChild(link);
              link.click();

              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false);
              commit('MY_LOADER', false);

            } else if (response.status === 204) {

              commit('ERROR_ALERT', true);
              commit('SUCCESS_ALERT', false);
              commit('MY_LOADER', false);

            } else {

              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', false);
              commit('MY_LOADER', false);

            }

            resolve()
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e)

        })

      })

    },

    downloadVesselPerformanceReport({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('MY_LOADER', true);

        TALLYAPI.downloadVesselPerformance(getters.getReportSearchDto).then(
          response => {

            if (response.status === 200) {

              commit('MY_LOADER', false);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'VESSEL-PERFORMANCE-REPORT-FOR' + getters.getReportName + '.pdf');
              document.body.appendChild(link);
              link.click();

              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false);
              commit('MY_LOADER', false);

            } else if (response.status === 204) {

              commit('ERROR_ALERT', true);
              commit('SUCCESS_ALERT', false);
              commit('MY_LOADER', false);

            } else {

              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', false);
              commit('MY_LOADER', false);

            }

            resolve();
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ", Requests Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e);

        });

      });
    },

    downloadTallyDailyReport({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('MY_LOADER', true);

        TALLYAPI.downloadTallyDaily(getters.getReportSearchDto).then(
          response => {
            if (response.status === 200) {
              commit('MY_LOADER', false);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'TALLY-DAILY-REPORT-FOR' + getters.getReportName + '.pdf');
              document.body.appendChild(link);
              link.click();

              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false);
              commit('MY_LOADER', false);

            } else if (response.status === 204) {

              commit('ERROR_ALERT', true);
              commit('SUCCESS_ALERT', false);
              commit('MY_LOADER', false);

            } else {

              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', false);
              commit('MY_LOADER', false);

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e)
        })
      })
    },

    downloadTallyShiftReport({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('MY_LOADER', true);

        TALLYAPI.downloadTallyShift(getters.getReportSearchDto).then(
          response => {
            if (response.status === 200) {
              commit('MY_LOADER', false);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'TALLY-SHIFT-REPORT-FOR' + getters.getReportName + '.pdf');
              document.body.appendChild(link);
              link.click();

              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false);
              commit('MY_LOADER', false);

            } else if (response.status === 204) {

              commit('ERROR_ALERT', true);
              commit('SUCCESS_ALERT', false);
              commit('MY_LOADER', false);

            } else {

              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', false);
              commit('MY_LOADER', false);

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('MY_LOADER', false);

          reject(e)
        })
      })
    }
    },

  getters: {

    getTallyShiftDetails: state => state.tallyShiftDetails,

    getOperationLocation: state => state.operationLocation,

    getOperationType: state => state.operationType,

    getReportSearchDto: state => state.reportSearchDto,

    getRequestList: state => state.requests,

    getTalliedDocumentId: state => state.talliedDocumentId,

    getPayloadDate: state => state.payloadDate,

    getCargoType: state => state.cargoType,

    getReportName: state => state.reportName,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getShiftName: state => state.name,

    getShiftId: state => state.shiftId,

    getShiftDay: state => state.day,

    getStartTime: state => state.startTime,

    getEndTime: state => state.endTime,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getMyLoader: state => state.myLoader

  }

}
