import API_REVENUE from '../../../api/rev'

export default {

  namespaced: true,

  state: {

    requests: [],
    noDataFound: false,
    myFirstLoader: false,
    totalElements: '',
    totalPages: '',
    pageNumber: '',
    isFirst: false,
    isLast: false,
    pageNo: 0,
    pageSize: 15,

  },

  mutations: {

    REQUEST_LIST: (state, requests) => state.requests = requests,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    TOTAL_ELEMENTS: (state, totalElements) => state.totalElements = totalElements,

    TOTAL_PAGES: (state, totalPages) => state.totalPages = totalPages,

    PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,

    IS_FIRST: (state, isFirst) => state.isFirst = isFirst,

    IS_LAST: (state, isLast) => state.isLast = isLast,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

  },

  actions: {

    requestlisting: ({state, commit}, payload) => commit('REQUEST_LIST', payload),

    pageNumbering: ({state, commit}, payload) => commit('PAGE_NO', payload),

    pageSizing: ({state, commit}, payload) => commit('PAGE_SIZE', payload),

    loadPageableRequestOnCreated({commit , getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true);
        commit('REQUEST_LIST', [])

        API_REVENUE.getBillReports().then(
          response => {

            if (response.data.status) {

              if (response.data.data.content.length > 0) {

                commit('REQUEST_LIST', response.data.data.content);
                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);

              } else {

                commit('TOTAL_ELEMENTS', response.data.data.totalElements);
                commit('TOTAL_PAGES', response.data.data.totalPages);
                commit('PAGE_NUMBER', response.data.data.number);
                commit('IS_FIRST', response.data.data.first);
                commit('IS_LAST', response.data.data.last);
                commit('NO_DATA_FOUND', true);
                commit('FIRST_LOADER', false);

              }

            } else {

            }

            resolve()
          }
        ).catch(e => {

          commit('FIRST_LOADER', false);

          reject(e)
        })
      })

    }

  },

  getters: {

    getRequestList: state => state.requests,

    getNoDataFound: state => state.noDataFound,

    getMyFirstLoader: state => state.myFirstLoader,

    getTotalElement: state => state.totalElements,

    getTotalPage: state => state.totalPages,

    getPageNumber: state => state.pageNumber,

    getIsFirst: state => state.isFirst,

    getIsLast: state => state.isLast,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

  }

}
