<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <router-view />
    <notifications group="session" id="session-expiry" position="bottom right" class="my-vue-notification"></notifications>
    <notifications group="connection" id="connection-lost" position="bottom right" class="my-vue-notification"></notifications>
    <notifications group="response" id="response-lost" position="top center" class="my-vue-notification"></notifications>
    <notifications animation-name="v-fade-left" group="response2" position="top right">

      <template slot="body" slot-scope="props">
        <div class="custom-template"  v-if="props.item.type === 'success'">
          <div class="custom-template-icon">
            <font-awesome-icon icon="check-circle" size="sm" v-if="props.item.type === 'success'"></font-awesome-icon>
          </div>
          <div class="custom-template-content">
            <div class="custom-template-title">
              {{props.item.title}}
            </div>
            <div class="custom-template-text" v-html="props.item.text"></div>
          </div>
          <div @click="props.close" class="custom-template-close">
            <i class="icon ion-android-close"></i>
          </div>
        </div>
        <div class="custom-template warning-template" v-if="props.item.type === 'warning'">
          <div class="custom-template-icon">
            <font-awesome-icon icon="exclamation" size="sm" v-if="props.item.type === 'warning'"></font-awesome-icon>
          </div>
          <div class="custom-template-content">
            <div class="custom-template-title">
              {{props.item.title}}

            </div>
            <div class="custom-template-text" v-html="props.item.text"></div>
          </div>
          <div @click="props.close" class="custom-template-close">
            <i class="icon ion-android-close"></i>
          </div>
        </div>
        <div class="custom-template error-template" v-if="props.item.type === 'error'">

          <div class="custom-template-icon">
            <font-awesome-icon icon="exclamation-triangle" size="sm" v-if="props.item.type === 'error'"></font-awesome-icon>
          </div>
          <div class="custom-template-content">
            <div class="custom-template-title">
              {{props.item.title}}

            </div>
            <div class="custom-template-text" v-html="props.item.text"></div>
          </div>
          <div @click="props.close" class="custom-template-close">
            <i class="icon ion-android-close"></i>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss">
  .vue-notifyjs.notifications {
    .list-move {
      transition: transform 0.3s, opacity 0.4s;
    }

    .list-item {
      display: inline-block;
      margin-right: 10px;

    }

    .list-enter-active {
      transition: transform 0.2s ease-in, opacity 0.4s ease-in;
    }

    .list-leave-active {
      transition: transform 1s ease-out, opacity 0.4s ease-out;
    }

    .list-enter {
      opacity: 0;
      transform: scale(1.1);

    }

    .list-leave-to {
      opacity: 0;
      transform: scale(1.2, 0.7);
    }
  }


  .custom-template {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 100px 5px 0;

    align-items: center;
    justify-content: center;

    &, & > div {
      box-sizing: border-box;
    }

    border-radius: 2px;
    background-color: #d4edda;
    /*border-color: #c3e6cb;*/
    border: 2px solid #D0F2E1;
    /*background: #E8F9F0;*/
    /*border: 2px solid #D0F2E1;*/


    .custom-template-icon {
      flex: 0 1 auto;
      color: #15C371;
      font-size: 32px;
      padding: 0 10px;
    }

    .custom-template-close {
      flex: 0 1 auto;
      padding: 0 20px;
      font-size: 16px;
      opacity: 0.2;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .custom-template-content {
      padding: 10px;
      flex: 1 0 auto;

      .custom-template-title {
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
      }
    }

    .v-fade-left-enter-active,
    .v-fade-left-leave-active,
    .v-fade-left-move {
      transition: all .5s;
    }

    .v-fade-left-enter,
    .v-fade-left-leave-to {
      opacity: 0;
      transform: translateX(-500px) scale(0.2);
    }

  }
  .error-template {
    background-color: #FF9595 !important;
    /*color: #06101B;*/
    border: 2px solid rgba(255, 170, 170, 80) !important;


    .custom-template-icon {
      flex: 0 1 auto;
      color: #06101B !important;
      font-size: 32px;
      padding: 0 10px;
    }

  }

  .warning-template {
    background-color: #FFC517 !important;
    border: 2px solid rgba(255, 206, 59, 80);
    .custom-template-icon {
      flex: 0 1 auto;
      color: #06101B !important;
      font-size: 32px;
      padding: 0 10px;
    }
  }

  .success-template {
    color: #28a745 !important;

    .custom-template-icon {
      flex: 0 1 auto;
      color: #3ACF5D !important;
      font-size: 32px;
      padding: 0 10px;
    }
  }

  .vue-notification-group {
    width: auto !important;
  }

  .response-lost {
    z-index: 5920;
    color:blue;
  }

  .my-vue-notification {
    
    z-index: 9999 !important;
    // padding: 10px;
    // margin: 0 5px 5px;
  
    // font-size: 12px;
  
    // color: #ffffff;
    // background: #44A4FC;
    // border-left: 5px solid #187FE7;
  
    // &.warn {
    //   background: #ffb648;
    //   border-left-color: #f48a06;
    // }
  
    // &.error {
    //   background: #E54D42;
    //   border-left-color: #B82E24;
    // }
  
    // &.success {
    //   background: #68CD86;
    //   border-left-color: #42A85F;
    // }
  }
</style>
