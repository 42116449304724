import DC_API from '../../../api/dc'
import AGENCY_APi from "../../../api/agency";

export default {
  namespaced: true,
  state: {
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',

    pageNo: 0,
    pageSize: 15,
    queryParam: '',

    manifestId: '',
    noDataFound: false,
    manifestDetails: {},
    manifestList: [],
    pdfNaming: '',
    myFirstLoader: '',
    documentType: '',
  },
  mutations: {

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    QUERY_PARAM: (state, queryParam) => state.queryParam = queryParam,

    MANIFEST_ID: (state, manifestId) => state.manifestId = manifestId,

    MANIFEST_DETAILS: (state, manifestDetails) => state.manifestDetails = manifestDetails,

    MANIFEST_LIST: (state, manifestList) => state.manifestList = manifestList,

    NAMING_DETAILS: (state, pdfNaming) => state.pdfNaming = pdfNaming,

    DOCUMENT_TYPE: (state, documentType) => state.documentType = documentType,
  },
  actions: {
    pageNumber: ({state, commit}, payload) => commit('PAGE_NO', payload),

    pageSize: ({state, commit}, payload) => commit('PAGE_SIZE', payload),

    queryingParam: ({state, commit}, payload) => commit('QUERY_PARAM', payload),

    manifestIdValue: ({state, commit}, payload) => commit('MANIFEST_ID', payload),

    namingDetails: ({state, commit}, payload) => commit('NAMING_DETAILS', payload),

    documentType:  ({state, commit}, payload) => commit('DOCUMENT_TYPE', payload),

    loadManifestCargoPageable({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('MANIFEST_LIST', []);

        DC_API.listAllPageableManifests(getters.getPageNo, getters.getPageSize, getters.getQueryParam, getters.getDocumentType).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('MANIFEST_LIST', response.data.data);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('MANIFEST_LIST', []);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', true);
              }
            } else {
              commit('MANIFEST_LIST', []);
              commit('FIRST_LOADER', false);
              commit('NO_DATA_FOUND', true);
              commit('ERROR_ALERT', true)
            }
            resolve()
          }
        ).catch(e => {
          commit('MANIFEST_LIST', []);
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e);
        })
      })
    },

    getDocumentDetailsByDocumentId({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('MANIFEST_DETAILS', {});

        DC_API.getManifestDetailsById(getters.getManifestId).then(
          response => {
            console.log("response", response)
            if (response.data.status) {

              if (Object.keys(response.data.data).length > 0) {
                commit('MANIFEST_DETAILS', response.data.data);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('MANIFEST_DETAILS', {});
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', true);
              }

            } else {

              commit('MANIFEST_DETAILS', {});
              commit('FIRST_LOADER', false);
              commit('NO_DATA_FOUND', true);
              commit('ERROR_ALERT', true)

            }
            resolve()
          }
        ).catch(e => {
          
          commit('FIRST_LOADER', false)

          reject(e);
        })
      })

    },

    printEDIDocument ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        DC_API.downloadManifestExcel(getters.getManifestId).then(
          response => {
            this.myloader = false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', getters.getNamingDetails + '.xls');
            document.body.appendChild(link);
            link.click();

            commit('SUCCESS_ALERT', true);
            commit('ERROR_ALERT', false);
            commit('FIRST_LOADER', false);

            resolve();
          }
        ).catch(e => {
          console.log(e)
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },


    uploadApprovedManifest({commit, getters}){
      return new Promise((resolve, reject) => {
        commit ('MY_LOADER', true)
        console.log('Submiting Approved Manifest to TESWS')

        DC_API.uploadApprovedManifestExcel(getters.getManifestId).then(
          response => {
            console.log(response)
            if (response.data.status) {
              if ( Object.keys(response.data.data).length > 0) {
                commit('ERROR_ALERT', false);
                commit('SUCCESS_ALERT', true);
                commit('FIRST_LOADER', false);
                // commit('VESSEL_DETAILS', response.data.data)
                console.log('ok')
              } else {
                console.log('not okay')
                commit('SUCCESS_ALERT', false);
                commit('FIRST_LOADER', false);
                commit('ERROR_ALERT', true);
              }

            } else {
              commit('SUCCESS_ALERT', false);
              commit('FIRST_LOADER', false);
              commit('ERROR_ALERT', true);
              console.log('not okay 2')
            }

            resolve();
          }
        ).catch(e => {

          commit('ERROR_MESSAGE', ", Requests Not loaded, please contact system administrator");
          commit('SUCCESS_MESSAGE', "");
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);

        });
      })

    }

  },
  getters: {

    getDCSuccessAlert: state => state.SuccessAlert,

    getDCErrorAlert: state => state.ErrorAlert,

    getDCMyLoader: state => state.myFirstLoader,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getQueryParam: state => state.queryParam,

    getManifestId: state => state.manifestId,

    getManifestDetails: state => state.manifestDetails,

    getManifestList: state => state.manifestList,

    getNamingDetails: state => state.pdfNaming,

    getDocumentType: state => state.documentType,
  }
}
