const shippingAgencyRoutes = [

  {
    path: '/',
    component: () => import(/* webpackChunkName: "DashboardLayout" */
      '../layout/DashboardLayout.vue'),
    meta: {
      requiresAuth: true,
      permission: 'DASHBOARD_VIEW'
    },
    children:[
      {
        path: '/overview/ship-agency/',
        name: 'OverviewSAC',
        component: () => import(/* webpackChunkName: "OverviewDc" */
          '../pages/shippingAgency/customer/dashboard'),
        // alias: '/',
        meta: {
          requiresAuth: true,
          permission: 'SA_DASHBOARD_VIEW'
        }
      },
      {
        path: '/shipping-agency/customer/',
        name: 'shipping-agency-customer',
        component: () => import(/* webpackChunkName: "TasacTarifFees" */
          '../pages/shippingAgency/subComponents/customer'),
        meta: {
          requiresAuth: true,
          //permission: 'TARIFF-FEES_LIST_{TARIFFID}_GET'
        }
      },
      {
        path: '/shipping-agency/cust/requests',
        name: 'shipping-agency-customerRequests',
        component: () => import(/* webpackChunkName: "TasacTarifFees" */
          '../pages/shippingAgency/customer/Customer'),
        meta: {
          requiresAuth: true,
          //permission: 'TARIFF-FEES_LIST_{TARIFFID}_GET'
        }
      }

    ]

  },
];

export default shippingAgencyRoutes;
