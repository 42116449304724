export default {

  namespaced: true,

  state: {

    pageName: ''

  },

  mutations: {

    PAGE_NAME: (state, pageName) => state.pageName = pageName

  },

  actions: {

    currentPageName: ({ state, commit }, payload) => commit('PAGE_NAME', payload)

  },

  getters: {

    getCurrentPageName: state => state.pageName

  }

}
