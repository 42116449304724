import API from '../../../api'

export default {

  namespaced: true,

  state: {

    userProfile: {},
    finalUserPermissions: [],
    loginPending: false,
    username: '',
    password: ''

  },

  mutations: {

    UPDATE_DATA: (state, userProfile) => state.userProfile = userProfile,

    UPDATE_PERMISSION: (state, finalUserPermissions) => state.finalUserPermissions = finalUserPermissions,

    LOGIN_PROGRESS: (state) => state.loginPending = true,

    LOGIN_SUCCESS: (state) => state.loginPending = false,

    UPDATE_PASSWORD: (state, password) => state.password = password,

    UPDATE_USERNAME: (state, username) => state.username = username

  },

  actions: {

    loggedInUserPermissions: ({ state, commit }, payload) => commit('UPDATE_PERMISSION', payload),

    loggedInUserData: ({ state, commit }, payload) => commit('UPDATE_DATA', payload),

    logout () {
      // localStorage.removeItem('appToken');
      if (window.$cookies.isKey('token')) {
        window.$cookies.remove('token')
      }

      if (window.$cookies.isKey('expires_in')) {
        window.$cookies.remove('expires_in')
      }

      if (window.$cookies.isKey('refresh_token')) {
        window.$cookies.remove('refresh_token')
      }
    },

    login ({ state, commit }) {
      commit('LOGIN_PROGRESS')

      return new Promise((resolve, reject) => {
        API.getCustomerToken(state.username, state.password)

          .then(res => {
            API.authenticate
            (
              res.data.access_token,

              res.data.refresh_token,

              res.data.expires_in

            )

            commit('LOGIN_SUCCESS')

            resolve()
          })
          .catch(err => {
            if (err.response != null) {

            }

            reject(err)
          })
      })
    }

  },

  getters: {

    loginPending: (state) => state.loginPending,

    getUserDetails: (state) => state.userProfile,

    getUserPermissions: (state) => state.finalUserPermissions,

    getUsername: (state) => state.password,

    getPassword: (state) => state.username

  }

}
