/* import axios */
import axios from 'axios'
import { REVENUE_SERVER_URL } from "../api/settings";

/* axios default headers for the below initiated instances */
axios.defaults.headers['access-control-allow-Origin'] = '*'
axios.defaults.headers['Access-Control-Allow-Methods'] = 'POST, PUT, GET, OPTIONS, DELETE'
axios.defaults.headers['Access-Control-Allow-Credentials'] = true
axios.defaults.headers['Cache-Control'] = 'no-cache'
axios.defaults.timeout = 120 * 1000 //  120 seconds
axios.defaults.headers['Set-Cookie'] = 'SameSite=Strict'

/* axios instance for revenue microservice server */
const HTTP_REVENUE = axios.create({

    'X-Requested-With': 'XMLHttpRequest',

    baseURL: REVENUE_SERVER_URL,

    headers: {

      'Content-Type': 'application/json'

    }

  });


export default {

  handle401 (success, error) {

    return HTTP_REVENUE.interceptors.response.use(success, error)

  },

  authenticate (token) {

    return HTTP_REVENUE.defaults.headers.common.Authorization = 'Bearer ' + token

  },

  isAuthenticated (token) {

    return HTTP_REVENUE.defaults.headers.common.Authorization = 'Bearer ' + token
  },

  generateBillForTalliedCharges (talliedDocumentId, operationType) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/tally-bill/' + talliedDocumentId + '/' + operationType)

  },

  generateBillForTallySheetCharges (talliedDocumentId, operationType, comment) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/tally-bill/' + talliedDocumentId + '/' + operationType + '?comment=' + comment)

  },

  approveTallyBillToGePG (billId) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/approve/' + billId);

  },

  getTallyBillsForApproval (billType , status , size, number) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + "/bills/all-type-pageable?billType=" + billType + "&billStatus=" + status + "&size=" + size + "&page=" + number);

  },

  generateBillForShippingOrderCharges (shippingOrderId) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/shipping-order-bill/' + shippingOrderId)

  },

  generateManifestBills (documentId , comment) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/generate/' + documentId + '?comment=' + comment);

  },

  generateBilOfLadingBill(blChargeId,comment){
    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/bl-bill/' + blChargeId +'?comment=' + comment);
  },

  generateV2ManifestBills (documentId , comment) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/v2/generate/' + documentId + '?comment=' + comment);

  },

  searchBill (searchTerm , type,status) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + `/bills/search-all-bills?searchTerm=${searchTerm}&billType=${type}&billStatus=${status}`);

  },

  removeItemFromBulkBill(billId,bulkBillIds){

    return HTTP_REVENUE.post(REVENUE_SERVER_URL + `/bills/remove-bulk-items?bulkBillIds=${bulkBillIds}&billId=${billId}`);

  },

  editBulkBillItemDescription(billIdSub,description){

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + `/bills/amend-bulk-item-description/${billIdSub}?description=${description}`);

  },

  getBulkBillItems(billId){

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + `/bills/bulk-bill-items/${billId}`);

  },

  extendBillExpireTime (billId, addedDays) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/resubmitExpired/' + billId + '/' + addedDays)

  },

  getAllRate () {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/tariff-rates/get-all/')

  },

  getIndividualBill (billId) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/get/' + billId)

  },

  downloadBillInvoice (billId) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/get-invoice-pdf/' + billId,

      {

        responseType: 'blob',

        headers: {
          Accept: 'application/pdf'
        }

      })

  },

  downloadAgedReceivable (customerId, billType) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/reports/aged-receivable?customerId=' + customerId + '&billType=' + billType,

      {

        responseType: 'blob',

        headers: {
          Accept: 'application/octet-stream'
        }

      })

  },

  getPageableCustomerCFABills (pageNo, pageSize, params) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/all-cfa-customer-pageable-bills?pageNo=' + pageNo + '&pageSize=' + pageSize + params)

  },

  getExchangeRate () {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/exchange/all')

  },
  postExchangeRate (data) {

    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/exchange/config', data)

  },
  deleteExchangeRate (data) {

    return HTTP_REVENUE.delete(REVENUE_SERVER_URL + '/exchange/config/' + data)

  },
  updateExchangeRate (data) {

    return HTTP_REVENUE.put(REVENUE_SERVER_URL + '/exchange/config', data)

  },
  getIndividualExchangeRate (data) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/exchange/config/' + data)

  },

  saveTariffees (data) {

    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/tariff-fees/create', data)

  },
  getTariffFeesById (tariffeeId) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/tariff-fees/get/' + tariffeeId)

  },
  getTariffFees (tariffId) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/tariff-fees/list/' + tariffId)

  },
  editTariffee (data) {

    return HTTP_REVENUE.put(REVENUE_SERVER_URL + '/tariff-fees/update', data)

  },
  saveTariffRule (data) {

    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/tariff-rules/create', data)

  },
  editTariffRule (data) {

    return HTTP_REVENUE.put(REVENUE_SERVER_URL + '/tariff-rules/update', data)

  },
  getTariffRuleByFee (data) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/tariff-rules/list-by-fee/' + data)

  },
  saveTariff (data) {

    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/tariffs/create', data)

  },
  editTariff (data) {

    return HTTP_REVENUE.put(REVENUE_SERVER_URL + '/tariffs/update', data)

  },
  getTariffs () {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/tariffs/list')

  },
  getTariffByTarifId (tariffId) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/tariffs/get/' + tariffId)

  },
  saveTariffItems (data) {

    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/tariff-items/create', data)

  },
  getTariffItems () {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/tariff-items/list')

  },
  getTariffItemsActive () {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/tariff-items/list-active')

  },
  getTariffItemsActiveByService (flag) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/tariff-items/list-active?tariffFlag=' + flag)

  },
  editTariffItems (data) {

    return HTTP_REVENUE.put(REVENUE_SERVER_URL + '/tariff-items/update', data)

  },
  saveTariffRate (data) {

    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/tariff-rates/create', data)

  },
  editTariffRates (data) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/tariff-rates/get/' + data)

  },
  getTariffItemsById (data) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/tariff-items/get/' + data)

  },
  getActiveTariffFee () {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/tariff-fees/list-active')

  },

  getBillTransactions (billId) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/transactions/' + billId)

  },

  cancelCfaBill (billId, reason) {

    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/bills/cancel?billId=' + billId + '&description=' + reason)

  },

  fiscalize (billId) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/submit-efd/' + billId)

  },

  resubmitCFABill (billId) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/resubmit/' + billId)

  },

  ammendBillDescription (billSubItemId, billId, description) {

    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/bills/cfa-bill/ammend-description?billSubItemId=' + billSubItemId + '&billId=' + billId + '&description=' + description)

  },

  getBillsPageable (pageNo, pageSize, params) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/reports/bill-report-pageable?pageNo=' + pageNo + '&pageSize=' + pageSize + params)

  },

  getBillNotifications (params) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/notifications/sent/pageable?' + params)

  },

  getReceipt (billId) {
    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/get-receipt-pdf/' + billId,
      {

        responseType: 'blob',

        headers: {
          Accept: 'application/pdf'
        }

      })
  },

  getBillByRequestId(requestId){
    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/cfa-bill-model/' + requestId);
  },

  generateCFABill (requestId, billComment,pbpaAmount) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/cfa-bill/' + requestId + '?comment=' + billComment + '&amount=' + pbpaAmount)

  },

  approveCFABill (requestId) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/approve/' + requestId)

  },

  downloadMatrixBillInvoice (billId) {
    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/get-invoice-pdf-dot-matrix/' + billId,

      {

        responseType: 'blob',

        headers: {
          Accept: 'application/pdf'
        }

      })
  },

  getBillReports() {
    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/reports/all');
  },

  geDocumentChargeDetails (documentId) {

    return HTTP_REVENUE.get(SERVER_URL + '/document-charge/get/' + documentId);

  },

  getChargesItemByRequestId (requestId, isCfa) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/get-bill-items-iscfa/' + requestId + '?isCfa=' + isCfa)

  },

  searchGeneral(params) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/search/bills' + params)

  },

  getRevenueTrendPerYearForDashboard (year) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/dashboard/' + year)

  },

  generateCFABulkCharges (bulkBillObject) {
    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/bills/cfa-bulk-bill/', bulkBillObject,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  generateDCCustomerBulkCharges (bulkBillObject) {
    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/bills/dc-bulk-bill/', bulkBillObject,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  getTotalRevenueDcPlusCfaAllYears (status) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bill-report/totalRevenueDcPlusCfaAllYears/' + status)

  },
  getTotalBillRevenueForYear (year, status, isCfa) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bill-report/totalForYear/' + year + '/' + status + '/' + isCfa)

  },
  getTotalBillRevenueYearly (status) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bill-report/totalYearly/' + status)

  },
  getTotalBillRevenueForStatusAndIsCfa (status, isCfa) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bill-report/totalRevenueFor/' + status + '/' + isCfa)

  },
  getTotalBillsMonthlyForYear (year, status, isCfa) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bill-report/totalMonthlyForYear/' + year + '/' + status + '/' + isCfa)

  },
  getTotalBillsGroupedMonthlyForYear (year, tariffItemId, status, isCfa) {

    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bill-report/totalGroupedMonthlyForYear/' + year + '/' + tariffItemId + '/' + status + '/' + isCfa)

  },

  generateRevenueBillingReport (params) {
    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/reports/bill-report' + params,

      {

        headers: {
          'Content-Type': 'application/json'
        }
      }
    )},

  generateRevenueVatBillingReportRevised (params) {
    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/reports/vat' + params, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    })
  },

  generateRevenueNonVatBillingReportRevised (params) {
    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/reports/report' + params,{
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream'
    }
  })
},

  generateAsyncVatReport (params) {

    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/reports/async/vat', params, {

      headers: {

        'Content-Type': 'application/json'

      }

    })

  },

  generateAsyncBillReport (params) {

    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/reports/async/bill', params, {

      headers: {

        'Content-Type': 'application/json'

      }

    })

  },

  generateAsyncRevenueReport (params) {

    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/reports/async/revenue', params,{

      headers: {

        'Content-Type': 'application/json'

      }

    })

  },

  uploadRevenueExcelReport (formData) {
    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/reports/upload-bill-report', formData)
  },

  getCustomerStatement(data){
    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/statement/get', data)
  },

  setInitialBalance(customerId,amount){
    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/statement/set?customerId='+ customerId + '&amount=' + amount)
  },

  confirmPbpa(vessel){
    return HTTP_REVENUE.get(REVENUE_SERVER_URL + '/bills/pbpa-description/'+ vessel);
  },

  printCustomerStatement(data){
    return HTTP_REVENUE.post(REVENUE_SERVER_URL + '/statement/pdf', data,

      {

        responseType: 'blob',

        // headers: {
        //   Accept: 'application/pdf'
        // }

      })

  },

}

