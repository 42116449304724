<template>
  <div>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <button
            type="button"
            class="close"
            @click="$emit('close')"
          >
            <span aria-hidden="true">×</span>
          </button>
          <div class="modal-header">
            <slot name="header">
              Modal Header
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              Modal Body
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  data () {
    return {
      showModal: false
    }
  }
}
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
button.close {
  padding: 0;
  background-color: #000;
  border: 0;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  margin: -2.2rem -2.9rem -1rem auto;
  opacity: 1;
  color: #dc3545;
  text-shadow: none;
  transition: all 0.3s;
}
button.close:hover {
  color: #000;
  background-color: #dc3545;
  opacity: 1;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
