const routes = [

  {
    path: '/',
    component: () => import(/* webpackChunkName: "DashboardLayout" */
      '../layout/DashboardLayout.vue'),
    meta: {
      requiresAuth: true,
      permission: 'DASHBOARD_VIEW'
    },
    children: [
      {
        path: '/clearing-and-forwarding/applications',
        name: 'ClearingRequest',
        component: () => import(/* webpackChunkName: "ClearingRequest" */
          '../pages/clearingAndForwarding/subComponents/ClearingRequest'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_CUSTOMER-REQUESTS_{CUSTOMERID}_GET'
        }
      },
      {
        path: '/clearing-and-forwarding/applications/:requestId',
        name: 'ClearingRequest2',
        component: () => import(/* webpackChunkName: "ClearingRequest" */
          '../pages/clearingAndForwarding/subComponents/ClearingRequest'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_CUSTOMER-REQUESTS_{CUSTOMERID}_GET'
        }
      },
      {
        path: '/overview/customer',
        name: 'OverviewCustomer',
        component: () => import(/* webpackChunkName: "OverviewCustomer" */
          '../pages/OverviewCustomer'),
        // alias: '/',
        meta: {
          requiresAuth: true,
          permission: 'CUSTOMER_DASHBOARD_VIEW'
        }
      },

      {
        path: '/clearing-and-forwarding/:status-application-list',
        name: 'MyApplicationList',
        component: () => import(/* webpackChunkName: "MyApplicationList" */
          '../pages/clearingAndForwarding/customer/applicationList.vue'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'
        }
      },

      {
        path: '/clearing-and-forwarding/application-details/:requestId',
        name: 'CustomerApplicationDetails',
        component: () => import(/* webpackChunkName: "MyApplication" */
          '../pages/clearingAndForwarding/customer/application.vue'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'
        },
        props: true
      },

      {
        path: '/clearing-and-forwarding/my-bill-list',
        name: 'MyBillList',
        component: () => import(/* webpackChunkName: "MyBillList" */
          '../pages/clearingAndForwarding/customer/billList.vue'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'
        }
      },

      {
        path: 'clearing-and-forwarding/application-bill-view/:billId',
        name: 'MyBillDetails',
        component: () => import(/* webpackChunkName: "ApplicationsCBills" */
          '../pages/clearingAndForwarding/customer/bill.vue'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'
        }
      },

      {
        path: 'clearing-and-forwarding/customer/new-application',
        name: 'NewApplication',
        component: () => import(/* webpackChunkName: "NewApplication" */
          '../pages/clearingAndForwarding/customer/newApplication.vue'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'
        },
        props: true

      },

      {
        path: 'clearing-and-forwarding/edit-application/:requestId',
        name: 'EditApplication',
        component: () => import(/* webpackChunkName: "EditApplication" */
          '../pages/clearingAndForwarding/customer/editApplication.vue'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'
        },
        // props: true

      },

      {
        path: 'clearing-and-forwarding/additional-attachment-for-application/:requestId',
        name: 'additionalAttachmentToApplication',
        component: () => import(/* webpackChunkName: "additionalAttachmentToApplication" */
          '../pages/clearingAndForwarding/customer/additionalAttachmentToApplication.vue'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'
        },
        // props: true

      },

      {
        path: 'clearing-and-forwarding/new-application-for-customer',
        name: 'TASACNewApplication',
        component: () => import(/* webpackChunkName: "TASACNewApplication" */
          '../pages/clearingAndForwarding/subComponents/newApplication.vue'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'
        },
        props: true

      },

      {
        path: '/clearing-and-forwarding/application/conversion/:requestId',
        name: 'CustomerChat',
        component: () => import(/* webpackChunkName: "CustomerChat" */
          '../pages/clearingAndForwarding/customer/chat.vue'),
        meta: {
          requiresAuth: true,
          permission: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'
        },
      },

    ]
  }
]

export default routes
