const routes = [

  {
    path: '/',
    component: () => import(/* webpackChunkName: "DashboardLayout" */
      '../layout/DashboardLayout.vue'),
    meta: {
      requiresAuth: true,
      permission: 'DASHBOARD_VIEW'
    },
    children: [
      {
        path: '/admin/overview',
        name: 'OverviewAdmin',
        component: () => import(/* webpackChunkName: "OverviewAdmin" */
          '../pages/OverviewAdmin'),
        // alias: '/',
        meta: {
          requiresAuth: true,
          permission: 'DASHBOARD_VIEW'
        }
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "Users" */
          '../pages/user/AddUser'),
        meta: {
          requiresAuth: true,
          permission: 'USERS_ALLUSERS_GET'
        }
      },
      {
        path: '/customers',
        name: 'Customer',
        component: () => import(/* webpackChunkName: "Customer" */
          '../pages/clearingAndForwarding/subComponents/Customer'),
        meta: {
          requiresAuth: true,
          permission: 'CUSTOMERS_ALL-PAGEABLE_GET'
        }
      },
      {
        path: '/roles',
        name: 'Roles',
        component: () => import(/* webpackChunkName: "Roles" */
          '../pages/user/AddRole'),
        meta: {
          requiresAuth: true,
          permission: 'ROLES_ALL_PAGEABLE_GET'
        }
      }

    ]
  }
]

export default routes
