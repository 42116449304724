import TALLYAPI from '../../../api/tally'

export default {

  namespaced: true,

  state: {

    requests: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    myLoader: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    searchParameter: '',
    resourceId: '',

    startTime: '',
    endTime: '',
    name: '',
    shiftId: '',
    day: '',
    cargoType: "",
    talliedDocumentId: '',
    payloadDate: '',
    reportName: ''

  },

  mutations: {

    REQUEST_LIST: (state, requests) => state.requests = requests,

    SHIFT_ID: (state, shiftId) => state.shiftId = shiftId,

    SHIFT_DAY: (state, day) => state.day = day,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    TALLIED_DOC_ID: (state, talliedDocumentId) => state.talliedDocumentId = talliedDocumentId,

    RESOURCE_ID: (state, resourceId) => state.resourceId = resourceId,

    CARGO_TYPE: (state, cargoType) => state.cargoType = cargoType,

    REPORT_NAME: (state, reportName) => state.reportName = reportName,

    PAYLOAD_DATE: (state, payloadDate) => state.payloadDate = payloadDate,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    START_TIME: (state, startTime) => state.startTime = startTime,

    END_TIME: (state, endTime) => state.endTime = endTime,

    SHIFT_NAME: (state, name) => state.name = name,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert

  },

  actions: {

    starttiming: ({state, commit}, payload) => commit('START_TIME', payload),

    endtiming: ({state, commit}, payload) => commit('END_TIME', payload),

    reportnaming: ({state, commit}, payload) => commit('REPORT_NAME', payload),

    tallieddocumentiding: ({state, commit}, payload) => commit('TALLIED_DOC_ID', payload),

    resourceiding: ({state, commit}, payload) => commit('RESOURCE_ID', payload),

    cargotyping: ({state, commit}, payload) => commit('CARGO_TYPE', payload),

    patloaddating: ({state, commit}, payload) => commit('PAYLOAD_DATE', payload),

    shiftnaming: ({state, commit}, payload) => commit('SHIFT_NAME', payload),

    shiftiding: ({state, commit}, payload) => commit('SHIFT_ID', payload),

    shiftdaying: ({state, commit}, payload) => commit('SHIFT_DAY', payload),

    successalerting: ({state, commit}, payload) => commit('SUCCESS_ALERT', payload),

    erroralerting: ({state, commit}, payload) => commit('ERROR_ALERT', payload),

    loadResources({commit}) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        TALLYAPI.getAllResources().then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                commit('REQUEST_LIST', response.data.data);
                commit('FIRST_LOADER', false);
                commit('NO_DATA_FOUND', false);
              } else {
                commit('NO_DATA_FOUND', true);
                commit('FIRST_LOADER', false);
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

    deleteResource({commit, getters}) {

      return new Promise((resolve, reject) => {

        commit('FIRST_LOADER', true)

        TALLYAPI.deleteResource(getters.getResourceId).then(
          response => {

            if (response.data.status) {

              commit('FIRST_LOADER', false);
              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false);

            } else {

              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('FIRST_LOADER', false);
            }

            resolve()
          }
        ).catch(e => {

          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e)
        })
      })
    },

  },

  getters: {

    getRequestList: state => state.requests,

    getTalliedDocumentId: state => state.talliedDocumentId,

    getPayloadDate: state => state.payloadDate,

    getCargoType: state => state.cargoType,

    getReportName: state => state.reportName,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getShiftName: state => state.name,

    getShiftId: state => state.shiftId,

    getShiftDay: state => state.day,

    getStartTime: state => state.startTime,

    getEndTime: state => state.endTime,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getMyLoader: state => state.myLoader,

    getResourceId: state => state.resourceId

  }

}
