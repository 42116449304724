import Vue from 'vue'

import Vuex from 'vuex'

import shifts from './modules/tally/shifts'
import resources from './modules/tally/resources'

import activities from './modules/activity/activity'

import attachments from './modules/attachment/attachment'

import attachmenttypes from './modules/attachmentType/attachmentType'

import billings from './modules/billing/billing'

import shippingtallybilling from './modules/shippingTallying/billing'

import tallybilling from './modules/shippingTallying/billing'

import customers from './modules/customer/customer'

import documenttypes from './modules/documentType/documentTypes'

import companies from './modules/company/company'

import clearingrequests from './modules/clearingAndForwardingApplications/clearingAndForwardingApplications'

import cfabilling from './modules/clearingAndForwardingApplications/billing'

import tallyNewbilling from './modules/tally/billing'

import dcbilling from './modules/documentControl/billing'

import shippingtallying from './modules/shippingTallying/shippingTally'

import talliedStuffingOrStripping from './modules/shippingTallying/talliedStuffingOrStripping'

import tallyingResources from './modules/shippingTallying/resources'

import talliedloadingordischarge from './modules/shippingTallying/talliedLoadingOrDischarge'

import tallyshift from './modules/shippingTallying/shifts'

import documents from './modules/document/document'

import global from './modules/global/global'

import report from './modules/report/report'

import permissionswithroles from './modules/role/Role'

import pagenames from './modules/pageName/pagename'

import resourcegroups from './modules/resourceGroup/resourcegroup'

import resourceplans from './modules/resourcePlan/resourceplan'

import resourceverification from './modules/Requestverification/resourceverification'

import regime from './modules/regime/regime'

import requesttypelist from './modules/requestType/requesttype'

import routes from './modules/route/route'

import sectors from './modules/sector/sector'
import stations from './modules/sector/sector'

import notifications from './modules/notification/notification'

import users from './modules/user/user'

import doattachmenttype from './modules/deliveryOrderAttachmentType/deliveryOrderAttachmentType'

import tancisdocumnets from './modules/tancisDocument/tancisDocument'

import transportmethods from './modules/transportMethod/transportmethod'

import vesseltypes from './modules/vesselType/vesseltype'

import vesselschedules from './modules/vesselSchedule/vesselschedule'

import vessels from './modules/vessel/vessel'

import login from './modules/login/login'

import customerlogin from './modules/customerLogin/login'

import loadinglists from './modules/loadinglist/approvedLoadingLists'

import disbursement from './modules/disbursment/disbursement'

import principal from './modules/principal/principal'

import vesselParticulars from './modules/vesselParticulars/vesselParticulars'

import manifest from './modules/manifest/manifest'

import deliveryOrder from './modules/deliveryOrder/deliveryOrder'

import CFAgent from './modules/CFAgent/CFAgent'

import outwardActivities from './modules/outwardActivities/outwardActivities'

import deliveryorderrequest from './modules/deliveryOrder/deliveryOrderRequests'

import edidocuments from './modules/documentControl/documentControl'

import shippingagencyserviceprovider from './modules/shippingAgencyServiceProvider/serviceProvider'

import disbursmentpaymentrequest from './modules/disbursmentPaymentRequests/paymentRequests'


import shippingOrder from './modules/shippingOrder/shippingOrder'
import agencyCharge from "./modules/agencyCharge/agencyCharge"
import tallyChargeBilling from "./modules/tally/tallyChargeBilling"
import agencyChargeList from "./modules/agencyCharge/agencyChargeList"
import tariffDetails from "./modules/billing/tariffDetails"
import tallyEvents from "./modules/tally/tallyEvents";
import vesselActivityReport from "./modules/vesselParticulars/vesselActivityReport"
import v2DocumentControl from "./modules/documentControl/v2DocumentControl"
import tallyTariffConfiguration from "./modules/tally/tallyTariffConfiguraton"

import subdisbursment from './modules/disbursment/subDisbursement'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export const store = new Vuex.Store({

  strict: debug,

  modules: {

    activities,
    attachments,
    attachmenttypes,
    billings,
    customers,
    documenttypes,
    companies,
    clearingrequests,
    documents,
    global,
    loadinglists,
    permissionswithroles,
    pagenames,
    resourcegroups,
    resourceplans,
    resourceverification,
    regime,
    requesttypelist,
    notifications,
    routes,
    sectors,
    stations,
    tancisdocumnets,
    transportmethods,
    users,
    vesseltypes,
    vesselschedules,
    vessels,
    login,
    customerlogin,
    shifts,
    resources,
    shippingtallying,
    disbursement,
    subdisbursment,
    principal,
    vesselParticulars,
    talliedStuffingOrStripping,
    talliedloadingordischarge,
    manifest,
    deliveryOrder,
    CFAgent,
    tallyshift,
    outwardActivities,
    shippingOrder,
    tallybilling,
    tallyNewbilling,
    agencyCharge,
    tallyChargeBilling,
    shippingtallybilling,
    agencyChargeList,
    tariffDetails,
    cfabilling,
    tallyEvents,
    dcbilling,
    vesselActivityReport,
    tallyingResources,
    v2DocumentControl,
    tallyTariffConfiguration,
    deliveryorderrequest,
    edidocuments,
    report,
    doattachmenttype,
    shippingagencyserviceprovider,
    disbursmentpaymentrequest
    
  }

});
