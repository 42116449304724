export default {

  namespaced: true,

  state: {

    valuesBeforeEdited: [],
    nameBeforeDeleted: '',
    AttachmentNameBeforeDeleted: '',
    idBeforeDelete: ''

  },

  mutations: {

    VALUES_BEFORE_EDIT: (state, valuesBeforeEdited) => state.valuesBeforeEdited = valuesBeforeEdited,

    NAME_BEFORE_DELETE: (state, nameBeforeDeleted) => state.nameBeforeDeleted = nameBeforeDeleted,

    ATTACHMENT_NAME_BEFORE_DELETE: (state, AttachmentNameBeforeDeleted) => state.AttachmentNameBeforeDeleted = AttachmentNameBeforeDeleted,

    ID_BEFORE_DELETE: (state, idBeforeDelete) => state.idBeforeDelete = idBeforeDelete

  },

  actions: {

    valuesBeforeEdit: ({ state, commit }, payload) => commit('VALUES_BEFORE_EDIT', payload),

    nameBeforeDelete: ({ state, commit }, payload) => commit('NAME_BEFORE_DELETE', payload),

    attachmentNameBeforeDelete: ({ state, commit }, payload) => commit('ATTACHMENT_NAME_BEFORE_DELETE', payload),

    idBeforeDelete: ({ state, commit }, payload) => commit('ID_BEFORE_DELETE', payload)

  },

  getters: {

    getValuesBeforeEdit: state => state.valuesBeforeEdited,

    getNameBeforeDelete: state => state.nameBeforeDeleted,

    getAttachmentNameBeforeDelete: state => state.AttachmentNameBeforeDeleted,

    getIdBeforeDelete: state => state.idBeforeDelete

  }

}
