import AGENCY_APi from '../../../api/agency'

export default {
  namespaced: true,

  state: {

    requestId: '',
    selectedManifest:{},
    requests: [],
    pageCount: 1,
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    pageNo: 0,
    pageSize: 15,
    myLoader: false,
    reloadAllRequest: false,
    hasSearchedVariable: false,
    searchParameter: '',
    sortBy: '',
    voyageId: '',
    queryDate: '',
    categoryId: '',
    saveDetails: {}

  },
  mutations: {

    SELECTED_MANIFEST: (state, manifest) => state.selectedManifest = manifest,

    VOYAGE_ID: (state, voyageId) => state.voyageId = voyageId,

    QUERY_DATE: (state, queryDate) => state.queryDate = queryDate,

    CATEGORY_ID: (state, categoryId) => state.categoryId = categoryId,

    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    REQUEST_LIST: (state, requests) => state.requests = requests,

    SAVE_DETAILS: (state, saveDetails) => state.saveDetails = saveDetails,

    PAGE_COUNT: (state, pageCount) => state.pageCount = pageCount,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    RELOAD_ALL_APPLICATIONS: (state, reloadAllRequest) => state.reloadAllRequest = reloadAllRequest,

    HAS_SEARCH_VARIABLE: (state, hasSearchedVariable) => state.hasSearchedVariable = hasSearchedVariable,

    SEARCH_PARAMETER: (state, searchParameter) => state.searchParameter = searchParameter,

    SORT_BY: (state, sortBy) => state.sortBy = sortBy

  },
  actions: {
    selectedManifest: ({ state, commit }, payload) => commit('SELECTED_MANIFEST', payload),

    pageNumbering: ({ state, commit }, payload) => commit('PAGE_NO', payload),

    requestStatusing: ({ state, commit }, payload) => commit('REQUEST_STATUS', payload),

    savingDetails: ({ state, commit }, payload) => commit('SAVE_DETAILS', payload),

    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),

    searchingParameter: ({ state, commit }, payload) => commit('SEARCH_PARAMETER', payload),

    sortingBy: ({ state, commit }, payload) => commit('SORT_BY', payload),

    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),

    voyageIding: ({ state, commit }, payload) => commit('VOYAGE_ID', payload),

    queryingDate: ({ state, commit }, payload) => commit('QUERY_DATE', payload),

    categoryIding: ({ state, commit }, payload) => commit('CATEGORY_ID', payload),

    reloadingRequest: ({ state, commit }, payload) => commit('RELOAD_ALL_APPLICATIONS', payload),

    getManifestByVoyageNo ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_APi.getManifestByVoyageNo(getters.getVoyageId).then(
          response => {
            if (response.data.status) {
              if (response.data.data !== null) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)

              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
              }
            } else {
              commit('NO_DATA_FOUND', true)
              commit('FIRST_LOADER', false)
            }

            resolve()
          }
        ).catch(e => {
          commit('NO_DATA_FOUND', true)
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    getManifestByDocumentId ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_APi.getManifestByDocumentId(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)

              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.data.totalPages)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    },

    getApprovedManifestByCallId ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true)
        commit('REQUEST_LIST', [])

        AGENCY_APi.getApprovedManifestByCallId(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('REQUEST_LIST', response.data.data)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)

              } else {
                commit('NO_DATA_FOUND', true)
                commit('PAGE_COUNT', response.data.data.totalPages)
                commit('FIRST_LOADER', false)
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)

          reject(e)
        })
      })
    }

  },
  getters: {
    getSelectedManifest: state => state.selectedManifest,

    getVoyageId: state => state.voyageId,

    getQueryDate: state => state.queryDate,

    getCategoryId: state => state.categoryId,

    getRequestList: state => state.requests,

    getSaveDetails: state => state.saveDetails,
    getPageCount: state => state.pageCount,

    getMyFirstLoader: state => state.myFirstLoader,

    getNoDataFound: state => state.noDataFound,

    getSuccessAlert: state => state.SuccessAlert,

    getErrorMessage: state => state.ErrorMessage,

    getErrorAlert: state => state.ErrorAlert,

    getSuccessMessage: state => state.SuccessMessage,

    getRequestId: state => state.requestId,

    getPageSize: state => state.pageSize,

    getPageNo: state => state.pageNo,

    getMyLoader: state => state.myLoader,

    getReloadAllRequest: state => state.reloadAllRequest,

    getHasSearchedVariable: state => state.hasSearchedVariable,

    getSearchParameter: state => state.searchParameter,

    getSortBy: state => state.sortBy

  }

}
