/* import axios */
import axios from 'axios'
import { AGENCY_SERVER_URL } from '../api/settings'
// Base URL for tally microservice server

/* axios default headers for the below initiated instances */
axios.defaults.headers['access-control-allow-Origin'] = '*'
axios.defaults.headers['Access-Control-Allow-Methods'] = 'POST, PUT, GET, OPTIONS, DELETE'
axios.defaults.headers['Access-Control-Allow-Credentials'] = true
axios.defaults.headers['Cache-Control'] = 'no-cache'
axios.defaults.timeout = 120 * 1000 //  120 seconds
axios.defaults.headers['Set-Cookie'] = 'SameSite=Strict'

// axios instance for the tally microservice server
const HTTP_AGENCY = axios.create({

  'X-Requested-With': 'XMLHttpRequest',

  baseURL: AGENCY_SERVER_URL,

  headers: {

    'Content-Type': 'application/json'

  }

})

// HTTP_AGENCY.defaults.headers.common.Authorization = localStorage.getItem('appToken');

export default {

  handle401(success, error) {

    return HTTP_AGENCY.interceptors.response.use(success, error)

  },

  authenticate(token) {

    HTTP_AGENCY.defaults.headers.common.Authorization = 'Bearer ' + token

  },

  isAuthenticated(token) {

    HTTP_AGENCY.defaults.headers.common.Authorization = 'Bearer ' + token

  },

  //   ***  Service Controller ***

  //   *** *** Disbursement Service Category*** ***
  addServiceCategory(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/service/category/add',

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })
  },

  getServiceCategoryById(categoryId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/service/category/get/' + categoryId)
  },

  loadDisbursementDeposits(params) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/payment/all/pageable' + params)

  },

  listAllServiceCategory(pageNo, pageSize) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/service/category/list?page=' + pageNo + '&size=' + pageSize)

  },

  updateServiceCategory(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/service/category/update',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  },

  //** FDA **//
  approveFda(subDisbursementAccountId){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/sub/generate/' + subDisbursementAccountId)

  },

  // *** *** Disbursement Service Items *** ***
  addServiceItem(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/service/item/add',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  },
  getServiceItemCategoryById(id) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/service/item/get/' + id)

  },

  getAllDisbursmentServiceItemByServiceCategoryId(categoryId) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/service/item/list/' + categoryId)

  },

  saveSubDisbursmentAccountEstmateForManyServices(data) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/disbursement/invoice/add-all?subDisbursementId=' + data.subDisbursementAccountId,
    data.object,
    {

      headers: {

        'Content-Type': 'application/json'

      }

    })

  },

  saveSubDisbursmentFinalAccountEstmateForSingleService(data) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/disbursement/final/invoice/add?subDisbursementId=' + data.subDisbursementAccountId,
      data.object,
      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  saveSubDisbursmentAccountEstmateForSingleService(data) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/disbursement/invoice/add?subDisbursementId=' + data.subDisbursementAccountId,
    data.object,
    {

      headers: {

        'Content-Type': 'application/json'

      }

    })

  },

  updateServiceItem(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/service/item/update',
      data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  // *** Refund Controller ** //
  refundAdd(subDisbursementId) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/disbursement/refund/add/' ,subDisbursementId)
  },

  getRefundById(refundId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/refund/' + refundId);
  },

  approveRefund(refundId){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/refund/approve/' + refundId);

  },

  getAllRefunds(){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/refund/all')
  },


  //     *** Principal Controller ***

  getPrincipalAccountStatement(principalId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/principal/transaction/by-account-no/' + principalId)
  },

  addPrincipalParticular(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/principal/add',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  updatePrincipalParticular(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/principal/update',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  getPrincipalAccountByAccountNo(accountNo) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/principal/account/by-account-no/' + accountNo)
  },
  getPrincipalAccountByReferenceNo(referenceNo) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/principal/account/by-reference/' + referenceNo)
  },
  listAllPrincipalAccounts() {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/principal/list?size=2000')
  },
  getPrincipalParticularById(id) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/principal/get/' + id)
  },
  getPrincipalParticularByCarrierCode(carrierCode) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/principal/get/by-code/' + carrierCode)
  },
  listAllPrincipalParticular(pageNo, pageSize) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/principal/list/pageable?size=' + pageSize + '&page=' + pageNo)

  },

  searchPrincipalParticular (data, size, page) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/principal/search?size=' + size + '&page=' + page,

      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  getDeliveryOrderRequests(params) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/request/all/search' + params)

  },

  getAllCfaAgents() {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/cf-agent/all')

  },

  //    *** Vessel Particular Controller ***
  addVesselParticular(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/vessel/add',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  editVesselParticular(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/vessel/edit',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  printVesselParticularActivityById(id) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/print/activity/' + id,
      {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob'
      })
  },

  getVesselParticularById(id) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/get/' + id)
  },
  getVesselParticularByVoyageNo(voyageNo) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/get/by-voyage/' + voyageNo)
  },
  listAllVesselParticular(pageNo, pageSize) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/list?size=' + pageSize + '&page=' + pageNo)
  },

  getAllVesselsV2(pageSize,pageNo) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel-data/all?size=' + pageSize + '&page=' + pageNo)


  },

  saveVesselsV2(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/vessel-data/',data)
  },

  getVesselV2DatabYiMO(imo){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel-data/imo/'+ imo);
  },

  // filterVessels(params){
  //   return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/all/search' + params)
  // },

  listAllVesselParticularNoPaging() {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/list/all');
  },


  listOrSearchAllVessels(pageNo, pageSize, searchQuery) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/search?size=' + pageSize + '&page=' + pageNo + '&q=' + searchQuery)

  },

  searchVesselParticulars(params) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/all/search' + params)

  },


  cancelVesselNomination(id) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/vessel/cancel/' + id)
  },

  closeMarineVessel(id) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/vessel/execute/' + id)
  },

  submitToTESWS(id) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/submit-tesws/' + id)
  },

  // fda
  allFDAccount(pageNo,size){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/final-disbursement/all/pageable');
  },

  // *** Disbursement Controller ***


  allDisbursementAccount(pageNo,size){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/sub/list/?' + 'page=' + pageNo + '&size=' + size);
  },



  addDisbursementAccount(data) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/disbursement/account/add',
      data,
      {
        headers: {

          'Content-Type': 'application/json'

        }

      })

  },
  getDisbursementAccountById(accountId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/account/id/' + accountId)
  },

  getDisbursementTransactions(referenceNo) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/principal/transaction/by-reference/' + referenceNo)
  },


  allRequestedDeliveryOrders() {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/request/all/pageable')
  },

  requestDeliveryOrder(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/delivery-oder/request/add', data)
  },

  getSingleDeliveryOrderRequest(data) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/request/get/' + data)
  },

  myDeliveryOrders() {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/request/all/pageable')
  },
  customerDeliveryOrder () {

      return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/request/get/customer/');
  },

  // Disbursement controller

  getDisbursementAccountByInvoiceNo(invoiceNo) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/account/invoice-no/' + invoiceNo)
  },
  listAllDisbursementAccount(pageNo, pageSize) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/account/list?size=' + pageSize + '&page=' + pageNo)
  },

  getDisbursementAccountPDF(accountId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/account/pdf/' + accountId,
      {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob'
      })
  },

  getBillOfLadingPDF(billOfLadingId) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/bill-of-ladings/print/' + billOfLadingId,
      {
        headers: {
          'Content-Type': 'application/octet-stream'
          // 'Content-Disposition':'attachment'
        },
        responseType: 'blob'
      })
  },

  cancelBillOfLadingCharge(billOfLadingId, reason) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/bl-charge/cancel/' + billOfLadingId + '?cancelReason=' + reason,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },

  updateDisbursementAccount(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/disbursement/account/update',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  addDisbursementInvoice(data) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/disbursement/invoice/add?' + `subDisbursementId=${data.subDisbursementId}` ,
      data,
      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  getDisbursementAccountByVesselId(vesselId) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/account/vessel/' + vesselId)

  },

  deleteDisbursementInvoiceByInvoiceId(invoiceId) {

    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/disbursement/invoice/delete/' + invoiceId)

  },

  /* Disbursement Item Controller*/

  deleteFinalDisbursementInvoiceItemById(itemId) {

    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/disbursement/final/item/delete/' + itemId)

  },

  deleteDisbursementInvoiceItemById(itemId) {

    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/disbursement/item/delete/' + itemId)

  },

  updateDisbursementInvoiceItem(data) {

    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/disbursement/item/update',
      data,

      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  getShippingFeeProducts () {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/ship-fee/product/list')

  },

  addShippingFeeProduct (data) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/ship-fee/product/add',
    data,

    {

      headers: {

        'Content-Type': 'application/json'

      }

    })

  },

  editShippingFeeProduct (data) {

    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/ship-fee/product/update',
    data,

    {

      headers: {

        'Content-Type': 'application/json'

      }

    })

  },

  getShippingFeeRoutes () {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/ship-fee/route/list')

  },

  addShippingFeeRoute (data) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/ship-fee/route/add',
    data,

    {

      headers: {

        'Content-Type': 'application/json'

      }

    })

  },

  editShippingFeeRoute (data) {

    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/ship-fee/route/update',
    data,

    {

      headers: {

        'Content-Type': 'application/json'

      }

    })

  },

  getShippingFeeRate (productId, routeId) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/ship-fee/rate/' + productId + '/' + routeId)

  },

  addShippingFeeRate (data) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/ship-fee/rate/add',
    data,

    {

      headers: {

        'Content-Type': 'application/json'

      }

    })

  },

  editShippingFeeRate (data) {

    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/ship-fee/rate/update',
    data,

    {

      headers: {

        'Content-Type': 'application/json'

      }

    })

  },

  // SUB-Disbursement Controller
  getSubDisbursementAccountByInvoiceNo(invoiceNo) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/sub/invoice/' + invoiceNo)
  },

  approveSubDisbursementAccount(id) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/sub/approve/' + id)

  },

  approveFDA(id) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/final-disbursement/sub/approve/' + id)

  },

  getSubDisbursementAccountById(accountId) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/sub/' + accountId)

  },

  getFinalDisbursementAccountById(accountId) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/final-disbursement/sub/' + accountId)

  },

  listAllDisbursementAccountsByVesselId(vesselId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/sub/list/' + vesselId)
  },

  getSubDisbursementAccountPDF(accountId) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/sub/pdf/' + accountId,
      {
        headers: {

          'Content-Type': 'application/octet-stream'

        },

        responseType: 'blob'
      })

  },

  getFdaAccountPDF(accountId) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/final-disbursement/sub/pdf/' + accountId,
      {
        headers: {

          'Content-Type': 'application/octet-stream'

        },

        responseType: 'blob'
      })

  },

  getAllDisbursementServiceCategories () {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/service/category/list/all')

  },

  addSubDisbursementAccount(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/disbursement/sub/add',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  addSubDisbursementAccountDeposit(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/disbursement/sub/deposit',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  deleteSubDisbursementInvoiceByAccountId(accountId) {
    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/disbursement/sub/delete/' + accountId)
  },

  // *** Disbursement Invoice Controller ***

  addDisbursementInvoicePayment(data) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/disbursement/payment/add',
      data,
      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  filterShippingAgencyServiceProvider (params) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/service/provider/all/search' + params)

  },

  getAllShippingAgencyServiceProvider () {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/service/provider/all/list')

  },

  addShippingAgencyServiceProvider (data) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/service/provider/add',
      data,
      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  updateShippingAgencyServiceProvider (data) {

    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/service/provider/update',
      data,
      {

        headers: {

          'Content-Type': 'application/json'

        }

      })

  },

  updateDisbursementInvoicePayment(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/disbursement/payment/update',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  approveDisbursementInvoicePaymentRequest(paymentId) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/payment/approve/' + paymentId)

  },

  getDisbursementInvoicePaymentById(paymentId) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/payment/id/' + paymentId)

  },

  getDisbursementInvoicePaymentRequests(pageNo, pageSize) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/payment/list?size=' + pageSize + '&page=' + pageNo)

  },

  listAllDisbursementInvoicePaymentByInvoiceNo(invoiceNo) {

    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/payment/list/' + invoiceNo)

  },

  getDisbursementInvoicePaymentByReference(referenceNo) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/payment/reference/' + referenceNo)
  },

  //  *** Delivery Order Controller***

  saveDeliveryOrder(data, requestId) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/delivery-oder/add?requestId=' + requestId,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  getDeliveryOrder(deliveryOrderId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/get/' + deliveryOrderId)
  },

  getDeliveryOderPDF(deliveryOrderId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/print-do/' + deliveryOrderId,
      {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob'
      })
  },

  setDeliveryOrderAsCharged(doId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/charged/' + doId)
  },

  getAllDeliveryOrdersByStatusPageable(pageNo, pageSize, status) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/all/status/pageable?size=' + pageSize + '&page=' + pageNo + '&status=' + status)
  },

  searchDeliveryOrders(doNumber) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/search/' + doNumber)
  },

  updateDeliveryOrder(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/delivery-oder/update',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  updateStatusDeliveryOder(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/delivery-oder/status/update', data)
  },
  cancelDeliveryOrder(DoId,cancelReason){
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/delivery-oder/cancel/'+ DoId + '?cancelReason=' + cancelReason)
  },

  getAllDeliveryOrders(pageNo, pageSize) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/all/pageable?size=' + pageSize + '&page=' + pageNo)
  },

  saveDeliveryOrderCharge(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/delivery-oder/charge/add',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  updateDeliveryOrderCharge(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/delivery-oder/charge/update',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  getDeliveryOrderCharge(chargeId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/get/charges/' + chargeId)
  },

  //  *** Manifest Controller ***

  getApprovedManifestByCallId(callId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + ' /manifest/approved' + callId)
  },
  getManifestByDocumentId(documentId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/manifest/id/' + documentId)
  },
  getManifestByVoyageNo(voyageNo) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/manifest/all/voyage/' + voyageNo)
  },

  // *** CF Agent Controller ***

  saveCfAgent(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/cf-agent/add',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  updateCFAgent(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/cf-agent/update',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  deleteCFAgent(agentId) {
    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/cf-agent/delete/' + agentId)
  },

  getCfAgentByAgentId(agentId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/cf-agent/get/' + agentId)
  },
  getCfAgentByAgentName(agentName) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/cf-agent/search/' + agentName)
  },

  getAllCfAgents(pageSize, pageNo) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/cf-agent/all?size=' + pageSize + '&page=' + pageNo)
  },

  getAllCfAgentsNonPegeable() {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/cf-agent/all')
  },

  //  *** Outward Activities Controller ***

  addOutwardActivity(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/outward-activities',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  updateOutwardActivity(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/outward-activities',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  getOutwardActivity(activityId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/outward-activities/' + activityId)
  },
  deleteOutwardActivity(activityId) {
    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/outward-activities/' + activityId)
  },
  getOutwardActivityPageable(pageNo, pageSize) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/outward-activities/all?size=' + pageSize + '&page=' + pageNo)
  },

  // *** Shipping Order Goods Controller ***

  addCargoToShippingOrder(cargo) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/shipping-order-goods',
      cargo,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  updateCargoToShippingOrder(cargo) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/shipping-order-goods',
      cargo,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  deleteCargoToShippingOrder(cargoId) {
    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/shipping-order-goods/' + cargoId,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  },
  getCargoforshippingOrder(shippingOrderId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/shipping-order-goods/shipping-order/' + shippingOrderId)
  },
  deleteCargoInShippingOrder(shippingOrderGoodId) {
    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/shipping-order-goods/' + shippingOrderGoodId)
  },
  getShippingOrderGoodsById(shippingOrderGoodsId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/shipping-order-goods/' + shippingOrderGoodsId)
  },

  // *** ***  Shipping Order Activity Assignment Controller  *** ***

  assignShippingOrderCargoActivity(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/booking-activity-assignments',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  updateShippingOrderCargoActivity(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/booking-activity-assignments/', data)
  },
  getShippingOderGoodActivities(cargoId, shippingOrderId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/booking-activity-assignments/shipping-order-good/' + shippingOrderId + '/' + cargoId)
  },
  deleteActivity(activityId) {
    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/booking-activity-assignments/' + activityId)
  },
  getShippingOrderActivityAssignmentByActivityAssignmentId(activityAssignmentID) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/booking-activity-assignments/' + activityAssignmentID)
  },

  getAllShippingOrderActivityAssignmentByShippingOrderId(shippingOrderId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/booking-activity-assignments/shipping-order/' + shippingOrderId)
  },
  getMyShippingOrderActivityAssignments() {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/booking-activity-assignments/my-assignments')
  },

  // *** *** Shipping Order Activity Assignment Progress Controller  *** ***
  addShippingOrderActivityAssignmentProgress(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/booking-activity-progresses',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  getShippingOrderActivityAssignmentProgressById(orderActivityId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/booking-activity-progresses/' + orderActivityId)
  },
  // get all progress of a specific cargo, activity and shipping order
  getShippingOrderProgressByShippingOrderIdAndActivityIdAndGoodId(shippingOrderId, activityId, goodsId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/booking-activity-progresses/shipping-order-progress/' + shippingOrderId + '/' + activityId + '/' + goodsId)
  },
  // get all progress based on a particular activity and shiping order
  getShippingOrderProgressByShippingOrderIdAndActivityId(shippingOrderId, activityId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/booking-activity-progresses/shipping-order-progress/' + shippingOrderId + '/' + activityId)
  },
  addShippingOrderActivityAttachment(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/booking-activity-progresses/shipping-order-attachment',
      data,
      {
        headers: {
          'Content-Type': undefined
        }
      })
  },
  updateShippingOrderActivityAttachment(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/booking-activity-progresses/shipping-order-attachment',
      data,
      {
        headers: {
          'Content-Type': undefined
        }
      })
  },
  listShippingOrderAttachmentByShippingOrderId(shippingOrderId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/booking-activity-progresses/shipping-order-attachments/' + shippingOrderId)
  },

  // payment methods -->
  getUnBilledShippingOrders() {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/booking-activity-progresses/unbilled-shipping-orders')
  },
  getShippingOrderChargesParticularsByOrderId(shippingOrderId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/booking-activity-progresses/shipping-order-charges-particular/' + shippingOrderId)
  },

  getCustomerShippingOrderRequestByBlNumber(blNumber) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-oder/request/all-by-bl/' + blNumber)
  },

  // *** *** *** ***  ->
  rejectShippingOrder(data, shippingOrderId) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/booking-activity-progresses/reject-bl-instruction/' + shippingOrderId, data)
  },

  containersForShippingOrder(shippingOrderId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/shipping-order-containers/shipping-order/' + shippingOrderId)
  },
  deleteContainers(shippingOrderId) {
    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/shipping-order-containers/' + shippingOrderId)
  },
  shiporderBooking(BOOKING) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/shipping-orders', BOOKING)
  },
  EditShippingOrder(BOOKING) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/shipping-orders', BOOKING)
  },
  getshippingOrders(pageSize, pageNo) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/shipping-orders/all/pageable?size=' + pageSize + '&page=' + pageNo)
  },

  getShippingOrderById(shippingOrderId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/shipping-orders/' + shippingOrderId)
  },

  assignVesselToShippingOrder(vesselParticularId, shippingOrderId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/shipping-orders/vessel/assign?shippingOrderId='+shippingOrderId+'&vesselId='+vesselParticularId)
  },

  assignContainerToshippingOder(ContainerDetails) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/shipping-order-containers/', ContainerDetails)
  },
  getContainerById(containerId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/shipping-order-containers/' + containerId)
  },
  editContainer(details) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/shipping-order-containers/', details)
  },
  getActivities() {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/outward-activities/all/')
  },

  //  *** *** Agency Charges Controller *** ***

  listAgencyCharge() {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/agency-charge/list/all')
  },

  addAgencyCharge(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/agency-charge/add',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  getAgencyChargesById(agencyChargeId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/agency-charge/get/' + agencyChargeId)
  },

  //  *** *** vessel-particular-activity-controller *** ***

  addVesselParticularActivity(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/vessel/activity/add',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  editVesselParticularActivity(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/vessel/activity/edit',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  getVesselParticularActivityById(id) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/activity/get/' + id)
  },
  listAllVesselParticularActivityByVesselPArticularId(vesselParticularId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/activity/list/' + vesselParticularId)
  },

  getSearchPageableAttachmenttypes(pageNo, pageSize, searchParameter) {
    if (searchParameter !== '') {
      return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/attachments/search?searchText=' + searchParameter + '&pageNo=' +
        pageNo + '&pageSize=' + pageSize)
    } else {
      return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/attachments/list-pageable?pageNo=' + pageNo + '&pageSize=' +
        pageSize)
    }
  },

  getPageableAttachmentTypes(pageNo, pageSize) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/attachments/list-pageable?pageNo=' + pageNo + '&pageSize=' +
      pageSize)
  },

  postAttachmentType(type) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/vessel/attachments/add', type)
  },

  getAttachmentTypeById(typeId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/attachments/get/' + typeId)
  },

  uploadSOF(dofDoc, vesselParticularId, attachmentTypeId, description) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/vessel/activity/attachment?vesselParticularId=' + vesselParticularId + '&attachmentTypeId=' + attachmentTypeId + '&description=' + description + '&verified=true',
      dofDoc,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  getAttachmentTypes() {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel/attachments/list')
  },

  getAllFiles(vesselParticularId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/vessel-particular/attachments/list-all/' + vesselParticularId)
  },

  updateAttachmentType(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/vessel/attachments/update', data)
  },

  deleteAttachment(attachmentTId) {
    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/vessel-particular/attachments/delete/' + attachmentTId)
  },

  getDeliveryOrderAttachmentsTypes(pageNo,pageSize) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + `/delivery-order-attachment-type/list-pageable?page=${pageNo}&size=${pageSize}`)
  },

  listDeliveryOrderAttachmentsTypes(pageNo,pageSize) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + `/delivery-order-attachment-type/list`)
  },

  addDeliveryOrderAttachmentsTypes(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/delivery-order-attachment-type/add/',data);
  },

  updateDeliveryOrderAttachmentsTypes(data) {
    return HTTP_AGENCY.put(AGENCY_SERVER_URL + '/delivery-order-attachment-type/update',data);
  },

  searchDeliveryOrderAttachmentsTypes(searchTerm) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + `/delivery-order-attachment-type/search?searchText=${searchTerm}&pageNo=0&pageSize=7`);
  },

  getDeliveryOrderAttachments(deliveryOrderRequestId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/delivery-order-attachment/list-all/' + deliveryOrderRequestId)
  },

  uploadDOattachment(dofDoc, attachmentTypeId, deliveryOrderRequestId, description) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/delivery-oder/request/attachment?attachmentTypeId=' + attachmentTypeId + '&description=' + description + '&deliveryOrderRequestId=' + deliveryOrderRequestId + '&verified=true',
      dofDoc,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  deleteDOAttachment(attachmentTId) {
    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/delivery-order-attachment/delete/' + attachmentTId)
  },

  cancelDeliveryOrderRequest(data) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/delivery-oder/request/status/update/', data)
  },


  printShippingOrder(shipping_order_id){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/shipping-orders/print-shipping-order/' + shipping_order_id,
      {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob'
      });

  },

  printBillofLading(bill_of_lading_id){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/bill-of-ladings/print/' + bill_of_lading_id,
      {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob'
      });

  },

  printCoOBillofLading(bill_of_lading_id){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/bill-of-ladings/print/coo/' + bill_of_lading_id,
      {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob'
      });

  },

  printCopyBillofLading(bill_of_lading_id){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/bill-of-ladings/print/copy/' + bill_of_lading_id,
      {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob'
      });

  },

  printDraftBillofLading(bill_of_lading_id){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/bill-of-ladings/print/draft/' + bill_of_lading_id,
      {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob'
      });

  },

  updateBillOfLadings(id, status) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/bill-of-ladings/update/' + id + '?blStatus=' + status);
  },

  generateBillofLading(shipping_order_id, billOfLading){
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/bill-of-ladings/generate/' + shipping_order_id, billOfLading);
  },

  gillBillOfLadingList(params){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/bill-of-ladings/all/search?' + params);
  },

  getBillOfLading(id) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/bill-of-ladings/' + id);
  },

  getBillOfLadingCharge(id) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/bl-charge/' + id);
  },

  generateVesselPerformance(params){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/reports/async/report?' + params);

  },

  getVesselPerformanceReports(pageNo,pageSize){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/reports/all?' + 'page=' + pageNo + '&size=' + pageSize);
  },

  addBillOfLadingCharge(billOfLadingCharge) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/bl-charge/create', billOfLadingCharge);
  },

  getBillOfLadingCharges(){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/bl-charge/all');
  },

  getBillOfLadingChargeById(billOfLadingId){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/bl-charge/' + billOfLadingId);

  },
  getDisbursementInvoiceByInvoiceId(invoiceId){
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/invoice/' + invoiceId);
  },
  getDisbursementPaymentInvoiceByInvoiceId(invoiceId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/payment/invoice/' + invoiceId);
  },

  deleteDisbursementPaymentAttachment(attachmentId) {
    return HTTP_AGENCY.delete(AGENCY_SERVER_URL + '/disbursement/attachment/delete/' + attachmentId);
  },

  getDisbursementPaymentAttachmentsByPaymentId(disbursementPaymentId) {
    return HTTP_AGENCY.get(AGENCY_SERVER_URL + '/disbursement/attachment/list-all/' + disbursementPaymentId);
  },

  uploadPDAPaymentAttachment(pdaDoc, disbursementInvoicePaymentId, description) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/disbursement/attachment/add?disbursementInvoicePaymentId='
      + disbursementInvoicePaymentId + '&description=' + description, pdaDoc,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  uploadRefundAttachment(pdaDoc, disbursementRefundId, description) {
    return HTTP_AGENCY.post(AGENCY_SERVER_URL + '/disbursement/refund/attachment/add?disbursementRefundId='
      + disbursementRefundId + '&description=' + description, pdaDoc,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
}
