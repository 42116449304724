import TALLYAPI from '../../../api/tally'

export default {
  namespaced: true,
  state: {

    requestId: '',
    tallyEventCodes: [],
    myFirstLoader: false,
    noDataFound: false,
    SuccessAlert: false,
    ErrorMessage: '',
    ErrorAlert: false,
    SuccessMessage: '',
    myLoader: false,
    pageNo: '',
    pageSize:'',
    saveDetails: {}

  },
  mutations:{
    REQUEST_ID: (state, requestId) => state.requestId = requestId,

    TALLY_EVENT_CODES: (state, tallyEventCodes) => state.tallyEventCodes = tallyEventCodes,

    FIRST_LOADER: (state, myFirstLoader) => state.myFirstLoader = myFirstLoader,

    NO_DATA_FOUND: (state, noDataFound) => state.noDataFound = noDataFound,

    ERROR_MESSAGE: (state, ErrorMessage) => state.ErrorMessage = ErrorMessage,

    SUCCESS_MESSAGE: (state, SuccessMessage) => state.SuccessMessage = SuccessMessage,

    ERROR_ALERT: (state, ErrorAlert) => state.ErrorAlert = ErrorAlert,

    SUCCESS_ALERT: (state, SuccessAlert) => state.SuccessAlert = SuccessAlert,

    MY_LOADER: (state, myLoader) => state.myLoader = myLoader,

    PAGE_NO: (state, pageNo) => state.pageNo = pageNo,

    PAGE_SIZE: (state, pageSize) => state.pageSize = pageSize,

    SAVE_DETAILS: (state, saveDetails) => state.saveDetails = saveDetails,

  },
  actions:{
    requestid: ({ state, commit }, payload) => commit('REQUEST_ID', payload),
    pageSizing: ({ state, commit }, payload) => commit('PAGE_SIZE', payload),
    pageNumbering: ({state, commit}, payload) => commit('PAGE_NO', payload),
    savingDetails: ({ state, commit }, payload) => commit('SAVE_DETAILS', payload),

    getPageableTallyEventCodes({commit, getters}){
      return new Promise((resolve, reject)=> {
        commit('FIRST_LOADER', true)
        commit('TALLY_EVENT_CODES', []);

        TALLYAPI.getTallyEventsPageable(getters.getPageNo, getters.getPageSize).then(
          response =>{
            if (response.data.status){
              if (Object.keys(response.data.data).length > 0) {
                commit('TALLY_EVENT_CODES', response.data.data)
                commit('FIRST_LOADER', false)
                commit('NO_DATA_FOUND', false)
              } else {
                commit('NO_DATA_FOUND', true)
                commit('FIRST_LOADER', false)
              }
            }else{
              commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
              commit('SUCCESS_MESSAGE', '')
              commit('SUCCESS_ALERT', false)
              commit('ERROR_ALERT', true)
              commit('FIRST_LOADER', false)
              commit('NO_DATA_FOUND', true)

            }
            resolve()
          }
        ).catch(e =>{
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator')
          commit('SUCCESS_MESSAGE', '')
          commit('SUCCESS_ALERT', false)
          commit('ERROR_ALERT', true)
          commit('FIRST_LOADER', false)
          commit('NO_DATA_FOUND', true)
          reject(e)
        })
      })
    },
    addTallyEventCode ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        commit('TALLY_EVENT_CODES', []);

        TALLYAPI.addTallyEvent(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('FIRST_LOADER', false);
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
              } else {
                commit('SUCCESS_ALERT', false);
                commit('ERROR_ALERT', true);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('FIRST_LOADER', false);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    updateTallyEventCode ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        TALLYAPI.updateTallyEvent(getters.getSaveDetails).then(
          response => {
            if (response.data.status) {
              if (Object.keys(response.data.data).length > 0) {
                commit('FIRST_LOADER', false);
                commit('SUCCESS_ALERT', true);
                commit('ERROR_ALERT', false);
              } else {
                commit('SUCCESS_ALERT', false);
                commit('ERROR_ALERT', true);
                commit('FIRST_LOADER', false);
              }
            } else {
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('FIRST_LOADER', false);
            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
    deleteTallyEventCode ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        commit('FIRST_LOADER', true);
        TALLYAPI.deleteTallyEvent(getters.getRequestId).then(
          response => {
            if (response.data.status) {
              commit('FIRST_LOADER', false);
              commit('SUCCESS_ALERT', true);
              commit('ERROR_ALERT', false);
            } else {
              commit('SUCCESS_ALERT', false);
              commit('ERROR_ALERT', true);
              commit('FIRST_LOADER', false);

            }

            resolve();
          }
        ).catch(e => {
          commit('ERROR_MESSAGE', ', Requests Not loaded, please contact system administrator');
          commit('SUCCESS_MESSAGE', '');
          commit('SUCCESS_ALERT', false);
          commit('ERROR_ALERT', true);
          commit('FIRST_LOADER', false);

          reject(e);
        });
      });
    },
  },
  getters:{
    getPageNo: state => state.pageNo,
    getPageSize: state => state.pageSize,
    getTallyEventCodes: state => state.tallyEventCodes,
    getMyFirstLoader: state => state.myFirstLoader,
    getMyLoader: state => state.myLoader,
    getErrorAlert: state => state.ErrorAlert,
    getSuccessAlert: state => state.SuccessAlert,
    getSuccessMessage: state => state.SuccessMessage,
    getErrorMessage: state => state.ErrorMessage,
    getNoDataFound: state => state.noDataFound,
    getSaveDetails: state => state.saveDetails,
    getRequestId: state => state.requestId,

  }
}
